import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BuildingCreationFormCast } from '../../BuildingCreationProvider';
import DataRow from '../Components/DataRow';
import RegulatoryConstraint from '../Components/RegulatoryConstraint';

type Props = {
  completedBuilding: BuildingCreationFormCast;
};

const AdditionalDataReview = ({ completedBuilding }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <DataRow label={t('General_RegulatoryConstraints')}>
        <Stack spacing={1} alignItems="end">
          {completedBuilding.information.additionalInformation.monumentProtection && (
            <RegulatoryConstraint
              title={t('General_MonumentProtection')}
              tooltipBody={t('Education_MonumentProtectionBody')}
            />
          )}
          {completedBuilding.information.additionalInformation.milieuProtection && (
            <RegulatoryConstraint
              title={t('General_MilieuProtection')}
              tooltipBody={t('Education_MilieuProtectionBody')}
            />
          )}
          {completedBuilding.information.additionalInformation.heritageDistrict && (
            <RegulatoryConstraint
              title={t('General_HeritageDistrict')}
              tooltipBody={t('Education_HeritageDistrictBody')}
            />
          )}
          {completedBuilding.information.additionalInformation.leasehold && (
            <RegulatoryConstraint title={t('General_Leasehold')} tooltipBody={t('Education_LeaseholdBody')} />
          )}
        </Stack>
      </DataRow>
    </Stack>
  );
};

export default AdditionalDataReview;
