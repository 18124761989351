import { useEffect } from 'react';
import { unstable_useBlocker } from 'react-router-dom';

const useBlockOnBack = (blockClose: boolean, handleCloseDialog: () => void) => {
  const blocker = unstable_useBlocker(({ historyAction }) => {
    if (historyAction === 'POP') {
      return blockClose;
    }

    return false;
  });

  useEffect(() => {
    if (blocker.state === 'blocked') {
      handleCloseDialog();
    }
  }, [blocker.state, handleCloseDialog]);

  return blocker;
};

export default useBlockOnBack;
