import { Box } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import InfoTooltip from '../InfoTooltip';

type TabLabelWithTooltipProps = {
  label: string;
  tooltipText?: string;
  link: React.ReactElement;
};

const TabLabelWithTooltip: React.FC<TabLabelWithTooltipProps> = ({ label, tooltipText, link }) => {
  return (
    <Box display="flex" alignItems="center">
      {label}
      {tooltipText && (
        <InfoTooltip
          text={
            <Trans
              i18nKey={tooltipText}
              components={{
                bold: <strong />,
                articleLink: link,
              }}
            />
          }
          maxwidth={600}
          sx={{ pr: 0 }}
        />
      )}
    </Box>
  );
};

export default TabLabelWithTooltip;
