import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import { ActionPlanningActionPlanActionPlanFragment } from '@predium/client-graphql';
import { TARGET_PATH_FIRST_YEAR, TARGET_PATH_LAST_YEAR, pathValueAtYear } from '@predium/client-lookup';
import { ensureDefined, getOnePointFiveTargetPath, getStrandingYearRanges } from '@predium/utils';
import uniq from 'lodash/uniq';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LegendKeys,
  PathGraph,
  PathGraphLegend,
  PathGraphSeries,
} from '../../../../components/data-visialization/PathGraph';
import { ActionPlanMetricsData } from '../../../../pages/ActionPlanning/ActionPlanningActionPlan';
import { useLanguage } from '../../../../provider/LanguageProvider';
import { getFilename } from '../../../../utils/getFilename';
import { getActionIcon } from '../../../../utils/icons';
import {
  determineStartYear,
  filterSeriesByStartYear,
  getActionType,
  getIconBase64ByActionType,
} from '../ActionPlan.utils';

type Props = {
  actionPlan: ActionPlanningActionPlanActionPlanFragment;
  metricsData: ActionPlanMetricsData;
  handleActionSelection: (id: number) => void;
  setHighlightCurrentAction: (id: number | null) => void;
};

export function ActionPlanPathGraph({
  actionPlan,
  handleActionSelection,
  setHighlightCurrentAction,
  metricsData,
}: Props) {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const theme = useTheme();

  const { building } = actionPlan;
  const { actions } = metricsData;

  //calculate series for actionPlan
  const actionPlanSeries: [number, number][] = [];
  const actualBuildingSeries: [number, number][] = [];
  const pointsAnnotations: PointAnnotations[] = [];

  const [svgIconsAsBase64, setSvgIconsAsBase64] = useState<Record<string, string | null>>({});

  const buildingModel = ensureDefined(building.active_building_model);
  const co2_emission_path = buildingModel.energy_paths.map((energy_path) => energy_path.crrem_co2_emissions ?? 0);

  const { target_paths } = building;

  let currentCo2EmissionData: number[] = co2_emission_path; //initialize with building co2 emission path

  const constructionYear = building.year_constructed;
  const pathStartYear = TARGET_PATH_FIRST_YEAR;

  if (actions) {
    //temp variables for calculating series

    for (let year = pathStartYear; year <= TARGET_PATH_LAST_YEAR; year++) {
      let newActions = actions.filter((action) => new Date(action.implementation_to).getFullYear() === year);
      //action for year found
      let pathValueAtYearVar = pathValueAtYear(currentCo2EmissionData, year);

      if (newActions.length > 0) {
        for (let actionIndex = 0; actionIndex < newActions.length; actionIndex++) {
          const currentAction = newActions[actionIndex];
          currentCo2EmissionData = currentAction.building_models[0].energy_paths.map(
            (path) => path.crrem_co2_emissions ?? 0,
          );
          pathValueAtYearVar = pathValueAtYear(currentCo2EmissionData, year);

          const lastAction = newActions[newActions.length - 1];
          const lastActionYear = new Date(lastAction.implementation_to).getFullYear();
          const pointerEmissionData = lastAction.building_models[0].energy_paths.map(
            (path) => path.crrem_co2_emissions,
          );
          const valueForPointer = pathValueAtYear(pointerEmissionData, lastActionYear);

          const actionType = getActionType(newActions[actionIndex]);

          pointsAnnotations.push({
            x: year,
            y: valueForPointer,
            id: newActions[actionIndex].id, //action id
            marker: {
              offsetX: actionIndex * 30,
              size: 17,
              cssClass: 'action-marker',
            },

            image: {
              path: actionType ? svgIconsAsBase64[actionType] ?? getActionIcon(actionType) : '',
              offsetX: actionIndex * 30,
            },
            click: function (e: any) {
              handleActionSelection(e.id);
            },
            mouseEnter: function (e: any) {
              setHighlightCurrentAction(e.id);
            },
            mouseLeave: function () {
              setHighlightCurrentAction(null);
            },
          });
        }
        //set new data based on the last action
        const lastAction = ensureDefined(newActions.at(-1));
        currentCo2EmissionData = lastAction.building_models[0].energy_paths.map(
          (path) => path.crrem_co2_emissions ?? 0,
        );

        //push new action to series
        actionPlanSeries.push([year, pathValueAtYearVar]);
      } else {
        //push old action to series
        actionPlanSeries.push([year, pathValueAtYearVar]);
      }

      //push business as usual to series
      actualBuildingSeries.push([year, pathValueAtYear(co2_emission_path, year)]);
    }
  }

  const co2EmissionPaths = actionPlanSeries.map(([year, value]) => ({ year, value }));
  const targetPath = getOnePointFiveTargetPath(target_paths);
  const buildingSeries = actualBuildingSeries.map(([year, value]) => ({ year, value }));

  const strandingYearsAfterActions = getStrandingYearRanges({
    targetPath,
    path: co2EmissionPaths,
    constructionYear,
  });

  const strandingYearsBeforeActions = getStrandingYearRanges({
    targetPath,
    path: buildingSeries,
    constructionYear,
  });

  const graphStartYear: number = determineStartYear(constructionYear);

  const strandingYearBeforeActions = strandingYearsBeforeActions[0]?.startYear ?? 2050;

  const strandingBeforeYValue =
    actualBuildingSeries.length > 0
      ? //@ts-ignore
        actualBuildingSeries.find(([year]) => year === strandingYearBeforeActions)[1]
      : null;

  //if there is only one stranding year after actions, we don't need to before actions markers will be overlapped with
  // after actions marker
  if (strandingYearsAfterActions.length !== 1 || actions.length === 0) {
    pointsAnnotations.push({
      x: strandingYearBeforeActions,
      y: strandingBeforeYValue,
      marker: {
        size: 6,
        strokeWidth: 8,
        fillColor: theme.palette.error.main,
        offsetX: 0,
        strokeColor: '',
      },
    });
  }

  if (actions.length > 0) {
    strandingYearsAfterActions.forEach(({ startYear }, index) => {
      const isStrandingInStartYear = startYear === graphStartYear;
      const strandingAfterYValue =
        //@ts-ignore
        actionPlanSeries.length > 0 ? actionPlanSeries.find(([year]) => year === startYear)[1] : null;

      //if there is only one stranding year after actions, we don't need to show the stranding before actions
      if (index !== 0 || strandingYearsAfterActions.length === 1) {
        pointsAnnotations.push({
          x: startYear,
          y: strandingAfterYValue,
          marker: {
            size: 10,
            strokeColor: theme.palette.error.light,
            strokeWidth: 8,
            fillColor: theme.palette.error.main,
            offsetX: strandingYearBeforeActions === graphStartYear || isStrandingInStartYear ? 5 : 0,
          },
        });
      }
    });
  }

  const annotations: ApexAnnotations = {
    points: pointsAnnotations ?? [],
    xaxis: strandingYearsAfterActions.map((strandingYear: { startYear: number; endYear: number }) => ({
      x: strandingYear.startYear,
      x2: strandingYear.endYear,
      fillColor: theme.palette.error.lighter,
    })),
  };

  const actionYears = [...new Set(actions.map((action) => new Date(action.implementation_to).getFullYear()))];
  const strandingStartYears = strandingYearsAfterActions.map(({ startYear }) => startYear);
  const strandingEndYears = strandingYearsAfterActions.map(({ endYear }) => endYear);

  const actionYearsMinusOne = actionYears.map((year) => year - 1); //to get the year before the action and to set the
  // type of line to stepline
  const allYears = [...actionYears, ...actionYearsMinusOne, ...strandingStartYears, ...strandingEndYears];
  const years = [pathStartYear, ...new Set(allYears), TARGET_PATH_LAST_YEAR].sort((a, b) => a - b);

  const actionPlanDataSeries = filterSeriesByStartYear(actionPlanSeries, graphStartYear);

  /**
   * Generates a series of data for a multiline series data based on the years array.
   * Each series represents a range of years from the years array.
   * If the year of a data point in actionPlanSeries is within the range of the current series, the data point is
   * included in the series. Otherwise, the data point is set to null. line is split into multiple lines to set the
   * different color and stroke curve The color of each series is determined based on whether there are any actions and
   * whether the range of the series overlaps with any stranding year. The function returns an array of series,
   * excluding the last series which is null.
   *
   * @returns {PathGraphSeries[]} An array of series for a multiline graph.
   */
  const generateMultilineSeries = () => {
    return years
      .map((year, index): PathGraphSeries => {
        return {
          name: actions.length > 0 ? t('General_ObjectWithActions') : t('General_Object'),
          data: actionPlanDataSeries.map(([y, value]) => (y >= year && y <= years[index + 1] ? [y, value] : [y, null])),
          color: strandingYearsAfterActions.find(
            (strandingYear) => year >= strandingYear.startYear && year + 1 <= strandingYear.endYear,
          )
            ? theme.palette.error.main
            : theme.palette.common.black,
          zIndex: 2,
        };
      })
      .filter(Boolean);
  };

  let series: PathGraphSeries[] = generateMultilineSeries();

  const actualPathSeries = filterSeriesByStartYear(actualBuildingSeries, graphStartYear);
  const targetPathSeries = filterSeriesByStartYear(
    targetPath.map((p) => [p.year, p.value]),
    graphStartYear,
  );

  series.push({
    data: actualPathSeries,
    color: theme.palette.grey[500],
    name: t('General_ObjectWithoutActions'),
    zIndex: 1,
  });

  series.push({
    data: targetPathSeries,
    color: theme.palette.energyEfficiencyClassColors.AA.light,
    name: t('General_1_5CTargetPath'),
  });

  const dataToExport = {
    actionPlanPath: actionPlanDataSeries,
    actualPath: actualPathSeries,
    targetPath: targetPathSeries,
  };

  const seriesLength = series.length;
  const dashArray = new Array(seriesLength - 2).fill(0).concat([4, 4]);
  const width = new Array(seriesLength - 2).fill(4).concat([2, 2]);

  const unit = 'kg CO₂e/m²a';

  const actionsExist = actions?.length > 0;

  const legends: { key: LegendKeys; color: string; text: string }[] = [
    { key: 'strandingWithoutActions', color: theme.palette.error.main, text: t('General_StrandingBeforeActions') },
    { key: 'StrandingCRREMPath1_5', color: theme.palette.error.main, text: t('General_StrandingCRREMPath1_5') },
    { key: 'strandingPeriod', color: theme.palette.error.lighter, text: t('General_StrandingPeriod') },
    {
      key: 'object',
      color: theme.palette.common.black,
      text: actionsExist ? t('General_ObjectWithActions') : t('General_Object'),
    },
    {
      key: '1_5CTargetPath',
      color: theme.palette.energyEfficiencyClassColors.AA.light,
      text: t('General_1_5CTargetPath'),
    },
  ];

  useEffect(() => {
    const fetchSvgIconsAsBase64 = async () => {
      const actionTypes = uniq(
        actions
          .map((action) => getActionType(action))
          .filter((actionType) => actionType && !svgIconsAsBase64[actionType]),
      ) as string[];

      const uris = await Promise.all(
        actionTypes.map(async (actionType) => {
          const uri = await getIconBase64ByActionType(actionType).catch(() => null);

          return [actionType, uri];
        }),
      );

      setSvgIconsAsBase64((prev) => ({ ...prev, ...Object.fromEntries(uris) }));
    };

    fetchSvgIconsAsBase64();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  if (actionsExist) {
    legends.splice(4, 0, {
      key: 'actualPath',
      color: theme.palette.grey[500],
      text: t('General_ObjectWithoutActions'),
    });
  }

  return (
    <Card
      sx={{
        p: 3,
        '.apexcharts-point-annotations image': {
          cursor: 'pointer',
        },
        '.apexcharts-marker': {
          display: 'none', //using prop from apexchart doesn't work for some reason so we hide it here
        },
      }}
    >
      <PathGraph
        filename={getFilename([t('General_CO2Path'), actionPlan.scenario.name, building.address.street], language)}
        xAxisLabel={t('General_CO2Intensity') + ` (${unit})`}
        series={series}
        annotations={annotations}
        options={{
          stroke: {
            curve: 'straight',
            dashArray: dashArray,
            width: width,
          },
          legend: {
            show: false,
          },
        }}
        unit={unit}
        dataToExport={dataToExport}
      />
      <PathGraphLegend legends={legends} />
    </Card>
  );
}
