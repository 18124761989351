import { Skeleton, Stack, TableRow } from '@mui/material';

import { TableCell } from '@mui/material';

export default function ActionListSkeletons() {
  return (
    <>
      {[...Array(3)].map((_, index) => (
        <TableRow key={index}>
          <TableCell>
            <Stack direction="row" alignItems="center" gap={2}>
              <Skeleton variant="circular" width={20} height={20} />
              <Stack gap={0.5}>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={160} sx={{ opacity: 0.7 }} />
              </Stack>
            </Stack>
          </TableCell>
          <TableCell>
            <Stack alignItems="flex-start" gap={0.5}>
              <Skeleton variant="text" width={60} />
              <Skeleton variant="rectangular" width={45} height={20} sx={{ borderRadius: 1 }} />
            </Stack>
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" width={80} height={24} sx={{ borderRadius: 1 }} />
          </TableCell>
          <TableCell>
            <Stack alignItems="flex-start" gap={0.5}>
              <Skeleton variant="text" width={60} />
              <Skeleton variant="rectangular" width={45} height={20} sx={{ borderRadius: 1 }} />
            </Stack>
          </TableCell>
          <TableCell>
            <Stack direction="row" gap={2}>
              <Skeleton variant="text" width={60} />
              <Skeleton variant="rectangular" width={45} height={20} sx={{ borderRadius: 1 }} />
            </Stack>
          </TableCell>
          <TableCell>
            <Stack alignItems="flex-end">
              <Skeleton variant="text" width={100} />
            </Stack>
          </TableCell>
          <TableCell>
            <Stack alignItems="flex-end" gap={0.5}>
              <Skeleton variant="text" width={100} />
              <Skeleton variant="rectangular" width={45} height={20} sx={{ borderRadius: 1 }} />
            </Stack>
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={80} />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}
