import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Grid, MenuItem, Stack } from '@mui/material';
import { EditBuildingDataMutationVariables } from '@predium/client-graphql';
import { building_state_enum, data_source_type_enum, sub_building_class_enum } from '@predium/enums';
import { translateBuildingStateEnum } from '@predium/i18n/client';
import { ensureDefined } from '@predium/utils';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ICONS } from '../../../../assets/icons';
import { FormProvider, RHFNumberField, RHFSelect, RHFTextField } from '../../../../components/hook-form';
import RHFToggleButton from '../../../../components/hook-form/RHFToggleButton';
import Iconify from '../../../../components/Iconify';
import { DelayedLoading } from '../../../../components/Loading';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import PreDialog, { PreDialogBody } from '../../../../components/presentations/PreDialog/PreDialog';
import { WarningModal } from '../../../../components/WarningModal';
import { EDIT_BUILDING_DATA } from '../../../../graphql/DataCollection.mutations';
import { getPostalCodeValidation } from '../../../../utils/formUtils';
import useBuilding from '../Context/useBuilding';

type Props = {
  onDirtyChange: (isDirty: boolean) => void;
  onSubmit: (variables: EditBuildingDataMutationVariables) => void;
};

const BuildingEditingDialogContent = ({ onSubmit, onDirtyChange }: Props) => {
  const { building } = useBuilding();
  const { t } = useTranslation();

  const schema = yup.object({
    address: yup.object({
      street: yup.string().required(t('General_Required')),
      postal_code: getPostalCodeValidation(t)(building.address.country_id),
      city: yup.string().required(t('General_Required')),
    }),
    building: yup.object({
      building_state_id: yup.mixed<building_state_enum>().oneOf(Object.values(building_state_enum)).required(),
      monument_protection: yup.boolean().required(),
      leasehold: yup.boolean().required(),
      heritage_district: yup.boolean().required(),
      milieu_protection: yup.boolean().required(),
      customer_external_identifier: yup.string().nullable(true),
    }),
    sub_building: yup.object({
      units_residential: yup.number().required(),
      units_commercial: yup.number().required(),
      units_data_source: yup.mixed<data_source_type_enum>().oneOf(Object.values(data_source_type_enum)).required(),
    }),
  });

  const subBuildingClass = ensureDefined(building.sub_buildings[0].sub_building_class);

  const defaultValues = useMemo(
    () => ({
      address: {
        street: building.address.street,
        postal_code: building.address.postal_code,
        city: building.address.city,
      },
      building: {
        building_state_id: building.building_state_id,
        monument_protection: building.monument_protection,
        leasehold: building.leasehold,
        heritage_district: building.heritage_district,
        milieu_protection: building.milieu_protection,
        customer_external_identifier: building.customer_external_identifier,
      },
      sub_building: {
        units_residential: building.sub_buildings[0].units_residential,
        units_commercial: building.sub_buildings[0].units_commercial,
        units_data_source: building.sub_buildings[0].units_data_source,
      },
    }),
    [building],
  );

  const methods = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { formState, setValue, watch } = methods;
  const { isDirty } = formState;

  const handleSubmit = methods.handleSubmit((data) => {
    onSubmit({
      buildingId: building.id,
      buildingData: data.building,
      subBuildingId: building.sub_buildings[0].id,
      subBuildingData: data.sub_building,
      addressId: building.address.id,
      addressData: data.address,
    });
  });

  const unitsCommercial = watch('sub_building.units_commercial');
  const unitsResidential = watch('sub_building.units_residential');

  useEffect(() => {
    if (defaultValues.sub_building.units_data_source === data_source_type_enum.MANUAL) {
      return;
    }

    const didUnitsChange =
      unitsCommercial !== defaultValues.sub_building.units_commercial ||
      unitsResidential !== defaultValues.sub_building.units_residential;

    setValue(
      'sub_building.units_data_source',
      didUnitsChange ? data_source_type_enum.MANUAL : data_source_type_enum.APPROXIMATED,
    );
  }, [unitsCommercial, unitsResidential, setValue, defaultValues]);

  useEffect(() => {
    onDirtyChange(isDirty);
  }, [isDirty, onDirtyChange]);

  return (
    <PreDialogBody
      dialogtitle={t('DataCollectionEditBuilding_Title')}
      actions={
        <Stack direction="row" spacing={1}>
          <Button variant="contained" onClick={handleSubmit}>
            {t('General_Save')}
          </Button>
        </Stack>
      }
      content={
        <FormProvider methods={methods} onSubmit={handleSubmit}>
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <Alert
                severity="info"
                sx={{
                  color: 'text.primary',
                  bgcolor: 'grey.200',
                  '.MuiAlert-icon': {
                    color: 'text.secondary',
                  },
                }}
              >
                {t('DataCollectionEditBuilding_Subtitle')}
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <RHFToggleButton
                name="building.building_state_id"
                leftValue={{
                  key: building_state_enum.ACQUISITION,
                  value: translateBuildingStateEnum(building_state_enum.ACQUISITION),
                }}
                rightValue={{
                  key: building_state_enum.INVENTORY,
                  value: translateBuildingStateEnum(building_state_enum.INVENTORY),
                }}
                size="medium"
              />
            </Grid>
            <Grid item xs={6}>
              <RHFTextField name="address.street" label={t('General_StreetCommaNumber')} />
            </Grid>
            <Grid item xs={3}>
              <RHFNumberField
                name="address.postal_code"
                label={t('General_PostalCode')}
                allowDecimals={false}
                allowLeadingZeros
                valueAsText
              />
            </Grid>
            <Grid item xs={3}>
              <RHFTextField name="address.city" label={t('General_City')} />
            </Grid>
            <Grid item xs={6}>
              <RHFTextField
                name="building.customer_external_identifier"
                label={t('General_CustomerExternalIdentifier')}
              />
            </Grid>
            <Grid item xs={6}>
              {subBuildingClass === sub_building_class_enum.COMMERCIAL && (
                <RHFNumberField
                  name="sub_building.units_commercial"
                  label={t('DataCollectionSubBuildingValues_UnitsCommercial')}
                />
              )}
              {subBuildingClass === sub_building_class_enum.RESIDENTIAL && (
                <RHFNumberField
                  name="sub_building.units_residential"
                  label={t('DataCollectionSubBuildingValues_UnitsResidential')}
                />
              )}
            </Grid>

            <Grid item xs={6}>
              <RHFSelect label={t('General_MonumentProtection')} name="building.monument_protection">
                <MenuItem value="true">{t('General_Yes')}</MenuItem>
                <MenuItem value="false">{t('General_No')}</MenuItem>
              </RHFSelect>
            </Grid>
            <Grid item xs={6}>
              <RHFSelect label={t('General_Leasehold')} name="building.leasehold">
                <MenuItem value="true">{t('General_Yes')}</MenuItem>
                <MenuItem value="false">{t('General_No')}</MenuItem>
              </RHFSelect>
            </Grid>
            <Grid item xs={6}>
              <RHFSelect label={t('General_HeritageDistrict')} name="building.heritage_district">
                <MenuItem value="true">{t('General_Yes')}</MenuItem>
                <MenuItem value="false">{t('General_No')}</MenuItem>
              </RHFSelect>
            </Grid>
            <Grid item xs={6}>
              <RHFSelect label={t('General_MilieuProtection')} name="building.milieu_protection">
                <MenuItem value="true">{t('General_Yes')}</MenuItem>
                <MenuItem value="false">{t('General_No')}</MenuItem>
              </RHFSelect>
            </Grid>
          </Grid>
        </FormProvider>
      }
    />
  );
};

const BuildingEditingDialog = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [openCancelEditDialog, setOpenCancelEditDialog] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { t } = useTranslation();

  const [mutateBuilding, { loading }] = useMutation(EDIT_BUILDING_DATA, {
    onCompleted(data) {
      const success = Boolean(
        data?.update_address_by_pk && data?.update_building_by_pk && data?.update_sub_building_by_pk,
      );

      if (!success) {
        enqueueSnackbar(t('General_BuildingUpdated-error'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        });
      } else {
        enqueueSnackbar(t('General_BuildingUpdated-success'), {
          variant: 'success',
          autoHideDuration: SnackbarTimeouts.Success,
        });
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
    setIsFormDirty(false);
    setOpenCancelEditDialog(false);
  };

  const handleCloseClick = () => {
    if (isFormDirty) {
      setOpenCancelEditDialog(true);
    } else {
      handleClose();
    }
  };

  const handleSubmit = (variables: EditBuildingDataMutationVariables) => {
    mutateBuilding({ variables });
    handleClose();
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)} startIcon={<Iconify icon={ICONS.EDIT_SQUARE} />}>
        {t('General_Edit')}
      </Button>
      <PreDialog open={open} onClose={handleCloseClick} showClose type="definedByChildren">
        <BuildingEditingDialogContent onSubmit={handleSubmit} onDirtyChange={setIsFormDirty} />
      </PreDialog>
      <WarningModal
        title={t('General_CancelEditModalTitle')}
        open={openCancelEditDialog}
        description={t('General_CancelEditModalDescription')}
        onAcknowledge={() => handleClose()}
        onClose={() => {
          setOpenCancelEditDialog(false);
        }}
        buttonText={t('General_Discard')}
        cancelText={t('General_Back')}
      />
      {loading && <DelayedLoading fullScreen />}
    </>
  );
};

export default BuildingEditingDialog;
