/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useSearchParams } from 'react-router-dom';
import { useEsgAnalysisFilterActions } from '../../../hooks/esg-hooks/useEsgAnalysisFilterActions';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import { searchParamsToFilters } from '../EsgAnalysisUtil';
import {
  EsgAnalysisFiltersKeys,
  EsgAnalysisFiltersSearchParams,
  FindEsgAnalysisFilterValue,
} from './EsgAnalysisFilters';

type TContext = {
  filters: EsgAnalysisFiltersSearchParams;
  setFilter: <Key extends EsgAnalysisFiltersKeys>(filter: Key, value: FindEsgAnalysisFilterValue<Key>) => void;
  replaceFilter: <Key extends EsgAnalysisFiltersKeys>(filter: Key, value: FindEsgAnalysisFilterValue<Key>[]) => void;
  resetFilter: (filter: EsgAnalysisFiltersKeys) => void;
  resetAllFilters: VoidFunction;
  resetFilters: (keysToDelete: EsgAnalysisFiltersKeys[]) => void;
  setFilters: (filters: EsgAnalysisFiltersSearchParams) => void;
  setIsError: (value: boolean) => void;
  isError: boolean;
};

const EsgAnalysisFilterPopupContext = createContext<TContext | null>(null);

function EsgAnalysisFilterPopupContextProvider({ children }: PropsWithChildren) {
  const [searchParams] = useSearchParams();
  const { filters: globalFilters } = useEsgAnalysisFilters();
  const [isError, setIsError] = useState(false);

  const [filters, setFilters] = useState<EsgAnalysisFiltersSearchParams>(globalFilters);

  useEffect(() => {
    setFilters(searchParamsToFilters(searchParams));
  }, [searchParams]);

  const { setFilter, replaceFilter, resetAllFilters, resetFilter, resetFilters } = useEsgAnalysisFilterActions(
    setFilters,
    filters,
  );

  return (
    <EsgAnalysisFilterPopupContext.Provider
      value={{
        filters,
        setFilter,
        replaceFilter,
        resetAllFilters,
        resetFilter,
        resetFilters,
        setFilters,
        setIsError,
        isError,
      }}
    >
      {children}
    </EsgAnalysisFilterPopupContext.Provider>
  );
}

const useEsgAnalysisPopupFilters = () => {
  const context = useContext(EsgAnalysisFilterPopupContext);

  if (!context) {
    throw new Error('useEsgAnalysisPopupFilters must be used within a EsgAnalysisFilterPopupContextProvider');
  }
  return context;
};

export { EsgAnalysisFilterPopupContext, EsgAnalysisFilterPopupContextProvider, useEsgAnalysisPopupFilters };
