import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import PreDialog, { PreDialogTitle } from '../../../../components/presentations/PreDialog/PreDialog';
import { useLanguage } from '../../../../provider/LanguageProvider';
import { InconsistentHeatingDemandDataAlert } from './BuildingAlerts';

type Props = {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
  heatingDemandSystem: number;
  heatingDemand: number;
  heatingDemandEnvelope: number;
};

export function HeatingDemandConflictDialog({
  open,
  onClose,
  onClick,
  heatingDemandSystem,
  heatingDemand,
  heatingDemandEnvelope,
}: Props) {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const systemDeviation = heatingDemandSystem / heatingDemand - 1;
  const hasSystemDeviation = Math.abs(systemDeviation) > 0.001;
  const envelopeDeviation = heatingDemandEnvelope / heatingDemand - 1;
  const hasEnvelopeDeviation = Math.abs(envelopeDeviation) > 0.001;

  const systemText = (
    <Trans
      i18nKey={'DataCollection_HeatingDemandConflictDialog_EnergyDeviation'}
      components={{ bold: <span style={{ fontWeight: 700 }} /> }}
      values={{
        deviation: localize.formatAsPercentage(systemDeviation * 100, {
          signDisplay: 'exceptZero',
          fractionDigits: 1,
        }),
      }}
    />
  );
  const envelopeText = (
    <Trans
      i18nKey={'DataCollection_HeatingDemandConflictDialog_EnergyDeviation'}
      components={{ bold: <span style={{ fontWeight: 700 }} /> }}
      values={{
        deviation: localize.formatAsPercentage(envelopeDeviation * 100, {
          signDisplay: 'exceptZero',
          fractionDigits: 1,
        }),
      }}
    />
  );

  const getDialogContent = () => {
    if (hasSystemDeviation && hasEnvelopeDeviation) {
      return (
        <>
          <Grid item xs={4}>
            <EnergyCard
              title={t('General_HeatingDemand')}
              subtitle={t('DataCollection_HeatingDemandConflictDialog_EnergyCertificate')}
              energy={heatingDemand}
            />
          </Grid>
          <Grid item xs={4}>
            <EnergyCard
              title={t('General_HeatingDemand')}
              subtitle={t('DataCollection_HeatingDemandConflictDialog_Technology')}
              energy={heatingDemandSystem}
              color="error"
              text={systemText}
            />
          </Grid>
          <Grid item xs={4}>
            <EnergyCard
              title={t('General_HeatingDemand')}
              subtitle={t('DataCollection_HeatingDemandConflictDialog_Envelope')}
              energy={heatingDemandEnvelope}
              color="error"
              text={envelopeText}
            />
          </Grid>
        </>
      );
    } else if (hasSystemDeviation) {
      return (
        <>
          <Grid item xs={6}>
            <EnergyCard
              title={t('General_HeatingDemand')}
              subtitle={t('DataCollection_HeatingDemandConflictDialog_EnergyCertificate')}
              energy={heatingDemand}
            />
          </Grid>
          <Grid item xs={6}>
            <EnergyCard
              title={t('General_HeatingDemand')}
              subtitle={t('DataCollection_HeatingDemandConflictDialog_Technology')}
              energy={heatingDemandSystem}
              color="error"
              text={systemText}
            />
          </Grid>
        </>
      );
    } else if (hasEnvelopeDeviation) {
      return (
        <>
          <Grid item xs={6}>
            <EnergyCard
              title={t('General_HeatingDemand')}
              subtitle={t('DataCollection_HeatingDemandConflictDialog_EnergyCertificate')}
              energy={heatingDemand}
            />
          </Grid>
          <Grid item xs={6}>
            <EnergyCard
              title={t('General_HeatingDemand')}
              subtitle={t('DataCollection_HeatingDemandConflictDialog_Envelope')}
              energy={heatingDemandEnvelope}
              color="error"
              text={envelopeText}
            />
          </Grid>
        </>
      );
    }
    return <></>; // This should never happen
  };

  return (
    <PreDialog
      open={open}
      fullWidth
      onClose={onClose}
      maxWidth={hasSystemDeviation && hasEnvelopeDeviation ? 'md' : 'sm'}
      type="definedByProperty"
      dialogtitle={<PreDialogTitle icon={'mdi:calculator'} title={t('General_CalculationConflict')} />}
      content={
        open && (
          <Box>
            <InconsistentHeatingDemandDataAlert />
            <Grid container spacing={2} my={1}>
              {getDialogContent()}
            </Grid>
          </Box>
        )
      }
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            {t('General_BackToEditing')}
          </Button>
          <Button variant="contained" onClick={onClick}>
            {t('General_Save')}
          </Button>
        </>
      }
    />
  );
}

type EnergyCardProps = {
  title: string;
  subtitle: string;
  energy: number;
  text?: string | React.ReactNode;
  color?: string;
};

function EnergyCard({ title, subtitle, energy, text, color }: EnergyCardProps) {
  const { localize } = useLanguage();
  return (
    <Card
      sx={{
        height: '100%',
        minHeight: 200,
        color: color ? color : '',
      }}
    >
      <CardHeader
        sx={{
          alignItems: 'center',
          '.MuiCardHeader-subheader': {
            overflowWrap: 'anywhere',
            lineHeight: 1.4,
          },
        }}
        title={title}
        subheader={subtitle}
      />
      <CardContent>
        <Box>
          <Typography variant="h3" mr={1}>
            {localize.formatAsCompact(energy)}
            <Typography component={'span'}> kWh/m²a</Typography>
          </Typography>
        </Box>
        {text && <Typography color={color}>{text}</Typography>}
      </CardContent>
    </Card>
  );
}
