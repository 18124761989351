import { Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RHFBoolean from '../../../../../../components/hook-form/RHFBoolean';

const AdditionalInformation = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{t('General_RegulatoryConstraints')}</Typography>
      <Stack direction="row" spacing={1}>
        <Tooltip title={t('Education_MonumentProtectionBody')}>
          <span>
            <RHFBoolean name="additionalInformation.monumentProtection" label={t('General_MonumentProtection')} />
          </span>
        </Tooltip>
        <Tooltip title={t('Education_MilieuProtectionBody')}>
          <span>
            <RHFBoolean name="additionalInformation.milieuProtection" label={t('General_MilieuProtection')} />
          </span>
        </Tooltip>
        <Tooltip title={t('Education_HeritageDistrictBody')}>
          <span>
            <RHFBoolean name="additionalInformation.heritageDistrict" label={t('General_HeritageDistrict')} />
          </span>
        </Tooltip>
        <Tooltip title={t('Education_LeaseholdBody')}>
          <span>
            <RHFBoolean name="additionalInformation.leasehold" label={t('General_Leasehold')} />
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default AdditionalInformation;
