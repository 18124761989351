import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { DelayedLoading } from '../../../../components/Loading';
import SaveAsDraftDialog from '../../../../components/NavigationModal/SaveAsDraftDialog';
import PreDialog, { PreDialogBody } from '../../../../components/presentations/PreDialog/PreDialog';
import { WarningModal } from '../../../../components/WarningModal';
import { PATHS } from '../../../../routes/paths';
import BuildingCreationActions from './BuildingCreationActions';
import BuildingCreationProvider from './BuildingCreationProvider';
import BuildingCreationTitle from './BuildingCreationTitle';
import { ACTION_TYPES } from './hooks/useGlobalState';

type BuildingCreationBodyProps = {
  id?: number;
  onClose: VoidFunction;
};

const BuildingCreationBody = ({ id, onClose }: BuildingCreationBodyProps) => {
  const { t } = useTranslation();

  return (
    <BuildingCreationProvider id={id} onClose={onClose}>
      {({ activeStep, state, handleCloseMainDialog, dispatch, handleSaveAsDraft, isLoading }) => (
        <>
          <PreDialogBody
            dialogtitle={<BuildingCreationTitle />}
            actions={<BuildingCreationActions />}
            content={<activeStep.Component />}
            showDividers
          />
          {state.isLeavePageDialogOpen && (
            <WarningModal
              title={t('General_LeavePageTitle')}
              description={t('General_CancelEditModalDescription')}
              buttonText={t('General_Leave')}
              cancelText={t('General_KeepEditing')}
              open
              onAcknowledge={handleCloseMainDialog}
              onClose={() => dispatch({ type: ACTION_TYPES.SET_LEAVE_DIALOG, payload: false })}
            />
          )}
          {state.isSaveAsDraftDialogOpen && (
            <SaveAsDraftDialog
              open
              onCloseWithSave={() => handleSaveAsDraft(handleCloseMainDialog)}
              onCloseWithoutSave={handleCloseMainDialog}
              onStay={() => dispatch({ type: ACTION_TYPES.SET_DRAFT_DIALOG, payload: false })}
            />
          )}
          {isLoading && <DelayedLoading />}
        </>
      )}
    </BuildingCreationProvider>
  );
};

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

const BuildingCreation = ({ open, onClose }: Props) => {
  const { id } = useParams();
  const idAsNumber = id ? parseInt(id) : undefined;

  if (Number.isNaN(idAsNumber)) {
    return <Navigate to={PATHS.notFound()} />;
  }

  return (
    <PreDialog type="definedByChildren" open={open} onClose={() => {}} showClose={false} fullScreen>
      <BuildingCreationBody id={idAsNumber} onClose={onClose} />
    </PreDialog>
  );
};

export default BuildingCreation;
