import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CREATE_BUILDING_FROM_BUILDING_DRAFT } from '../../../../../graphql/DataCollection.mutations';
import { PATHS } from '../../../../../routes';

export const useCreateBuilding = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return useMutation(CREATE_BUILDING_FROM_BUILDING_DRAFT, {
    onCompleted: (data) => {
      if (data.createBuildingFromBuildingDraft.success) {
        enqueueSnackbar(t('BuildingCreation_CreateBuildingFromDraft-success'), {
          variant: 'success',
        });
        navigate(PATHS.dataCollection.building({ id: data.createBuildingFromBuildingDraft.buildingId }));
      } else {
        enqueueSnackbar(t('BuildingCreation_CreateBuildingFromDraft-error'), {
          variant: 'error',
        });
      }
    },
    onError: () => {
      enqueueSnackbar(t('BuildingCreation_CreateBuildingFromDraft-error'), {
        variant: 'error',
      });
    },
  });
};
