import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../../assets/icons';
import Iconify from '../../../../../../components/Iconify';

type Props = {
  label: string;
  /**
   * If the data is being approximated, to show the approximated icon
   */
  isApproximating?: boolean;
  /**
   * If the data is approximated, to show the approximated icon
   */
  isApproximated?: boolean;
};

const DataRow = ({ label, isApproximating, isApproximated, children }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between">
      <Typography variant="body2">{label}</Typography>
      {isApproximating ? (
        <CircularProgress size={16} />
      ) : (
        <Stack direction="row" spacing={0.5} alignItems="center">
          {isApproximated && (
            <Box display="inline-flex" title={t('BuildingCreation_EstimatedFields')}>
              <Iconify width={20} height={20} color="info.main" icon={ICONS.APPROXIMATED} />
            </Box>
          )}
          <Typography variant="body2">{children || '-'}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default DataRow;
