/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  DataCollectionEmissionCertificateDraftFragment,
  DataCollectionEmissionCertificateFragment,
} from '../../../../../../libs/generated/client-graphql/src/generated/graphql';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import {
  CREATE_EMISSION_CERTIFICATE_FROM_DRAFT,
  UPDATE_EMISSION_CERTIFICATE,
  UPDATE_EMISSION_CERTIFICATE_DRAFT,
} from '../../../graphql/DataCollection.mutations';
import {
  GET_ALL_EMISSION_CERTIFICATES_DROPDOWN,
  GET_BUILDING,
  GET_EMISSION_CERTIFICATES,
  GET_EMISSION_CERTIFICATE_DRAFTS,
} from '../../../graphql/DataCollection.queries';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import EmissionCertificateEditWarningModal from '../../../pages/DataCollection/EmissionCertificates/EmissionCertificateEditWarningModal';

/**
 * A custom hook for creating a form for a specific document type. Meant to be used in combination with `ProcessDocumentDialog`.
 * The idea is to have a single hook that can be used to create a form for any document type, and to have the form logic and callbacks in one place.
 * We will clean this up a bit more once we start supporting more document types.
 *
 * Ideally, this is the single source of truth for the form logic, callbacks, default values and schemas for a specific document type.
 */

type ValidDocumentTypes = 'emission_certificate' | 'invoice' | 'energy_certificate';

export type ValidDocumentFragments =
  | DataCollectionEmissionCertificateDraftFragment
  | DataCollectionEmissionCertificateFragment;

type Props = {
  documentType: ValidDocumentTypes;
  //@TODO expand this later
  document: ValidDocumentFragments;
  closeDialog: () => void;
};

/**
 * A custom hook for creating a form for a specific document type. Meant to be used in combination with `ProcessDocumentDialog`.
 * @returns methods - The form methods from react-hook-form
 * @returns onContinueLater - A callback for saving the form data to a draft
 * @returns onCreate - A callback for creating a document from a draft
 * @returns onSave - A callback for saving the form data to a document (not a draft)
 * @returns resetToDocumentValues - A function for resetting the form to the original document values
 */
export default function useCustomDocumentForm({ documentType, document, closeDialog }: Props) {
  type FormValueProps = EmissionCertificateFormValuesProps;
  let schema: yup.AnyObjectSchema;

  const [warningModalOpen, setWarningModalOpen] = useState(false);

  //@ts-ignore
  const { EmissionCertificateFormSchema } = useFormSchema(documentType);

  //@ts-ignore

  //@ts-ignore

  //@ts-ignore
  const { onContinueLater, onCreate, onSave } = useFormCallback(documentType);

  //@ts-ignore
  const { defaultValues } = useDefaultFormValues(documentType, document);

  const resetToDocumentValues = () => methods.reset(defaultValues);

  switch (documentType) {
    case 'emission_certificate':
      schema = EmissionCertificateFormSchema;
      break;
    case 'invoice':
      break;
    case 'energy_certificate':
      break;
    default:
      const exhaustiveCheck: never = documentType;
      throw new Error(`Unhandled documentType ${documentType}: ${exhaustiveCheck}`);
  }

  const methods = useForm<FormValueProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',

    //@ts-ignore
    resolver: yupResolver(schema),
    defaultValues,
  });

  const saveAfterWarning = async (data: EmissionCertificateFormValuesProps) => {
    await onSave(data);
    setWarningModalOpen(false);
    closeDialog();
  };

  //@ts-ignore
  const { warningModal } = useWarningDialogs(
    documentType,
    document,
    warningModalOpen,
    () => setWarningModalOpen(false),
    async () => await saveAfterWarning(methods.getValues()),
  );

  return { methods, onContinueLater, onCreate, onSave, resetToDocumentValues, warningModal, setWarningModalOpen };
}

// TYPES

export type EmissionCertificateFormValuesProps = {
  field_of_application: string;
  id: number;
  issuer: string;
  has_emission_factor: boolean;
  emission_factor_value: number | null;
  emission_factor_unit: (typeof EmissionFactorUnits)[keyof typeof EmissionFactorUnits];
  has_primary_energy_factor: boolean;
  primary_energy_factor: number | null;
  valid_from: string;
  valid_until: string;
};
const EmissionFactorUnits = {
  gco2e_kwh: 'gco2e_kwh',
  kgco2e_kwh: 'kgco2e_kwh',
  kgco2e_mwh: 'kgco2e_mwh',
} as const;

// SCHEMAS

function useFormSchema(documentType: ValidDocumentTypes) {
  const { t } = useTranslation();

  // emission certificates
  const emissionCertificateErrorMessages = {
    valid_from: {
      afterIssueDate: t('DataCollection_EmissionCertificatesFormErrors_ValidUntil-afterIssueDate'),
    },
  };

  const requiredMessage = t('General_Required');
  const validDateMessage = t('General_InvalidDate');
  const oneOfTwoFactorsMessage = t('EmissionCertificateForm_EmissionFactorOrPrimaryEnergyFactorIsRequired');

  const oneOfTwoFactorsRequiredCheck = (hasEmissionFactor: boolean, hasPrimaryEnergyFactor: boolean) =>
    !hasEmissionFactor && !hasPrimaryEnergyFactor;

  const oneOfTwoFactorsRequiredSchema = yup
    .number()
    .transform(() => null)
    .typeError(oneOfTwoFactorsMessage)
    .required(oneOfTwoFactorsMessage);

  const EmissionCertificateFormSchema = yup.object().shape(
    {
      field_of_application: yup.string().required(requiredMessage),
      issuer: yup.string().required(requiredMessage),
      has_emission_factor: yup.boolean().required(requiredMessage),
      emission_factor_value: yup
        .number()
        .nullable(true)
        .when(['has_emission_factor'], {
          is: true,
          then: (schema) => schema.required(requiredMessage),
        })
        .when(['has_emission_factor', 'has_primary_energy_factor'], {
          is: oneOfTwoFactorsRequiredCheck,
          then: oneOfTwoFactorsRequiredSchema,
        }),
      emission_factor_unit: yup.string().oneOf(Object.values(EmissionFactorUnits)).required(requiredMessage),
      has_primary_energy_factor: yup.boolean().required(requiredMessage),
      primary_energy_factor: yup
        .number()
        .nullable(true)
        .min(0, t('General_MinRangeMessage', { min: 0 }))
        .max(5, t('General_MaxRangeMessage', { max: 5 }))
        .when(['has_primary_energy_factor'], {
          is: true,
          then: (schema) => schema.required(requiredMessage),
        })
        .when(['has_emission_factor', 'has_primary_energy_factor'], {
          is: oneOfTwoFactorsRequiredCheck,
          then: oneOfTwoFactorsRequiredSchema,
        }),
      valid_from: yup.date().typeError(validDateMessage).required(requiredMessage),
      //needs to be after issue_date
      valid_until: yup
        .date()
        .min(yup.ref('valid_from'), emissionCertificateErrorMessages.valid_from.afterIssueDate)
        .typeError(validDateMessage)
        .required(requiredMessage),
    },
    [['primary_energy_factor', 'emission_factor_value']],
  );

  switch (documentType) {
    case 'emission_certificate':
      return { EmissionCertificateFormSchema };
    case 'invoice':
      break;
    case 'energy_certificate':
      break;
    default:
      const exhaustiveCheck: never = documentType;
      throw new Error(`Unhandled documentType ${documentType}: ${exhaustiveCheck}`);
  }
}

// CALLBACKS

function useFormCallback(documentType: ValidDocumentTypes) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();
  const { enqueueSnackbar } = useSnackbar();

  // --- EMISSION CERTIFICATES ---
  const [updateEmissionCertificateDraft] = useMutation(UPDATE_EMISSION_CERTIFICATE_DRAFT, {
    // onCompleted: () => {
    //   enqueueSnackbar(t('UpdateDocumentDraft_Success'), {
    //     variant: 'success',
    //     autoHideDuration: SnackbarTimeouts.Success,
    //   });
    // },
    onError: () => {
      enqueueSnackbar(t('UpdateDocumentDraft_Error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    refetchQueries: [GET_EMISSION_CERTIFICATES, GET_BUILDING],
  });

  const [createEmissionCertificateFromDraft] = useMutation(CREATE_EMISSION_CERTIFICATE_FROM_DRAFT, {
    onCompleted: () => {
      enqueueSnackbar(t('CreateDocumentFromDraft_Success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
    onError: () => {
      enqueueSnackbar(t('CreateDocumentFromDraft_Error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    refetchQueries: [
      { query: GET_ALL_EMISSION_CERTIFICATES_DROPDOWN },
      GET_EMISSION_CERTIFICATES,
      GET_EMISSION_CERTIFICATE_DRAFTS,
    ],
  });

  const [updateEmissionCertificate] = useMutation(UPDATE_EMISSION_CERTIFICATE, {
    onCompleted: ({ updateEmissionCertificate: { success } }) => {
      if (success) {
        enqueueSnackbar(t('DataCollectionSubBuildingConsumption_DataUpdated-success'), {
          variant: 'success',
          autoHideDuration: SnackbarTimeouts.Success,
        });
      } else {
        enqueueSnackbar(t('DataCollectionSubBuildingConsumption_DataUpdated-error'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        });
      }
    },
    onError: () => {
      enqueueSnackbar(t('DataCollectionSubBuildingConsumption_DataUpdated-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    refetchQueries: [{ query: GET_ALL_EMISSION_CERTIFICATES_DROPDOWN }, GET_EMISSION_CERTIFICATES],
  });

  const calculateEmissionFactor = (data: EmissionCertificateFormValuesProps) => {
    if (data.emission_factor_value === null) {
      return null;
    }

    if (data.emission_factor_unit === 'gco2e_kwh' || data.emission_factor_unit === 'kgco2e_mwh') {
      return Number((data.emission_factor_value / 1000).toFixed(5));
    } else {
      return Number(data.emission_factor_value.toFixed(5));
    }
  };

  const onContinueLaterEmissionCertificates = async (data: EmissionCertificateFormValuesProps) => {
    const emissionFactorValue = calculateEmissionFactor(data);
    await updateEmissionCertificateDraft({
      variables: {
        id: data.id,
        issuer: data.issuer,
        valid_from: data.valid_from,
        valid_until: data.valid_until,
        emission_factor: data.has_emission_factor ? emissionFactorValue : null,
        primary_energy_factor: data.has_primary_energy_factor ? data.primary_energy_factor : null,
      },
    });
  };

  const onCreateEmissionCertificates = async (draftId: number) => {
    await createEmissionCertificateFromDraft({
      variables: {
        draft_id: draftId,
      },
    });
  };

  const onSaveEmissionCertificates = async (data: EmissionCertificateFormValuesProps) => {
    const emissionFactorValue = calculateEmissionFactor(data);
    const result = await updateEmissionCertificate({
      variables: {
        emissionCertificate: {
          id: data.id,
          issuer: data.issuer,
          valid_from: data.valid_from,
          valid_until: data.valid_until,
          emission_factor: data.has_emission_factor ? emissionFactorValue : null,
          primary_energy_factor: data.has_primary_energy_factor ? data.primary_energy_factor : null,
        },
      },
    });
    if (result.data?.updateEmissionCertificate.success) {
      trackEvent('EMISSION_CERTIFICATE_CREATED', {
        emission_factor: emissionFactorValue ?? 0,
        primary_energy_factor: data?.primary_energy_factor ?? 0,
      });
    }
  };

  switch (documentType) {
    case 'emission_certificate':
      return {
        onContinueLater: onContinueLaterEmissionCertificates,
        onCreate: onCreateEmissionCertificates,
        onSave: onSaveEmissionCertificates,
      };
    case 'invoice':
      break;
    case 'energy_certificate':
      break;
    default:
      const exhaustiveCheck: never = documentType;
      throw new Error(`Unhandled documentType ${documentType}: ${exhaustiveCheck}`);
  }
}

//DEFAULT VALUES

function useDefaultFormValues(
  documentType: ValidDocumentTypes,
  //@TODO expand this
  document: ValidDocumentFragments,
) {
  switch (documentType) {
    case 'emission_certificate':
      const defaultValues: EmissionCertificateFormValuesProps = {
        field_of_application: 'district_heating',
        issuer: document?.issuer ?? '',
        emission_factor_unit: 'kgco2e_kwh',
        has_emission_factor: document?.emission_factor !== null,
        emission_factor_value: document?.emission_factor ?? null,
        primary_energy_factor: document?.primary_energy_factor ?? null,
        has_primary_energy_factor: document?.primary_energy_factor !== null,

        //@ts-ignore
        valid_from: document?.valid_from ?? undefined,

        //@ts-ignore
        valid_until: document?.valid_until ?? undefined,
        id: document?.id ?? undefined,
      };
      return { defaultValues };
    case 'invoice':
      break;
    case 'energy_certificate':
      break;
    default:
      const exhaustiveCheck: never = documentType;
      throw new Error(`Unhandled documentType ${documentType}: ${exhaustiveCheck}`);
  }
}

// WARNING DIALOGS

function useWarningDialogs(
  documentType: ValidDocumentTypes,
  document: ValidDocumentFragments,
  open: boolean,
  onClose: () => void,
  onSave?: (data: EmissionCertificateFormValuesProps) => void,
) {
  switch (documentType) {
    case 'emission_certificate':
      const warningModal = (
        <EmissionCertificateEditWarningModal
          emissionCertificate={document}
          open={open}
          onClose={onClose}
          onSave={onSave}
        />
      );
      return { warningModal };
    case 'invoice':
      break;
    case 'energy_certificate':
      break;
    default:
      const exhaustiveCheck: never = documentType;
      throw new Error(`Unhandled documentType ${documentType}: ${exhaustiveCheck}`);
  }
}
