import { Card, CardProps } from '@mui/material';
import { efficiency_class_enum, getTaxBracketLandlordEnumTitle, tax_bracket_enum } from '@predium/enums';
import { translateEfficiencyClassEnum_dynamic } from '@predium/i18n/client';
import { Localize } from '@predium/utils';
import { TFunction } from 'i18next';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { memo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import { useLanguage } from '../../../provider/LanguageProvider';
import { EsgAnalysisFiltersKeys } from '../../../sections/EsgAnalysis/EsgAnalysisFilters/EsgAnalysisFilters';
import BaseOptionChart from '../../../theme/apexcharts';
import addCustomCSVExportButtonToChartToolbar from '../../../utils/addCustomCSVExportButtonToChartToolbar';
import { getFilename } from '../../../utils/getFilename';
import { toCsvContent } from '../../../utils/toCsvContent';

const getCsvContent = (
  series: number[],
  categories: tax_bracket_enum[] | efficiency_class_enum[],
  title: string,
  categoryFormatter: (value: tax_bracket_enum | efficiency_class_enum) => string,
  t: TFunction<'translation', undefined>,
  localize: Localize,
) => {
  const csvRows = [
    [title, ''],
    [t('General_Category'), t('General_NumberOfBuildings')],
    ...categories.map((category, index) => [categoryFormatter(category), localize.formatAsInteger(series[index])]),
  ];

  return toCsvContent(csvRows);
};

type chartType = 'taxBracket' | 'efficiencyClass';

interface Props extends CardProps {
  id: string;
  title: string;
  series: number[];
  categories: tax_bracket_enum[] | efficiency_class_enum[];
  colors: string[];
  animated?: boolean;
  type: chartType;
}

function UnmemoizedPortfolioBuildingDistributionGraph({
  id,
  title,
  series,
  categories,
  type,
  colors,
  animated,
  sx,
}: Props) {
  const { t } = useTranslation();
  const { language, localize } = useLanguage();

  const { setFilter } = useEsgAnalysisFilters();

  function isEfficiencyClass(value: string): value is efficiency_class_enum {
    return value != null && type === 'efficiencyClass';
  }

  function categoryFormatter(value: tax_bracket_enum | efficiency_class_enum) {
    if (isEfficiencyClass(value)) {
      return translateEfficiencyClassEnum_dynamic(value, t);
    } else {
      return getTaxBracketLandlordEnumTitle(value);
    }
  }

  const filename = getFilename([title], language);

  const chartState: ApexCharts.ApexOptions = {
    colors,
    chart: {
      animations: {
        enabled: !!animated,
      },
      type: 'bar',
      events: {
        dataPointSelection: (_, __, config) => {
          const value = config.w.config.xaxis.categories[config.dataPointIndex];

          if (isEfficiencyClass(value)) {
            setFilter(EsgAnalysisFiltersKeys.efficiencyClasses, value);
          } else {
            setFilter(EsgAnalysisFiltersKeys.taxBrackets, value);
          }
        },
        dataPointMouseEnter: function (event) {
          event.target.style.cursor = 'pointer';
        },
        mounted(chart: any) {
          addCustomCSVExportButtonToChartToolbar(
            chart.el.id,
            getCsvContent(series, categories, title, categoryFormatter, t, localize),
            filename,
          );
        },
        updated(chart: any) {
          addCustomCSVExportButtonToChartToolbar(
            chart.el.id,
            getCsvContent(series, categories, title, categoryFormatter, t, localize),
            filename,
          );
        },
      },
      toolbar: {
        export: {
          svg: {
            filename,
          },
          png: {
            filename,
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -18,
      formatter: function (val: number) {
        return val > 0 ? val : '';
      },
    },
    tooltip: {
      enabled: false,
    },
    title: {
      text: title,
    },
    xaxis: {
      categories,
      labels: {
        formatter: function (value: tax_bracket_enum | efficiency_class_enum) {
          return categoryFormatter(value);
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        distributed: true,
        columnWidth: '50%',
        barHeight: '100%',
        borderRadius: 8,
        borderRadiusApplication: 'end',
        dataLabels: {
          position: 'top',
          orientation: 'horizontal',
        },
      },
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'darken',
          value: 0.5,
        },
      },
    },
  };

  return (
    <Card sx={{ p: 3, pb: 1, ...sx }}>
      <ReactApexChart
        id={id}
        type={chartState.chart?.type}
        options={merge(BaseOptionChart(), chartState)}
        series={[{ data: series }]}
        height={350}
      />
    </Card>
  );
}

export const MemoizedPortfolioBuildingDistributionGraph = memo(UnmemoizedPortfolioBuildingDistributionGraph, isEqual);
