/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { DataCollectionGetEconomicUnitsListWithBuildingsQuery } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { DelayedLoading } from '../../../components/Loading';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { EDIT_ECONOMIC_UNIT } from '../../../graphql/DataCollection.mutations';
import {
  GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS,
  GET_ECONOMIC_UNITS_WITH_BUILDINGS,
} from '../../../graphql/DataCollection.queries';
import { GET_PORTFOLIOS_WITH_WRITE_PERMISSIONS } from '../../../graphql/Portfolio.queries';
import useSessionData from '../../../hooks/useSessionData';

type FormValuesProps = {
  portfolio_id: string;
  economic_unit_name: string;
  customer_economic_unit_id: string;
};

type Props = {
  onEconomicUnitEdited: (id: number) => void;
  onCancel: () => void;
  economicUnit: DataCollectionGetEconomicUnitsListWithBuildingsQuery['economic_unit'][number];
};

export default function EditEconomicUnitModal({ onEconomicUnitEdited, onCancel, economicUnit }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    //@ts-ignore
    user: { id: user_id },
  } = useSessionData();

  const { loading, data } = useQuery(GET_PORTFOLIOS_WITH_WRITE_PERMISSIONS, { variables: { user_id } });

  const EnergyCertificateDraftBaseInfoSchema = Yup.object().shape({
    portfolio_id: Yup.string().required(t('DataCollection_PortfolioIsRequired')),
    economic_unit_name: Yup.string().required(t('DataCollection_EconomicUnitName-isRequired')),
    customer_economic_unit_id: Yup.string()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue?.trim() === '' ? null : value;
      }),
  });

  const methods = useForm<FormValuesProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(EnergyCertificateDraftBaseInfoSchema),
    defaultValues: {
      portfolio_id: economicUnit.portfolio.id.toString(),
      economic_unit_name: economicUnit.name,
      customer_economic_unit_id: economicUnit.customer_economic_unit_id ?? '',
    },
  });

  const { control } = methods;

  const [editEconomicUnit, { loading: ecoUnitsLoading }] = useMutation(EDIT_ECONOMIC_UNIT, {
    onError: () =>
      enqueueSnackbar(t('General_FailedToSaveChanges'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: (data) => {
      enqueueSnackbar(t('General_ChangesSavedSuccessfully'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });

      //@ts-ignore
      onEconomicUnitEdited(data.update_economic_unit_by_pk.id);
    },
    refetchQueries: [{ query: GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS }, { query: GET_ECONOMIC_UNITS_WITH_BUILDINGS }],
  });

  const onSubmit = async (values: FormValuesProps) => {
    editEconomicUnit({
      variables: {
        economicUnitId: economicUnit.id,
        economicUnitName: values.economic_unit_name,
        portfolioId: parseInt(values.portfolio_id),
        customerEconomicUnitId: values.customer_economic_unit_id,
      },
    });
  };

  if (loading) {
    return <DelayedLoading />;
  }

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogTitle>{t('DataCollection_EditEconomicUnit')}</DialogTitle>

      <DialogContent>
        <Stack>
          {
            //TODO refactor this:  current RHF select and unify select everywhere
          }
          <Controller
            name="portfolio_id"
            control={control}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <FormControl variant="outlined" fullWidth sx={{ mt: 3 }}>
                <TextField {...field} error={!!error} select label={t('General_Portfolio')} helperText={error?.message}>
                  {/*@ts-ignore */}
                  {data.portfolio.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
          <RHFTextField
            name="economic_unit_name"
            label={t('DataCollection_EconomicUnitName-textFieldLabel')}
            sx={{ mt: 4, mb: 4 }}
          />
          <RHFTextField name="customer_economic_unit_id" label={t('DataCollection_EconomicUnitIdentifier')} />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          {t('General_Cancel')}
        </Button>
        <LoadingButton type="submit" variant="contained" loading={ecoUnitsLoading}>
          {t('General_Save')}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
