import { Dispatch, useReducer } from 'react';

export type GlobalState = {
  activeStepIndex: number;
  isCurrentStepInValid: boolean;
  isLeavePageDialogOpen: boolean;
  isSaveAsDraftDialogOpen: boolean;
  blockClose: boolean;
};

// Action type constants
export const ACTION_TYPES = {
  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
  NEXT_STEP: 'NEXT_STEP',
  PREVIOUS_STEP: 'PREVIOUS_STEP',
  SET_STEP_INVALIDITY: 'SET_STEP_INVALIDITY',
  SET_LEAVE_DIALOG: 'SET_LEAVE_DIALOG',
  SET_DRAFT_DIALOG: 'SET_DRAFT_DIALOG',
  SET_BLOCK_CLOSE: 'SET_BLOCK_CLOSE',
} as const;

export type ActionType =
  | { type: typeof ACTION_TYPES.SET_ACTIVE_STEP; payload: number }
  | { type: typeof ACTION_TYPES.NEXT_STEP }
  | { type: typeof ACTION_TYPES.PREVIOUS_STEP }
  | { type: typeof ACTION_TYPES.SET_STEP_INVALIDITY; payload: boolean }
  | { type: typeof ACTION_TYPES.SET_LEAVE_DIALOG; payload: boolean }
  | { type: typeof ACTION_TYPES.SET_DRAFT_DIALOG; payload: boolean }
  | { type: typeof ACTION_TYPES.SET_BLOCK_CLOSE; payload: boolean };

const initialState: GlobalState = {
  activeStepIndex: 0,
  isCurrentStepInValid: false,
  blockClose: false,
  isLeavePageDialogOpen: false,
  isSaveAsDraftDialogOpen: false,
};

function reducer(state: GlobalState, action: ActionType): GlobalState {
  switch (action.type) {
    case ACTION_TYPES.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStepIndex: action.payload,
      };
    case ACTION_TYPES.NEXT_STEP:
      return {
        ...state,
        activeStepIndex: state.activeStepIndex + 1,
      };
    case ACTION_TYPES.PREVIOUS_STEP:
      return {
        ...state,
        activeStepIndex: state.activeStepIndex - 1,
      };
    case ACTION_TYPES.SET_STEP_INVALIDITY:
      return {
        ...state,
        isCurrentStepInValid: action.payload,
      };
    case ACTION_TYPES.SET_LEAVE_DIALOG:
      return {
        ...state,
        isLeavePageDialogOpen: action.payload,
      };
    case ACTION_TYPES.SET_DRAFT_DIALOG:
      return {
        ...state,
        isSaveAsDraftDialogOpen: action.payload,
      };
    case ACTION_TYPES.SET_BLOCK_CLOSE:
      return {
        ...state,
        blockClose: action.payload,
      };
    default:
      return state;
  }
}

export const useGlobalState = ({ defaultStep }: { defaultStep: number }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    activeStepIndex: defaultStep,
  });

  return {
    state,
    dispatch,
  };
};

export type ActionTypeDispatch = Dispatch<ActionType>;
