import { graphql } from '@predium/client-graphql';

graphql(`
  fragment AsyncJob on async_job {
    description
    title
    entity_id
    status_id
    user_id
    org_id
  }
`);

graphql(`
  fragment AccessRightsPortfolio on portfolio {
    created_at
    id
    name
  }
`);

graphql(`
  fragment AccessRightsBuilding on building {
    address {
      city
      country_id
      house_number
      postal_code
      street
    }
    areas(where: { area_type_id: { _eq: EBF } }) {
      __typename
      value
      type_of_use_id
      area_type_id
    }
    created_at
    id
    economic_unit {
      portfolio {
        name
        id
      }
    }
  }
`);

export const GET_ALL_PORTFOLIOS_AND_BUILDINGS = graphql(`
  query GetAllPortfoliosAndBuildings {
    portfolio {
      ...AccessRightsPortfolio
    }
    building {
      ...AccessRightsBuilding
    }
  }
`);

export const GET_ORG_PREFERENCES = graphql(`
  query GetOrgPreferences {
    org {
      org_preferences {
        economic_unit_enabled
        emission_factor_type_id
        __typename
      }
      __typename
    }
  }
`);

export const ASYNC_JOB_SUBSCRIPTION_ORG = graphql(`
  subscription AsyncJobSubscriptionOrg($title: String!, $orgId: Int) {
    async_job(where: { org_id: { _eq: $orgId }, title: { _eq: $title } }, order_by: { created_at: desc }, limit: 1) {
      ...AsyncJob
    }
  }
`);

export const ASYNC_JOB_SUBSCRIPTION = graphql(`
  subscription AsyncJobSubscription($title: String!, $entityId: Int) {
    async_job(
      where: { entity_id: { _eq: $entityId }, title: { _eq: $title } }
      order_by: { created_at: desc }
      limit: 1
    ) {
      ...AsyncJob
    }
  }
`);
