import { Divider, Stack } from '@mui/material';
import { CompleteBuildingQuery, data_source_type_enum } from '@predium/client-graphql';
import { translateEnergySourceTypeEnum, translateEnergySystemTypeEnum } from '@predium/i18n/client';
import { ensureDefined } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import { BuildingCreationFormCast } from '../../BuildingCreationProvider';
import DataRow from '../Components/DataRow';

type Props = {
  completedBuilding: BuildingCreationFormCast;
  loading: boolean;
  data: CompleteBuildingQuery | undefined;
};

const EnergyDataReview = ({ completedBuilding, loading, data }: Props) => {
  const { t } = useTranslation();
  const isEnergyDataApproximated = data?.completeBuilding.energyDataSource === data_source_type_enum.APPROXIMATED;

  if (!completedBuilding.information.energyData?.length && loading) {
    return (
      <Stack spacing={2}>
        <DataRow isApproximating label={t('General_EnergyConsumer')}></DataRow>
        <DataRow isApproximating label={t('General_EnergySource')}></DataRow>
        <DataRow isApproximating label={t('General_FinalEnergy')}></DataRow>
      </Stack>
    );
  }

  return (
    <Stack spacing={2} divider={<Divider />}>
      {completedBuilding.information.energyData?.map((energy) => (
        <Stack spacing={2}>
          <DataRow isApproximated={isEnergyDataApproximated} label={t('General_EnergyConsumer')}>
            {translateEnergySystemTypeEnum(ensureDefined(energy.energyConsumer))}
          </DataRow>
          <DataRow isApproximated={isEnergyDataApproximated} label={t('General_EnergySource')}>
            {translateEnergySourceTypeEnum(ensureDefined(energy.energySource))}
          </DataRow>
          <DataRow isApproximated={isEnergyDataApproximated} label={t('General_FinalEnergy')}>
            {energy.finalEnergy} kWh/m²a
          </DataRow>
        </Stack>
      ))}
    </Stack>
  );
};

export default EnergyDataReview;
