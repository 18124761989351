/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, Stack, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Iconify from '../../components/Iconify';
import FilterWithSearch, { FilterItem } from '../../components/search/FilterWithSearch';
import { getTemplateMetadataForTemplateTitle } from './ReportingDataClasses/ReportTemplate.dc';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

type Props = {
  availablePortfolios: { id: number; name: string }[];
  availableTemplates: { id: number; title: string }[];
  availableOwners: { label: string; value: number }[];
  setSelectedPortfolioIdFilter: (value: 'General_AllPortfolios' | number) => void;
  selectedPortfolioIdFilter: 'General_AllPortfolios' | number;
  selectedOwnerIdFilter: 'General_AllOwners' | number;
  setSelectedOwnerIdFilter: (value: 'General_AllOwners' | number) => void;
  selectedTemplateFilter: string;
  setSelectedTemplateFilter: (value: string) => void;
  onCreateReport: VoidFunction;
};

export default function ReportingTableToolbar({
  availablePortfolios,
  availableTemplates,
  availableOwners,
  selectedOwnerIdFilter,
  setSelectedOwnerIdFilter,
  selectedTemplateFilter,
  setSelectedTemplateFilter,
  onCreateReport,
  setSelectedPortfolioIdFilter,
  selectedPortfolioIdFilter,
}: Props) {
  const { t } = useTranslation();

  const templateItems: FilterItem[] = [
    { name: t('Reporting_AllTemplates'), value: 'Reporting_AllTemplates' },
    ...availableTemplates.map(({ title }) => ({
      name: getTemplateMetadataForTemplateTitle(title).readableTitle ?? title,
      value: title,
    })),
  ];

  const ownerItems: FilterItem[] = [
    { name: t('General_AllOwners'), value: 'General_AllOwners' },
    ...availableOwners.map(({ label, value }) => ({
      name: label,
      value,
    })),
  ];

  const portfolioItems: FilterItem[] = [
    { name: t('General_AllPortfolios'), value: 'General_AllPortfolios' },
    ...availablePortfolios.map(({ id, name }) => ({
      name,
      value: id,
    })),
  ];

  const selectedTemplateDisplay =
    selectedTemplateFilter === 'Reporting_AllTemplates'
      ? t('Reporting_AllTemplates')
      : getTemplateMetadataForTemplateTitle(selectedTemplateFilter).readableTitle ?? selectedTemplateFilter;

  return (
    <RootStyle>
      <Stack direction="row" sx={{ width: '100%' }} gap={2} alignItems="center">
        <FilterWithSearch
          label={t('General_Template')}
          items={templateItems}
          onChanged={(value) => setSelectedTemplateFilter(value as string)}
          selectedLabel={t('Reporting_AllTemplates')}
          selectedItem={{ name: selectedTemplateDisplay, value: selectedTemplateFilter }}
          searchable
        />

        <FilterWithSearch
          label={t('General_Owner')}
          items={ownerItems}
          onChanged={(value) =>
            setSelectedOwnerIdFilter(value === 'General_AllOwners' ? 'General_AllOwners' : (value as number))
          }
          selectedLabel={t('General_AllOwners')}
          selectedItem={{
            name:
              selectedOwnerIdFilter === 'General_AllOwners'
                ? t('General_AllOwners')
                : availableOwners.find((o) => o.value === selectedOwnerIdFilter)?.label || '',
            value: selectedOwnerIdFilter,
          }}
        />

        <FilterWithSearch
          label={t('General_AllPortfolios')}
          items={portfolioItems}
          onChanged={(value) =>
            setSelectedPortfolioIdFilter(
              value === 'General_AllPortfolios' ? 'General_AllPortfolios' : (value as number),
            )
          }
          selectedLabel={t('General_AllPortfolios')}
          selectedItem={{
            name:
              selectedPortfolioIdFilter === 'General_AllPortfolios'
                ? t('General_AllPortfolios')
                : availablePortfolios.find((p) => p.id === selectedPortfolioIdFilter)?.name || '',
            value: selectedPortfolioIdFilter,
          }}
          searchable
        />

        <Button
          variant="contained"
          startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
          sx={{ marginLeft: 'auto' }}
          size="medium"
          onClick={onCreateReport}
        >
          {t('Reporting_CreateReportButton')}
        </Button>
      </Stack>
    </RootStyle>
  );
}
