import { useQuery } from '@apollo/client';
import { Grid, MenuItem, Typography } from '@mui/material';
import { building_state_enum } from '@predium/enums';
import { translateBuildingStateEnum } from '@predium/i18n/client';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RHFNumberField, RHFSelect, RHFTextField } from '../../../../../../components/hook-form';
import RHFRadio from '../../../../../../components/hook-form/RHFRadio';
import { PermissionType } from '../../../../../../contexts/PermissionContext';
import { GET_PORTFOLIOS_AND_ECONOMIC_UNITS } from '../../../../../../graphql/Portfolio.queries';
import useOrgPreferences from '../../../../../../hooks/useOrgPreferences';
import usePermissions from '../../../../../../hooks/usePermissions';

const CoreDataForm = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const { economicUnitsToggled } = useOrgPreferences();
  const { checkPortfolioPermission } = usePermissions();

  const { data } = useQuery(GET_PORTFOLIOS_AND_ECONOMIC_UNITS);

  const portfolios = (data?.portfolio ?? []).filter((portfolio) =>
    checkPortfolioPermission(portfolio.id, PermissionType.WRITE),
  );
  const selectedPortfolio = portfolios.find((portfolio) => portfolio.id === watch('coreData.portfolioId'));

  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">{t('BuildingCreation_CoreData')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{t('General_Status')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <RHFRadio
          row
          name="coreData.state"
          options={[
            { value: building_state_enum.INVENTORY, label: translateBuildingStateEnum(building_state_enum.INVENTORY) },
            {
              value: building_state_enum.ACQUISITION,
              label: translateBuildingStateEnum(building_state_enum.ACQUISITION),
            },
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{t('BuildingCreation_AdministrativeInformation')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <RHFSelect name="coreData.portfolioId" label={t('General_Portfolio')} required>
          {portfolios.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </RHFSelect>
      </Grid>
      {economicUnitsToggled && selectedPortfolio && (
        <Grid item xs={6}>
          <RHFSelect name="coreData.economicUnitId" label={t('General_EconomicUnit')}>
            {selectedPortfolio.economic_units.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>
      )}
      <Grid item xs={6}>
        <RHFTextField name="coreData.externalId" label={t('General_CustomerExternalIdentifier')} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{t('General_Building')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <RHFNumberField
          allowDecimals={false}
          nullable
          name="coreData.constructionYear"
          label={t('General_ConstructionYear')}
        />
      </Grid>
      <Grid item xs={6}>
        <RHFNumberField nullable name="coreData.units" label={t('General_AmountOfUnits')} />
      </Grid>
    </Grid>
  );
};

export default CoreDataForm;
