/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, ListItemIcon, MenuItem } from '@mui/material';
import {
  energy_consumer_technology_type_enum,
  energy_consumer_type_enum,
  energy_source_type_enum,
  energy_system_type_enum,
  renovation_type_enum,
} from '@predium/client-graphql';
import { getTechnologyTemplateCostPerM2 } from '@predium/client-lookup';
import { accessEnum, ensureDefined, maybeEnum } from '@predium/utils';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFSelect } from '../../../../../../../components/hook-form';
import InfoTable, { InfoTableData } from '../../../../../../../components/presentations/InfoTable';
import { BuildingTabEnum } from '../../../../../../../pages/DataCollection/DataCollectionBuilding';
import { getActionIcon } from '../../../../../../../utils/icons';
import { createSystemInfoTable } from '../../../../../Scenario/scenarios.util';
import { SelectSx, StyledListItemText } from '../../../../Components/ActionCustomStyles';
import { ConsumerPartialRenovationOption } from '../../../CreateAction';
import AffectedConsumerPartsTable from '../../../PartialRenovations/SystemRenovation/AffectedConsumerPartsTable';
import { ActionProps } from '../../../ViewAction';
import TechnicalCommonFields from './TechnicalCommonFields';

type CreateNewTechnicalActionForm = {
  action: energy_consumer_type_enum;
  technology_name: string;
  efficiency: number;
  energy_source_type: energy_source_type_enum;
  consumer_technology_type: energy_consumer_technology_type_enum;
  implementation_to: string;
  cost_per_m2: number;
};

export default function HotWaterAction({ action, previousActionBuildingModel, affectedParts }: ActionProps) {
  const { t } = useTranslation();

  const consumerData = ensureDefined(action?.renovations_energy_consumer[0]?.new_energy_consumer);

  const consumerType = accessEnum(energy_consumer_type_enum, consumerData.energy_consumer_type_id);
  const energySourceType = consumerData?.energy_system_consumer_routes[0]?.energy_source_type_id;
  const buildingId = previousActionBuildingModel.building!.id;

  // cost per m2 is not saved in the DB and must be fetched from the lookup
  let costPerM2;
  if (consumerData.energy_consumer_technology_type_id && consumerData.energy_consumer_type_id) {
    costPerM2 = getTechnologyTemplateCostPerM2(
      accessEnum(energy_system_type_enum, consumerData.energy_consumer_type_id),
      accessEnum(energy_consumer_technology_type_enum, consumerData.energy_consumer_technology_type_id),
    );
  }

  const defaultValues = {
    action: consumerType,
    technology_name: consumerData.name ?? '',
    efficiency: consumerData.efficiency ?? undefined,
    energy_source_type: energySourceType,
    consumer_technology_type: maybeEnum(
      energy_consumer_technology_type_enum,
      consumerData.energy_consumer_technology_type_id,
    ),
    implementation_to: action.implementation_to,
    cost_per_m2: costPerM2,
  };

  const formMethods = useForm<CreateNewTechnicalActionForm>({
    defaultValues: defaultValues,
  });

  const infoTableData: InfoTableData[] = createSystemInfoTable(
    consumerType as energy_consumer_type_enum,
    previousActionBuildingModel,
    t,
  );

  return (
    <FormProvider methods={formMethods}>
      <Box my={3}>
        <RHFSelect name="action" label={t('General_Category')} size={'small'} sx={{ ...SelectSx, mb: 3 }} disabled>
          <MenuItem value={consumerType}>
            <ListItemIcon style={{ minWidth: 24 }}>
              <Box component="img" src={getActionIcon(renovation_type_enum.ENERGY_CONSUMER)} />
            </ListItemIcon>
            <StyledListItemText action={t('Scenarios_ListItem-HotWaterConsumerType')} />
          </MenuItem>
        </RHFSelect>

        {infoTableData && (
          <Box mb={3}>
            <InfoTable
              buildingId={buildingId}
              showTab={BuildingTabEnum.technology}
              data={infoTableData}
              title={t('General_StateBeforeRenovation')}
            />
          </Box>
        )}

        <AffectedConsumerPartsTable affectedParts={affectedParts as ConsumerPartialRenovationOption[]} />

        <TechnicalCommonFields />
      </Box>
    </FormProvider>
  );
}
