import { useMutation } from '@apollo/client';
import { building_draft_insert_input, building_draft_update_column } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { Dispatch } from 'react';
import { UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  DELETE_BUILDING_DRAFT_CONSUMERS,
  UPSERT_BUILDING_DRAFT,
} from '../../../../../graphql/DataCollection.mutations';
import { PATHS } from '../../../../../routes';
import { BuildingCreationFormCast } from '../BuildingCreationProvider';
import { ACTION_TYPES, ActionType } from './useGlobalState';

type Props = {
  /**
   * The id of the building draft to update.
   */
  id?: number;
  /**
   * The dispatch function to update the global state.
   */
  dispatch: Dispatch<ActionType>;
  /**
   * The reset function to reset the form.
   */
  reset: UseFormReset<BuildingCreationFormCast>;
};

/**
 * Insert or update a building draft and navigate to the building draft page if the building draft is created.
 */
export const useUpsertDraftBuilding = ({ id, dispatch, reset }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [upsertDraftBuilding, { loading }] = useMutation(UPSERT_BUILDING_DRAFT, {
    onCompleted: (data, options) => {
      if (options?.context?.skipNotification) return;

      const result = data.insert_building_draft_one;
      if (!result) {
        enqueueSnackbar(t('BuildingCreation_CreateBuildingFromDraft-error'), { variant: 'error' });
        return;
      }

      dispatch({ type: ACTION_TYPES.SET_BLOCK_CLOSE, payload: false });

      const id = result.id;

      if (!id) {
        enqueueSnackbar(t('BuildingCreation_CreateBuildingFromDraft-success'), {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(t('BuildingCreation_UpdateBuildingFromDraft-success'), {
          variant: 'success',
        });
      }
    },
    onError: () => {
      if (!id) {
        enqueueSnackbar(t('BuildingCreation_CreateBuildingFromDraft-error'), { variant: 'error' });
      } else {
        enqueueSnackbar(t('BuildingCreation_UpdateBuildingFromDraft-error'), {
          variant: 'error',
        });
      }
    },
  });

  const [deleteBuildingDraftConsumers] = useMutation(DELETE_BUILDING_DRAFT_CONSUMERS);

  const handleSubmit = async (building: BuildingCreationFormCast, skipNotification: boolean = false) => {
    if (id) {
      await deleteBuildingDraftConsumers({
        variables: {
          buildingDraftId: id,
        },
      });
    }

    const values = {
      city: building.address.city,
      country_id: building.address.countryId,
      postal_code: building.address.postalCode,
      street: building.address.street,
      responsible_user_id: building.information.responsibleUserId,
      type_of_use_id: building.information.area.typeOfUse,
      building_state_id: building.information.coreData.state,
      portfolio_id: building.information.coreData.portfolioId,
      area_usable: building.information.area.totalArea,
      external_id: building.information.coreData.externalId,
      milieu_protection: building.information.additionalInformation.milieuProtection,
      monument_protection: building.information.additionalInformation.monumentProtection,
      heritage_district: building.information.additionalInformation.heritageDistrict,
      leasehold: building.information.additionalInformation.leasehold,
      year_constructed: building.information.coreData.constructionYear,
      units: building.information.coreData.units,
      economic_unit_id: building.information.coreData.economicUnitId,
    } satisfies building_draft_insert_input;

    const consumers = {
      data:
        building.information.energyData?.map((consumer) => ({
          energy_system_type_id: consumer.energyConsumer,
          energy_source_type_id: consumer.energySource,
          energy_final: consumer.finalEnergy,
        })) ?? [],
    } satisfies building_draft_insert_input['consumers'];

    const result = await upsertDraftBuilding({
      variables: {
        obj: {
          id,
          consumers,
          ...values,
        },
        updateColumns: Object.keys(values) as building_draft_update_column[],
      },
      context: {
        skipNotification,
      },
    });

    const resultId = result.data?.insert_building_draft_one?.id;
    if (!id && resultId) {
      navigate(PATHS.dataCollection.buildingDraft({ id: resultId }));
    }

    reset(building);

    return resultId;
  };

  return {
    handleCreateDraftBuilding: handleSubmit,
    loading,
  };
};
