/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal';
import Iconify from '../../../../components/Iconify';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import { DELETE_FILE } from '../../../../graphql/DataCollection.mutations';
import { deleteCache } from '../../../../utils/cacheHelpers';
import { EnrichedFileType } from './Documents';

type Props = {
  file: EnrichedFileType;
};

function DeleteFile({ file }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);

  const showErrorSnackbar = () => {
    enqueueSnackbar(t('DataCollection_EnergyCertificateDelete_ErrorMessage'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const [deleteFileMutation] = useMutation(DELETE_FILE, {
    variables: {
      fileId: file.id,
    },
    update: (cache, { data }) => {
      if (data?.delete_file_by_pk) {
        deleteCache(cache, data.delete_file_by_pk);
      }
    },
    onCompleted: (data) => {
      if (!data?.delete_file_by_pk) {
        showErrorSnackbar();
      }
    },
    onError: () => showErrorSnackbar(),
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleDeleteDocument = () => {
    deleteFileMutation();
    handleClose();
  };

  return (
    <>
      <MenuItem onClick={handleOpen} sx={{ color: 'error.main' }}>
        <Iconify icon={ICONS.TRASH} />
        {t('General_Delete')}
      </MenuItem>
      <DeleteConfirmationModal
        open={isOpen}
        title={t('General_DeleteModalTitle')}
        description={
          <Trans
            i18nKey={'General_DeleteModalDescription'}
            values={{
              name: file.name ?? '',
            }}
            components={{ bold: <strong /> }}
          />
        }
        onClose={handleClose}
        onDelete={handleDeleteDocument}
      />
    </>
  );
}

export default DeleteFile;
