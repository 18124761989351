/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { action_subsidy_value_type_enum, ActionPlanningActionSubsidyFragment } from '@predium/client-graphql';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../../components/Iconify';
import { useLanguage } from '../../../../../../provider/LanguageProvider';

type Props = {
  total: number;
  actionSubsidies: Omit<ActionPlanningActionSubsidyFragment, 'id'>[];
  actionCost: number;
  canEdit?: boolean;
  // we need to make sure that the actual action costs from the DB are used in the Financial KPIs overview
  useActionCostsInDB?: boolean;
};

export default function ExpectedSubsidyFunding({
  total,
  actionSubsidies,
  actionCost,
  useActionCostsInDB = false,
  canEdit = false,
}: Props) {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset', whiteSpace: 'nowrap', width: '100%', p: 0 } }}>
        <TableCell>
          <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
            <Typography variant="subtitle2" fontWeight={700}>
              {t('General_Subsidy', { count: 2 })}
            </Typography>
            {canEdit && <Iconify icon="ph:note-pencil-fill" width="16px" height="16px" />}
          </Stack>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" fontWeight={400} textAlign={'right'}>
            {localize.formatAsCurrency(total > 0 ? total : null)}
          </Typography>
        </TableCell>
      </TableRow>
      {actionSubsidies.map((subsidyData, index) => {
        const { subsidy, value, action, type } = subsidyData;
        const isRelative = type === action_subsidy_value_type_enum.percentage;
        const totalActionCost = useActionCostsInDB
          ? action.renovations.reduce((acc, curr) => acc + (curr.cost_total ?? 0), 0)
          : actionCost;

        const subsidyAmount = isRelative ? totalActionCost * (value / 100) : value;
        return (
          <TableRow key={index}>
            <TableCell>
              <Box display={'flex'} flex={'row'} gap={0.5}>
                <ItemIcon />
                <Typography color="text.secondary" variant="body2" sx={{ width: '100%' }}>
                  {subsidy.title}
                </Typography>
              </Box>
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign={'right'}
                sx={{ verticalAlign: 'bottom', minWidth: '100px' }}
              >
                {localize.formatAsCurrency(subsidyAmount > 0 ? subsidyAmount : null)}
              </Typography>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}

const ItemIcon = () => {
  return (
    <svg style={{ marginTop: 2 }} width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 2.123L0 0.5C0 0.367392 0.0526785 0.240214 0.146447 0.146446C0.240215 0.0526781 0.367392 0 0.5 0C0.632608 0 0.759785 0.0526781 0.853553 0.146446C0.947321 0.240214 1 0.367392 1 0.5V2.1C1 3.228 1 4.045 1.053 4.686C1.105 5.323 1.205 5.743 1.381 6.089C1.7166 6.7478 2.2522 7.2834 2.911 7.619C3.257 7.795 3.677 7.895 4.314 7.947C4.955 8 5.772 8 6.9 8H8.5C8.63261 8 8.75979 8.05268 8.85355 8.14645C8.94732 8.24021 9 8.36739 9 8.5C9 8.63261 8.94732 8.75979 8.85355 8.85355C8.75979 8.94732 8.63261 9 8.5 9H6.877C5.777 9 4.919 9 4.232 8.944C3.534 8.887 2.967 8.769 2.457 8.51C1.61005 8.07853 0.921469 7.38995 0.49 6.543C0.23 6.033 0.113 5.466 0.056 4.768C-7.45058e-09 4.08 0 3.224 0 2.123Z"
        fill="#637381"
      />
    </svg>
  );
};
