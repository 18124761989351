import { CardProps, useTheme } from '@mui/material';
import { efficiency_class_enum } from '@predium/enums';
import isEqual from 'lodash/isEqual';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { MemoizedPortfolioBuildingDistributionGraph } from './MemoizedPortfolioBuildingDistributionGraph';

interface Props extends CardProps {
  buildingsByEfficiencyClass: {
    count: number;
    efficiencyClass: efficiency_class_enum;
  }[];
}

function UnmemoizedPortfolioBuildingDistributionByEfficiencyClassGraph({ buildingsByEfficiencyClass, sx }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  // Sum counts of NOT_APPLICABLE and UNKNOWN classes to UNKNOWN (this is a business requirement)
  const unknownGroup = buildingsByEfficiencyClass
    .filter(
      (group) =>
        group.efficiencyClass === efficiency_class_enum.NOT_APPLICABLE ||
        group.efficiencyClass === efficiency_class_enum.UNKNOWN,
    )
    .reduce((acc, group) => ({ count: acc.count + group.count, efficiencyClass: efficiency_class_enum.UNKNOWN }));

  // Remove all NOT_APPLICABLE and UNKNOWN classes
  const filteredBuildingsByEfficiencyClass = buildingsByEfficiencyClass.filter(
    (group) =>
      !(
        group.efficiencyClass === efficiency_class_enum.NOT_APPLICABLE ||
        group.efficiencyClass === efficiency_class_enum.UNKNOWN
      ),
  );

  // Add the new single UNKNOWN class
  filteredBuildingsByEfficiencyClass.push(unknownGroup);

  const series = filteredBuildingsByEfficiencyClass.map((group) => group.count);
  const categories = filteredBuildingsByEfficiencyClass.map((group) => efficiency_class_enum[group.efficiencyClass]);

  const colors = categories.map((category) => {
    const color = theme.palette.energyEfficiencyClassColors[category];
    if (color) {
      return color.light;
    }
    return theme.palette.grey[300];
  });

  return (
    <MemoizedPortfolioBuildingDistributionGraph
      id="distribution_by_energy_efficiency_class_graph"
      title={t('General_DistributionByEnergyEfficiencyClass')}
      series={series}
      categories={categories}
      colors={colors}
      sx={sx}
      type="efficiencyClass"
    />
  );
}

export const MemoizedPortfolioBuildingDistributionByEfficiencyClassGraph = memo(
  UnmemoizedPortfolioBuildingDistributionByEfficiencyClassGraph,
  isEqual,
);
