import { country_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { getPostalCodeValidation } from '../../../../../utils/formUtils';

export const getManualAddressFormSchema = (t: TFunction) => {
  const REQUIRED_MESSAGE = t('General_Required');

  return yup.object({
    countryId: yup
      .mixed<country_enum>()
      .oneOf(Object.values(country_enum), REQUIRED_MESSAGE)
      .required(REQUIRED_MESSAGE),
    street: yup.string().required(REQUIRED_MESSAGE),
    postalCode: yup.string().when('countryId', getPostalCodeValidation(t)),
    city: yup.string().required(REQUIRED_MESSAGE),
  });
};

export type ManualAddressFormType = yup.InferType<ReturnType<typeof getManualAddressFormSchema>>;
