import { Box, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { custom_action_type_enum, energy_source_type_enum, energy_system_type_enum } from '@predium/enums';
import { ensureDefined } from '@predium/utils';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import {
  FormProvider,
  RHFDatePicker,
  RHFNumberField,
  RHFSelect,
  RHFTextField,
} from '../../../../../components/hook-form';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../Components/ActionCustomStyles';
import InfoTableCustomAction from '../ActionDetails/InfoTableCustomAction';
import { ConsumerPartialRenovationOption } from '../CreateAction';
import {
  CustomActionCategory,
  CustomActionType,
  getCustomActionIcon,
  getCustomActionName,
} from '../CreateActions/CustomAction';
import ConsumerAffectedPartsTable from '../PartialRenovations/SystemRenovation/AffectedConsumerPartsTable';
import { ActionProps } from '../ViewAction';

const availableCustomOptions = {
  ENERGY_SOURCE: Object.values(energy_source_type_enum),
  SYSTEM: Object.values(energy_system_type_enum),
};

const CustomAction = ({ action, previousActionBuildingModel, affectedParts }: ActionProps) => {
  const energySystems = previousActionBuildingModel.energy_systems;

  const filteredSystemOptions = availableCustomOptions.SYSTEM.filter((option) =>
    energySystems.some((system) => system.energy_system_type_id === option),
  );

  const filteredEnergySourceOptions = availableCustomOptions.ENERGY_SOURCE.filter((option) =>
    energySystems.some((system) =>
      system.energy_system_consumer_routes.some((route) => route.energy_source_type_id === option),
    ),
  );

  const filteredOptions = {
    ENERGY_SOURCE: filteredEnergySourceOptions,
    SYSTEM: filteredSystemOptions,
  };

  const affectedCategory = ensureDefined(action.custom_action_type_id);
  const renovatedRoutes = action.renovations_energy_routes.map((r) => r.new_energy_system_route);

  const option =
    affectedCategory === custom_action_type_enum.ENERGY_SOURCE
      ? renovatedRoutes[0]?.energy_source_type_id
      : renovatedRoutes[0]?.energy_system.energy_system_type_id;
  const selectedCustomActionType = filteredOptions[affectedCategory].find((o) => o === option)!;

  const defaultValues = {
    action: affectedCategory,
    implementation_to: action.implementation_to,
    custom_action_renovation_parameter: {
      renovation_ids: [],
      type: null,
      custom_name: action.custom_name ?? '',
      fraction: action.custom_saving_fraction ? action.custom_saving_fraction * 100 : '',
      energy_source_type: null,
      system_type: null,
    },
  };

  const formMethods = useForm({
    defaultValues: defaultValues,
  });

  return (
    <FormProvider methods={formMethods}>
      <RHFSelect
        name="action"
        label={t('General_ToOptimize')}
        size={'small'}
        sx={{ ...SelectSx, my: 2 }}
        fullWidth
        disabled
      >
        <MenuItem sx={{ textTransform: 'none' }} value={affectedCategory}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box component="img" src={getCustomActionIcon(selectedCustomActionType)} sx={{ width: 16, height: 16 }} />
            <StyledListItemText action={getCustomActionName(selectedCustomActionType, t)} />
          </Stack>
        </MenuItem>
      </RHFSelect>
      <InfoTableCustomAction
        affectedCategory={affectedCategory as unknown as CustomActionCategory}
        selectedCustomActionType={selectedCustomActionType as CustomActionType}
        buildingId={previousActionBuildingModel?.building?.id}
        buildingModel={previousActionBuildingModel}
      />
      <RHFTextField
        name="custom_action_renovation_parameter.custom_name"
        label={t('General_ActionTitle')}
        size={'small'}
        sx={{ ...NumberInputSx, pb: 0, mb: 0, mt: 3 }}
        disabled
      />
      {Object.values(energy_system_type_enum).includes(selectedCustomActionType as energy_system_type_enum) && (
        <ConsumerAffectedPartsTable
          affectedParts={affectedParts as ConsumerPartialRenovationOption[]}
          sx={{
            '&.MuiAccordion-root ': {
              boxShadow: 'none',
              mb: 0,
              mt: 3,
            },
          }}
        />
      )}
      <RHFNumberField
        name="custom_action_renovation_parameter.fraction"
        label={`${t('General_FinalEnergyReduction')} - ${getCustomActionName(selectedCustomActionType, t)}`}
        size="small"
        min={0}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography variant="body2" color="text.secondary">
                %
              </Typography>
            </InputAdornment>
          ),
        }}
        sx={{ ...NumberInputSx, mt: 3 }}
        disabled
      />
      <RHFDatePicker
        label={t('General_PlannedFor')}
        name="implementation_to"
        size={'small'}
        sx={{ ...NumberInputSx, mt: 3 }}
        disabled
      />
    </FormProvider>
  );
};
export default CustomAction;
