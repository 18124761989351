type LocalizeValue = string | number | null | undefined;
type FormatAsBaseOptions = {
  defaultString?: string;
  unit?: string;
  signDisplay?: Intl.NumberFormatOptions['signDisplay'];
  roundToNearestHundred?: boolean;
};
type FormatAsFloat = Omit<FormatAsBaseOptions, 'roundToNearestHundred'> & { fractionDigits?: 1 | 2 | 3 | 4 | 5 };
type FormatAsPercentage = Omit<FormatAsBaseOptions, 'unit' | 'roundToNearestHundred'> & {
  fractionDigits?: 0 | 1 | 2;
};
type FormatAsCurrency = Omit<FormatAsBaseOptions, 'unit'> & {
  currency?: 'EUR';
  fractionDigits?: 0 | 1 | 2;
  displayCompact?: boolean;
  perUnit?: string;
};
type FormatAsDataSize = Omit<FormatAsBaseOptions, 'unit' | 'signDisplay' | 'roundToNearestHundred'>;
type FormatAsCompact = FormatAsBaseOptions & {
  fractionDigits?: 0 | 1 | 2;
};

const GERMAN_NUMBER_REGEX = /^\d{1,3}(\.\d{3})*(,\d+)?$/;
const ENGLISH_GBNUMBER_REGEX = /^\d{1,3}(,\d{3})*(\.\d+)?$/;

export class Localize {
  private _locale: string | (() => string) = 'de-DE';

  constructor(locale: string | (() => string)) {
    this._locale = locale;
  }

  setLocale(locale: string | (() => string)) {
    if (typeof locale === typeof this._locale) {
      this._locale = locale;
    } else {
      throw new Error(
        `Changing the locale type is not allowed. Current locale type: ${typeof this
          ._locale}. New locale type: ${typeof locale}`,
      );
    }
  }

  get locale(): string {
    if (typeof this._locale === 'string') {
      return this._locale;
    }

    return this._locale();
  }

  private removeFormatting(numStr: string, locale: 'de-DE' | 'en-GB') {
    switch (locale) {
      case 'de-DE':
        return numStr.replace(/\./g, '').replace(',', '.');
      case 'en-GB':
        return numStr.replace(/,/g, '');
      default:
        return numStr;
    }
  }

  private isNumber(value: LocalizeValue): [boolean, number] {
    const parsedValue = value === null ? undefined : value === '' ? undefined : value === 0 ? undefined : value;

    // WHY: some places using Localize are passing already formatted numbers coming from number inputs.
    // Fixing those cases at the root level would take too much time and effort.
    const isGermanFormattedValue = typeof parsedValue === 'string' && GERMAN_NUMBER_REGEX.test(parsedValue);
    const isEnglishGbFormattedValue = typeof parsedValue === 'string' && ENGLISH_GBNUMBER_REGEX.test(parsedValue);
    const unformattedValue = isGermanFormattedValue
      ? this.removeFormatting(parsedValue, 'de-DE')
      : isEnglishGbFormattedValue
      ? this.removeFormatting(parsedValue, 'en-GB')
      : parsedValue;

    const numberValue = Number(unformattedValue);
    return [value != null && !Number.isNaN(numberValue), numberValue] as const;
  }

  private parseUnit(unitType?: string) {
    return unitType ? ` ${unitType}` : '';
  }

  private parsePerUnit(perUnitType?: string) {
    return perUnitType ? `/${perUnitType}` : '';
  }

  private toDataSizeAndUnitType(value: number) {
    if (value >= 1e12) {
      return { dataSize: value / 1e12, unit: 'terabyte' };
    }
    if (value >= 1e9) {
      return { dataSize: value / 1e9, unit: 'gigabyte' };
    }
    if (value >= 1e6) {
      return { dataSize: value / 1e6, unit: 'megabyte' };
    }
    if (value >= 1e3) {
      return { dataSize: value / 1e3, unit: 'kilobyte' };
    }
    return { dataSize: value, unit: 'byte' };
  }

  private getFractionDigitOptions(value: number, fractionDigits: number, isCurrency = false) {
    // hide fraction digits if value is an integer
    // i.e. if value is 9, then fractionDigits = 2 will display 9 instead of 9.00
    const digits = value % 1 === 0 ? 0 : fractionDigits;
    return {
      minimumFractionDigits: isCurrency ? digits : undefined,
      maximumFractionDigits: digits,
    } as const;
  }

  private getNumberFormatCompactOptions() {
    return {
      notation: 'compact',
      compactDisplay: 'short',
      useGrouping: true,
    } as const;
  }

  // TODO: pre 5723: unit should be union type of possible number units in the app
  /**
   * Format a value as an integer using the locale. The value is
   * coerced to a number and then formatted according to the
   * locale. If value is a float, it will be rounded to an integer using "halfExpand" rounding mode.
   *
   * @param value - The value to format.
   * @param options - Options for formatting the value.
   * @param options.defaultString - The default string to return if the value is null or undefined or if parsing to a
   *   Number return NaN.
   * @param options.unit - The unit to append to the formatted value.
   * @param options.signDisplay - The sign display option for the formatter (default is 'auto').
   * @param options.roundToNearestHundred - Rounds up the value to the nearest hundred.
   * @returns {string} The formatted value.
   */
  formatAsInteger(
    value: LocalizeValue,
    {
      defaultString = '-',
      unit,
      signDisplay = 'auto',
      roundToNearestHundred = false,
    }: Partial<FormatAsBaseOptions> = {},
  ): string {
    const parsedUnit = this.parseUnit(unit);
    const [isNumberValue, numberValue] = this.isNumber(value);
    if (!isNumberValue) {
      return `${defaultString}${parsedUnit}`;
    }

    const roundedNumber = roundToNearestHundred ? Math.round(numberValue / 100) * 100 : numberValue;
    const formattedValue = new Intl.NumberFormat(this.locale, { maximumFractionDigits: 0, signDisplay }).format(
      roundedNumber,
    );

    return `${formattedValue}${parsedUnit}`;
  }

  // TODO: pre 5723: unit should be union type of possible number units in the app
  /**
   * Format a value as a float with one fractional digit as default using the locale. The value is
   * coerced to a number and then formatted according to the
   * locale.
   *
   * @param value - The value to format.
   * @param options - Options for formatting the value.
   * @param options.defaultString - The default string to return if the value is null or undefined or if parsing to a
   *   Number return NaN.
   * @param options.unit - The unit to append to the formatted value.
   * @param options.fractionDigits - The number of fractional digits to display when value is not an integer (default
   *   is 2 i.e. 9.00)
   * @param options.signDisplay - The sign display option for the formatter (default is 'auto').
   * @returns {string} The formatted value.
   */
  formatAsFloat(
    value: LocalizeValue,
    { defaultString = '-', unit, fractionDigits = 2, signDisplay = 'auto' }: Partial<FormatAsFloat> = {},
  ): string {
    const parsedUnit = this.parseUnit(unit);
    const [isNumberValue, numberValue] = this.isNumber(value);
    if (!isNumberValue) {
      return `${defaultString}${parsedUnit}`;
    }

    const formattedValue = new Intl.NumberFormat(this.locale, {
      signDisplay,
      ...this.getFractionDigitOptions(numberValue, fractionDigits),
    }).format(numberValue);

    return `${formattedValue}${parsedUnit}`;
  }

  /**
   * Format a value as a percentage using the locale. The value is
   * coerced to a number and then formatted according to the
   * locale.
   *
   * @param value - The value to format.
   * @param options - Options for formatting the value.
   * @param options.defaultString - The default string to return if the value is null or undefined or if parsing to a
   *   Number return NaN.
   * @param options.fractionDigits - The number of fractional digits to display when value is not an integer (default
   *   is 2 i.e. 9.00)
   * @param options.signDisplay - The sign display option for the formatter (default is 'auto').
   * @returns {string} The formatted value.
   */
  formatAsPercentage(
    value: LocalizeValue,
    { defaultString = '-', fractionDigits = 2, signDisplay = 'auto' }: Partial<FormatAsPercentage> = {},
  ): string {
    const [isNumberValue, numberValue] = this.isNumber(value);
    if (!isNumberValue) {
      return new Intl.NumberFormat(this.locale, {
        style: 'percent',
        signDisplay,
        ...this.getFractionDigitOptions(numberValue, fractionDigits),
      })
        .format(numberValue)
        .replace(String(NaN), defaultString);
    }

    return new Intl.NumberFormat(this.locale, {
      style: 'percent',
      signDisplay,
      ...this.getFractionDigitOptions(numberValue, fractionDigits),
    }).format(numberValue / 100);
  }

  /**
   * Format a value as currency using the locale. The default currency is the Euro (EUR). The value is
   * coerced to a number and then formatted according to the
   * locale.
   *
   * @param value - The value to format.
   * @param options - Options for formatting the value.
   * @param options.defaultString - The default string to return if the value is null or undefined or if parsing to a
   *   Number return NaN.
   * @param options.fractionDigits - The number of fractional digits to display when value is not an integer (default
   *   is 2 i.e. 9.00)
   * @param options.currency - The currency to format the value in. Default is 'EUR'.
   * @param options.roundToNearestHundred - Rounds up the value to the nearest hundred.
   * @param options.displayCompact - Whether to display the value in compact format.
   * @param options.perUnit - A unit, i.e. m², to be displayed as the denominator of the currency i.e. "€/m²"
   * @param options.signDisplay - The sign display option for the formatter (default is 'auto').
   * @returns {string} The formatted value.
   */
  formatAsCurrency(
    value: LocalizeValue,
    {
      defaultString = '-',
      fractionDigits = 2,
      currency = 'EUR',
      roundToNearestHundred = false,
      displayCompact = false,
      perUnit,
      signDisplay,
    }: Partial<FormatAsCurrency> = {},
  ): string {
    const [isNumberValue, numberValue] = this.isNumber(value);
    const parsedPerUnit = this.parsePerUnit(perUnit);

    if (!isNumberValue) {
      return `${new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency,
        signDisplay,
        ...this.getFractionDigitOptions(numberValue, fractionDigits),
      })
        .format(numberValue)
        .replace(String(NaN), defaultString)}${parsedPerUnit}`;
    }

    const roundedNumber =
      roundToNearestHundred && numberValue > 100 ? Math.round(numberValue / 100) * 100 : numberValue;
    const compactDisplayOptions = displayCompact ? this.getNumberFormatCompactOptions() : {};

    return `${new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency,
      signDisplay,
      ...this.getFractionDigitOptions(roundedNumber, fractionDigits, true),
      ...compactDisplayOptions,
    }).format(roundedNumber)}${parsedPerUnit}`;
  }

  /**
   * Format a value as a data size using the locale based on the value size. The value is
   * coerced to a number and then formatted according to the
   * locale.
   *
   * @param value - The value to format.
   * @param options - Options for formatting the value.
   * @param options.defaultString - The default string to return if the value is null or undefined or if parsing to a
   *   Number return NaN.
   * @returns {string} The formatted value.
   */
  formatAsDataSize(value: LocalizeValue, { defaultString = '-' }: Partial<FormatAsDataSize> = {}): string {
    const [isNumberValue, numberValue] = this.isNumber(value);
    if (!isNumberValue) {
      return new Intl.NumberFormat(this.locale, {
        style: 'unit',
        unit: 'byte',
        ...this.getFractionDigitOptions(numberValue, 1),
      })
        .format(numberValue)
        .replace(String(NaN), defaultString);
    }

    const { dataSize, unit } = this.toDataSizeAndUnitType(numberValue);
    return new Intl.NumberFormat(this.locale, {
      style: 'unit',
      unit,
      ...this.getFractionDigitOptions(dataSize, 1),
    }).format(dataSize);
  }

  // TODO: pre 5723: unit should be union type of possible number units in the app
  /**
   * Format a value as compact (abbreviated) number using the locale. The value is
   * coerced to a number and then formatted according to the
   * locale.
   *
   * @param value - The value to format.
   * @param options - Options for formatting the value.
   * @param options.defaultString - The default string to return if the value is null or undefined or if parsing to a
   *   Number return NaN.
   * @param options.unit - The unit to append to the formatted value.
   * @param options.fractionDigits - The number of fractional digits to display when value is not an integer (default
   *   is 2 i.e. 9.00)
   * @param options.signDisplay - The sign display option for the formatter (default is 'auto').
   * @param options.roundToNearestHundred - Rounds up the value to the nearest hundred.
   * @returns {string} The formatted value.
   */
  formatAsCompact(
    value: LocalizeValue,
    {
      defaultString = '-',
      unit,
      fractionDigits = 2,
      signDisplay,
      roundToNearestHundred,
    }: Partial<FormatAsCompact> = {},
  ): string {
    const parsedUnit = this.parseUnit(unit);
    const [isNumberValue, numberValue] = this.isNumber(value);
    if (!isNumberValue) {
      return `${defaultString}${parsedUnit}`;
    }

    const roundedNumber =
      roundToNearestHundred && numberValue > 100 ? Math.round(numberValue / 100) * 100 : numberValue;

    const formattedValue = new Intl.NumberFormat(this.locale, {
      signDisplay,
      ...this.getNumberFormatCompactOptions(),
      ...this.getFractionDigitOptions(roundedNumber, fractionDigits),
    }).format(roundedNumber);

    return `${formattedValue}${parsedUnit}`;
  }
}
