import { Card, CardProps, useTheme } from '@mui/material';
import { energy_source_type_enum } from '@predium/enums';
import { translateEnergySourceTypeEnum_dynamic } from '@predium/i18n/client';
import { Localize } from '@predium/utils';
import { TFunction } from 'i18next';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import sum from 'lodash/sum';
import { memo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../../provider/LanguageProvider';
import BaseOptionChart from '../../../theme/apexcharts';
import addCustomCSVExportButtonToChartToolbar from '../../../utils/addCustomCSVExportButtonToChartToolbar';
import { getFilename } from '../../../utils/getFilename';
import { toCsvContent } from '../../../utils/toCsvContent';

const getCsvContent = (
  series: number[],
  labels: energy_source_type_enum[],
  unit: string,
  t: TFunction<'translation', undefined>,
  localize: Localize,
) => {
  const totalValue = sum(series);

  const csvRows = [
    [t('General_FinalEnergyByEnergySource'), t('General_Percent'), unit],
    ...series.map((it, index) => [
      translateEnergySourceTypeEnum_dynamic(labels[index], t),
      localize.formatAsPercentage((it / totalValue) * 100),
      localize.formatAsFloat(it),
    ]),
  ];

  return toCsvContent(csvRows);
};

interface Props extends CardProps {
  title: string;
  series: number[];
  labels: energy_source_type_enum[];
  unit: string;
  colors: string[];
  animated?: boolean;
  onItemSelect?: (value: energy_source_type_enum) => void;
}

function EnergySourceGraph({ title, series, labels, colors, unit, animated, onItemSelect }: Props) {
  const { t } = useTranslation();
  const { language, localize } = useLanguage();
  const theme = useTheme();
  const totalValue = series.reduce((acc, cur) => acc + cur);

  const filename = getFilename([t('General_FinalEnergyByEnergySource')], language);

  const chartState: ApexCharts.ApexOptions = {
    colors,
    chart: {
      animations: {
        enabled: !!animated,
      },
      type: 'donut',
      events: {
        dataPointSelection: (_, __, config) => {
          setTimeout(() => {
            const value: energy_source_type_enum = config.w.config.labels[config.dataPointIndex];
            onItemSelect?.(value);
          });
        },
        dataPointMouseEnter: function (event) {
          event.target.style.cursor = 'pointer';
        },
        mounted(chart: any) {
          addCustomCSVExportButtonToChartToolbar(
            chart.el.id,
            getCsvContent(series, labels, unit, t, localize),
            filename,
          );
        },
        updated(chart: any) {
          addCustomCSVExportButtonToChartToolbar(
            chart.el.id,
            getCsvContent(series, labels, unit, t, localize),
            filename,
          );
        },
      },
      toolbar: {
        export: {
          svg: {
            filename,
          },
          png: {
            filename,
          },
        },
      },
    },
    title: {
      text: title,
      offsetY: 5,
      margin: 20,
      style: {
        fontSize: theme.typography.h5.fontSize as string,
      },
    },
    legend: {
      position: 'right',
      formatter: (legendEntry: energy_source_type_enum, opts) => {
        const value = opts.w.globals.series[opts.seriesIndex];
        const percent = localize.formatAsPercentage((value / totalValue) * 100);
        const energyValue = localize.formatAsCompact(value, { unit });

        return `<strong>${translateEnergySourceTypeEnum_dynamic(legendEntry, t)}
        </strong> <span style="font-size: 13px; color: ${
          theme.palette.grey[500]
        }">(${percent})</span><p style="font-size: 13px; color: ${theme.palette.grey[500]}">${energyValue}</p>`;
      },
      fontSize: '16px',
      fontWeight: 600,
      fontFamily: theme.typography.fontFamily,
      offsetX: -10,
      offsetY: 10,
      markers: {
        offsetX: -5,
      },
      itemMargin: {
        vertical: 5,
      },
      width: 250,
    },

    dataLabels: { enabled: false },
    tooltip: {
      y: {
        formatter: (value) => `${localize.formatAsCompact(value)} <span style="font-size: 10px">${unit}</span>`,
        title: {
          formatter: (seriesName: energy_source_type_enum) => translateEnergySourceTypeEnum_dynamic(seriesName, t),
        },
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '90%',
          labels: {
            show: true,
            total: {
              show: true,
              label: t('General_Total'),
              color: theme.palette.text.secondary,
              fontSize: theme.typography.subtitle2.fontSize as string,
              fontWeight: theme.typography.subtitle2.fontWeight,
              fontFamily: theme.typography.fontFamily,
              formatter: () => localize.formatAsCompact(totalValue),
            },
            value: {
              show: true,
              offsetY: -20,
              color: theme.palette.text.primary,
              fontSize: theme.typography.h3.fontSize as string,
              fontWeight: theme.typography.h3.fontWeight,
              fontFamily: theme.typography.fontFamily,
              formatter: (value) => localize.formatAsCompact(value),
            },
            name: {
              show: true,
              formatter: (value: energy_source_type_enum | string) =>
                `${unit} (${
                  value === t('General_Total')
                    ? t('General_Total')
                    : translateEnergySourceTypeEnum_dynamic(value as energy_source_type_enum, t)
                })`,
              color: theme.palette.text.secondary,
              offsetY: 20,
            },
          },
        },
      },
    },
    labels,
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.5,
        },
      },
    },
    stroke: {
      width: 0,
    },
    // ---------
    // 'totalValue' here is very important!
    // Reason: if totalValue changes, the ChartOptions object must be updated to reflect these changes in ApexCharts
    // https://github.com/apexcharts/react-apexcharts/issues/107#issuecomment-557318940
    //@ts-ignore
    totalValue,
    // ---------
  };

  return (
    <Card
      sx={{
        p: 4,
        '.apexcharts-legend-series': {
          display: 'flex',
          flexDirection: 'row',
        },
        '.apexcharts-legend-marker': {
          marginTop: 0.5,
          minWidth: 12,
        },
      }}
    >
      <ReactApexChart
        id="energy_source_graph"
        type={chartState.chart?.type}
        options={merge(BaseOptionChart(), chartState)}
        series={series}
        height={300}
      />
    </Card>
  );
}

export default memo(EnergySourceGraph, isEqual);
