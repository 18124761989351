import { Grid, InputAdornment, ListSubheader, MenuItem, Typography } from '@mui/material';
import { getClassOfUse } from '@predium/client-lookup';
import { area_type_enum, sub_building_class_enum, type_of_use_enum } from '@predium/enums';
import { translateSubBuildingClassEnum, translateTypeOfUseEnum } from '@predium/i18n/client';
import { accessEnum } from '@predium/utils';
import groupBy from 'lodash/groupBy';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RHFNumberField, RHFSelect } from '../../../../../../components/hook-form';

const groupedAreasByClassOfUse = groupBy(Object.values(type_of_use_enum), (type) => getClassOfUse(type));

const typeOfUseOptions = Object.entries(groupedAreasByClassOfUse)
  .reverse()
  // fragment can't be used for MUI Select
  .map(([classOfUse, typeOfUses]) => [
    <ListSubheader>{translateSubBuildingClassEnum(accessEnum(sub_building_class_enum, classOfUse))}</ListSubheader>,
    Object.values(typeOfUses).map((type) => (
      <MenuItem key={type} value={type}>
        {translateTypeOfUseEnum(type)}
      </MenuItem>
    )),
  ]);

const Area = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const typeOfUse = watch('area.typeOfUse');

  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">{t('General_Area')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <RHFSelect name="area.typeOfUse" label={t('General_TypeOfUse')} includeEmptyOption>
          {typeOfUseOptions}
        </RHFSelect>
      </Grid>
      {typeOfUse && (
        <>
          <Grid item xs={6}>
            <RHFNumberField
              name="area.totalArea"
              label={t('General_Area')}
              InputProps={{
                endAdornment: <InputAdornment position="end">m²</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <RHFSelect name="area.typeOfArea" disabled label={t('General_TypeOfArea')}>
              <MenuItem value={area_type_enum.EBF}>{t('General_EnergyReferenceArea')}</MenuItem>
            </RHFSelect>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Area;
