import { language_enum } from '@predium/enums';
import { HYPHEN } from './constants';
import { COMMON_DATE_FORMATS, formatDateToLocale } from './formatTime';

const toKebabCase = (str: string): string => {
  return str
    .normalize('NFD')
    .replace(/[\s,:._-]+/g, HYPHEN) // Replace spaces & special chars with hyphen
    .replace(/[^a-zA-Z0-9-]/g, '') // Remove remaining invalid chars
    .replace(/^-+|-+$/g, '') // Trim leading/trailing hyphens
    .toLowerCase();
};

export function getFilename(entities: string[], language: language_enum, includeDate = true): string {
  const localizedDate = formatDateToLocale(new Date(), COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language);

  return ['predium', ...entities, ...(includeDate ? [localizedDate] : [])].map(toKebabCase).join(HYPHEN);
}
