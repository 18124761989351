import { Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import { RoleOption } from '../../../utils/Roles';
import { TableToolbar } from '../Portfolio/PortfolioTableToolbar';

type Props = {
  optionsRole: RoleOption[];
  filterName: string;
  filterRole: RoleOption;
  onFilterName: (value: string) => void;
  onFilterRole: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function UserTableToolbar({
  filterName,
  filterRole,
  onFilterName,
  onFilterRole,
  optionsRole,
  children,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();

  return (
    <TableToolbar>
      <TextField
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder={t('General_SearchUser')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          ),
        }}
        sx={{
          maxWidth: '200px',
          '.MuiOutlinedInput-input': {
            boxSizing: 'border-box',
            height: 36,
            py: 0.5,
            pr: 1.5,
          },
        }}
      />
      <TextField
        select
        label={t('General_Role')}
        value={filterRole.name}
        onChange={onFilterRole}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { sm: 240 },
          minWidth: 120,
          textTransform: 'capitalize',
          '.MuiOutlinedInput-input': {
            display: 'flex',
            alignItems: 'center',
            py: 0.5,
            pr: 1,
          },
          '& .MuiOutlinedInput-root': {
            boxSizing: 'border-box',
            height: 36,
          },
        }}
      >
        {optionsRole.map((option) => (
          <MenuItem
            key={option.name}
            value={option.name}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option.displayName}
          </MenuItem>
        ))}
      </TextField>
      <Box sx={{ marginLeft: 'auto' }}>{children}</Box>
    </TableToolbar>
  );
}
