import { graphql } from '@predium/client-graphql';

export const DELETE_ACTIONPLAN = graphql(`
  mutation ActionPlanningActionDeleteActionPlan($actionPlanId: Int!) {
    delete_action_plan_by_pk(id: $actionPlanId) {
      __typename
      id
    }
  }
`);

export const EXPORT_SCENARIO_AS_EXCEL = graphql(`
  mutation ExportScenarioAsExcel($scenarioId: Int!) {
    exportScenarioAsExcel(scenario_id: $scenarioId) {
      body
    }
  }
`);

export const DELETE_ACTION = graphql(`
  mutation ActionPlanDeleteAction($actionId: Int!) {
    delete_action_by_pk(id: $actionId) {
      __typename
      id
    }
  }
`);

graphql(`
  fragment SimulateActionOutputResponse on SimulateActionOutput {
    energy_final
    energy_primary
    heating_demand
    co2_emissions
    stranding_date
    efficiency_class
    renovation_costs
    energy_final_cost
    co2_tax_cost
  }
`);

graphql(`
  fragment EditActionOutputResponse on EditActionOutput {
    message
  }
`);

export const SIMULATE_ACTION = graphql(`
  mutation SimulateTechologyAction(
    $action_plan_id: Int!
    $envelope_renovation_parameter: EnvelopeRenovationParameterInput
    $system_renovation_parameter: SystemRenovationParameterInput
    $energy_system_route_renovation_parameter: EnergySystemRouteRenovationParameterInput
    $hydraulic_balancing_renovation_parameter: HydraulicBalancingRenovationParameterInput
    $solar_renovation_parameter: SolarRenovationParameterInput
    $custom_action_renovation_parameter: CustomActionRenovationParameterInput
    $implementation_to: DateTime!
  ) {
    simulateAction(
      action_plan_id: $action_plan_id
      custom_action_renovation_parameter: $custom_action_renovation_parameter
      envelope_renovation_parameter: $envelope_renovation_parameter
      system_renovation_parameter: $system_renovation_parameter
      energy_system_route_renovation_parameter: $energy_system_route_renovation_parameter
      hydraulic_balancing_renovation_parameter: $hydraulic_balancing_renovation_parameter
      solar_renovation_parameter: $solar_renovation_parameter
      implementation_to: $implementation_to
    ) {
      ...SimulateActionOutputResponse
    }
  }
`);

export const IMPLEMENT_ACTION = graphql(`
  mutation ImplementAction(
    $action_plan_id: Int!
    $implementation_from: DateTime!
    $implementation_to: DateTime!
    $envelope_renovation_parameter: EnvelopeRenovationParameterInput
    $system_renovation_parameter: SystemRenovationParameterInput
    $energy_system_route_renovation_parameter: EnergySystemRouteRenovationParameterInput
    $hydraulic_balancing_renovation_parameter: HydraulicBalancingRenovationParameterInput
    $solar_renovation_parameter: SolarRenovationParameterInput
    $subsidies: [SubsidyInput!]!
    $customCost: CustomCostInput
    $custom_action_renovation_parameter: CustomActionRenovationParameterInput
  ) {
    implementAction(
      action_plan_id: $action_plan_id
      implementation_from: $implementation_from
      implementation_to: $implementation_to
      envelope_renovation_parameter: $envelope_renovation_parameter
      system_renovation_parameter: $system_renovation_parameter
      energy_system_route_renovation_parameter: $energy_system_route_renovation_parameter
      hydraulic_balancing_renovation_parameter: $hydraulic_balancing_renovation_parameter
      solar_renovation_parameter: $solar_renovation_parameter
      subsidies: $subsidies
      customCost: $customCost
      custom_action_renovation_parameter: $custom_action_renovation_parameter
    ) {
      action_id
    }
  }
`);

export const EDIT_ACTION = graphql(`
  mutation EditAction(
    $action_id: Int!
    $envelope_renovation_parameter: EnvelopeRenovationParameterInput!
    $implementation_to: DateTime!
    $customCost: CustomCostInput!
  ) {
    editAction(
      action_id: $action_id
      implementation_to: $implementation_to
      envelope_renovation_parameter: $envelope_renovation_parameter
      customCost: $customCost
    ) {
      ...EditActionOutputResponse
    }
  }
`);

export const UPDATE_CUSTOM_COST = graphql(`
  mutation UpdateCustomCost($action_id: Int!, $custom_cost: renovation_set_input) {
    update_renovation(where: { action_id: { _eq: $action_id } }, _set: $custom_cost) {
      returning {
        id
      }
    }
  }
`);

export const CREATE_ACTION_PLAN = graphql(`
  mutation CreateActionPlanModalCreateActionPlan($scenario_id: Int!, $building_id: Int!) {
    insert_action_plan(objects: { building_id: $building_id, scenario_id: $scenario_id }) {
      returning {
        id
      }
    }
  }
`);

export const CREATE_SCENARIO = graphql(`
  mutation CreateScenarioModalCreateScenario($description: String!, $name: String!, $portfolio_id: Int!) {
    insert_scenario(objects: { portfolio_id: $portfolio_id, name: $name, description: $description }) {
      returning {
        id
        name
      }
    }
  }
`);

export const DELETE_SCENARIO = graphql(`
  mutation ActionPlanningScenarioDeleteScenario($id: Int!) {
    delete_scenario_by_pk(id: $id) {
      __typename
      id
    }
  }
`);

export const UPDATE_SCENARIO = graphql(`
  mutation UpdateScenario($id: Int!, $name: String!, $description: String) {
    update_scenario_by_pk(pk_columns: { id: $id }, _set: { name: $name, description: $description }) {
      id
    }
  }
`);
