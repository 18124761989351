/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useApolloClient, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  CreatedEnergyCertificateDraftFragment,
  DataCollectionEconomicUnitFragment,
  DataCollectionEconomicUnitPortfoliosFragment,
} from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import DropzoneComponent, { UploadingFile } from '../../../../components/DropzoneComponent';
import Iconify from '../../../../components/Iconify';
import { DelayedLoading } from '../../../../components/Loading';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import { FormProvider, RHFSelect } from '../../../../components/hook-form';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import { CREATE_ENERGY_CERTIFICATE_DRAFT } from '../../../../graphql/DataCollection.mutations';
import { GET_ECONOMIC_UNITS_WITH_PORTFOLIOS } from '../../../../graphql/DataCollection.queries';
import { GET_PORTFOLIOS_WITH_WRITE_PERMISSIONS } from '../../../../graphql/Portfolio.queries';
import useOrgPreferences from '../../../../hooks/useOrgPreferences';
import useSessionData from '../../../../hooks/useSessionData';
import useUpdateFilename from '../../../../hooks/useUpdateFilename';
import AddEconomicUnitModal from '../../EconomicUnit/AddEconomicUnitModal';

type FormValuesProps = {
  economic_unit_id: string;
  portfolio_id: string;
};

type Props = {
  onEnergyCertificateDraftsAdded: (energyCertificateDraftIds: number[]) => void;
  createdEnergyCertificateDrafts: React.MutableRefObject<CreatedEnergyCertificateDraftFragment[]>;
  selectedPortfolio?: string;
};

type PortfolioEconomicUnit = {
  id: number;
  economicUnits: DataCollectionEconomicUnitFragment[];
};

export const arrangeEconomicUnitsByPortfolios = (
  data: DataCollectionEconomicUnitPortfoliosFragment[],
): PortfolioEconomicUnit[] => {
  const resultMap = new Map<number, PortfolioEconomicUnit>();

  for (const { id, name, customer_economic_unit_id, portfolio } of data) {
    const { id: portfolioId } = portfolio;
    if (!resultMap.has(portfolioId)) {
      resultMap.set(portfolioId, {
        id: portfolioId,
        economicUnits: [{ id, name, customer_economic_unit_id, __typename: 'economic_unit' }],
      });
    } else {
      //@ts-ignore
      resultMap
        .get(portfolioId)
        .economicUnits.push({ id, name, customer_economic_unit_id, __typename: 'economic_unit' });
    }
  }

  return Array.from(resultMap.values());
};

/**
 * A component that is used in when user clicks on "Add building" from table toolbar
 * and they need to select a portfolio and economic unit before uploading the building
 */
export default function UploadEnergyCertificateDraftModalCommon({
  onEnergyCertificateDraftsAdded,
  createdEnergyCertificateDrafts,
  selectedPortfolio,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { economicUnitsToggled } = useOrgPreferences();
  const {
    //@ts-ignore
    user: { id: userId },
  } = useSessionData();

  const apollo = useApolloClient();
  const { updateFilename } = useUpdateFilename();
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState<FormValuesProps | null>(null);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [economicUnitsData, setEconomicUnitsData] = useState<PortfolioEconomicUnit[]>([]);
  const [openEconomicUnitModal, setEconomicUnitsModalOpen] = useState<boolean>(false);
  const [selectOpen, setSelectOpen] = useState(false);

  const { loading, data: portfolios } = useQuery(GET_PORTFOLIOS_WITH_WRITE_PERMISSIONS, {
    variables: { user_id: userId },
    onCompleted(data) {
      if (selectedPortfolio) {
        const selectedPortfolioId = data?.portfolio?.filter((p) => p.name === selectedPortfolio)?.[0].id;

        setValue('portfolio_id', selectedPortfolioId.toString());
      }
    },
  });

  const { loading: ecoUnitsLoading, refetch } = useQuery(GET_ECONOMIC_UNITS_WITH_PORTFOLIOS, {
    onCompleted: (data) => {
      const newData = arrangeEconomicUnitsByPortfolios(data.economic_unit);
      setEconomicUnitsData(newData);
    },
  });

  const EnergyCertificateDraftBaseInfoSchema = Yup.object().shape({
    portfolio_id: Yup.string().required(t('General_PortfolioIsRequired')),
    economic_unit_id: Yup.string(),
  });

  const methods = useForm<FormValuesProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(EnergyCertificateDraftBaseInfoSchema),
    defaultValues: {
      portfolio_id: '',
      economic_unit_id: '',
    },
  });

  const { watch, control, unregister, setValue } = methods;

  const portfolio_id = watch('portfolio_id');

  useEffect(() => {
    if (!economicUnitsToggled) {
      unregister('economic_unit_id');
    }
  }, [economicUnitsToggled, unregister]);

  if (loading || ecoUnitsLoading) {
    return <DelayedLoading />;
  }
  const portfolio = economicUnitsData.find((item) => item.id === parseInt(portfolio_id as unknown as string));
  const economicUnitsSorted: DataCollectionEconomicUnitFragment[] = portfolio?.economicUnits ?? [];

  //@ts-ignore
  const asyncCreateUploadUrl = async (): Promise<UploadingFile> => {
    const { data, errors } = await apollo.mutate({
      mutation: CREATE_ENERGY_CERTIFICATE_DRAFT,
      variables: {
        //@ts-ignore
        portfolioId: parseInt(formValues.portfolio_id),

        //@ts-ignore
        economicUnitId: parseInt(formValues.economic_unit_id),
      },
    });

    if (errors) {
      enqueueSnackbar(t('DataCollection_EnergyCertificateUpload_ErrorMessage'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    } else {
      //@ts-ignore

      //@ts-ignore
      createdEnergyCertificateDrafts.current.push(data.createEnergyCertificateDraft.CreateEnergyCertificateDraftOutput);

      return {
        //@ts-ignore
        fileUrl: data.createEnergyCertificateDraft.upload_url,

        //@ts-ignore

        //@ts-ignore
        fileId: data.createEnergyCertificateDraft.CreateEnergyCertificateDraftOutput.energy_certificate_file_id,
      };
    }
  };

  const onSubmit = async (values: FormValuesProps) => {
    setFormValues(values);
    setActiveStep(1);
  };

  return (
    <>
      {activeStep === 0 && (
        <>
          <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogTitle>{t('General_CreateBuilding')}</DialogTitle>
            <DialogContent>
              <Stack spacing={3} width={'100%'}>
                <RHFSelect
                  name="portfolio_id"
                  label="Portfolio"
                  helperText={t('DataCollection_EnergyCertificateUpload_PortfolioHelperText')}
                  sx={{ mt: 1 }}
                >
                  {/*@ts-ignore */}
                  {portfolios.portfolio.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </RHFSelect>
                {economicUnitsToggled && portfolio_id && (
                  <Controller
                    name="economic_unit_id"
                    control={control}
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                      <FormControl variant="outlined">
                        <TextField
                          {...field}
                          select
                          label={t('General_EconomicUnit')}
                          error={!!error}
                          helperText={t('DataCollection_EnergyCertificateUpload_EconomicUnitHelperText')}
                          SelectProps={{
                            open: selectOpen,
                            onOpen: () => setSelectOpen(true),
                            onClose: () => setSelectOpen(false),
                          }}
                        >
                          {economicUnitsSorted.map((option: DataCollectionEconomicUnitFragment) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                              {option.customer_economic_unit_id && ` (${option.customer_economic_unit_id})`}
                            </MenuItem>
                          ))}
                          <Divider />
                          <MenuItem
                            onClick={() => {
                              setEconomicUnitsModalOpen(true);
                              setValue('economic_unit_id', '');
                              setSelectOpen(false);
                            }}
                          >
                            <Button
                              sx={{
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                              size="small"
                              variant="text"
                            >
                              {t('General_AddNewEconomicUnit')}
                            </Button>
                          </MenuItem>
                        </TextField>
                      </FormControl>
                    )}
                  />
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                endIcon={<Iconify icon={'formkit:arrowright'} width={18} height={18} />}
              >
                {t('General_SelectEnergyCertificate')}
              </Button>
            </DialogActions>
          </FormProvider>
        </>
      )}

      {activeStep === 1 && (
        <>
          <DialogTitle>{t('General_CreateNewBuilding')}</DialogTitle>
          <DialogContent>
            <DropzoneComponent
              fileDisplayName={t('General_EnergyCertificate')}
              createUploadUrl={asyncCreateUploadUrl}
              description={
                <Typography variant="body1">
                  <Trans
                    i18nKey="DataCollection_AddNewEnergyCertificate_Description"
                    components={{ underline: <span style={{ textDecoration: 'underline' }} /> }}
                  />
                  {
                    //Delete document/draft option if possible : PRE-737
                  }
                </Typography>
              }
              onUploadSuccess={(file) => {
                updateFilename(file.fileId, file.name);
              }}
              setUploadInProgress={setUploadInProgress}
            />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => onEnergyCertificateDraftsAdded(createdEnergyCertificateDrafts.current.map((d) => d.id))}
              variant="contained"
              disabled={uploadInProgress || createdEnergyCertificateDrafts.current.length === 0}
              fullWidth
            >
              {t('General_ReviewData')}
            </Button>
          </DialogActions>
        </>
      )}

      <PreDialog
        type="definedByChildren"
        fullWidth
        open={openEconomicUnitModal}
        onClose={() => {
          setEconomicUnitsModalOpen(false);
        }}
      >
        <AddEconomicUnitModal
          onEconomicUnitAdded={async (id: number) => {
            await refetch();
            setValue('economic_unit_id', id.toString());
            setEconomicUnitsModalOpen(false);
          }}
          onCancel={() => setEconomicUnitsModalOpen(false)}
          portfolioId={portfolio_id}
        />
      </PreDialog>
    </>
  );
}
