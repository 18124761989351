import { IconButton, MenuItem, TextField, useTheme } from '@mui/material';

import { Stack } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../assets/icons';
import Iconify from '../../../../../components/Iconify';
import usePosthogTracking from '../../../../../hooks/usePosthogTracking';
import { getMaxDate } from '../../ActionPlan.utils';
import { ActionType, filters, SortingId } from '../RecommendedActions';

type RecommendedActionsFiltersProps = {
  actionsLoading: boolean;
  stickyFilters: boolean;
  selectedSorting: SortingId;
  setSelectedSorting: (sorting: SortingId) => void;
  selectedButton: ActionType;
  setSelectedButton: (button: ActionType) => void;
  newDate: Date;
  setNewDate: (date: Date) => void;
  minDate: Date;
  setExpanded: (expanded: boolean) => void;
  expanded: boolean;
  buttonLabels: (t: TFunction<'translation', undefined>) => { id: string; name: string }[];
};

export default function RecommendedActionsFilters({
  actionsLoading,
  stickyFilters,
  selectedSorting,
  setSelectedSorting,
  selectedButton,
  setSelectedButton,
  newDate,
  setNewDate,
  minDate,
  setExpanded,
  expanded,
  buttonLabels,
}: RecommendedActionsFiltersProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { trackEvent } = usePosthogTracking();

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{
        position: stickyFilters ? 'sticky' : 'relative',
        right: 16,
        backgroundColor: theme.palette.grey[100],
        zIndex: 2,
        pl: 2,
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          height: '100%',
          width: '8px',
          background: `linear-gradient(to left, ${theme.palette.grey[100]}, transparent)`,
        },
      }}
    >
      <TextField
        label={t('General_Goal')}
        select
        disabled={actionsLoading}
        size="small"
        value={selectedSorting}
        onChange={(e) => {
          setExpanded(true);
          trackEvent('RECOMMENDED_ACTION_FILTER_CHANGED', {
            sorting: e.target.value,
          });
          setSelectedSorting(e.target.value as SortingId);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{
          width: 300,
          '& .MuiOutlinedInput-input': {
            py: 1,
            pl: 1.5,
          },
        }}
      >
        {filters(t).map((filter) => (
          <MenuItem
            key={filter.id}
            value={filter.id}
            sx={{
              typography: 'body2',
              my: 0.5,
              borderRadius: 0.75,
            }}
          >
            {filter.name}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label={t('General_ActionType')}
        select
        disabled={actionsLoading}
        size="small"
        value={selectedButton}
        onChange={(event) => {
          setExpanded(true);
          const value = event.target.value as ActionType;
          if (value !== selectedButton) {
            setSelectedButton(value);
          }
        }}
        sx={{
          width: 160,
          '& .MuiOutlinedInput-input': {
            py: 1,
            pl: 1.5,
          },
        }}
      >
        {buttonLabels(t).map((button) => (
          <MenuItem
            key={button.id}
            value={button.id}
            sx={{
              typography: 'body2',
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
            }}
          >
            {button.name}
          </MenuItem>
        ))}
      </TextField>

      <DesktopDatePicker
        label={t('General_PlanFor')}
        value={newDate}
        disabled={actionsLoading}
        onChange={(date) => {
          setExpanded(true);
          const selectedDate = new Date(date as Date);
          if (selectedDate.getDate() === minDate.getDate() || selectedDate > minDate) {
            setNewDate(selectedDate);
          }
        }}
        renderInput={(params) => <TextField {...params} size="small" sx={{ width: 160 }} />}
        minDate={minDate}
        maxDate={getMaxDate()}
      />

      <IconButton onClick={() => setExpanded(!expanded)}>
        <Iconify icon={expanded ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN} />
      </IconButton>
    </Stack>
  );
}
