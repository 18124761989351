import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, Typography } from '@mui/material';
import {
  area_system_type_enum,
  area_type_enum,
  data_source_type_enum,
  sub_building_class_enum,
  type_of_use_enum,
} from '@predium/enums';
import { ensureDefined } from '@predium/utils';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Iconify from '../../../../components/Iconify';
import NavigationModal from '../../../../components/NavigationModal/NavigationModal';
import { FormProvider } from '../../../../components/hook-form';
import AccessRightsWrapper from '../../../../components/permission-tooltips/AccessRightsWrapper';
import { InconsistentDataAlert } from '../Common/BuildingAlerts';
import useBuilding from '../Context/useBuilding';
import AreaSummary from './AreaSummary';
import AreaTabs from './AreaTabs';
import { CalculatorProvider } from './Calculator/CalculatorProvider';
import SaveChangesModal from './SaveChangesModal';
import { ValidationProvider, ValidationProviderRef } from './Validation/ValidationProvider';
import { Validation } from './Validation/validations';
import { Area, AreaFormValues, AreaRef, AreaState } from './types';

type Props = {
  initialAreas: Area[];
  isActionPlanningAvailable: boolean;
  setAreaState: React.Dispatch<React.SetStateAction<AreaState>>;
};

const AreaSchema = yup.object({
  areas: yup.array().of(
    yup.object({
      id: yup.number().optional(),
      value: yup.number().required(),
      create: yup.boolean().when('id', {
        is: (id: number) => id !== undefined,
        then: (schema) => schema.oneOf([false]).optional(),
      }),
      delete: yup.boolean().when('id', {
        is: (id: number) => id === undefined,
        then: (schema) => schema.oneOf([false]).optional(),
      }),
      area_type_id: yup.mixed<area_type_enum>().oneOf(Object.values(area_type_enum)).required(),
      area_system_id: yup.mixed<area_system_type_enum>().oneOf(Object.values(area_system_type_enum)).required(),
      is_rented: yup.boolean().required(),
      class_of_use_id: yup.mixed<sub_building_class_enum>().oneOf(Object.values(sub_building_class_enum)).required(),
      type_of_use_id: yup.mixed<type_of_use_enum>().oneOf(Object.values(type_of_use_enum)).required(),
      data_source_id: yup.mixed<data_source_type_enum>().oneOf(Object.values(data_source_type_enum)).optional(),
      name: yup.string(),
      description: yup.string(),
    }),
  ),
});

const Areas = forwardRef<AreaRef, Props>(({ initialAreas, isActionPlanningAvailable, setAreaState }, ref) => {
  const { t } = useTranslation();
  const { hasEditAccess } = useBuilding();
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const validationRef = useRef<ValidationProviderRef>(null);

  const methods = useForm<AreaFormValues>({
    defaultValues: {
      areas: initialAreas,
    },
    resolver: yupResolver(AreaSchema),
  });

  const { reset, formState } = methods;
  const { isDirty } = formState;

  const handlePropagateFormState = useCallback(
    (errors: Validation[], onClick: VoidFunction) => {
      setAreaState({
        errorLabel: errors[0] ? (
          <Stack direction="row" alignItems="center" onClick={onClick} spacing={0.5} sx={{ cursor: 'pointer' }}>
            <Iconify color="error.main" icon="mdi:alert-circle" />
            <Typography fontSize={14} color="error.darker">
              {errors[0].errorLabels.title}
            </Typography>
          </Stack>
        ) : null,
        isDirty: methods.formState.isDirty,
      });
    },
    [methods, setAreaState],
  );

  const handleCancel = useCallback(() => {
    setAreaState({
      errorLabel: null,
      isDirty: false,
    });

    reset({ areas: initialAreas });

    ensureDefined(validationRef.current).triggerValidation();
  }, [initialAreas, reset, setAreaState]);

  const handleSave = useCallback(() => {
    setIsSaveModalOpen(true);
  }, []);

  useEffect(() => {
    handleCancel();
  }, [handleCancel]);

  useImperativeHandle(ref, () => ({
    onCancel: handleCancel,
    onSave: handleSave,
  }));

  return (
    <FormProvider methods={methods}>
      <CalculatorProvider>
        <ValidationProvider handlePropagateFormState={handlePropagateFormState} ref={validationRef}>
          <AccessRightsWrapper hasAccess={hasEditAccess}>
            {!isActionPlanningAvailable && (
              <Box mb={3}>
                <InconsistentDataAlert />
              </Box>
            )}
          </AccessRightsWrapper>
          <Stack direction="row" spacing={2}>
            <AreaTabs />
            <AreaSummary />
          </Stack>
          <NavigationModal
            title={t('General_LeavePageTitle')}
            message={t('General_CancelEditModalDescription')}
            primaryActionText={t('General_KeepEditing')}
            secondaryActionText={t('General_Continue')}
            primaryActionCallback={() => false}
            reverseActions
            singleBlock={false}
            shouldBlock={isDirty}
            primaryActionProps={{
              variant: 'outlined',
            }}
            secondaryActionProps={{
              variant: 'contained',
              startIcon: null,
            }}
          />
          <SaveChangesModal isSaveModalOpen={isSaveModalOpen} setIsSaveModalOpen={setIsSaveModalOpen} />
        </ValidationProvider>
      </CalculatorProvider>
    </FormProvider>
  );
});

export default Areas;
