/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { DataCollectionGetEconomicUnitsListWithBuildingsQuery } from '@predium/client-graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import { DelayedLoading } from '../../../components/Loading';
import OverflowText from '../../../components/OverflowText';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import Unavailable from '../../../components/Unavailable';
import PreDialog from '../../../components/presentations/PreDialog/PreDialog';
import { TableSelectedActions } from '../../../components/table';
import TablePaginationStandard from '../../../components/table/TablePaginationStandard';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../components/table/TableRowWithHighlight';
import { GET_ECONOMIC_UNITS_WITH_BUILDINGS } from '../../../graphql/DataCollection.queries';
import { GET_PORTFOLIOS } from '../../../graphql/Portfolio.queries';
import useDataCollectionFilters from '../../../hooks/useDataCollectionFilters';
import usePermissions from '../../../hooks/usePermissions';
import useTable, { applySortFilter, OnFilterCallbackParams } from '../../../hooks/useTable';
import { ALL_PORTFOLIOS } from '../../EsgAnalysis/NavigationSelectors/PortfolioSelector';
import AddEconomicUnitModal from '../EconomicUnit/AddEconomicUnitModal';
import EconomicUnitActionMenu from '../EconomicUnit/EconomicUnitActionMenu';
import EconomicUnitActionsMenu from './EconomicUnitActionsMenu';
import EconomicUnitTableHead, { HeadLabel } from './EconomicUnitsHead';
import EconomicUnitsListBuildingsList from './EconomicUnitsListBuildingsList';
import EconomicUnitToolbar from './EconomicUnitsToolbar';

type Props = {
  onFilterChange: ({ orderBy, order }: OnFilterCallbackParams) => void;
  tabTableFilter?: {
    orderBy: string;
    order: 'asc' | 'desc';
  };
};

export default function DataCollectionEconomicUnitList({ onFilterChange, tabTableFilter }: Props) {
  const { t } = useTranslation();
  const { hasPortfolioWithWriteAccess } = usePermissions();

  const { page, setPage, rowsPerPage, setRowsPerPage, order, orderBy, setOrder, setOrderBy, handleRequestSort } =
    useTable({
      defaultOrderBy: tabTableFilter?.orderBy || 'economic_unit_name',
      defaultOrder: tabTableFilter?.order,
      defaultRowsPerPage: 25,
      onSortCallback: onFilterChange,
    });

  const [selectedEconomicUnits, setSelectedEconomicUnits] = useState<number[]>([]);
  const [openEconomicUnitModal, setEconomicUnitsModalOpen] = useState<boolean>(false);
  const [portfolioOptions, setPortfolioOptions] = useState<string[]>([]);

  const {
    filters: {
      dataCollectionEconomicUnitsFilters: { filterName, selectedPortfolio },
    },
    setSelectedPortfolioEconomicUnits: setSelectedPortfolio,
    setFilterNameEconomicUnit: handleFilterByName,
  } = useDataCollectionFilters();
  const { loading: portfoliosLoading } = useQuery(GET_PORTFOLIOS, {
    onCompleted: (data) => {
      //@ts-ignore
      setPortfolioOptions([ALL_PORTFOLIOS, ...data.portfolio.map((portfolio) => portfolio.name)]);
    },
  });

  const {
    loading: economicsUnitsLoading,
    data: economicUnits,
    refetch: refetchEconomicUnits,
  } = useQuery(GET_ECONOMIC_UNITS_WITH_BUILDINGS);

  if (economicsUnitsLoading || portfoliosLoading || !economicUnits) {
    return <DelayedLoading />;
  }

  const rows = economicUnits.economic_unit;

  const filteredData = applySortFilter({
    data: rows,
    nameFilter: {
      fieldName: 'name',
      filterValue: filterName,
    },

    //@ts-ignore
    dataFilters: [
      selectedPortfolio !== ALL_PORTFOLIOS
        ? (economicUnit: (typeof rows)[number]) => economicUnit.portfolio.name === selectedPortfolio
        : undefined,
    ].filter(Boolean),
    orderOptions: {
      order,
      orderBy,
    },
  });

  const handleEconomicUnitCheckbox = (id: number) => {
    const selectedIndex = selectedEconomicUnits.indexOf(id);

    if (selectedIndex === -1) {
      setSelectedEconomicUnits([...selectedEconomicUnits, id]);
    } else {
      // removes the index from the array in a more efficient way than filter
      setSelectedEconomicUnits(
        //@ts-ignore
        [].concat(selectedEconomicUnits.slice(0, selectedIndex), selectedEconomicUnits.slice(selectedIndex + 1)),
      );
    }
  };

  const handleSelectAllEconomicUnits = (checked: boolean) => {
    if (checked) {
      const newSelectedIds = filteredData.map((units) => units.id);
      setSelectedEconomicUnits(newSelectedIds);
    } else {
      setSelectedEconomicUnits([]);
    }
  };

  const onEconomicUnitAdded = () => {
    refetchEconomicUnits();
    setEconomicUnitsModalOpen(false);

    //make sure the newest economic unit is on top
    setOrderBy('created_at');
    setOrder('desc');
  };

  const isNotFound = filteredData.length === 0 && Boolean(filterName);

  const economicUnitsColumns: HeadLabel[] = [
    { id: 'name', label: t('General_EconomicUnit'), minWidth: 300 },
    { id: 'customer_economic_unit_id', label: t('General_Identifier'), minWidth: 160 },
    { id: 'first_building[0].address.city', label: t('General_City'), minWidth: 140 },
    { id: 'first_building[0].address.postal_code', label: t('General_PostalCode'), minWidth: 140 },
    { id: 'portfolio', label: t('General_Portfolio'), sortingDisabled: true, minWidth: 160 },
    { id: 'action_menu', label: '', sortingDisabled: true },
  ];

  return (
    <>
      {rows.length === 0 ? (
        <>
          <Unavailable
            title={t('DataCollection_NoEconomicUnitsAvailable-title')}
            subTitle={t('DataCollection_NoEconomicUnitsAvailable-subtitle')}
          />
          <Stack
            sx={{
              alignItems: 'center',
              mt: 0.5,
            }}
          >
            <Button
              onClick={() => setEconomicUnitsModalOpen(true)}
              size="medium"
              variant="contained"
              startIcon={<Iconify icon={'ic:baseline-plus'} />}
            >
              {t('General_AddEconomicUnit')}
            </Button>
          </Stack>
        </>
      ) : (
        <Card>
          <EconomicUnitToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            handlePortfolioChange={(portfolioName) => setSelectedPortfolio(portfolioName)}
            portfolioOptions={portfolioOptions}
            selectedPortfolio={selectedPortfolio}
          >
            {hasPortfolioWithWriteAccess && (
              <Button
                variant="contained"
                onClick={() => setEconomicUnitsModalOpen(true)}
                sx={{ marginLeft: 'auto' }}
                size="medium"
                startIcon={<Iconify icon={'ic:baseline-plus'} />}
              >
                {t('General_AddEconomicUnit')}
              </Button>
            )}
          </EconomicUnitToolbar>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {selectedEconomicUnits.length > 0 && (
                <TableSelectedActions
                  selectedText={t('General_EconomicUnitSelected', { count: selectedEconomicUnits.length })}
                  numSelected={selectedEconomicUnits.length}
                  rowCount={selectedEconomicUnits.length}
                  onSelectAllRows={handleSelectAllEconomicUnits}
                  action={
                    <EconomicUnitActionsMenu
                      selectedEconomicUnits={selectedEconomicUnits}
                      economicUnitsData={rows}
                      onActionCompleted={() => setSelectedEconomicUnits([])}
                    />
                  }
                  sx={{ pl: 8.5, pr: 3 }}
                />
              )}

              <Table>
                <EconomicUnitTableHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={economicUnitsColumns}
                  rowCount={filteredData.length}
                  numSelected={selectedEconomicUnits.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllEconomicUnits}
                />
                {/*
              Using colgroup to set the width of the columns. needed for nested tables to also have same width as parent columns width
              there might be another solution for this but will be explored later
              */}
                <colgroup>
                  <col width={'60px'}></col>
                  <col width={'60px'}></col>
                  <col width={'30%'}></col>
                  <col width={'15%'}></col>
                  <col width={'15%'}></col>
                  <col width={'15%'}></col>
                  <col width={'15%'}></col>
                  <col></col>
                </colgroup>
                {filteredData.length > 0 && (
                  <TableBody>
                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <Row
                          key={row.id}
                          row={row}
                          selectedEconomicUnits={selectedEconomicUnits}
                          handleEconomicUnitCheckbox={handleEconomicUnitCheckbox}
                        />
                      );
                    })}
                  </TableBody>
                )}
                {isNotFound && (
                  <TableBody>
                    <SearchNotFound searchQuery={filterName} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePaginationStandard
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Card>
      )}
      <PreDialog
        type="definedByChildren"
        fullWidth
        open={openEconomicUnitModal}
        onClose={() => {
          setEconomicUnitsModalOpen(false);
        }}
      >
        <AddEconomicUnitModal
          onEconomicUnitAdded={onEconomicUnitAdded}
          onCancel={() => setEconomicUnitsModalOpen(false)}
        />
      </PreDialog>
    </>
  );
}

type RowProps = {
  row: DataCollectionGetEconomicUnitsListWithBuildingsQuery['economic_unit'][number];
  handleEconomicUnitCheckbox: (id: number) => void;
  selectedEconomicUnits: number[];
};

function Row({ row, handleEconomicUnitCheckbox, selectedEconomicUnits }: RowProps) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const isItemSelected = selectedEconomicUnits.indexOf(row.id) !== -1;

  return (
    <>
      <TableRowWithHighlight
        background={
          hasRecentlyChanged(row.created_at) ? 'success' : hasRecentlyChanged(row.updated_at) ? 'warning' : 'default'
        }
        removeHighlightOnHover={false}
        sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
        selected={isItemSelected}
      >
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            <Iconify icon="ic:outline-arrow-forward-ios" style={{ transform: `rotate(${open ? '90deg' : '0deg'})` }} />
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <Checkbox checked={isItemSelected} onClick={() => handleEconomicUnitCheckbox(row.id)} />
        </TableCell>
        <TableCell onClick={() => setOpen(!open)}>
          <OverflowText
            text={row.name}
            maxWidth="250px"
            TooltipProps={{
              arrow: true,
              placement: 'top-start',
            }}
          />
        </TableCell>
        <TableCell onClick={() => setOpen(!open)}>{row.customer_economic_unit_id ?? '-'}</TableCell>
        <TableCell onClick={() => setOpen(!open)}>{row.first_building[0]?.address.city ?? '-'}</TableCell>
        <TableCell onClick={() => setOpen(!open)}>{row.first_building[0]?.address.postal_code ?? '-'}</TableCell>
        <TableCell onClick={() => setOpen(!open)}>{row.portfolio.name}</TableCell>
        <TableCell align="right">
          <EconomicUnitActionMenu economicUnit={row} />
        </TableCell>
      </TableRowWithHighlight>
      {row.first_building.length ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <EconomicUnitsListBuildingsList economicUnitId={row.id} isEconomicUnitSelected={isItemSelected} />
            </Collapse>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ textAlign: 'center', p: 2 }}>
                <Typography variant="caption">{t('DataCollection_NoBuildingExistsInEconomicUnit')}</Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
