/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  ActionPlanningActionSubsidyFragment,
  BuildingModelFragment,
  DataCollectionSubBuildingSubBuildingSubsidyFragment,
  RecommendedActionImpactOutput,
  SimulateActionOutputResponseFragment,
  SubsidyInput,
  country_enum,
  renovation_type_enum,
} from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import Scrollbar from '../../../../components/Scrollbar';
import { FormProvider } from '../../../../components/hook-form';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import { IMPLEMENT_ACTION, SIMULATE_ACTION } from '../../../../graphql/ActionPlanning.mutations';
import {
  GET_ACTIONPLAN,
  GET_ACTIONS_FOR_ACTION_PLAN,
  GET_ACTION_PLAN_METRICS,
  GET_SUBSIDIES,
} from '../../../../graphql/ActionPlanning.queries';
import usePosthogTracking from '../../../../hooks/usePosthogTracking';
import { useLanguage } from '../../../../provider/LanguageProvider';
import { getActionIcon } from '../../../../utils/icons';
import {
  getMaxDate,
  getModificationImpactAfterSimulation,
  getRecommendedActionModificationImpactBeforeSimulation,
} from '../ActionPlan.utils';
import { RecommendationOutput } from '../ActionPlanSections/RecommendedActions';
import { getRecommendedActionTitle } from '../ActionPlanSections/RecommendedActions/RecommendedAction';
import DiscardActionModal from '../Components/DiscardActionModal';
import ActionDetails from './ActionDetails';
import { CustomCostsForm } from './ActionDetails/ActionPricing/RowCosts';
import {
  ActionFormSchemaType,
  EnvelopeActionFormRef,
  TechnicalActionFormRef,
  categoryShortNameConversion,
  category_enum,
  formatFormData,
} from './CreateAction';
import EnvelopeAction from './EditActions/EnvelopeAction';
import TechnicalAction from './EditActions/TechnicalAction';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  baseBuildingModel: BuildingModelFragment;
  metricData: RecommendedActionImpactOutput | null;
  subBuildingSubsidies: DataCollectionSubBuildingSubBuildingSubsidyFragment[];
  implementationTo: Date;
  country: country_enum;
  defaultSimulatedData: SimulateActionOutputResponseFragment;
  action: RecommendationOutput;
};

export default function EditRecommendedAction({
  isOpen,
  onClose,
  baseBuildingModel,
  metricData,
  subBuildingSubsidies,
  implementationTo,
  country,
  defaultSimulatedData,
  action,
}: Props) {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const { enqueueSnackbar } = useSnackbar();
  const { id: actionPlanId } = useParams();
  const { trackEvent } = usePosthogTracking();
  const [category, setCategory] = useState<category_enum>(
    action.renovation_type === renovation_type_enum.ENVELOPE ? category_enum.ENVELOPE : category_enum.TECHNICAL,
  );
  const [simulatedData, setSimulatedData] = useState<SimulateActionOutputResponseFragment | undefined>(
    defaultSimulatedData,
  );
  const [subsidyData, setSubsidyData] = useState<SubsidyInput[]>([]);
  const [isCurrentFormDirty, setIsCurrentFormDirty] = useState(false);
  const [showCloseWarningDialog, setCloseWarningDialog] = useState(false);

  const selectedActionType = action.renovation_type;

  const formRef: EnvelopeActionFormRef | TechnicalActionFormRef = useRef(null);

  const previousActionBuildingModel = baseBuildingModel;

  const [simulateEnvelopeAction, { loading: simulationLoading }] = useMutation(SIMULATE_ACTION, {
    onCompleted(data) {
      setSimulatedData(data.simulateAction);
    },
    onError(error) {
      console.error(error);
    },
  });

  const [implementAction, { loading: implementActionLoading }] = useMutation(IMPLEMENT_ACTION, {
    onError: () =>
      enqueueSnackbar(t('CreateNewAction_ImplementAction-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: () => {
      enqueueSnackbar(t('CreateNewAction_ImplementAction-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      trackEvent('RECOMMENDED_ACTION_IMPLEMENTED', {
        action_type: action.action_type,
        renovation_type: action.renovation_type,
      });
      onClose();
    },
    refetchQueries: [GET_ACTIONPLAN, GET_ACTIONS_FOR_ACTION_PLAN, GET_ACTION_PLAN_METRICS, GET_SUBSIDIES], //Do we need
    // to
    // refetch
    // subsidies?
  });

  //TODO: move this to cost component
  const costFormMethods = useForm<CustomCostsForm>({
    defaultValues: {
      cost_custom_input: undefined,
      cost_total_custom: undefined,
      use_custom_cost: false,
    },
  });

  const { getValues, setValue: setCostFormValue } = costFormMethods;

  const getCustomCost = () => {
    const formValues = getValues();

    return {
      cost_custom_input: formValues.cost_custom_input,
      cost_total_custom: formValues.cost_total_custom,
      use_custom_cost: formValues.use_custom_cost ?? false,
    };
  };

  const resetSimulatedData = () => {
    setIsCurrentFormDirty(true);
    if (simulatedData) {
      costFormMethods.reset();
      //can we reset complete simulated data? the current use case half cooked. neither this nor that
      setSimulatedData(undefined);
    }
  };

  useEffect(() => {
    setCostFormValue('cost_total_custom', undefined);
    const cost2Str = Math.round(simulatedData?.renovation_costs ?? 0).toString();
    setCostFormValue('cost_custom_input', cost2Str);
    if (!getValues('use_custom_cost')) setCostFormValue('use_custom_cost', false);
  }, [simulatedData, setCostFormValue, getValues, localize]);

  const handleSubmitForm = async (): Promise<ActionFormSchemaType | null> => {
    let formData: ActionFormSchemaType | null = null;
    const formMethods = formRef?.current?.methods;

    if (!formMethods) {
      return null;
    }

    await formMethods.handleSubmit((data: ActionFormSchemaType) => {
      formData = data;
    })();

    return formData;
  };

  const onSimulateActionClick = async () => {
    let formData = await handleSubmitForm();

    if (!formData || !actionPlanId) {
      return;
    }
    const implementationToDate = formData.implementation_to as Date;

    //reassigning to new variable is important rather than updating formData directly. it also updates the form input
    // because of useref
    const updatedFormData = formatFormData(formData, selectedActionType as renovation_type_enum);

    simulateEnvelopeAction({
      variables: {
        action_plan_id: parseInt(actionPlanId),
        ...updatedFormData,
        implementation_to: implementationToDate,
      },
    });
  };

  const onImplementActionClick = async () => {
    const formData = await handleSubmitForm();

    if (!formData || !actionPlanId) {
      return;
    }

    const customCost = getCustomCost();
    const customCostWithNumbers = customCost
      ? {
          use_custom_cost: customCost.use_custom_cost ?? false,
          cost_total_custom: customCost.cost_total_custom ?? 0,
        }
      : undefined;

    const implementationToDate = formData.implementation_to as Date;

    const updatedFormData = formatFormData(formData, selectedActionType as renovation_type_enum);

    await implementAction({
      variables: {
        action_plan_id: parseInt(actionPlanId),
        ...updatedFormData,
        implementation_to: implementationToDate,
        implementation_from: implementationToDate, // TODO: handle this in the backend. frontend doesn't have
        // implementation_from date
        subsidies: subsidyData,
        customCost: customCostWithNumbers,
      },
    });
  };

  // @ts-ignore TODO: move it to subsidy component PRE-3998
  const actionSubsidies: Omit<ActionPlanningActionSubsidyFragment, 'id'>[] = subBuildingSubsidies
    .filter((availableSubsidy) =>
      subsidyData.find((selectedSubsidyInput) => selectedSubsidyInput.id === availableSubsidy.id),
    )
    .map((selectedSubsidy) => {
      //we know this subsidy is selected, but we need to get the input data again
      const subsidyInput = subsidyData.find((selectedSubsidyInput) => selectedSubsidyInput.id === selectedSubsidy.id);
      if (!subsidyInput) return null;

      const { cost_total_custom, use_custom_cost } = getCustomCost();

      return {
        subsidy: {
          id: selectedSubsidy.id,
          title: selectedSubsidy.title,
          categories: selectedSubsidy.categories,
        },
        value: subsidyInput.value,
        type: subsidyInput.type,
        action: {
          //since the action is not yet created
          id: undefined,
          renovations: [
            {
              cost_total: use_custom_cost ? cost_total_custom : simulatedData?.renovation_costs ?? 0,
              renovation_type_id: selectedActionType,
            },
          ],
        },
      };
    });

  const props = {
    previousActionBuildingModel: previousActionBuildingModel,
    setSimulatedData: setSimulatedData,
    minimumDate: implementationTo,
    maximumDate: getMaxDate(),
    implementationTo: implementationTo,
    resetSimulatedData: resetSimulatedData,
    loading: simulationLoading || implementActionLoading,
    affectedParts: [], //not supported for recommended actions
  };

  const handleCancel = () => {
    if (isCurrentFormDirty && simulatedData) {
      setCloseWarningDialog(true);
    } else {
      onClose();
    }
  };

  const isDataSimulated = simulatedData !== undefined;

  const baseForCreateAction = metricData;

  const beforeSimulationState = getRecommendedActionModificationImpactBeforeSimulation(baseForCreateAction, false);
  //@ts-ignore
  const afterSimulationState = getModificationImpactAfterSimulation(beforeSimulationState, simulatedData);
  const modificationImpact = isDataSimulated ? afterSimulationState : beforeSimulationState;

  const parameters = action.parameters;

  const { title, icon } = getRecommendedActionTitle(action, t);

  return (
    <>
      <PreDialog
        type="definedByChildren"
        open={isOpen}
        onClose={handleCancel}
        maxWidth="lg"
        fullWidth
        disableCloseAction={simulationLoading || implementActionLoading}
      >
        <DialogTitle>
          <Stack direction={'row'} alignItems={'center'}>
            <Box component={'img'} src={icon} width={24} mr={2} />
            <Typography variant="h6">{title}</Typography>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Scrollbar>
            <Box component={Paper} height={'100%'}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4} xl={4} p={0}>
                  <Box py={3} pr={3} minHeight={500}>
                    <ToggleButtonGroup
                      size="small"
                      value={category}
                      exclusive
                      sx={{ width: '100%' }}
                      onChange={(_, value) => {
                        setCategory(value);
                      }}
                      disabled={true}
                    >
                      {Object.values(category_enum).map((value, i) => (
                        <ToggleButton
                          key={i}
                          value={value}
                          sx={{
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >
                          <Box component={'img'} src={getActionIcon(value)} sx={{ width: 16 }} />
                          <Typography sx={{ pl: 0.5 }} variant="subtitle2">
                            {categoryShortNameConversion(value)}
                          </Typography>
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>

                    {category === category_enum.ENVELOPE ? (
                      <>
                        {'envelope_type' in parameters && (
                          <EnvelopeAction
                            {...props}
                            formRef={formRef as EnvelopeActionFormRef}
                            parameters={parameters}
                          />
                        )}
                      </>
                    ) : (
                      <TechnicalAction {...props} formRef={formRef as TechnicalActionFormRef} action={action} />
                    )}
                    <Box justifyContent={'end'} display={'flex'} my={2}>
                      <LoadingButton
                        size="medium"
                        variant="contained"
                        //Enabling if all form values are there for now
                        disabled={simulationLoading || !isCurrentFormDirty || isDataSimulated}
                        loading={simulationLoading}
                        onClick={onSimulateActionClick}
                      >
                        {t('General_Simulate')}
                      </LoadingButton>
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} item md={8} lg={8} xl={8} p={0}>
                  <FormProvider methods={costFormMethods}>
                    <ActionDetails
                      //@ts-ignore
                      metricData={modificationImpact}
                      actionSubsidies={actionSubsidies ?? []}
                      currentView="action_modal_view"
                      simulated={isDataSimulated}
                      setSubsidyData={setSubsidyData}
                      showAddSubsidyButton={isDataSimulated}
                      renovationType={selectedActionType}
                      country={country}
                    />
                  </FormProvider>
                </Grid>
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ mr: '-2px' }} />
            </Box>
          </Scrollbar>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCancel} variant="outlined">
            {t('General_Cancel')}
          </Button>
          <LoadingButton
            disabled={!simulatedData || implementActionLoading}
            variant="contained"
            onClick={onImplementActionClick}
            loading={implementActionLoading}
          >
            {t('General_CreateAction')}
          </LoadingButton>
        </DialogActions>
      </PreDialog>

      {showCloseWarningDialog && (
        <DiscardActionModal
          isOpen={showCloseWarningDialog}
          onClose={() => setCloseWarningDialog(false)}
          onAcknowledge={onClose}
        />
      )}
    </>
  );
}
