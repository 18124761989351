import { useQuery } from '@apollo/client';
import { Box, Card, CardHeader, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DelayedLoading } from '../../../components/Loading';
import LinearProgressBar from '../../../components/data-visialization/LinearProgressBar';
import { REPORT_HEAD_GET_PROGRESS } from '../../../graphql/Report.queries';
import { useLanguage } from '../../../provider/LanguageProvider';

type Props = {
  id: number;
};

function ReportProgress({ id }: Props) {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const { data: progressData, loading: progressLoading } = useQuery(REPORT_HEAD_GET_PROGRESS, {
    variables: {
      reportId: id,
    },
  });

  if (progressLoading) return <DelayedLoading />;

  const progress =
    progressData?.report_by_pk?.report_sections_progress.map(({ progress, report_template_section }) => ({
      section_name: report_template_section.name,
      section_progress: progress,
    })) ?? [];

  return (
    <Card sx={{ p: 3, height: '100%' }}>
      <CardHeader
        title={t('General_Progress')}
        sx={{ p: 0, pr: 1 }}
        action={localize.formatAsPercentage(
          progressData?.report_by_pk?.progress != null ? progressData.report_by_pk.progress * 100 : 0,
        )}
      />

      {progress.map(({ section_name, section_progress }, index) => (
        <Box key={index} sx={{ mt: 3 }}>
          <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
            <Typography variant="body1">{section_name}</Typography>

            <Typography align="right" fontWeight={800}>
              {localize.formatAsPercentage(section_progress * 100)}
            </Typography>
          </Stack>

          <LinearProgressBar progress={section_progress} />
        </Box>
      ))}
    </Card>
  );
}

export default ReportProgress;
