import { useMutation } from '@apollo/client';
import { alpha, Box, Button, MenuItem, Stack, useTheme } from '@mui/material';
import {
  ActionPlanningActionPlanActionPlanFragment,
  BuildingModelFragment,
  efficiency_class_enum,
  ImplementActionMutationVariables,
  renovation_type_enum,
  SimulateActionOutputResponseFragment,
} from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../assets/icons';
import ActionMoreMenu from '../../../../../components/common/ActionMoreMenu';
import Iconify from '../../../../../components/Iconify';
import { SnackbarTimeouts } from '../../../../../components/NotistackProvider';
import { IMPLEMENT_ACTION } from '../../../../../graphql/ActionPlanning.mutations';
import {
  GET_ACTION_PLAN_METRICS,
  GET_ACTIONPLAN,
  GET_ACTIONS_FOR_ACTION_PLAN,
  GET_RECOMMENDED_ACTIONS_FOR_BUILDING_MODEL,
  GET_SUBSIDIES,
} from '../../../../../graphql/ActionPlanning.queries';
import usePosthogTracking from '../../../../../hooks/usePosthogTracking';
import EditRecommendedAction from '../../Actions/EditRecommendedAction';
import { RecommendationOutput } from '../RecommendedActions';

type Props = {
  action: RecommendationOutput;
  hideAction: (id: number) => void;
  baseBuildingModel: BuildingModelFragment;
  actionPlan: ActionPlanningActionPlanActionPlanFragment;
  defaultImplementationDate: Date;
  editModalOpen: boolean;
  setEditModalOpen: (open: boolean) => void;
};

const RecommendedActionMenu = ({
  action,
  baseBuildingModel,
  actionPlan,
  defaultImplementationDate,
  editModalOpen,
  setEditModalOpen,
  hideAction,
}: Props) => {
  const theme = useTheme();
  const { trackEvent } = usePosthogTracking();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { actionImpact } = action;
  const building = actionPlan.building;

  const [implementAction, { loading: implementActionLoading }] = useMutation(IMPLEMENT_ACTION, {
    onError: () =>
      enqueueSnackbar(t('CreateNewAction_ImplementAction-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: () => {
      enqueueSnackbar(t('CreateNewAction_ImplementAction-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      trackEvent('RECOMMENDED_ACTION_IMPLEMENTED', {
        renovation_type: action.renovation_type,
        action_type: action.action_type,
      });
    },
    refetchQueries: [
      GET_ACTIONPLAN,
      GET_ACTIONS_FOR_ACTION_PLAN,
      GET_ACTION_PLAN_METRICS,
      GET_SUBSIDIES,
      GET_RECOMMENDED_ACTIONS_FOR_BUILDING_MODEL,
    ],
  });

  const getParameters = (
    recommendation: RecommendationOutput,
  ): Pick<
    ImplementActionMutationVariables,
    | 'solar_renovation_parameter'
    | 'system_renovation_parameter'
    | 'envelope_renovation_parameter'
    | 'energy_system_route_renovation_parameter'
    | 'hydraulic_balancing_renovation_parameter'
  > => {
    switch (recommendation.renovation_type) {
      case renovation_type_enum.ENERGY_SYSTEM_ROUTE:
        return {
          energy_system_route_renovation_parameter: recommendation.parameters,
        };
      case renovation_type_enum.ENVELOPE:
        return {
          envelope_renovation_parameter: recommendation.parameters,
        };
      case renovation_type_enum.HYDRAULIC_BALANCING:
        return {
          hydraulic_balancing_renovation_parameter: recommendation.parameters,
        };
      case renovation_type_enum.SOLAR_PLANT:
        return {
          solar_renovation_parameter: {
            ...recommendation.parameters,
            // Roof area ratio is in percentage, but the backend expects it in decimal e.g. 0.8 instead of 80
            // This is also done in SolarAction.tsx
            roof_area_ratio: recommendation.parameters.roof_area_ratio / 100,
          },
        };
      case renovation_type_enum.ENERGY_CONSUMER:
        return {
          system_renovation_parameter: recommendation.parameters,
        };
    }
  };

  const onImplementActionClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const implementationToDate = defaultImplementationDate;

    const newParameters = getParameters(action);

    await implementAction({
      variables: {
        action_plan_id: actionPlan.id,
        ...newParameters,
        implementation_to: implementationToDate,
        implementation_from: implementationToDate, // TODO: handle this in the backend. frontend doesn't have implementation_from date
        subsidies: [],
      },
    });
  };

  const subBuildingSubsidies = building.sub_buildings
    .flatMap((subBuilding) => subBuilding.subsidies)
    .map((item) => item.subsidy);

  const {
    efficiency_class,
    energy_cost_total,
    co2_cost_total,
    co2_emissions,
    final_energy,
    primary_energy,
    stranding_date,
    total_estimated_cost,
    heating_demand,
  } = actionImpact;

  const defaultSimulatedData: SimulateActionOutputResponseFragment = {
    energy_final: final_energy.after ?? 0,
    energy_primary: primary_energy.after ?? 0,
    co2_emissions: co2_emissions.after ?? 0,
    stranding_date: stranding_date.after ?? new Date(),
    efficiency_class: efficiency_class.after ?? efficiency_class_enum.NOT_APPLICABLE,
    renovation_costs: total_estimated_cost ?? 0, //TODO: double check this
    energy_final_cost: energy_cost_total.after ?? 0,
    co2_tax_cost: co2_cost_total.after ?? 0,
    heating_demand: heating_demand?.after ?? 0,
  };

  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={'end'}
        alignItems={'center'}
        gap={1}
        onClick={(e) => e.stopPropagation()}
      >
        <Button
          startIcon={<Iconify icon={ICONS.ADD} width={16} height={16} />}
          variant="outlined"
          sx={{ borderColor: alpha(theme.palette.grey[500], 0.24) }}
          onClick={onImplementActionClick}
          disabled={implementActionLoading}
        >
          {t('General_Add')}
        </Button>
        <Box onClick={(e) => e.stopPropagation()}>
          <ActionMoreMenu
            variant="icon"
            sx={{
              pointerEvents: implementActionLoading ? 'none' : 'auto',
            }}
            actions={
              <>
                <MenuItem
                  onClick={() => {
                    setEditModalOpen(true);
                  }}
                >
                  <Iconify icon={ICONS.EDIT_SQUARE} width={16} height={16} />
                  {t('General_Edit')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    hideAction(action.id as number);
                    trackEvent('RECOMMENDED_ACTION_HIDDEN', {
                      action_type: action.action_type,
                      renovation_type: action.renovation_type,
                    });
                  }}
                >
                  <Iconify icon={ICONS.HIDE} width={16} height={16} />
                  {t('General_Hide')}
                </MenuItem>
              </>
            }
          />
        </Box>
      </Stack>

      {editModalOpen && (
        <EditRecommendedAction
          isOpen={editModalOpen}
          onClose={() => {
            setEditModalOpen(false);
          }}
          baseBuildingModel={baseBuildingModel}
          metricData={actionImpact}
          subBuildingSubsidies={subBuildingSubsidies}
          implementationTo={defaultImplementationDate}
          country={building.address.country_id}
          defaultSimulatedData={defaultSimulatedData}
          action={action}
        />
      )}
    </>
  );
};

export default RecommendedActionMenu;
