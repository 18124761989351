import { downloadFile } from './createDownloadLink';

function addCustomCSVExportButtonToChartToolbar(id: string, csvContent: string, filename: string) {
  const apexChartCsvExport = document.getElementById(id)?.querySelector<HTMLElement>('.apexcharts-menu-item.exportCSV');

  if (apexChartCsvExport != null) {
    apexChartCsvExport.remove();
  }

  const toolbar = document.getElementById(id)?.querySelector<HTMLElement>('.apexcharts-menu');
  const customExport = document.getElementById(id)?.querySelector<HTMLElement>('.exportCSVCustom');

  if (toolbar && !customExport) {
    // Create your custom icon element
    const customIcon = document.createElement('div');
    customIcon.className = 'apexcharts-menu-item exportCSVCustom';
    customIcon.title = 'Download CSV';
    customIcon.innerHTML = 'Download CSV';

    // Add event listener for your custom action
    customIcon.addEventListener('click', function () {
      downloadFile(csvContent, 'csv', filename);
    });

    toolbar.appendChild(customIcon);

    return;
  }
}

export default addCustomCSVExportButtonToChartToolbar;
