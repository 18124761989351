import { useMutation } from '@apollo/client';
import { Button, MenuItem } from '@mui/material';
import { ActionPlanningScenariosScenarioFragment } from '@predium/client-graphql';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { ICONS } from '../../../assets/icons';
import FullScreenLoader from '../../../components/FullScreenLoader';
import Iconify from '../../../components/Iconify';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import ActionMoreMenu from '../../../components/common/ActionMoreMenu';
import PreDialog from '../../../components/presentations/PreDialog/PreDialog';
import { EXPORT_SCENARIO_AS_EXCEL } from '../../../graphql/ActionPlanning.mutations';
import { useLanguage } from '../../../provider/LanguageProvider';
import { onExportCompleted } from '../../../utils/createDownloadLink';
import DeleteScenarioModal from './DeleteScenarioModal';
import EditScenarioModal from './EditScenarioModal';

export type ViewType = 'table' | 'page';

export type ScenarioType = {
  id: number;
  name: string;
  totalActionPlans: number;
  totalActions: number;
};

type Props = {
  scenario: ActionPlanningScenariosScenarioFragment;
  hasScenarioEditDeletePermission: boolean;
  viewType: ViewType;
  scenariosNames: string[];
};

const ScenarioActionMenu: React.FC<Props> = ({
  scenario,
  hasScenarioEditDeletePermission,
  viewType,
  scenariosNames,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { language } = useLanguage();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const totalActionPlans = scenario?.action_plans ? scenario.action_plans.length : 0;
  const hasAtleastOneAction = totalActionPlans > 0;

  const [downloadScenario, { loading: loadingAction }] = useMutation(EXPORT_SCENARIO_AS_EXCEL, {
    variables: {
      scenarioId: scenario.id,
    },
    onCompleted: async (data) => {
      onExportCompleted(data?.exportScenarioAsExcel.body, 'xlsx', [scenario.name, 'action_plan'], language);
    },
    onError: () => {
      enqueueSnackbar(t('ActionPlanningScenario_ScenarioCouldNotBeExported'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
  });

  const exportIcon = <Iconify icon="mdi:file-download-outline" width={18} height={18} mr={1} />;

  const onEditDialogClose = () => {
    setEditModalOpen(false);
  };

  return (
    <>
      {viewType === 'page' && hasAtleastOneAction && (
        <Button
          onClick={async () => {
            await downloadScenario();
          }}
          variant={'outlined'}
          size="small"
          sx={{ mr: 2 }}
        >
          {exportIcon} {t('ActionPlanningScenario_ExportAsExcel')}
        </Button>
      )}
      <ActionMoreMenu
        variant="icon"
        actions={
          <>
            {hasScenarioEditDeletePermission && (
              <MenuItem onClick={() => setEditModalOpen(true)}>
                <Iconify icon={ICONS.EDIT} width={18} height={18} />
                {t('General_Edit')}
              </MenuItem>
            )}

            {viewType === 'table' && hasAtleastOneAction && (
              <MenuItem
                onClick={async () => {
                  await downloadScenario();
                }}
              >
                {exportIcon} {t('ActionPlanningScenario_ExportAsExcel')}
              </MenuItem>
            )}

            {hasScenarioEditDeletePermission && (
              <MenuItem onClick={() => setDeleteModalOpen(true)} sx={{ color: 'error.main' }}>
                <Iconify icon={ICONS.TRASH} width={18} height={18} />
                {t('General_Delete')}
              </MenuItem>
            )}
          </>
        }
      />
      <DeleteScenarioModal
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={() => setDeleteModalOpen(false)}
        scenario={{
          id: scenario.id,
          name: scenario.name,
          totalActionPlans,
          totalActions: scenario?.action_plans?.reduce(
            (acc, actionPlan) => acc + (actionPlan?.actions_aggregate?.aggregate?.count || 0),
            0,
          ),
        }}
      />
      <PreDialog type="definedByChildren" open={editModalOpen} onClose={onEditDialogClose} maxWidth="sm" fullWidth>
        <EditScenarioModal scenario={scenario} onClose={onEditDialogClose} scenariosNames={scenariosNames} />
      </PreDialog>

      <FullScreenLoader open={loadingAction} disableCloseAction text={t('General_InProgress')} />
    </>
  );
};

export default ScenarioActionMenu;
