import { type useSubscription } from '@apollo/client';
import { Box, Card, CardHeader, CircularProgress, Divider, Typography, useTheme } from '@mui/material';
import { report_template_score_display_type_enum } from '@predium/enums';
import { useTranslation } from 'react-i18next';
import { DelayedLoading } from '../../../components/Loading';
import { REPORT_HEAD_GET_SCORES } from '../../../graphql/Report.queries';
import { useLanguage } from '../../../provider/LanguageProvider';

type Props = {
  id: number;
  isGresbReport: boolean;
  useSubscription: typeof useSubscription;
};

function ReportScores({ id, isGresbReport, useSubscription }: Props) {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const theme = useTheme();

  const { data: scoreData, loading: scoreLoading } = useSubscription(REPORT_HEAD_GET_SCORES, {
    variables: {
      reportId: id,
    },
    skip: isGresbReport,
  });

  if (isGresbReport)
    return (
      <Card sx={{ p: 3, height: '100%' }}>
        <CardHeader title={t('Reporting_ReportScoresGrebCardTitle')} sx={{ pt: 0 }} />

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', height: '100%' }}>
          <div>
            <CircularProgress
              variant="determinate"
              value={100}
              thickness={6}
              size={150}
              sx={{
                circle: { color: theme.palette.grey[300] },
                position: 'absolute',
                bottom: 'calc((100% - 150px - 48px) / 2)',
              }}
            />
            <div
              style={{
                display: 'grid',
                placeItems: 'center',
                position: 'relative',
                left: '38px',
              }}
            >
              <Typography variant="h3">0</Typography>
              <Divider style={{ width: '75px', borderBottomWidth: 2 }} />
              <Typography variant="body1" fontWeight={800}>
                100
              </Typography>
            </div>
          </div>

          <Box sx={{ display: 'grid', placeItems: 'center' }}>
            <Typography variant="body1" fontSize={16} fontWeight={600} color={theme.palette.grey[600]}>
              {t('Reporting_StarRating')}
            </Typography>
            <Divider style={{ width: '75px', borderBottomWidth: 2 }} />
            <Typography variant="body1" fontSize={16} fontWeight={600} color={theme.palette.grey[600]}>
              {t('General_Benchmark')}
            </Typography>
          </Box>
        </div>
      </Card>
    );

  if (scoreLoading) return <DelayedLoading />;

  if (!scoreData) return null;

  const scores = scoreData?.report_by_pk?.report_template.report_template_scores ?? [];

  return scores.length > 0 ? (
    <Card sx={{ p: 3, height: '100%', maxHeight: 650, overflowY: 'auto' }}>
      {/*
      Scoring status is not completely implemented so hiding as part of PRE-1249 for now. We will be needing this later so commenting it for now
      <CardHeader title={'Score'} sx={{ p: 0, pr: 1 }} action={isIncomplete ? 'Ungeeignet' : 'Geeignet'} /> */}
      <CardHeader title={t('General_Score')} sx={{ p: 0, pr: 1 }} />

      {scores.map(({ name, display_type, max_score, report_scores }, index) => {
        let displayValue: string;
        const score_value = report_scores[0]?.value ?? 0;

        switch (display_type) {
          case report_template_score_display_type_enum.BOOLEAN:
            displayValue = score_value === 0 ? t('General_No') : t('General_Yes');
            break;
          case report_template_score_display_type_enum.PERCENTAGE:
            displayValue =
              max_score != null
                ? localize.formatAsPercentage((100 * score_value) / max_score)
                : localize.formatAsInteger(score_value);
            break;
          case report_template_score_display_type_enum.POINTS:
            displayValue =
              max_score !== null
                ? `${localize.formatAsCompact(score_value)}/${max_score}`
                : localize.formatAsInteger(score_value);
            break;
          default:
            const exhaustiveCheck: never = display_type;
            throw new Error(`Unhandled display_type: ${exhaustiveCheck}`);
        }

        return (
          <Box key={index} sx={{ my: 1 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ maxWidth: 390 }}>
                <Typography>{name}</Typography>
              </div>
              <div
                style={{
                  display: 'grid',
                  placeItems: 'top',
                }}
              >
                <Typography
                  align="right"
                  fontWeight={800}
                  color={
                    display_type === report_template_score_display_type_enum.BOOLEAN
                      ? score_value === 1
                        ? 'success'
                        : 'error'
                      : undefined
                  }
                >
                  {displayValue}
                </Typography>
              </div>
            </div>
          </Box>
        );
      })}
    </Card>
  ) : null;
}

export default ReportScores;
