import { Stack, StackProps, Tooltip, Typography } from '@mui/material';
import { data_source_type_enum } from '@predium/client-graphql';
import { language_enum } from '@predium/enums';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { FIELD_ICON_STATES } from '../../assets/icons';
import { useLanguage } from '../../provider/LanguageProvider';
import Iconify from '../Iconify';

export type ValueProps = {
  value: number;
  isEdited?: boolean;
  source?: data_source_type_enum;
  suffix?: string;
  showTooltip?: boolean;
  isLoading?: boolean;
  onSourceClick?: MouseEventHandler<HTMLDivElement>;
  onSourceClickAway?: VoidFunction;
  showSourceTooltip?: boolean;
  valueRootProps?: StackProps;
  disabled?: boolean;
} & StackProps;

const Value = ({
  value,
  source,
  isEdited,
  suffix,
  disabled = false,
  showSourceTooltip,
  valueRootProps,
}: ValueProps) => {
  const { t } = useTranslation();
  const { language, localize } = useLanguage();
  const getPrintedValue = (value: number | string) => {
    if (typeof value === 'string' && Number.isNaN(Number(value))) {
      switch (language) {
        case language_enum.deDE:
          return `${value} ${suffix}`;
        case language_enum.enGB:
          if (suffix?.includes('€')) {
            return `${suffix}${value}`;
          }
          return `${value}${suffix}`;
        default:
          const exhaustiveCheck: never = language;
          throw new Error(`Unhandled language: ${exhaustiveCheck}`);
      }
    }

    return localize.formatAsFloat(value, { unit: suffix });
  };

  const tooltipTitle =
    source === data_source_type_enum.APPROXIMATED ? t('General_ApproximatedValue') : t('General_CustomValue');

  return (
    <Tooltip title={showSourceTooltip ? tooltipTitle : undefined} arrow placement="top">
      <Stack direction="row" px={0.25} py={0.25} alignItems="center" className="input-value-root" {...valueRootProps}>
        {!isEdited && source === data_source_type_enum.APPROXIMATED && !disabled && (
          <Iconify
            width={20}
            height={20}
            color="info.main"
            icon={FIELD_ICON_STATES.APPROXIMATED}
            mr={1}
            data-cy="approximation-icon"
          />
        )}
        {(isEdited || source === data_source_type_enum.MANUAL) && (
          <Iconify color="success.main" icon={FIELD_ICON_STATES.MANUAL_EDIT} mr={1} data-cy="manual-icon" />
        )}

        <Typography
          variant="body2"
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {getPrintedValue(value)}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default Value;
