import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, AlertTitle, Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useImperativeHandle } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ICONS } from '../../../../../assets/icons';
import { FormProvider } from '../../../../../components/hook-form';
import Iconify from '../../../../../components/Iconify';
import { useBuildingCreation } from '../BuildingCreationProvider';
import EstimatedFieldsDetailsIcon from '../Components/EstimatedFieldsDetailsIcon';
import { ACTION_TYPES } from '../hooks/useGlobalState';
import AdditionalInformation from './form-sections/AdditionalInformation';
import Area from './form-sections/Area';
import CoreDataForm from './form-sections/CoreData';
import EnergyData from './form-sections/EnergyData';
import Sidebar from './Sidebar';
import { getInformationFormSchema } from './validations';

export const BUILDING_INFORMATION = 'BUILDING_INFORMATION' as const;

type InformationFormType = yup.InferType<ReturnType<typeof getInformationFormSchema>>;

export type InformationFormRef = {
  type: typeof BUILDING_INFORMATION;
  onSubmit: UseFormReturn<InformationFormType>['handleSubmit'];
};

const Step2Information = () => {
  const { t } = useTranslation();
  const schema = getInformationFormSchema(t);
  const { getBuilding, activeStepRef, dispatch } = useBuildingCreation();
  const building = getBuilding();

  const methods = useForm<InformationFormType>({
    defaultValues: building.information,
    resolver: yupResolver(schema),
  });

  const { reset, formState } = methods;
  const { isSubmitted, isValid, isDirty } = formState;

  useImperativeHandle(activeStepRef, () => ({
    type: BUILDING_INFORMATION,
    onSubmit: methods.handleSubmit,
  }));

  useEffect(() => {
    if (isSubmitted) {
      dispatch({ type: ACTION_TYPES.SET_STEP_INVALIDITY, payload: !isValid });
    }
  }, [isSubmitted, isValid, dispatch]);

  useEffect(() => {
    if (isDirty) {
      dispatch({ type: ACTION_TYPES.SET_BLOCK_CLOSE, payload: true });
    }
  }, [isDirty, dispatch]);

  useEffect(() => {
    reset(building.information);
  }, [building.information, reset]);

  return (
    <FormProvider methods={methods}>
      <Stack direction="row" mt={3} mb={4} px={2} position="relative">
        <Sidebar />
        <Grid
          container
          maxWidth="sm"
          mx="auto"
          spacing={5}
          flexShrink={0}
          sx={{
            '&.MuiGrid-root.MuiGrid-container': {
              maxWidth: 640,
            },
          }}
        >
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h4">{t('BuildingCreation_Step2Title')}</Typography>
              <Typography variant="body1" color="text.secondary">
                {t('BuildingCreation_Step2Subtitle')}
              </Typography>
              <Alert
                severity="info"
                sx={{
                  color: 'text.primary',
                  bgcolor: 'grey.200',
                }}
                icon={<Iconify icon={ICONS.APPROXIMATED} />}
              >
                <AlertTitle>
                  <Typography variant="subtitle2">{t('BuildingCreation_Step2AlertTitle')}</Typography>
                </AlertTitle>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body2">{t('BuildingCreation_Step2Alert')}</Typography>
                  <EstimatedFieldsDetailsIcon />
                </Stack>
              </Alert>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <CoreDataForm />
            <Area />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <EnergyData />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <AdditionalInformation />
          </Grid>
        </Grid>
        <Box maxWidth={300} width="100%" />
      </Stack>
    </FormProvider>
  );
};

export default Step2Information;
