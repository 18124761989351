/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import {
  DataCollectionBuildingEnergySystemFragment,
  DataCollectionBuildingWithSubBuildingsFragment,
  country_enum,
  data_source_type_enum,
  emission_factor_type_enum,
  energy_consumer_technology_type_enum,
  energy_consumer_type_enum,
  energy_distribution_type_enum,
  energy_source_type_enum,
  energy_storage_type_enum,
  energy_system_type_enum,
} from '@predium/client-graphql';
import {
  getEmissionsFactor,
  getEnergyCostsAtYear,
  getPrimaryEnergyCoefficientForEnergySource,
} from '@predium/client-lookup';
import { Localize } from '@predium/utils';
import { t } from 'i18next';
import sortBy from 'lodash/sortBy';
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { FieldErrors, FieldNamesMarkedBoolean, useForm, useFormState } from 'react-hook-form';
import * as Yup from 'yup';
import { TechnologyIcons } from '../../../assets/images';
import Unavailable from '../../../components/Unavailable';
import FormProvider from '../../../components/hook-form/FormProvider';
import AccessRightsWrapper from '../../../components/permission-tooltips/AccessRightsWrapper';
import useOrgPreferences from '../../../hooks/useOrgPreferences';
import { useLanguage } from '../../../provider/LanguageProvider';
import { FormRef, NestedForm } from './BuildingTechnologyEnvelope';
import { InconsistentDataAlert } from './Common/BuildingAlerts';
import useBuilding from './Context/useBuilding';
import Technology from './Technology/Technology';
import TechnologyAddMenu, { UpdatedEnergySystems, systemMenuOrderArray } from './Technology/TechnologyAddMenu';
import { GlOBAL_EMISSION_CERTIFICATE_ID } from './Technology/TechnologySection/TechnologyRoute/TechnologyUnit/EnergySource/EnergySourceEditModal';

export function getEnergySystemIcon(systemType: energy_system_type_enum): string {
  switch (systemType) {
    case energy_system_type_enum.HEATING:
      return TechnologyIcons.heating;
    case energy_system_type_enum.LIGHTING:
      return TechnologyIcons.lighting;
    case energy_system_type_enum.COOLING:
      return TechnologyIcons.cooling;
    case energy_system_type_enum.HOT_WATER:
      return TechnologyIcons.warm_water;
    case energy_system_type_enum.VENTILATION:
      return TechnologyIcons.ventilation;
    case energy_system_type_enum.ELECTRICITY:
      return TechnologyIcons.electricity;
    case energy_system_type_enum.GENERAL:
      return TechnologyIcons.electricity; // default icon. we dont show general system in UI
    case energy_system_type_enum.SOLAR:
      return TechnologyIcons.solar;
    default:
      const exhaustiveCheck: never = systemType;
      throw new Error(`Unhandled Energy System key ${systemType}: ${exhaustiveCheck}`);
  }
}

export type SystemRoute = {
  energySystemType: energy_system_type_enum | undefined;
  energySource: energy_source_type_enum | undefined;
  energyRouteId: number;
  energyFinal: number | null;
  energyConsumerType: energy_consumer_type_enum | null;
  consumerTechnologyType: energy_consumer_technology_type_enum | null;
  energyStorageType: energy_storage_type_enum | null;
  energyDistributionType: energy_distribution_type_enum | undefined;
  emissionFactor: number;
  emissionCertificateId: number;
  primaryEnergyFactor: number;
  primaryEnergyFactorEmissionCertificateId: number;
  pricePerKwh: string;
  energyStorageHeatLoss: number | null;
  distributionHeatLoss: number | null;
  transferHeatLoss: number | null;
  lastHydraulicBalancing: Date | null;
  lastBalancingSetToNever: boolean;
  energyConsumerConstructionYear: number | null;
  energyStorageConstructionYear: number | null;
  energyDistributionConstructionYear: number | null;
  expenditureFactor: number | null;
  consumerId: number;
  distributionId: number;
  storageId: number;
  storageDeleted?: boolean;
  distributionDeleted?: boolean;
  routeDeleted?: boolean;
};

const userUnEditableFields = [
  'systemId',
  'energySystemType',
  'energyRouteId',
  'dataSourceType',
  'storageId',
  'consumerId',
  'distributionId',
  'emissionCertificateId',
  'primaryEnergyFactorEmissionCertificateId',
  'energyConsumerType',
];

export const energyRouteFields = [
  'energySource',
  'storageId',
  'consumerId',
  'distributionId',
  'energyFinal',
  'routeDeleted',
  'emissionCertificateId',
  'primaryEnergyFactorEmissionCertificateId',
];

export const consumerFields = [
  'energyConsumerType',
  'consumerTechnologyType',
  'energyConsumerConstructionYear',
  'expenditureFactor',
  'consumerId',
];

export const storageFields = [
  'energyStorageType',
  'energyStorageHeatLoss',
  'energyStorageConstructionYear',
  'storageDeleted',
];
export const distributionFields = [
  'energyDistributionType',
  'distributionHeatLoss',
  'transferHeatLoss',
  'lastHydraulicBalancing',
  'lastBalancingSetToNever',
  'energyDistributionConstructionYear',
  'distributionDeleted',
];

export type System = {
  id: number;
  type: energy_system_type_enum;
  dataSourceType: data_source_type_enum;
  routes: SystemRoute[];
};

export type EnergySystemFormValues = {
  [Key in energy_system_type_enum]?: System[];
};

//TODO fix me pre-2234 using workaround with dirtyFields as appending to field array is not working proparly
//it adds empty objects to other arrays/energySystems so manually removing empty objects
const cleanedDirtyFields = (dirtyFields: FieldNamesMarkedBoolean<EnergySystemFormValues>) => {
  if (dirtyFields) {
    return Object.entries(dirtyFields).reduce((acc, [systemType, systems]) => {
      const cleanedSystems = systems
        .map((system) => {
          //routes can be undefined if none of its fields are dirty
          const cleanedRoutes = system.routes && system.routes.filter((route) => Object.keys(route).length !== 0);
          return { ...system, routes: cleanedRoutes };
        })
        .filter((system) => system.routes && system.routes.length > 0);

      if (cleanedSystems.length > 0) {
        acc[systemType as keyof typeof dirtyFields] = cleanedSystems;
      }

      return acc;
    }, {} as FieldNamesMarkedBoolean<EnergySystemFormValues>);
  }
};

const calculateTotalTechnologyCount = (
  fields: FieldErrors<EnergySystemFormValues> | FieldNamesMarkedBoolean<EnergySystemFormValues>,
  isErrors: boolean = false,
  ignoreFields: string[] = [],
): number => {
  return Object.values(fields).reduce((count: number, systems: System[]) => {
    systems.forEach((system) => {
      if (system && system.routes) {
        system.routes.forEach((route: SystemRoute) => {
          for (const key in route) {
            if (
              route.hasOwnProperty(key) &&
              (isErrors || route[key as keyof SystemRoute] === true) &&
              !ignoreFields.includes(key)
            ) {
              count++;
            }
          }
        });
      }
    });
    return count;
  }, 0);
};

export const getDefaultParameterValues = (
  energySourceType: energy_source_type_enum,
  address: {
    country_id: country_enum;
    postal_code: string;
  },
  localize: Localize,
) => {
  const year = new Date().getFullYear();

  const { country_id, postal_code } = address;

  const primaryEnergyFactor = getPrimaryEnergyCoefficientForEnergySource(country_id, energySourceType);
  const pricePerKwh = localize.formatAsFloat(getEnergyCostsAtYear(1, energySourceType, year, country_id, postal_code));

  return {
    primaryEnergyFactor,
    pricePerKwh,
  };
};

const createSystemsFormValues = (
  energySystems: DataCollectionBuildingEnergySystemFragment[],
  address: DataCollectionBuildingWithSubBuildingsFragment['address'],
  emissionFactorType: emission_factor_type_enum,
  localize: Localize,
): EnergySystemFormValues => {
  const transformData: EnergySystemFormValues = {};

  energySystems.forEach((system: DataCollectionBuildingEnergySystemFragment) => {
    const systemType = system.energy_system_type_id;
    const routes = sortBy(
      system.energy_system_consumer_routes.map((route) => {
        const {
          energy_source_type_id,
          id,
          secondary_id,
          energy_final,
          energy_consumer,
          energy_distribution,
          emission_certificate_id,
          energy_storage,
          primary_energy_factor_emission_certificate_id,
        } = route;

        const emissionFactor =
          route.emission_certificate?.emission_factor ??
          getEmissionsFactor({
            energySourceType: energy_source_type_id,
            emissionFactorType,
            year: new Date().getFullYear(),
            country: address.country_id,
          });

        const { pricePerKwh, primaryEnergyFactor: defaultPrimaryEnergyFactor } = getDefaultParameterValues(
          energy_source_type_id,
          address,
          localize,
        );

        const primaryEnergyFactor =
          route.primary_energy_factor_emission_certificate?.primary_energy_factor ?? defaultPrimaryEnergyFactor;

        return {
          energySystemType: systemType,
          energySource: energy_source_type_id,
          energyRouteId: id,
          secondary_id,
          energyFinal: energy_final,
          consumerTechnologyType: energy_consumer.energy_consumer_technology_type_id,
          energyDistributionType: energy_distribution?.energy_distribution_type_id,
          emissionFactor,
          emissionCertificateId: emission_certificate_id ?? GlOBAL_EMISSION_CERTIFICATE_ID,
          primaryEnergyFactorEmissionCertificateId:
            primary_energy_factor_emission_certificate_id ?? GlOBAL_EMISSION_CERTIFICATE_ID,
          primaryEnergyFactor,
          pricePerKwh,
          expenditureFactor: energy_consumer.efficiency,
          consumerId: energy_consumer.id,
          storageId: energy_storage?.id,
          energyConsumerType: energy_consumer.energy_consumer_type_id,
          energyStorageType: energy_storage?.energy_storage_type_id,
          energyStorageHeatLoss: energy_storage?.annual_heat_loss,
          distributionId: energy_distribution?.id,
          distributionHeatLoss: energy_distribution?.heat_distribution_loss,
          transferHeatLoss: energy_distribution?.heat_transfer_loss,
          lastHydraulicBalancing: energy_distribution?.last_hydraulic_balancing,
          lastBalancingSetToNever: energy_distribution?.last_balancing_set_to_never ?? false,
          energyConsumerConstructionYear: energy_consumer.construction_year,
          energyStorageConstructionYear: energy_storage?.construction_year,
          energyDistributionConstructionYear: energy_distribution?.construction_year,
        };
      }),
      (r) => r.secondary_id,
    );

    if (!transformData[systemType]) {
      transformData[systemType] = [];
    }

    //@ts-ignore
    transformData[systemType].push({
      id: system.id,
      type: systemType,

      //@ts-ignore
      dataSourceType: system.data_source_type_id,

      //@ts-ignore
      routes,
    });
  });

  return transformData;
};

const BuildingTechnology = forwardRef<
  FormRef<EnergySystemFormValues>,
  NestedForm & {
    portfolioEmissionFactorType: emission_factor_type_enum;
    address: DataCollectionBuildingWithSubBuildingsFragment['address'];
  }
>(({ buildingModel, portfolioEmissionFactorType, isShown, setFormState, address }, ref) => {
  const { energy_systems: energySystems } = buildingModel;
  const { localize } = useLanguage();
  const { hasEditAccess } = useBuilding();
  const { orgEmissionFactorType } = useOrgPreferences();

  const globalEmissionFactorType = portfolioEmissionFactorType ?? orgEmissionFactorType;

  const defaultValues = useMemo(() => {
    if (energySystems?.length > 0) {
      return createSystemsFormValues(energySystems, address, globalEmissionFactorType, localize);
    }
  }, [energySystems, address, globalEmissionFactorType, localize]);

  const routeSchema = Yup.object().shape({
    energyFinal: Yup.number().required('').moreThan(0, '').max(99999, ''),
    consumerTechnologyType: Yup.string()
      .nullable()
      .when(['energyRouteId', 'energySystemType'], {
        is: (energyRouteId: number, energySystemType: energy_system_type_enum) =>
          energyRouteId < 0 && energySystemType !== energy_system_type_enum.ELECTRICITY, //only need to validate when
        // it is a new route
        then: Yup.string()
          .nullable()
          .required('')
          .oneOf([...Object.values(energy_consumer_technology_type_enum), null], ''),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    energySource: Yup.string().when('energyRouteId', {
      is: (energyRouteId: number) => energyRouteId < 0,
      then: Yup.string().required('').oneOf(Object.values(energy_source_type_enum), ''),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const systemSchema = Yup.object().shape({
    routes: Yup.array().of(routeSchema),
  });

  const TechnologyDataSchema = Yup.object().shape({
    HEATING: Yup.array().of(systemSchema),
    HOT_WATER: Yup.array().of(systemSchema),
    LIGHTING: Yup.array().of(systemSchema),
    COOLING: Yup.array().of(systemSchema),
    VENTILATION: Yup.array().of(systemSchema),
    ELECTRICITY: Yup.array().of(systemSchema),
  });

  const methods = useForm<EnergySystemFormValues>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(TechnologyDataSchema),
  });

  const { control, reset, getValues } = methods;
  const { errors, dirtyFields } = useFormState({
    control,
  });

  const errorCount = calculateTotalTechnologyCount(errors, true);

  //@ts-ignore
  const editCount = calculateTotalTechnologyCount(cleanedDirtyFields(dirtyFields), false, userUnEditableFields);

  //@ts-ignore
  const isDirty = Object.keys(cleanedDirtyFields(dirtyFields)).length > 0;

  useEffect(() => {
    setFormState({
      isDirty,
      errorCount,
      editCount,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyFields, editCount, errorCount, isDirty, setFormState]);

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  useImperativeHandle(ref, () => ({
    methods: methods,
  }));

  const systems = getValues();

  return (
    <>
      {isShown && (
        <FormProvider methods={methods}>
          <Box width={'100%'} mb={3}>
            {energySystems.length === 0 ? (
              <Unavailable iconString="la:fire-alt" title={t('DataCollection_Technology_NoTechnology')} />
            ) : (
              <>
                <Box mt={3}>
                  <AccessRightsWrapper hasAccess={hasEditAccess}>
                    {!buildingModel.action_planning_available && (
                      <Box mb={3}>
                        <InconsistentDataAlert />
                      </Box>
                    )}
                    <TechnologyAddMenu />
                  </AccessRightsWrapper>

                  {systemMenuOrderArray.map((energySystem: UpdatedEnergySystems) => {
                    return (
                      systems.hasOwnProperty(energySystem) && (
                        <Technology
                          key={energySystem}
                          portfolioEmissionFactorType={portfolioEmissionFactorType}
                          energySystem={energySystem}
                          defaultValues={defaultValues ?? {}}
                        />
                      )
                    );
                  })}
                </Box>
              </>
            )}
          </Box>
        </FormProvider>
      )}
    </>
  );
});
export default BuildingTechnology;
