import { yupResolver } from '@hookform/resolvers/yup';
import { Box, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { BuildingModelFragment } from '@predium/client-graphql';
import { energy_source_type_enum, energy_system_type_enum } from '@predium/enums';
import { translateEnergySourceTypeEnum_dynamic, translateEnergySystemTypeEnum_dynamic } from '@predium/i18n/client';
import { TFunction, t } from 'i18next';
import uniqBy from 'lodash/uniqBy';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FormProvider, UseFormSetValue, useForm } from 'react-hook-form';
import { TechnologyIcons } from '../../../../../assets/images';
import { RHFDatePicker, RHFNumberField, RHFTextField } from '../../../../../components/hook-form';
import { useLanguage } from '../../../../../provider/LanguageProvider';
import { getMaxDate } from '../../ActionPlan.utils';
import { DropdownIcon, NumberInputSx, SelectSx, StyledListItemText } from '../../Components/ActionCustomStyles';
import { CustomCostsForm } from '../ActionDetails/ActionPricing/RowCosts';
import InfoTableCustomAction from '../ActionDetails/InfoTableCustomAction';
import { ConsumerPartialRenovationOption, CustomFormRef } from '../CreateAction';
import SystemPartialRenovationsAutocomplete from '../PartialRenovations/SystemRenovation/ConsumerPartialRenovationsAutocomplete';
import { CustomActionFormSchema } from './validation-schema';

export enum CustomActionCategory {
  ENERGY_SOURCE = 'ENERGY_SOURCE',
  SYSTEM = 'SYSTEM',
  OTHER = 'OTHER',
}

const availableCustomOptions = {
  ENERGY_SOURCE: Object.values(energy_source_type_enum),
  SYSTEM: Object.values(energy_system_type_enum),
};

export type CustomActionType = (typeof availableCustomOptions)[keyof typeof availableCustomOptions][number];

export const getCustomActionCategoryName = (category: CustomActionCategory, t: TFunction) => {
  switch (category) {
    case CustomActionCategory.ENERGY_SOURCE:
      return t('General_EnergySource');
    case CustomActionCategory.SYSTEM:
      return t('General_System');
  }
};

export const getCustomActionName = (value: CustomActionType, t: TFunction) => {
  if (availableCustomOptions.ENERGY_SOURCE.includes(value as energy_source_type_enum)) {
    return translateEnergySourceTypeEnum_dynamic(value as energy_source_type_enum, t);
  }
  if (availableCustomOptions.SYSTEM.includes(value as energy_system_type_enum)) {
    return translateEnergySystemTypeEnum_dynamic(value as energy_system_type_enum, t);
  }

  //should never happen
  throw new Error('No custom action name found');
};

export const getCustomActionIcon = (
  value: (typeof availableCustomOptions)[keyof typeof availableCustomOptions][number],
) => {
  if (availableCustomOptions.ENERGY_SOURCE.includes(value as energy_source_type_enum)) {
    switch (value as energy_source_type_enum) {
      case energy_source_type_enum.ELECTRICITY_GREEN:
      case energy_source_type_enum.ELECTRICITY_MIX:
        return TechnologyIcons.electricity;
      default:
        return TechnologyIcons.heatingV2;
    }
  }
  if (availableCustomOptions.SYSTEM.includes(value as energy_system_type_enum)) {
    switch (value as energy_system_type_enum) {
      case energy_system_type_enum.HEATING:
        return TechnologyIcons.heatingV2;
      case energy_system_type_enum.LIGHTING:
        return TechnologyIcons.lighting;
      case energy_system_type_enum.COOLING:
        return TechnologyIcons.coolingV2;
      case energy_system_type_enum.VENTILATION:
        return TechnologyIcons.ventilation;
      case energy_system_type_enum.HOT_WATER:
        return TechnologyIcons.warm_water;
    }
  }
  return TechnologyIcons.custom;
};

export type CustomActionForm = {
  implementation_to: DateTime | '';
  custom_action_renovation_parameter: {
    renovation_ids: string[];
    type?: CustomActionType | null;
    energy_source_type: energy_source_type_enum | null;
    system_type: energy_system_type_enum | null;
    custom_name: string;
    fraction: number;
    cost?: number;
  };
  affected_parts: ConsumerPartialRenovationOption[];
};

type CustomActionCreationProps = {
  previousActionBuildingModel: BuildingModelFragment;
  minimumDate: Date;
  resetSimulatedData: () => void;
  loading: boolean;
  setIsCurrentFormDirty: (isDirty: boolean) => void;
  setCostFormValue: UseFormSetValue<CustomCostsForm>;
  formRef: CustomFormRef;
};

const CustomAction = forwardRef(
  ({
    loading,
    previousActionBuildingModel,
    minimumDate,
    setIsCurrentFormDirty,
    setCostFormValue,
    resetSimulatedData,
    formRef,
  }: CustomActionCreationProps) => {
    const { language } = useLanguage();
    const [validationSchema, setValidationSchema] = useState(() => CustomActionFormSchema(minimumDate, language));

    const energySystems = previousActionBuildingModel.energy_systems;

    //filter out anything solar/pv related
    const filteredEnergySystems = energySystems
      .filter((system) => system.energy_system_type_id !== energy_system_type_enum.SOLAR)
      .filter((system) =>
        system.energy_system_consumer_routes.some(
          (route) => route.energy_source_type_id !== energy_source_type_enum.SOLAR,
        ),
      );

    const filteredSystemOptions = availableCustomOptions.SYSTEM.filter((option) =>
      filteredEnergySystems.some((system) => system.energy_system_type_id === option),
    );

    const filteredEnergySourceOptions = availableCustomOptions.ENERGY_SOURCE.filter((option) =>
      filteredEnergySystems.some((system) =>
        system.energy_system_consumer_routes.some(
          (route) =>
            route.energy_source_type_id === option && route.energy_source_type_id !== energy_source_type_enum.SOLAR,
        ),
      ),
    );

    const filteredOptions = {
      ENERGY_SOURCE: filteredEnergySourceOptions,
      SYSTEM: filteredSystemOptions,
    };

    const defaultValues: CustomActionForm = {
      implementation_to: '',
      custom_action_renovation_parameter: {
        renovation_ids: [],
        type: null,
        custom_name: '',
        fraction: 0,
        cost: 0,
        energy_source_type: null,
        system_type: null,
      },
      affected_parts: [],
    };

    const formMethods = useForm<CustomActionForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: yupResolver(validationSchema),
    });

    const {
      watch,
      formState: { isDirty },
    } = formMethods;

    useImperativeHandle(formRef, () => ({
      methods: formMethods,
    }));

    const customName = watch('custom_action_renovation_parameter.custom_name');
    const selectedCustomActionType = watch('custom_action_renovation_parameter.type');
    const fraction = watch('custom_action_renovation_parameter.fraction');
    const cost = watch('custom_action_renovation_parameter.cost');
    const implementationTo = watch('implementation_to');

    const energyConsumers = uniqBy(
      previousActionBuildingModel.energy_systems
        ?.filter((system) => system?.energy_system_type_id === selectedCustomActionType)
        .flatMap((system) => system?.energy_system_consumer_routes.map((route) => route.energy_consumer)) ?? [],
      (c) => c.id,
    );

    const affectedCategory = selectedCustomActionType
      ? availableCustomOptions.ENERGY_SOURCE.includes(selectedCustomActionType as energy_source_type_enum)
        ? CustomActionCategory.ENERGY_SOURCE
        : CustomActionCategory.SYSTEM
      : null;

    const noSearchResults = Object.values(filteredOptions).every((options) => options.length === 0);
    const enablePartialRenovations = Object.values(energy_system_type_enum).includes(
      selectedCustomActionType as energy_system_type_enum,
    );

    useEffect(() => {
      setIsCurrentFormDirty(isDirty);
    }, [isDirty, setIsCurrentFormDirty]);

    // Update validation schema when selection changes
    useEffect(() => {
      if (selectedCustomActionType) {
        setValidationSchema(CustomActionFormSchema(minimumDate, language, enablePartialRenovations));
      }
    }, [enablePartialRenovations, language, minimumDate, selectedCustomActionType, formMethods]);

    useEffect(() => {
      resetSimulatedData();
      setCostFormValue('use_custom_cost', true);
      setCostFormValue('cost_total_custom', cost);
      setCostFormValue('cost_custom_input', cost?.toString());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomActionType, fraction, implementationTo, cost]);

    return (
      <FormProvider {...formMethods}>
        <RHFTextField
          name="custom_action_renovation_parameter.custom_name"
          label={t('General_ActionTitle')}
          size={'small'}
          sx={{ ...NumberInputSx, pb: 0, mb: 0, mt: 3 }}
          disabled={loading}
        />
        <TextField
          select
          label={t('General_ToOptimize')}
          onChange={(event) => {
            formMethods.reset({
              custom_action_renovation_parameter: {
                ...defaultValues.custom_action_renovation_parameter,
                type: event.target.value as CustomActionType,
                energy_source_type: event.target.value as energy_source_type_enum,
                system_type: event.target.value as energy_system_type_enum,
                custom_name: customName,
              },
              affected_parts: [],
            });
            setCostFormValue('use_custom_cost', false);
          }}
          fullWidth
          sx={{ ...SelectSx, mt: 2, mb: 0 }}
          size="small"
          disabled={loading}
          SelectProps={{
            IconComponent: DropdownIcon,
            MenuProps: {
              PaperProps: {
                sx: { maxHeight: 450 },
              },
              autoFocus: false,
            },
          }}
        >
          {Object.entries(filteredOptions).flatMap(([category, options]) => [
            <MenuItem
              key={`header-${category}`}
              tabIndex={-1}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                  cursor: 'default',
                },
                padding: 0,
                pt: options.length > 0 ? 1 : 0,
                pointerEvents: 'none',
              }}
            >
              {options.length > 0 && (
                <Typography variant="overline" sx={{ pl: 2 }}>
                  {getCustomActionCategoryName(category as CustomActionCategory, t)}
                </Typography>
              )}
            </MenuItem>,
            ...options.map((value) => (
              <MenuItem
                value={value}
                key={value}
                sx={{
                  textTransform: 'none',
                  '&.Mui-selected': {
                    bgcolor: selectedCustomActionType === value ? 'action.selected' : 'transparent',
                  },
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box component="img" src={getCustomActionIcon(value)} sx={{ width: 16, height: 16 }} />
                  <StyledListItemText action={getCustomActionName(value, t)} />
                </Stack>
              </MenuItem>
            )),
          ])}
          {noSearchResults && (
            <MenuItem sx={{ p: 1, '&:hover': { backgroundColor: 'transparent' } }} disableRipple>
              <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', width: '100%' }}>
                {t('General_NoResults')}
              </Typography>
            </MenuItem>
          )}
        </TextField>
        {selectedCustomActionType && (
          <>
            <InfoTableCustomAction
              affectedCategory={affectedCategory as CustomActionCategory}
              selectedCustomActionType={selectedCustomActionType as CustomActionType}
              buildingId={previousActionBuildingModel?.building?.id}
              buildingModel={previousActionBuildingModel}
              sx={{ mt: 3 }}
            />
            {enablePartialRenovations && (
              <SystemPartialRenovationsAutocomplete
                action={selectedCustomActionType as energy_system_type_enum}
                energyConsumers={energyConsumers}
                sx={{ mt: 3 }}
                setValue={(value: ConsumerPartialRenovationOption[]) => {
                  formMethods.setValue(
                    'custom_action_renovation_parameter.renovation_ids',
                    value.flatMap((v) => v.energy_system_consumer_routes.flatMap((route) => route.secondary_id)),
                    {
                      shouldDirty: true,
                      shouldValidate: true,
                    },
                  );
                }}
                disabled={loading}
              />
            )}
            <RHFNumberField
              name="custom_action_renovation_parameter.fraction"
              label={`${t('General_FinalEnergyReduction')} - ${getCustomActionName(selectedCustomActionType, t)}`}
              size="small"
              min={0}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2" color="text.secondary">
                      %
                    </Typography>
                  </InputAdornment>
                ),
              }}
              sx={{ ...NumberInputSx, mt: 3 }}
              disabled={loading}
            />
            <RHFNumberField
              name="custom_action_renovation_parameter.cost"
              label={t('General_Cost', { count: 2 })}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2" color="text.secondary">
                      €
                    </Typography>
                  </InputAdornment>
                ),
              }}
              onValueChange={(value) => {
                setCostFormValue('use_custom_cost', true);
                setCostFormValue('cost_total_custom', Number(value.floatValue));
                setCostFormValue('cost_custom_input', value.formattedValue);
              }}
              sx={{ ...NumberInputSx, mt: 3 }}
              disabled={loading}
            />
            <RHFDatePicker
              label={t('General_PlannedFor')}
              name="implementation_to"
              size={'small'}
              sx={{ ...NumberInputSx, mt: 3 }}
              disabled={loading}
              minDate={minimumDate}
              maxDate={getMaxDate()}
            />
          </>
        )}
      </FormProvider>
    );
  },
);

export default CustomAction;
