import {
  Box,
  Card,
  ClickAwayListener,
  ClickAwayListenerProps,
  IconButton,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../assets/icons';
import Iconify, { IconifyProps } from '../Iconify';

const WhiteTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  pointerEvents: 'none',
  [`& .${tooltipClasses.tooltip}`]: {
    /* HACK: make the original popper component invisible so we replace it with a card */
    backgroundColor: 'transparent',
    color: 'transparent',
    padding: 0,
    margin: 0,
    pointerEvents: 'auto',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
});

export type Variant = 'error' | 'warning' | 'approximation' | 'calculation';

export type DetailedTooltipProps = {
  variant?: Variant;
  labels: {
    title: ReactNode;
    subtitle?: ReactNode;
    body?: ReactNode;
    preBody?: ReactNode;
    equations?: ReactNode;
  };
  readMoreLink?: string;
  slotsProps?: {
    tooltip?: Omit<TooltipProps, 'title' | 'children'>;
    onClickAway?: Partial<Omit<ClickAwayListenerProps, 'children'>>;
    icon?: Partial<IconifyProps>;
  };
  children?: JSX.Element;
  size?: number;
  hide?: boolean;
  onClose?: VoidFunction;
};

const DetailedTooltip = ({
  labels,
  onClose,
  slotsProps,
  children,
  variant,
  size = 14,
  hide = false,
  readMoreLink,
}: DetailedTooltipProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = () => {
    // To not have multiple tooltips open at the same time
    document.body.click();

    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const { icon, iconColor } = useMemo(() => {
    if (!variant) {
      return {};
    }

    return (
      {
        error: { icon: 'mdi:alert-circle', iconColor: 'error.main' },
        warning: { icon: 'mdi:alert', iconColor: 'warning.main' },
        approximation: { icon: 'mdi:chart-timeline-variant-shimmer', iconColor: 'info.main' },
        calculation: { icon: 'mdi:calculator', iconColor: 'primary.main' },
      } satisfies Record<Variant, { icon: string; iconColor: string }>
    )[variant];
  }, [variant]);

  if (hide) {
    return null;
  }

  return (
    <ClickAwayListener mouseEvent={open ? 'onClick' : false} onClickAway={handleClose} {...slotsProps?.onClickAway}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <WhiteTooltip
          open={open}
          onClose={handleClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="right"
          arrow
          {...slotsProps?.tooltip}
          title={
            <Card>
              <Stack spacing={1} py={1}>
                <Stack px={2} direction="row" alignItems="center" justifyContent="space-between">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {icon && <Iconify color={iconColor} icon={icon} fontSize={16} />}
                    <Typography variant="subtitle2">{labels.title}</Typography>
                  </Stack>
                  <IconButton size="small" onClick={handleClose}>
                    <Iconify icon={ICONS.CLOSE} width={18} height={18} sx={{ color: 'text.secondary' }} />
                  </IconButton>
                </Stack>
                {labels.subtitle && (
                  <Typography variant="caption" color="text.primary" px={2}>
                    {labels.subtitle}
                  </Typography>
                )}
                {labels.preBody && (
                  <Stack spacing={1} px={2}>
                    <Typography variant="caption" color="text.secondary">
                      {labels.preBody}
                    </Typography>
                  </Stack>
                )}
                {Boolean(labels.equations) && (
                  <Stack bgcolor="#F4F6F8" py={1} px={2} spacing={1}>
                    {typeof labels.equations === 'string' ? (
                      <Typography variant="caption" fontWeight={400}>
                        {labels.equations}
                      </Typography>
                    ) : (
                      labels.equations
                    )}
                  </Stack>
                )}
                {labels.body && (
                  <Stack spacing={1} px={2}>
                    <Typography variant="caption" color="text.secondary">
                      {labels.body}
                    </Typography>
                  </Stack>
                )}
                {readMoreLink && (
                  <Stack spacing={0.5} px={2} direction="row" alignItems="center">
                    <Typography component="a" href={readMoreLink} target="_blank" variant="caption" color="#1877F2">
                      {t('General_ReadMore')}
                    </Typography>
                    <Iconify icon={ICONS.EXTERNAL_LINK} width={16} height={16} color="#1877F2" />
                  </Stack>
                )}
              </Stack>
            </Card>
          }
        >
          {children ?? (
            <IconButton disableRipple sx={{ p: 0 }} onClick={handleOpen}>
              <Iconify icon={ICONS.INFO} width={size} height={size} {...slotsProps?.icon} />
            </IconButton>
          )}
        </WhiteTooltip>
      </Box>
    </ClickAwayListener>
  );
};

export default DetailedTooltip;
