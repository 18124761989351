import { Box, Typography, useTheme } from '@mui/material';
import { ActionPlanningActionSubsidyFragment, country_enum } from '@predium/client-graphql';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';
import { DelayedLoading } from '../../../../components/Loading';
import { FormProvider } from '../../../../components/hook-form';
import { ActionPlanMetricsData } from '../../../../pages/ActionPlanning/ActionPlanningActionPlan';
import ActionDetails from '../Actions/ActionDetails';
import { CustomCostsForm } from '../Actions/ActionDetails/ActionPricing/RowCosts';
import ActionPlanSectionTitle from '../Components/ActionPlanSectionTitle';

type Props = {
  setNewActionModalOpen: (value: boolean) => void;
  actionSubsidies: ActionPlanningActionSubsidyFragment[];
  metricsData: ActionPlanMetricsData | null;
  street: string;
  country: country_enum;
};

const ActionPlanDetails = ({ metricsData, setNewActionModalOpen, actionSubsidies, street, country }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const methods = useForm<CustomCostsForm>();

  return (
    <>
      <ActionPlanSectionTitle title={t('General_Analysis')} icon={ICONS.CHART_BAR} />

      <>
        {metricsData ? (
          <>
            {metricsData.actions.length > 0 ? (
              <FormProvider methods={methods}>
                <ActionDetails
                  currentView={'overview'}
                  actionSubsidies={actionSubsidies}
                  metricData={metricsData.action_plan}
                  simulated={true}
                  country={country}
                />
              </FormProvider>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                  flexDirection: 'column',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setNewActionModalOpen(true);
                }}
              >
                <Iconify
                  icon={'bx:add-to-queue'}
                  width={50}
                  height={50}
                  sx={{ mb: 1 }}
                  color={theme.palette.grey[500]}
                />

                <Typography variant="h6" color={theme.palette.grey[500]}>
                  {t('ActionPlanningActionPlan_CreateFirstAction')}
                </Typography>
                <Typography variant="body2" color={theme.palette.grey[500]}>
                  {t('ActionPlanningActionPlan_CreateFirstActionText', { name: street ?? '' })}
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <Box height={350}>
            <DelayedLoading />
          </Box>
        )}
      </>
    </>
  );
};

export default ActionPlanDetails;
