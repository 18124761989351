import { useQuery } from '@apollo/client';
import { MenuItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RHFSelect } from '../../../../../components/hook-form';
import InlineUser from '../../../../../components/InlineUser';
import { GET_USERS } from '../../../../../graphql/DataCollection.queries';
import { useBuildingCreation } from '../BuildingCreationProvider';

const Sidebar = () => {
  const { t } = useTranslation();
  const { getBuilding } = useBuildingCreation();
  const { data } = useQuery(GET_USERS);

  const building = getBuilding();

  const users = data?.user ?? [];

  return (
    <Stack spacing={2} flexBasis={310} flexShrink={0} position="sticky" top={0} alignSelf="flex-start">
      <Stack border="1px solid" borderColor="divider" borderRadius={2} p={2} spacing={0.5}>
        <Typography variant="subtitle1" fontWeight={700} color="text.primary">
          {building.address.street}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {building.address.postalCode} {building.address.city}
        </Typography>
      </Stack>
      <RHFSelect size="small" label={t('General_ResponsibleUser')} name="responsibleUserId">
        {users.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <InlineUser size={30} firstName={option.first_name} lastName={option.last_name} />
          </MenuItem>
        ))}
      </RHFSelect>
    </Stack>
  );
};

export default Sidebar;
