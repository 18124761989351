/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Badge, Tab, Tabs, Typography, useTheme } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {
  ReportSectionGetRootQuestionsAndAnswersAnswersFragment,
  report_answer_state_enum,
  report_template_question_type_enum,
  report_template_section_target_enum,
} from '@predium/client-graphql';
import { Localize } from '@predium/utils';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMAGES } from '../../../assets';
import Iconify from '../../../components/Iconify';
import { DelayedLoading } from '../../../components/Loading';
import Scrollbar from '../../../components/Scrollbar';
import Unavailable from '../../../components/Unavailable';
import { GET_ROOT_QUESTIONS_AND_ANSWERS } from '../../../graphql/Report.queries';
import { useLanguage } from '../../../provider/LanguageProvider';
import { SEARCH_PARAMS, useTypeSafeSearchParams } from '../../../routes';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../utils/formatTime';
import { ReportQuestionDC } from '../ReportingDataClasses/ReportQuestion.dc';
import { ReportSectionDC } from '../ReportingDataClasses/ReportTemplate.dc';
import { ReportQuestionRoot, isAnsweredQuestion, isAutoFillQuestion } from './ReportQuestion';

// ----------------------------------------------------------------------

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<Iconify icon={'akar-icons:chevron-up'} width={25} height={25} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.grey[500_12],

  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

type Props = {
  section: ReportSectionDC;
  reportId: number;
  subBuildingId: number | null;
  goToNextIncompleteSection: () => void;
};

export default function ReportSectionContent({ section, reportId, subBuildingId, goToNextIncompleteSection }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [currentTab, setCurrentTab] = useState<'openQuestions' | 'answeredQuestions'>('openQuestions');

  const { data, loading } = useQuery(GET_ROOT_QUESTIONS_AND_ANSWERS, {
    variables: {
      sectionId: section.id,
      reportId,
      subBuildingWhere: subBuildingId ? { _eq: subBuildingId } : {},
    },
  });

  const questions = useMemo(
    () =>
      //@ts-ignore
      data?.report_by_pk.report_answers.map((answer) => {
        const question = answer.report_template_question;

        return new ReportQuestionDC({
          ...question,
          answer,
          reportId,

          //@ts-ignore
          subBuildingId:
            section.target === report_template_section_target_enum.SUB_BUILDING ? subBuildingId : undefined,
        });
      }),
    [data, reportId, subBuildingId, section.target],
  );

  if (loading || !questions) return <DelayedLoading />;

  const openQuestions = questions.filter((question) => question.answer?.state === report_answer_state_enum.UNANSWERED);
  const answeredQuestions = questions.filter(isAnsweredQuestion);

  return (
    <Box sx={{ p: 1 }}>
      {/* screen - card padding top - card padding bottom - some spacer padding */}
      <Scrollbar sx={{ maxHeight: 'calc(100vh - 16px - 4px - 16px)' }}>
        <div id="report-intro-autofill-step-1">
          <div id="report-intro-common-step-0">
            <Tabs value={currentTab} onChange={(_, value) => setCurrentTab(value)} sx={{ mb: 4 }}>
              <Tab
                disableRipple
                label={t('ReportSectionContent_OpenQuestions')}
                value="openQuestions"
                iconPosition="end"
                sx={{
                  width: 200,
                  '& .MuiTab-iconWrapper': {
                    ml: 2,
                  },
                }}
                icon={
                  <Badge
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: theme.palette.grey[300],
                        color: currentTab === 'openQuestions' ? theme.palette.primary.main : theme.palette.grey[600],
                      },
                    }}
                    badgeContent={openQuestions.length}
                  />
                }
              />

              <Tab
                data-cy="cy-report-answered-questions-tab"
                disableRipple
                label={t('ReportSectionContent_AnsweredQuestions')}
                value="answeredQuestions"
                disabled={answeredQuestions.length === 0}
                iconPosition="end"
                sx={{
                  width: 200,
                  '& .MuiTab-iconWrapper': {
                    ml: 2,
                  },
                }}
                icon={
                  <Badge
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: theme.palette.grey[300],
                        color: currentTab === 'openQuestions' ? theme.palette.primary.main : theme.palette.grey[600],
                      },
                    }}
                    badgeContent={answeredQuestions.length}
                  />
                }
              />
            </Tabs>
          </div>
        </div>

        {currentTab === 'answeredQuestions' && (
          <Questions
            questions={answeredQuestions}
            goToNextIncompleteSection={goToNextIncompleteSection}
            isAnsweredQuestion
          >
            {answeredQuestions.length === 0 && (
              <Unavailable
                title={t('ReportSectionContent_NoQuestionsAnswered-title')}
                iconString="carbon:error-outline"
              />
            )}
          </Questions>
        )}

        {currentTab === 'openQuestions' && (
          <Questions
            questions={openQuestions}
            goToNextIncompleteSection={goToNextIncompleteSection}
            isAnsweredQuestion={false}
          >
            {openQuestions.length === 0 && (
              <Unavailable
                title={t('ReportSectionContent_AllQuestionsAnswered-title')}
                iconString="carbon:checkmark-outline"
              />
            )}
          </Questions>
        )}
      </Scrollbar>
    </Box>
  );
}

type QuestionsProps = {
  questions: ReportQuestionDC[];
  goToNextIncompleteSection: () => void;
  isAnsweredQuestion: boolean;
};

function Questions({
  questions,
  goToNextIncompleteSection,
  isAnsweredQuestion,
  children,
}: PropsWithChildren<QuestionsProps>) {
  const { searchParams } = useTypeSafeSearchParams(SEARCH_PARAMS.report);

  // An array is used so that every time an question is answered an update is triggered to the hook even though the index has not changed. (reference inequality)
  const [presentedQuestionIndex, setPresentedQuestionIndex] = useState<number[]>([]);

  const handleSingleQuestionAnswered = (answeredQuestion: ReportQuestionDC) => {
    const questionIndex = questions.findIndex((question) => question.id === answeredQuestion.id);

    if (questionIndex < questions.length - 1) {
      setPresentedQuestionIndex([questionIndex]);
    } else {
      setPresentedQuestionIndex([]);
    }

    // TODO: This is also executed when the last answer is reset which needs to be avoided.
    if (questions.length === 1 && questions[0] === answeredQuestion && !isAnsweredQuestion) {
      goToNextIncompleteSection();
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      {children}

      {questions.map((question, index) => (
        <QuestionWrapper
          key={question.id}
          defaultExpanded={index === 0}
          isAnsweredQuestion={isAnsweredQuestion}
          question={question}
          // Do not autofocus when introduction dialog is displayed to avoid weird focus shifts.

          autoFocus={index === 0 && !isAnsweredQuestion && !searchParams.init}
          handleSingleQuestionAnswered={handleSingleQuestionAnswered}
          presentedQuestionIndexes={presentedQuestionIndex}
          index={index}
        />
      ))}
    </Box>
  );
}

const formatAutofillInfoDisplay = (
  localize: Localize,
  type?: report_template_question_type_enum,
  answer?: ReportSectionGetRootQuestionsAndAnswersAnswersFragment,
) => {
  switch (type) {
    case report_template_question_type_enum.DATE:
      //@ts-ignore
      return formatDateToLocale(answer.autofill, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language);

    case report_template_question_type_enum.MULTIPLE_CHOICE:
    case report_template_question_type_enum.SINGLE_CHOICE:
      return answer?.report_answer_autofill_options
        .map((option) => option.report_template_question_option.value)
        .join(', ');

    case report_template_question_type_enum.PERCENTAGE:
      return localize.formatAsPercentage(answer?.autofill);

    case report_template_question_type_enum.FLOAT:
    case report_template_question_type_enum.INTEGER:
    case report_template_question_type_enum.TEXT:
      return answer?.autofill;

    default:
      throw new Error(`Unhandled question type: ${type}`);
  }
};

type QuestionWrapperProps = {
  defaultExpanded: boolean;
  isAnsweredQuestion: boolean;
  question: ReportQuestionDC;
  autoFocus: boolean;
  handleSingleQuestionAnswered: (answeredQuestion: ReportQuestionDC) => void;
  presentedQuestionIndexes: number[];
  index: number;
};

/**
 * The accordion behavior can only be modeled with a controlled component.
 * In order to always open the first accordion initially and not re-render all accordions we need this wrapper.
 */
function QuestionWrapper({
  defaultExpanded,
  isAnsweredQuestion,
  question,
  autoFocus,
  handleSingleQuestionAnswered,
  presentedQuestionIndexes,
  index,
}: QuestionWrapperProps) {
  const theme = useTheme();
  const { localize } = useLanguage();

  const [expanded, setExpanded] = useState(defaultExpanded);

  // When a question is answered or deleted we need to open the next one automatically.
  useEffect(() => {
    if (defaultExpanded) {
      setExpanded(true);
    }
  }, [defaultExpanded]);

  // When a question is answered the next in line should be opened automatically.
  // An array is used so that every time an question is answered an update is triggered to the hook even though the index has not changed. (reference inequality)
  useEffect(() => {
    const [presentedQuestionIndex] = presentedQuestionIndexes;

    if (presentedQuestionIndex === index) {
      setExpanded(true);
    }

    // The question should only expand when answered not when new questions are added. Therefore the index must not trigger the hook!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentedQuestionIndexes]);

  return (
    <Accordion
      // TODO: Nice performance idea but messes with animation
      // TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={(_, expanded) => {
        setExpanded(expanded);
      }}
      sx={{
        mb: 1,
      }}
    >
      <AccordionSummary>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 1.5,
          }}
        >
          {isAnsweredQuestion ? (
            isAutoFillQuestion(question) ? (
              <img
                style={{ height: 21, width: 21 }}
                src={IMAGES.AutofilledAnswer}
                alt="full circle with a yellow checkmark"
              />
            ) : (
              <Iconify
                color={theme.palette.success.main}
                icon={'eva:checkmark-circle-2-outline'}
                width={25}
                height={25}
              />
            )
          ) : isAutoFillQuestion(question) ? (
            <img
              style={{ height: 21, width: 21 }}
              src={IMAGES.AutofillAvailable}
              alt="half circle with a yellow checkmark"
            />
          ) : (
            <Iconify color={theme.palette.grey[500]} icon={'mdi:question-mark-circle-outline'} width={25} height={25} />
          )}
        </Box>

        <div>
          <Typography>{question.question}</Typography>

          {!isAnsweredQuestion && isAutoFillQuestion(question) && (
            <Typography variant="body2" color={theme.palette.grey[600]} fontSize={13} sx={{ paddingTop: 0.5 }}>
              {formatAutofillInfoDisplay(localize, question.answer?.report_template_question.type, question.answer)}
            </Typography>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <ReportQuestionRoot
          reportId={question.reportId}
          question={question}
          isSubQuestion={false}
          isAnsweredQuestion={isAnsweredQuestion}
          autoFocus={autoFocus}
          handleSingleQuestionAnswered={handleSingleQuestionAnswered}
        />
      </AccordionDetails>
    </Accordion>
  );
}
