import { Stack, Typography } from '@mui/material';
import { useLanguage } from '../../../../provider/LanguageProvider';

type Props = {
  title: string;
  value?: number;
  unit: string;
  percentage?: number;
  hidePercentage?: boolean;
};

const Meter = ({ title, value, percentage, unit, hidePercentage }: Props) => {
  const { localize } = useLanguage();
  const percentageLabel = localize.formatAsPercentage(percentage);

  return (
    <Stack direction="row">
      <Typography variant="body2" color="text.secondary" mr={2}>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" mr={1} ml="auto">
        {!hidePercentage && percentageLabel}
      </Typography>
      <Typography variant="body2" fontWeight={700} minWidth={110} textAlign="right">
        {localize.formatAsCompact(value, { unit })}
      </Typography>
    </Stack>
  );
};

export default Meter;
