import { Box, Grid } from '@mui/material';
import { climate_risk_type_enum } from '@predium/client-graphql';
import ClimateRiskGraph from '../../../components/data-visialization/esg-analysis/ClimateRiskChart';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import { BenchmarkType } from '../Components/EsgAnalysisBenchmark';
import { DisplayViewType } from '../Components/EsgAnalysisViewSwitcher';
import EsgAnalysisBuildingListTab from './BuildingListTab/EsgAnalysisBuildingListTab';
import EsgAnalysisSection from './EsgAnalysisSection';
import { CurrentPageView, EsgAnalysisTab } from './PortfolioAnalysisHeader';

type Props = {
  currentPageView?: CurrentPageView;
  currentTab: EsgAnalysisTab;
  showTotal: boolean;
  currentView: DisplayViewType;
  selectedBenchmark: BenchmarkType;
};

export default function PortfolioAnalysisBody({
  currentPageView,
  currentTab,
  showTotal,
  currentView,
  selectedBenchmark,
}: Props) {
  const { analyzedBuildings } = useEsgAnalysisFilters();

  const climateRisks = analyzedBuildings?.climateRisks || [];

  const climateRisksData = climateRisks.map((risk) => ({
    ...risk,
    type: climate_risk_type_enum[risk.type],
  }));

  if (currentTab === 'demand') {
    if (currentView === 'dashboard') {
      return (
        <Box sx={{ pt: 5 }}>
          <EsgAnalysisSection
            showTotal={showTotal}
            currentPageView={currentPageView}
            selectedBenchmark={selectedBenchmark}
          />
        </Box>
      );
    }

    return (
      <Box sx={{ pt: 5 }}>
        <EsgAnalysisBuildingListTab showTotal={showTotal} />
      </Box>
    );
  }

  if (currentTab === 'climate_risks') {
    return (
      <Box sx={{ pt: 5 }}>
        <Grid item xs={12}>
          <ClimateRiskGraph climateRisks={climateRisksData} />
        </Grid>
      </Box>
    );
  }

  return null;
}
