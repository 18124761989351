import { Box, CardHeader, useTheme } from '@mui/material';
import { TARGET_PATH_FIRST_YEAR, TARGET_PATH_LAST_YEAR } from '@predium/client-lookup';
import isEqual from 'lodash/isEqual';
import max from 'lodash/max';
import merge from 'lodash/merge';
import { memo, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import BaseOptionChart from '../../../theme/apexcharts';

type Props = {
  strandingData: number[];
};
const PortfolioDistributionByStranding = ({ strandingData }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { filters } = useEsgAnalysisFilters();

  const minStrandingYear = useMemo(() => {
    return filters?.minStrandingYear && filters?.minStrandingYear?.length > 0
      ? filters.minStrandingYear
      : [TARGET_PATH_FIRST_YEAR];
  }, [filters?.minStrandingYear]);

  const maxStrandingYear = useMemo(() => {
    return filters?.maxStrandingYear && filters?.maxStrandingYear?.length > 0
      ? filters.maxStrandingYear
      : [TARGET_PATH_LAST_YEAR];
  }, [filters?.maxStrandingYear]);

  const totalYears = maxStrandingYear[0] - minStrandingYear[0];

  const years = Array.from({ length: totalYears + 1 }, (_, i) => minStrandingYear[0] + i);
  const dataWithYears = strandingData ? years.map((year, index) => [year, strandingData[index]]) : [];

  const chartState: ApexCharts.ApexOptions = merge(BaseOptionChart(), {
    chart: {
      id: 'portfolio-distribution-by-stranding',
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          fontSize: '12px',
        },
      },
      tickAmount: years.length > 20 ? 20 : years.length,
      title: {
        text: t('General_Year'),
        style: {
          fontSize: '12px',
          color: theme.palette.text.secondary,
          fontWeight: 500,
        },
        offsetY: 5,
      },
    },
    yaxis: {
      min: 0,
      max: strandingData ? max(strandingData) : 10,
      tooltip: {
        enabled: false,
      },

      labels: {
        formatter: function (val: number) {
          return Math.round(val).toString();
        },
      },
      title: {
        text: t('General_NoOfStrandedBuildings'),
        style: {
          fontSize: '12px',
          color: theme.palette.text.secondary,
          fontWeight: 500,
        },
      },
    },
    stroke: {
      width: 3,
      curve: 'straight',
      colors: [theme.palette.energySourceTypeColors.NATURAL_GAS.dark],
    },
    markers: {
      size: 3,
      colors: [theme.palette.energySourceTypeColors.NATURAL_GAS.dark],
      strokeColors: theme.palette.energySourceTypeColors.NATURAL_GAS.dark,
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },

    tooltip: {
      custom: ({ seriesIndex, dataPointIndex, w }: { seriesIndex: number; dataPointIndex: number; w: any }) => {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return `
          <div class="apexcharts-custom-tooltip" style="font-size:12px;">           
            <div>
              <span>${t('General_Year')}: ${data[0]}</span>
            </div>
            <div>
               <span>${t('General_Number')}: ${data[1]}</span>
            </div>
          </div>
        `;
      },
    },
  });

  const series = [
    {
      name: t('General_NoOfStrandedBuildings'),
      data: dataWithYears,
    },
  ];

  return (
    <Box>
      <CardHeader
        title={t('EsgAnalysis_PortfolioDistributionByStrandingGraph-Title')}
        sx={{ p: 0, pl: 3 }}
        subheader={t('EsgAnalysis_PortfolioDistributionByStrandingGraph-SubTitle')}
      />
      <Chart options={chartState} series={series} type="line" height={350} />
    </Box>
  );
};

export default memo(PortfolioDistributionByStranding, isEqual);
