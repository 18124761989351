/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { mergeSx } from '../../../utils/cssStyles';
import Iconify from '../../Iconify';
import Scrollbar from '../../Scrollbar';

type BaseProps = Omit<DialogProps, 'actions' | 'children'> & {
  open: boolean;
  onClose: () => void;
  showClose?: boolean;
  disableCloseAction?: boolean;
};

type DialogContentProps = {
  dialogtitle: ReactNode | string;
  content: ReactNode;
  actions: ReactNode;
  /**
   * If true, two dividers will be shown between title, content and actions
   * @default false
   */
  showDividers?: boolean;
  /**
   * Height of the scrollbar. If provided, the content will be scrollable and actions will be sticky
   */
  scrollBarContentHeight?: string;
};

type DefinedByProperty = BaseProps &
  DialogContentProps & {
    type: 'definedByProperty';
  };

type DefinedByChildren = BaseProps & {
  type: 'definedByChildren';
  children: ReactNode;
};

type CloseIconProps = {
  onClose: VoidFunction;
  disabled?: boolean;
  sx?: SxProps;
};

export const CloseIcon = ({ onClose, disabled = false, sx }: CloseIconProps) => {
  return (
    <IconButton
      sx={mergeSx({ position: 'absolute', top: 20, right: 12, zIndex: 5 }, sx)}
      onClick={onClose}
      disabled={disabled}
    >
      <Iconify icon={'charm:cross'} width={20} height={20} />
    </IconButton>
  );
};

export const PreDialogBody = ({
  dialogtitle,
  content,
  actions,
  showDividers = false,
  scrollBarContentHeight,
}: DialogContentProps) => {
  const theme = useTheme();
  return (
    <>
      {typeof dialogtitle === 'string' ? (
        //@ts-ignore
        <DialogTitle
          style={{ overflow: 'hidden' }}
          sx={{ borderBottom: showDividers ? `1px solid ${theme.palette.grey[500_24]}` : undefined, mr: 2 }}
        >
          <Typography variant="h5">{dialogtitle}</Typography>
        </DialogTitle>
      ) : (
        dialogtitle
      )}
      {scrollBarContentHeight ? (
        <Scrollbar
          sx={{
            // make actions sticky and content scrollable by fixing maxHeight
            maxHeight: scrollBarContentHeight,
          }}
        >
          <DialogContent>{content}</DialogContent>
        </Scrollbar>
      ) : (
        <DialogContent>{content}</DialogContent>
      )}
      {/*@ts-ignore */}
      <DialogActions sx={{ borderTop: showDividers && `1px solid ${theme.palette.grey[500_24]}`, overflow: 'hidden' }}>
        {actions}
      </DialogActions>
    </>
  );
};

type PreDialogTitleIconProps = {
  icon?: string | ReactNode;
};

type PreDialogTitleProps = PreDialogTitleIconProps & {
  title: string;
};

export const PreDialogTitle = ({ icon, title }: PreDialogTitleProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        p: 3,
        //Need some space between close icon so different right padding
        pr: 5,
      }}
    >
      {icon ? (
        typeof icon === 'string' ? (
          <Iconify width={24} height={24} icon={icon} sx={{ mr: 1, mt: 0.5 }} />
        ) : (
          icon
        )
      ) : null}

      <Typography variant="h5">{title}</Typography>
    </Box>
  );
};

/**
 *
 * @param props discriminator properties. You can use either of these to create a default Dialog with less boilerplate or create your own Dialog by providing all Elements as children.
 *
 *  * @returns A vanilla MUI Dialog with the appropriate inner components or an layer around your own content.
 */
function PreDialog(props: DefinedByChildren | DefinedByProperty): JSX.Element | null {
  const { onClose, open, type, showClose = true, disableCloseAction = false, ...dialogProps } = props;

  if (type === 'definedByChildren') {
    return (
      <Dialog open={open} onClose={onClose} {...dialogProps} disableEscapeKeyDown={disableCloseAction}>
        {showClose && <CloseIcon onClose={onClose} disabled={disableCloseAction} />}
        {props.children}
      </Dialog>
    );
  } else if (type === 'definedByProperty') {
    const { dialogtitle, content, actions } = props;
    return (
      <Dialog open={open} onClose={onClose} {...dialogProps} disableEscapeKeyDown={disableCloseAction}>
        {showClose && <CloseIcon onClose={onClose} disabled={disableCloseAction} />}
        <PreDialogBody dialogtitle={dialogtitle} content={content} actions={actions} />
      </Dialog>
    );
  }

  return null;
}

export default PreDialog;
