/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Box, Stack, Typography } from '@mui/material';
import { getBuildingsWhere } from '@predium/graphql-where-client';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBoxWithLazyLoading, { SearchItemType } from '../../../components/search/SearchBoxWithLazyLoading';
import { GET_BUILDINGS_BY_IDS, GET_FILTERED_BUILDINGS } from '../../../graphql/EsgAnalysis.queries';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import { getEconomicUnitWhereFilters, getPortfolioWhereFilters } from '../../../utils/getWhereFilters';
import { EsgAnalysisFiltersKeys } from '../EsgAnalysisFilters/EsgAnalysisFilters';
import { CurrentPageView } from '../Portfolio/PortfolioAnalysisHeader';
import { mapToBuildingSelectOption } from './BuildingSelector';
import NavigationSelectorButton from './NavigationSelectorButton';

type Props = {
  currentPageView?: CurrentPageView;
};

export default function BuildingsSelector({ currentPageView = 'esg-portfolio' }: Props) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const limit = 10; // limit for pagination

  const [buildings, setBuildings] = useState<SearchItemType[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(''); // For debounced query trigger
  const [filteredBuildings, setFilteredBuildings] = useState<SearchItemType[]>([]);

  const { filters, setFilter, replaceFilter } = useEsgAnalysisFilters();
  const buildingIds = filters.buildingIds;

  const { loading, fetchMore, refetch } = useQuery(GET_FILTERED_BUILDINGS, {
    variables: {
      offset: 0,
      limit,
      buildingWhere: getBuildingsWhere(
        currentPageView === 'esg-portfolio' ? getPortfolioWhereFilters(filters) : getEconomicUnitWhereFilters(filters),
        debouncedSearchTerm.trim(),
      ), // list should only show buildings that are in the selected portfolios
    },
    skip: !showSearchBox || !debouncedSearchTerm, // Skip if no debounced search term
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.building) {
        setBuildings(data.building.map(mapToBuildingSelectOption));
        setHasMore(data.building.length === limit); // Check if we have more data
      }
    },
  });

  const { data } = useQuery(GET_BUILDINGS_BY_IDS, {
    variables: {
      // @ts-ignore
      ids: buildingIds,
    },
    skip: showSearchBox || !buildingIds || buildingIds.length === 0,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowSearchBox(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const getItemFromIds = (ids: number[] | undefined) => {
    if (!ids) {
      return [];
    }
    return buildings.filter((building: SearchItemType) => ids.includes(building.value));
  };

  const onBuildingSelected = (selectedBuilding: SearchItemType) => {
    setFilter(EsgAnalysisFiltersKeys.buildingIds, selectedBuilding.value);
  };

  const loadMore = async (newOffset: number, newDebouncedSearchTerm: string) => {
    if (!hasMore) return;

    const result = await fetchMore({
      variables: {
        offset: newOffset,
        limit,
        text: `%${newDebouncedSearchTerm.trim()}%`,
      },
    });

    // Check if we received more data
    if (result.data.building.length < limit) {
      setHasMore(false); // No more data
    } else {
      setHasMore(true); // More data exists
    }

    // Append new buildings
    setBuildings((prev) => [...prev, ...result.data.building.map(mapToBuildingSelectOption)]);
  };

  useEffect(() => {
    if (showSearchBox) {
      setFilteredBuildings(getItemFromIds(buildingIds) ?? []);
    } else {
      setFilteredBuildings(data?.building ? data?.building.map(mapToBuildingSelectOption) : []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildings, data, buildingIds, showSearchBox]);

  const count = filters.buildingIds?.length ?? 0;

  const selectorLabel =
    count > 1
      ? `${count} ${t('General_Building', {
          count: count,
        })}`
      : filteredBuildings[0]?.label ?? t('General_Building');

  const handleTypographyClick = () => {
    setShowSearchBox(!showSearchBox);
    if (buildings.length === 0) {
      refetch(); // Fetch initial data if buildings are empty
    }
  };

  return (
    <Stack direction="row" alignItems={'center'}>
      <Box ref={ref} ml={1}>
        <Stack direction="row" alignItems={'center'}>
          <NavigationSelectorButton
            title={selectorLabel}
            onClick={handleTypographyClick}
            isSelected={showSearchBox}
            tooltipText={t('General_SelectBuilding')}
          />

          <Typography variant="h4" sx={{ ml: 0.5 }}>
            {t('General_In')}
          </Typography>
        </Stack>

        {showSearchBox && (
          <SearchBoxWithLazyLoading
            refetch={refetch}
            onItemSelected={onBuildingSelected}
            selectedItems={filteredBuildings}
            loading={loading}
            listItems={buildings}
            loadMore={loadMore}
            hasMore={hasMore}
            setHasMore={setHasMore}
            setDebouncedSearchTerm={setDebouncedSearchTerm}
            debouncedSearchTerm={debouncedSearchTerm}
            noDataText={t('EsgAnalysisFilterBarBuildingFilter_NoBuildingsFound')}
            placeholder={t('General_SearchBuildingPlaceholder')}
            isMultiSelect
            handleClear={() => replaceFilter(EsgAnalysisFiltersKeys.buildingIds, [])}
            showClearButton={count > 0 || filteredBuildings.length > 0}
            onHideSearchBox={() => setShowSearchBox(false)}
          />
        )}
      </Box>
    </Stack>
  );
}
