import { Skeleton, Stack, TableCell, TableRow } from '@mui/material';

export default function RecommendedActionSkeletons() {
  return (
    <>
      {[...Array(3)].map((_, index) => (
        <TableRow key={`recommended-${index}`}>
          <TableCell>
            <Stack direction="row" alignItems="center" gap={2}>
              <Skeleton variant="circular" width={20} height={20} />
              <Stack gap={0.5}>
                <Skeleton variant="text" width={180} />
                <Skeleton variant="text" width={140} sx={{ opacity: 0.7 }} />
              </Stack>
            </Stack>
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={60} />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" width={80} height={24} sx={{ borderRadius: 1 }} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={60} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={60} />
          </TableCell>
          <TableCell>
            <Stack alignItems="flex-end">
              <Skeleton variant="text" width={100} />
            </Stack>
          </TableCell>
          <TableCell>
            <Stack alignItems="flex-end">
              <Skeleton variant="text" width={100} />
            </Stack>
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={80} />
          </TableCell>
          <TableCell />
        </TableRow>
      ))}
    </>
  );
}
