/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Checkbox, Stack, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';

export type HeadLabel = {
  id: string;
  label: string;
  subtitle?: string;
  sortingDisabled?: Boolean;
  minWidth?: number;
  alignment?: 'left' | 'right' | 'center';
  tooltip?: string;
};

type EsgAnalysisBuildingListHeadProps = {
  order: 'asc' | 'desc';
  orderBy: string;
  rowCount: number;
  headLabel: HeadLabel[];
  numSelected: number;
  onSelectAllClick: (checked: boolean) => void;
  onRequestSort: (id: string) => void;
  buildingIds: number[];
  isNotFound: boolean;
};

export default function EsgAnalysisBuildingListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onSelectAllClick,
  onRequestSort,
  buildingIds,
  isNotFound,
}: EsgAnalysisBuildingListHeadProps) {
  return (
    <TableHead>
      <TableRow>
        {!buildingIds.length && !isNotFound && rowCount !== 0 && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSelectAllClick(event.target.checked)}
            />
          </TableCell>
        )}

        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            //@ts-ignore
            sx={{ ...(!isNaN(headCell.minWidth) && { minWidth: headCell.minWidth }) }}
          >
            {!headCell.sortingDisabled ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onRequestSort(headCell.id)}
                sx={{
                  mt: !!headCell.subtitle ? 2 : 0,
                  alignItems: 'flex-start',
                }}
              >
                <Label headCell={headCell} />
              </TableSortLabel>
            ) : (
              <Label headCell={headCell} />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type LabelProps = {
  headCell: HeadLabel;
};

function Label({ headCell }: LabelProps) {
  return (
    <Stack flexDirection={'column'} justifyContent={'center'}>
      {headCell.label}
      {headCell.subtitle && (
        <Typography variant="caption" color="text.secondary" component={'div'}>
          {headCell.subtitle}
        </Typography>
      )}
    </Stack>
  );
}
