import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { country_enum } from '@predium/enums';
import { translateCountryEnum } from '@predium/i18n/client';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, UseFormReturn, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { COUNTRIES_ICON_PATH } from '../../../../../../assets/images';
import { FormProvider, RHFNumberField, RHFSelect, RHFTextField } from '../../../../../../components/hook-form';
import { ActiveStepRef } from '../../BuildingCreationProvider';
import { getManualAddressFormSchema, type ManualAddressFormType } from '../validations';

type FormDefaultValues = ReturnType<ReturnType<typeof getManualAddressFormSchema>['cast']>;

export const MANUAL_ADDRESS = 'MANUAL_ADDRESS' as const;

export type ManualAddressFormRef = {
  type: typeof MANUAL_ADDRESS;
  onSubmit: UseFormReturn<ManualAddressFormType>['handleSubmit'];
};

type Props = {
  defaultValues: FormDefaultValues;
  onValidChange: (isValid: boolean) => void;
  onDirtyChange: (dirty: boolean) => void;
  isParentDirty: boolean;
};

const ManualAddressForm = forwardRef<ActiveStepRef, Props>(
  ({ defaultValues, onValidChange, onDirtyChange, isParentDirty }, ref) => {
    const { t } = useTranslation();
    const schema = getManualAddressFormSchema(t);

    const methods = useForm<ManualAddressFormType>({
      defaultValues,
      resolver: yupResolver(schema, { stripUnknown: true }),
    });

    const { trigger } = methods;
    const { isSubmitted, isValid, dirtyFields, isDirty } = useFormState({
      control: methods.control,
    });

    const handleCountryChange = () => {
      if (isSubmitted && dirtyFields.postalCode) {
        trigger('postalCode');
      }
    };

    useEffect(() => {
      if (isSubmitted) {
        onValidChange(isValid);
      }
    }, [isSubmitted, isValid, onValidChange]);

    useEffect(() => {
      const isFormSecondUse = isParentDirty && !isValid;
      onDirtyChange(isDirty || isFormSecondUse);
    }, [isDirty, isValid, onDirtyChange, isParentDirty]);

    useImperativeHandle(ref, () => ({
      type: MANUAL_ADDRESS,
      onSubmit: methods.handleSubmit,
    }));

    return (
      <FormProvider methods={methods}>
        <Grid
          container
          maxWidth="sm"
          mx="auto"
          spacing={2}
          py={3}
          sx={{
            '&.MuiGrid-root.MuiGrid-container': {
              maxWidth: 640,
            },
          }}
        >
          <Grid item xs={12}>
            <Stack spacing={1} mb={2}>
              <Typography variant="h4">{t('BuildingCreation_ManualAddressTitle')}</Typography>
              <Typography variant="body1" color="text.secondary">
                {t('BuildingCreation_ManualAddressSubtitle')}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <RHFSelect label={t('General_Country')} name="countryId" onValueChange={handleCountryChange}>
              {Object.values(country_enum).map((option) => (
                <MenuItem key={option} value={option}>
                  <Stack direction="row" alignItems="center">
                    <Box component="img" src={`${COUNTRIES_ICON_PATH}${option}.svg`} mr={1} />
                    <Typography>{translateCountryEnum(option)}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </RHFSelect>
          </Grid>
          <Grid item xs={12}>
            <RHFTextField name="street" label={t('General_Address')} />
          </Grid>
          <Grid item xs={6}>
            <RHFNumberField
              allowLeadingZeros
              allowDecimals={false}
              name="postalCode"
              label={t('General_PostalCode')}
              valueAsText
            />
          </Grid>
          <Grid item xs={6}>
            <RHFTextField name="city" label={t('General_City')} />
          </Grid>
        </Grid>
      </FormProvider>
    );
  },
);

export default ManualAddressForm;
