import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  options: { value: string; label: ReactNode }[];

  /**
   * if true, the radio group is rendered in a horizontal row
   */
  row?: boolean;
};

export default function RHFRadio({ name, options, row }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <FormControl>
          <RadioGroup {...field} row={row}>
            {options.map((option) => (
              <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
}
