import { ApolloCache, StoreObject } from '@apollo/client';

/**
 * Delete apollo cache by id
 *
 * @example
 * ```ts
 * const [deleteBuildingData] = useMutation(DELETE_BUILDING_DATA, {
 *  ...
 *   update(cache, { data }) {
 *     if (data.delete_building_by_pk) {
 *       deleteCache(cache, data.delete_building_by_pk);
 *     }
 *   });
 * });
 * ```
 */
export const deleteCache = (cache: ApolloCache<any>, data: StoreObject) => {
  cache.evict({ id: cache.identify(data) });
  cache.gc();
};
