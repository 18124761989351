/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useLazyQuery } from '@apollo/client';
import { BuildingsTableViewWhere, EsgAnalysisGetPortfolioQuery } from '@predium/client-graphql';
import { getAnalyzedBuildingsWhere } from '@predium/graphql-where-client';
import { useSnackbar } from 'notistack';
import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useSearchParams } from 'react-router-dom';
import { createStandardErrorSnackbar } from '../components/NotistackProvider';
import { GET_PORTFOLIO } from '../graphql/EsgAnalysis.queries';
import { useEsgAnalysisFilterActions } from '../hooks/esg-hooks/useEsgAnalysisFilterActions';
import {
  esgAnalysisFilters,
  EsgAnalysisFiltersKeys,
  EsgAnalysisFiltersSearchParams,
  FindEsgAnalysisFilterValue,
} from '../sections/EsgAnalysis/EsgAnalysisFilters/EsgAnalysisFilters';
import { filtersToSearchParams, searchParamsToFilters } from '../sections/EsgAnalysis/EsgAnalysisUtil';
import { getBuildingWhereFilters } from '../utils/getWhereFilters';

export const defaultFilters = (): EsgAnalysisFiltersSearchParams => {
  const defaultFilters: EsgAnalysisFiltersSearchParams = {};

  esgAnalysisFilters.forEach((filter) => {
    defaultFilters[filter.key] = [];
  });

  return defaultFilters;
};

type TContext = {
  filters: EsgAnalysisFiltersSearchParams;
  setFilter: <Key extends EsgAnalysisFiltersKeys>(filter: Key, value: FindEsgAnalysisFilterValue<Key>) => void;
  replaceFilter: <Key extends EsgAnalysisFiltersKeys>(filter: Key, value: FindEsgAnalysisFilterValue<Key>[]) => void;
  resetFilter: (filter: EsgAnalysisFiltersKeys) => void;
  resetAllFilters: VoidFunction;
  isFilterDataLoading: boolean;
  analyzedBuildings?: EsgAnalysisGetPortfolioQuery['getAnalyzedBuildings'] | null;
  resetFilters: (keysToDelete: EsgAnalysisFiltersKeys[]) => void;
  setFilters: (filters: EsgAnalysisFiltersSearchParams) => void;
};

const EsgAnalysisFilterContext = createContext<TContext | null>(null);

function EsgAnalysisFilterContextProvider({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<EsgAnalysisFiltersSearchParams>(() => searchParamsToFilters(searchParams));

  const filterCount = Object.values(filters).reduce((acc, curr) => acc + curr.length, 0);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    setSearchParams(filtersToSearchParams(filters));
  }, [filters, setSearchParams]);

  const [loadPortfolio, { loading: isFilterDataLoading, data, previousData: previousFilterBuildingData }] =
    useLazyQuery(GET_PORTFOLIO, {
      onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    const strandingYearFilter: BuildingsTableViewWhere = {};
    const minStrandingYear = filters?.minStrandingYear?.[0];
    const maxStrandingYear = filters?.maxStrandingYear?.[0];

    if (minStrandingYear) {
      strandingYearFilter.first_stranding_year = strandingYearFilter.first_stranding_year || {};
      strandingYearFilter.first_stranding_year.gte = minStrandingYear;
    }
    if (maxStrandingYear) {
      strandingYearFilter.first_stranding_year = strandingYearFilter.first_stranding_year || {};
      strandingYearFilter.first_stranding_year.lte = maxStrandingYear;
    }

    loadPortfolio({
      variables: {
        buildingWhere: filterCount > 0 ? getAnalyzedBuildingsWhere(getBuildingWhereFilters(filters), currentYear) : {},
        tableViewWhere: strandingYearFilter,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const { setFilter, replaceFilter, resetAllFilters, resetFilter, resetFilters } = useEsgAnalysisFilterActions(
    setFilters,
    filters,
  );

  const analyzedBuildings = data?.getAnalyzedBuildings ?? previousFilterBuildingData?.getAnalyzedBuildings;

  return (
    <EsgAnalysisFilterContext.Provider
      value={{
        filters,
        setFilter,
        replaceFilter,
        resetAllFilters,
        resetFilter,
        isFilterDataLoading,
        analyzedBuildings,
        resetFilters,
        setFilters,
      }}
    >
      {children}
    </EsgAnalysisFilterContext.Provider>
  );
}

export { EsgAnalysisFilterContext, EsgAnalysisFilterContextProvider };
