import { Stack, SxProps, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { maybeEnum } from '@predium/utils';
import { useMemo } from 'react';
import { FILE_FORMAT_ICONS } from '../assets/icons';
import { mergeSx } from '../utils/cssStyles';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

type FileIconProps = {
  format?: FileFormat;
  tooltip?: boolean;
  sx?: SxProps<Theme>;
  title?: string;
};

export enum FileFormat {
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  XLS = 'xls',
  XLSX = 'xlsx',
  PPT = 'ppt',
  PPTX = 'pptx',
  JPEG = 'jpeg',
  JPG = 'jpg',
  PNG = 'png',
  GIF = 'gif',
  MP3 = 'mp3',
  WAV = 'wav',
  OGG = 'ogg',
  FLAC = 'flac',
  CSV = 'csv',
}

const DOC_FORMATS = [FileFormat.DOC, FileFormat.DOCX];
const XLS_FORMATS = [FileFormat.XLS, FileFormat.XLSX];
const PPT_FORMATS = [FileFormat.PPT, FileFormat.PPTX];
export const IMG_FORMATS = [FileFormat.JPEG, FileFormat.JPG, FileFormat.PNG, FileFormat.GIF];
const AUDIO_FORMATS = [FileFormat.MP3, FileFormat.WAV, FileFormat.OGG, FileFormat.FLAC];

/**
 * This was a MinimalV4 component but I removed overkill code and made it my own.
 */
export default function FileThumbnail({ title, tooltip, sx, format }: FileIconProps) {
  const renderContent = useMemo(() => {
    const sharedSx = {
      width: 32,
      height: 32,
    } satisfies SxProps<Theme>;

    const defaultIcon = <Iconify icon={FILE_FORMAT_ICONS.FILE} sx={mergeSx(sharedSx, { color: '#C4CDD5' }, sx)} />;

    if (!format) {
      return defaultIcon;
    }

    if (format === FileFormat.PDF) {
      return <Iconify icon={FILE_FORMAT_ICONS.PDF} sx={mergeSx(sharedSx, { color: '#B71D18' }, sx)} />;
    }

    if (DOC_FORMATS.includes(format)) {
      return <Iconify icon={FILE_FORMAT_ICONS.DOC} sx={mergeSx(sharedSx, { color: '#0070C0' }, sx)} />;
    }

    if (XLS_FORMATS.includes(format)) {
      return <Iconify icon={FILE_FORMAT_ICONS.XLS} sx={mergeSx(sharedSx, { color: '#217346' }, sx)} />;
    }

    if (PPT_FORMATS.includes(format)) {
      return <Iconify icon={FILE_FORMAT_ICONS.PPT} sx={mergeSx(sharedSx, { color: '#C0504D' }, sx)} />;
    }

    if (IMG_FORMATS.includes(format)) {
      return <Iconify icon={FILE_FORMAT_ICONS.IMG} sx={mergeSx(sharedSx, { color: '#22C55E' }, sx)} />;
    }

    if (AUDIO_FORMATS.includes(format)) {
      return <Iconify icon={FILE_FORMAT_ICONS.MP3} sx={mergeSx(sharedSx, { color: '#36B37E' }, sx)} />;
    }

    if (format === FileFormat.CSV) {
      return <Iconify icon={FILE_FORMAT_ICONS.CSV} sx={mergeSx(sharedSx, { color: '#8B8B8B' }, sx)} />;
    }

    return defaultIcon;
  }, [format, sx]);

  if (tooltip) {
    return (
      <Tooltip title={title}>
        <Stack
          flexShrink={0}
          component="span"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 'fit-content',
            height: 'inherit',
          }}
        >
          {renderContent}
          {/* Download Button clashes with Button style overrides */}
          {/* {onClick && <DownloadButton onDownload={onClick} />} */}
        </Stack>
      </Tooltip>
    );
  }

  return (
    <>
      {renderContent}
      {/* {onClick && <DownloadButton onDownload={onClick} />} */}
    </>
  );
}

export const getFileFormat = (fileName: string) => {
  return maybeEnum(FileFormat, (fileName.split('.').at(-1) ?? '').toLowerCase());
};
