import { Stack, Typography } from '@mui/material';
import DetailedTooltip from '../../../../../../components/DetailedTooltip/DetailedTooltip';

type Props = {
  title: string;
  tooltipBody: string;
};

const RegulatoryConstraint = ({ title, tooltipBody }: Props) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="body2">{title}</Typography>
      <DetailedTooltip
        labels={{
          title,
          body: tooltipBody,
        }}
      />
    </Stack>
  );
};

export default RegulatoryConstraint;
