import { Box, Divider, SxProps, Theme, useTheme } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';

type Props = {
  sx?: SxProps<Theme>;
  showDivider?: boolean;
};

/**
 * A sticky box that sticks to the top of the screen and has an optional divider at the bottom. Meant to be used as a header for a page.
 */
const StickyBox = ({ children, sx, showDivider = true }: PropsWithChildren<Props>) => {
  const theme = useTheme();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 60);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        right: 0,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        mx: isScrolled ? '-1%' : 0,
        px: isScrolled ? 2 : 0,
        pt: isScrolled ? 2 : 0,

        ...sx,
      }}
    >
      {children}
      {showDivider && <Divider />}
    </Box>
  );
};

export default StickyBox;
