import { Grid, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function EmptyActionList() {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell colSpan={10}>
        <Grid
          color="text.disabled"
          sx={{
            textAlign: 'center',
            placeItems: 'center',
            pt: 5,
            pb: 7,
          }}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6" color={'text.secondary'}>
            {t('ActionPlanningActionPlan_ActionTableEmpty-title')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('ActionPlanningActionPlan_ActionTableEmpty-subtitle')}
          </Typography>
        </Grid>
      </TableCell>
    </TableRow>
  );
}
