import { CardHeader } from '@mui/material';
import Iconify from '../../../../components/Iconify';

type Props = {
  title: string;
  subtitle?: string;
  icon: string;
};

const ActionPlanSectionTitle = ({ title, subtitle, icon }: Props) => {
  return (
    <CardHeader
      avatar={<Iconify icon={icon} width={20} height={20} />}
      title={title}
      subheader={subtitle}
      sx={{
        px: 0,
        mb: 3,
        '& .MuiCardHeader-avatar': {
          alignSelf: 'flex-start',
          marginTop: 0.25,
          mr: 1,
        },
        '& .MuiCardHeader-subheader': {
          mt: 0,
        },
      }}
    />
  );
};

export default ActionPlanSectionTitle;
