import { SxProps, TextField, TextFieldProps, Theme } from '@mui/material';
import { language_enum } from '@predium/enums';
import { ReactNode, useEffect, useState } from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import { useLanguage } from '../../provider/LanguageProvider';

interface IProps {
  label?: ReactNode;
  value?: number | null;
  onValueChange?: (values: NumberFormatValues) => void;
  min?: number;
  max?: number;
  size?: TextFieldProps['size'];
  sx?: SxProps<Theme>;
  disabled?: boolean;
  variant?: TextFieldProps['variant'];
  placeholder?: string;
  decimalScale?: number;
  helperText?: string;
  autoFocus?: boolean;
  numberFormatProps?: NumberFormatProps<TextFieldProps>;
  allowDecimals?: boolean;
  maxDecimalPlaces?: number;
  autoComplete?: TextFieldProps['autoComplete'];
  isError?: boolean;
}

export type NumberFieldProps = IProps & Pick<TextFieldProps, 'InputProps' | 'onChange'>;

export default function NumberField({
  value,
  label,
  size = 'medium',
  disabled = false,
  min,
  max = Number.MAX_SAFE_INTEGER,
  sx,
  variant,
  placeholder,
  helperText,
  onValueChange,
  allowDecimals = true,
  maxDecimalPlaces = 3,
  autoComplete = 'on',
  isError = false,
  ...other
}: NumberFieldProps) {
  const { language } = useLanguage();

  const [decimalSeparator, setDecimalSeparator] = useState<string>('.');
  const [thousandSeparator, setThousandSeparator] = useState<string>(',');

  useEffect(() => {
    setDecimalSeparator(language === language_enum.deDE ? ',' : '.');
    setThousandSeparator(language === language_enum.deDE ? '.' : ',');
  }, [language]);

  return (
    <NumberFormat
      value={value !== null ? value : ''}
      onValueChange={(values, _) => {
        if (onValueChange) {
          onValueChange(values);
        }
      }}
      size={size}
      variant={variant}
      customInput={TextField}
      autoComplete={autoComplete}
      label={label}
      sx={sx}
      disabled={disabled}
      placeholder={placeholder}
      helperText={helperText}
      error={isError}
      allowedDecimalSeparators={allowDecimals ? [',', '.'] : undefined}
      decimalSeparator={allowDecimals ? decimalSeparator : undefined}
      thousandSeparator={allowDecimals ? thousandSeparator : false}
      decimalScale={allowDecimals ? maxDecimalPlaces : 0} // Default to 3 decimal places if decimals are allowed
      allowNegative={false}
      onChange={() => {
        //this is intentionally left blank since the value is handled by onValueChange
      }}
      isAllowed={(values) => {
        const { floatValue, formattedValue } = values;
        return formattedValue === '' || (floatValue !== undefined && floatValue >= 0 && floatValue < max);
      }}
      {...other}
    />
  );
}
