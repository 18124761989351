/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Checkbox, MenuItem, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import {
  CreateReportModalGetAllTemplatesTemplateFragment,
  CreateReportMutationVariables,
} from '@predium/client-graphql';
import { isEuTaxonomyAvailable } from '@predium/utils';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Iconify from '../../../components/Iconify';
import { DelayedLoading } from '../../../components/Loading';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import Unavailable from '../../../components/Unavailable';
import { FormProvider, RHFDatePicker, RHFSelect } from '../../../components/hook-form';
import { GET_PORTFOLIOS } from '../../../graphql/Portfolio.queries';
import { GET_SUB_BUILDINGS, GET_USERS } from '../../../graphql/Report.queries';
import useSessionData from '../../../hooks/useSessionData';
import useTable, { applySortFilter } from '../../../hooks/useTable';
import { PATHS } from '../../../routes';
import { DataCollectionTabActionsEnum } from '../../DataCollection/Buildings/TabsEnums';
import SearchTableToolbar from '../../SearchTableToolbar';
import { getTemplateMetadataForTemplateTitle } from '../ReportingDataClasses/ReportTemplate.dc';
import ReportingReportsTableHead, { HeadLabel } from '../ReportingReportsTableHead';

type Props = {
  template: CreateReportModalGetAllTemplatesTemplateFragment;
  onParameterSelection: (parameter: CreateReportMutationVariables) => Promise<any>;
};

const currentYear = new Date().getFullYear();

type CreateReportFormValues = {
  portfolio_id: number;
  start_date: Date;
  end_date: Date;
  due_date: Date;
  responsible_user_id: number;
  sub_buildings: number[];
};

export default function CreateReportModalParameter({ template, onParameterSelection }: Props) {
  const { t } = useTranslation();
  const { user } = useSessionData();
  const theme = useTheme();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  // TABLE
  const columns: HeadLabel[] = [
    { id: 'street', label: t('General_Building') },
    { id: 'city', label: t('General_City') },
    { id: 'postal_code', label: t('General_PostalCodeAbbreviated') },
    { id: 'action', label: '' },
  ];

  // QUERIES
  const [currentPortfolioId, setCurrentPortfolioId] = useState<number | null>(null);
  const { data: portfolioData, loading: portfoliosLoading } = useQuery(GET_PORTFOLIOS, {
    onCompleted: (data) => setCurrentPortfolioId(data.portfolio[0].id),
  });
  const { data: userData, loading: usersLoading } = useQuery(GET_USERS);
  const { loading: subBuildingsLoading, data: subBuildingData } = useQuery(GET_SUB_BUILDINGS, {
    //@ts-ignore
    variables: { portfolio_id: currentPortfolioId },
    skip: !currentPortfolioId,
  });

  const { page, setPage, order, orderBy, filterName, handleFilterByName, handleRequestSort } = useTable({
    defaultOrderBy: 'street',
  });

  const portfolios = portfolioData?.portfolio ?? [];

  const ErrorMessages = {
    portfolio_id: {
      required: t('Reporting_CreateReportFormErrorMessages-portfolioRequired'),
    },
    start_date: {
      required: t('Reporting_CreateReportFormErrorMessages-startDateRequired'),
    },
    end_date: {
      required: t('Reporting_CreateReportFormErrorMessages-endDateRequired'),
      afterStart: t('Reporting_CreateReportFormErrorMessages-endDateAfterStartDate'),
    },
    due_date: {
      required: t('Reporting_CreateReportFormErrorMessages-dueDateRequired'),
      afterEnd: t('Reporting_CreateReportFormErrorMessages-dueDateAfterEndDate'),
    },
    responsible_user_id: {
      required: t('Reporting_CreateReportFormErrorMessages-responsibleUserRequired'),
    },
    sub_buildings: {
      required: t('Reporting_CreateReportFormErrorMessages-subBuildingsRequired'),
    },
  };

  // FORM
  // All state is managed via the form. The form can be tried for submit initially. Afterwards the submit action is only available if the form is valid.
  const BasicDataSchema = Yup.object().shape({
    portfolio_id: Yup.number()
      .typeError(ErrorMessages.portfolio_id.required)
      .integer(ErrorMessages.portfolio_id.required)
      .oneOf(
        portfolios.map((p) => p.id),
        ErrorMessages.portfolio_id.required,
      )
      .optional(),
    start_date: Yup.date().required(ErrorMessages.start_date.required),
    end_date: Yup.date()
      .required(ErrorMessages.end_date.required)
      .test('is-after-start', ErrorMessages.end_date.afterStart, function (value) {
        const { start_date } = this.parent;
        if (!start_date || !value) return true; // Skip validation if dates are not set
        return new Date(value) > new Date(start_date);
      }),
    due_date: Yup.date()
      .required(ErrorMessages.due_date.required)
      .min(Yup.ref('end_date'), ErrorMessages.due_date.afterEnd),
    responsible_user_id: Yup.number()
      .integer()
      .required(ErrorMessages.responsible_user_id.required)
      .typeError(ErrorMessages.responsible_user_id.required),
    sub_buildings: Yup.array().of(Yup.number().integer()).min(1, ErrorMessages.sub_buildings.required),
  });

  const methods = useForm<CreateReportFormValues>({
    resolver: yupResolver(BasicDataSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      portfolio_id: undefined,
      start_date: new Date(`${currentYear}-01-01`),
      end_date: new Date(`${currentYear}-12-31`),
      due_date: new Date(`${currentYear + 1}-01-01`),

      //@ts-ignore
      responsible_user_id: user.id,
      sub_buildings: [],
    },
  });
  const {
    handleSubmit,
    watch,
    formState: { isSubmitted, isValid, errors },
    setValue,
    trigger,
  } = methods;

  if (portfoliosLoading || usersLoading || subBuildingsLoading || !subBuildingData) return <DelayedLoading />;

  const selectedPortfolioId = watch('portfolio_id');
  if (selectedPortfolioId && selectedPortfolioId !== currentPortfolioId) {
    setCurrentPortfolioId(selectedPortfolioId);
  }
  // Filtering Mixed sub buildings not to be shown in the list
  const subBuildings = subBuildingData.sub_building;
  const selectableSubBuildings = subBuildings.filter((subBuilding) =>
    isEuTaxonomyAvailable(subBuilding.building.areas),
  );

  //@ts-ignore
  const users = userData.user;

  const rowsPerPage = 5;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subBuildings.length) : 0;
  const filteredRows = applySortFilter({
    data: subBuildings,
    nameFilter: {
      fieldName: (subBuilding) => subBuilding.building.address.street,
      filterValue: filterName,
    },
    orderOptions: {
      order,
      orderBy: (item) => item.building.address[orderBy as keyof typeof item.building.address],
    },
  });

  const isNotFound = !filteredRows.length && Boolean(filterName);

  const selectedSubBuildings = watch('sub_buildings');
  const handleSubBuildingSelectionToggle = (subBuildingId: number) => {
    // react hook form get current sub-buildings
    const isAlreadySelectedIndex = selectedSubBuildings.findIndex(
      (currentSubBuildingId) => currentSubBuildingId === subBuildingId,
    );

    // remove sub_building from react hook form if already set
    if (isAlreadySelectedIndex !== -1) {
      selectedSubBuildings.splice(isAlreadySelectedIndex, 1);
      setValue('sub_buildings', selectedSubBuildings);
    } else {
      // Otherwise add it
      setValue('sub_buildings', [...selectedSubBuildings, subBuildingId]);
    }

    trigger('sub_buildings');
  };

  const handleSelectAllSubBuildingCheckboxToggle = () => {
    const allSubBuildingsSelected = selectedSubBuildings.length === selectableSubBuildings.length;
    if (allSubBuildingsSelected) {
      setValue('sub_buildings', []);
    } else {
      setValue(
        'sub_buildings',
        selectableSubBuildings.map((subBuilding) => subBuilding.id),
      );
    }

    trigger('sub_buildings');
  };

  const onSubmit = async (data: CreateReportFormValues) => {
    setIsSubmitting(true);
    await onParameterSelection({
      ...data,
      report_template_id: template.id,
    });
    setIsSubmitting(false);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ mt: 3 }} spacing={3}>
        <Grid item xs={5}>
          <Typography variant="subtitle1" sx={{ mb: 3 }}>
            {t('General_Portfolio')}
          </Typography>
          <RHFSelect label={t('General_Portfolio')} name="portfolio_id">
            {portfolios.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </RHFSelect>

          <Box sx={{ mt: 5 }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              {t('General_ReportingPeriod')}
            </Typography>
            <RHFDatePicker label={t('General_FromTo-from')} name="start_date" />
          </Box>
          <Box sx={{ mt: 3 }}>
            <RHFDatePicker label={t('General_FromTo-to')} name="end_date" />
          </Box>

          <Box sx={{ mt: 5 }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              {t('General_DueAt')}
            </Typography>
            <RHFDatePicker label={t('General_DueAt')} name="due_date" />
          </Box>

          <Box sx={{ mt: 5 }}>
            <Typography variant="subtitle1" sx={{ mb: 3 }}>
              {t('General_ResponsibleUser')}
            </Typography>
            <RHFSelect label={t('General_User')} name="responsible_user_id">
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.first_name} {user.last_name}
                </MenuItem>
              ))}
            </RHFSelect>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: 1, minHeight: '3rem' }}>
            <Typography variant="subtitle1">{t('General_SelectBuilding')}</Typography>

            {!template.single_sub_building && (
              <Box sx={{ display: 'flex' }}>
                <Typography variant="body1" fontWeight={900} sx={{ display: 'flex', alignItems: 'center' }}>
                  {t('Reporting_SelectWholePortfolio')}
                </Typography>
                <Checkbox
                  checked={selectedSubBuildings.length === selectableSubBuildings.length}
                  onChange={handleSelectAllSubBuildingCheckboxToggle}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Box>
            )}
          </Box>

          {/* BUILDING TABLE */}
          <Box
            sx={{
              border: '1px solid',
              borderColor: theme.palette.grey[500_32],
              borderRadius: '8px',
            }}
          >
            <SearchTableToolbar numSelected={0} filterName={filterName} onFilterName={handleFilterByName} />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table sx={{ height: '100%' }}>
                  <ReportingReportsTableHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={columns}
                    onRequestSort={handleRequestSort}
                  />

                  <TableBody>
                    {subBuildingData.sub_building.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <div style={{ display: 'grid', placeItems: 'center' }}>
                            <Unavailable
                              title={t('Reporting_CreateReportModalNoBuildingsTitle')}
                              subTitle={t('Reporting_CreateReportModalNoBuildingsSubTitle')}
                              onClick={() =>
                                navigate(
                                  PATHS.dataCollection.buildings(null, {
                                    action: DataCollectionTabActionsEnum.CREATE_ENERGY_CERTIFICATE_DRAFT,
                                  }),
                                )
                              }
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    )}

                    {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((subBuilding) => {
                      const isDisabled = !isEuTaxonomyAvailable(subBuilding.building.areas);
                      const { address } = subBuilding.building;

                      return (
                        <TableRow key={subBuilding.id}>
                          <TableCell>{address.street}</TableCell>
                          <TableCell>{address.city}</TableCell>
                          <TableCell>{address.postal_code}</TableCell>
                          <TableCell align="right">
                            {selectedSubBuildings.includes(subBuilding.id) ? (
                              <LoadingButton
                                sx={{ width: '100%', m: 0 }}
                                variant="outlined"
                                color="error"
                                loading={subBuildingsLoading}
                                onClick={() => handleSubBuildingSelectionToggle(subBuilding.id)}
                              >
                                {template.single_sub_building
                                  ? t('General_RemoveSelection')
                                  : t('General_RemoveBuilding')}
                              </LoadingButton>
                            ) : template.single_sub_building && selectedSubBuildings.length === 1 ? (
                              <></>
                            ) : (
                              <Tooltip title={isDisabled ? t('Reporting_CreateReportModalMixedBuildingTooltip') : ''}>
                                <span>
                                  <LoadingButton
                                    sx={{ width: '100%', m: 0 }}
                                    variant={!isDisabled ? 'contained' : 'outlined'}
                                    loading={subBuildingsLoading}
                                    onClick={() => handleSubBuildingSelectionToggle(subBuilding.id)}
                                    disabled={isDisabled}
                                  >
                                    {template.single_sub_building
                                      ? t('General_SelectBuilding')
                                      : t('General_AddBuilding')}
                                  </LoadingButton>
                                </span>
                              </Tooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}

                    {isNotFound && <SearchNotFound searchQuery={filterName} />}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={subBuildings.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(_, page) => setPage(page)}
              labelRowsPerPage={t('General_RowsPerPage')}
              labelDisplayedRows={({ from, to, count }) => t('General_TableDisplayedRowsLabel', { from, to, count })}
            />
          </Box>

          {isSubmitted && errors['sub_buildings'] && (
            <Typography sx={{ color: 'error.main', textAlign: 'center' }}>{errors['sub_buildings'].message}</Typography>
          )}
        </Grid>

        <LoadingButton
          variant="contained"
          type="submit"
          loading={isSubmitting}
          startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
          sx={{ py: 1.5, ml: 3, mt: 4, width: '100%' }}
          disabled={(isSubmitted && !isValid) || selectedSubBuildings.length === 0}
        >
          {getTemplateMetadataForTemplateTitle(template.title).readableTitle}{' '}
          {t('Reporting_CreateReportButton', { count: selectedSubBuildings.length })}
        </LoadingButton>
      </Grid>
    </FormProvider>
  );
}
