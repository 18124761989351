/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from '@apollo/client';
import { TextField, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DropzoneComponent, { FileId, UploadingFile } from '../../../../../components/DropzoneComponent';
import { SnackbarTimeouts } from '../../../../../components/NotistackProvider';
import { CREATE_EVIDENCE_FROM_FILE } from '../../../../../graphql/Report.mutations';
import { GET_REPORT_EVIDENCES } from '../../../../../graphql/Report.queries';
import useUpdateFilename from '../../../../../hooks/useUpdateFilename';
import { EvidenceListEntry } from '../RootQuestionEvidences';
import { AddURLEvidenceFormHandles } from './AddURLEvidenceForm';

type Props = {
  reportId: number;
  onEvidencesAdded: (evidences: EvidenceListEntry[]) => void;
  persistedUploadFileRef: React.MutableRefObject<(Dropzone.DropzoneFile & FileId) | null>;
};

const AddDocumentEvidenceForm = forwardRef<AddURLEvidenceFormHandles, Props>(
  ({ persistedUploadFileRef, reportId, onEvidencesAdded }, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const [fileEvidence, setFileEvidence] = useState<Omit<EvidenceListEntry, 'description'>>();
    const [description, setDescription] = useState('');
    // FIXME: weird state that is currently needed for the upload. Might be removed after refactor to react-upload
    const [, setUploadInProgress] = useState(false);

    const { updateFilename } = useUpdateFilename();
    const { data, refetch: refetchEvidences } = useQuery(GET_REPORT_EVIDENCES, { variables: { reportId } });
    const [createEvidenceFromFileMutation] = useMutation(CREATE_EVIDENCE_FROM_FILE, {
      variables: { reportId },
      onCompleted: () =>
        enqueueSnackbar(t('AddDocumentEvidenceForm_UploadEvidence-success'), {
          variant: 'success',
          autoHideDuration: SnackbarTimeouts.Success,
        }),
      onError: () =>
        enqueueSnackbar(t('AddDocumentEvidenceForm_UploadEvidence-error'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        }),
    });

    const asyncCreateUploadUrl = async (): Promise<UploadingFile> => {
      const {
        //@ts-ignore
        data: { createEvidenceFromFile },
      } = await createEvidenceFromFileMutation();

      return {
        fileUrl: createEvidenceFromFile.upload_url,
        fileId: createEvidenceFromFile.CreateEvidenceOutput[0].file.id,
      };
    };

    // If a document has been uploaded before we initialize the intermediate default value.
    useEffect(() => {
      if (persistedUploadFileRef.current && !fileEvidence && data) {
        const chosenEvidence = data.report_evidence.filter(
          //@ts-ignore
          (option) => persistedUploadFileRef.current.fileId === option.file?.id,
        );
        const displayedEvidence = chosenEvidence.map<Omit<EvidenceListEntry, 'description'>>(({ id, file }) => ({
          id,

          //@ts-ignore
          title: file.name ?? '---',
          type: 'file',

          //@ts-ignore
          url: file.downloadUrl,
        }));
        setFileEvidence(displayedEvidence[0]);
      }
    }, [data, fileEvidence, persistedUploadFileRef]);

    useImperativeHandle(ref, () => ({
      submit: () => {
        //@ts-ignore
        onEvidencesAdded([{ ...fileEvidence, description }]);
      },
    }));

    return (
      <div style={{ marginTop: 16 }}>
        <DropzoneComponent
          fileDisplayName={t('General_Evidence_one')}
          description={
            <Typography variant="body1">
              <Trans
                i18nKey="AddDocumentEvidenceForm_UploadEvidence-description"
                components={{
                  span: <span style={{ textDecoration: 'underline', cursor: 'pointer' }} />,
                }}
              />
            </Typography>
          }
          // I can only make it work to commit evidence directly with 1 file. Dropzone is just too buggy.
          maxFiles={1}
          createUploadUrl={asyncCreateUploadUrl}
          defaultValue={persistedUploadFileRef.current ? [persistedUploadFileRef.current] : undefined}
          onUploadSuccess={(file) => {
            updateFilename(file.fileId, file.name).then(async () => {
              const {
                data: { report_evidence },
              } = await refetchEvidences();
              const chosenEvidence = report_evidence.filter((option) => file.fileId === option.file?.id);
              const displayedEvidence = chosenEvidence.map<Omit<EvidenceListEntry, 'description'>>(({ id, file }) => ({
                id,

                //@ts-ignore
                title: file.name ?? '---',
                type: 'file',

                //@ts-ignore
                url: file.downloadUrl,
              }));
              setFileEvidence(displayedEvidence[0]);
              persistedUploadFileRef.current = file;
            });
          }}
          setUploadInProgress={setUploadInProgress}
        />

        {fileEvidence !== undefined && (
          <>
            <Trans
              i18nKey="ReportEvidence_EvidenceHintOptional"
              components={{
                span: <span style={{ color: theme.palette.grey[600] }} />,
              }}
            />
            <TextField
              style={{ marginTop: 16 }}
              multiline
              fullWidth
              label={t('General_Note')}
              minRows={3}
              maxRows={6}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </>
        )}
      </div>
    );
  },
);

export default AddDocumentEvidenceForm;
