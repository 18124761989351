import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { data_source_type_enum } from '@predium/enums';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../../components/Iconify';
import CustomDataSourceInput from '../../../../../../components/custom-data-source-input';
import { useLanguage } from '../../../../../../provider/LanguageProvider';

export type CustomCosts = {
  cost_total_custom?: number;
  use_custom_cost: boolean;
};

export type CustomCostsForm = CustomCosts & { cost_custom_input?: string };

type Props = {
  renovationArea: number;
  totalCostEstimated?: number;
  forceCustomCost?: boolean;
  showResetButton?: boolean;
};

const RowCosts = ({ renovationArea, totalCostEstimated, forceCustomCost, showResetButton = true }: Props) => {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const { watch, setValue, reset } = useFormContext<CustomCostsForm>();

  const use_custom_cost = watch('use_custom_cost');
  const cost_total_custom = watch('cost_total_custom');
  const pricePerM2 = (use_custom_cost ? cost_total_custom ?? 0 : totalCostEstimated ?? 0) / (renovationArea ?? 1);
  const formattedPricePerM2 = localize.formatAsCurrency(pricePerM2 !== 0 ? pricePerM2 : null, {
    perUnit: 'm²',
    fractionDigits: 0,
  });

  const dataSource = useMemo(
    () => (use_custom_cost ? data_source_type_enum.MANUAL : data_source_type_enum.APPROXIMATED),
    [use_custom_cost],
  );

  const resetToEstimated = () => {
    const cost2Str = Math.round(totalCostEstimated ?? 0).toString();
    reset({ cost_custom_input: cost2Str });
    setValue('cost_total_custom', undefined, { shouldDirty: true });
    setValue('use_custom_cost', false, { shouldDirty: true });
  };

  return (
    <TableRow
      sx={{
        '& > *': {
          borderBottom: 'unset',
          whiteSpace: 'nowrap',
        },
        width: '100%',
      }}
    >
      <TableCell component="th" scope="row" sx={{ width: '100%', verticalAlign: 'top' }}>
        <Typography variant="subtitle2" fontWeight={700}>
          {t('General_Cost', { count: 2 })}
        </Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <Stack display="flex" flexDirection={'column'} alignItems={'end'}>
          <Stack direction="row" alignItems="center" justifyContent={'right'} spacing={2}>
            {use_custom_cost && !forceCustomCost && showResetButton && (
              <Tooltip title={t('ActionPricingTotalCost_BackToEstimatedCosts')} arrow placement="top">
                <IconButton onClick={() => resetToEstimated()}>
                  <Iconify icon="material-symbols:undo" height={18} width={18} />
                </IconButton>
              </Tooltip>
            )}
            <CustomDataSourceInput
              name="cost_custom_input"
              suffix="€"
              source={dataSource}
              showSourceTooltip
              onClickAway={(value) => {
                setValue('cost_total_custom', value);
                if (!use_custom_cost) setValue('use_custom_cost', true);
              }}
              showTooltip
              disabled={forceCustomCost}
            />
          </Stack>
          {!!pricePerM2 && !!renovationArea && (
            <Typography color="text.secondary" variant="caption">
              {formattedPricePerM2}
            </Typography>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default RowCosts;
