export type InputValue = string | number | null;

/**
 * Rounds a numeric value to a specified number of decimal places.
 *
 * @param {InputValue} value - The numeric value to be rounded.
 * @param {number} [decimals=1] - The number of decimal places to round to (default is 1).
 * @returns {number} The rounded numeric value.
 */
export function fRoundedNumber(value: InputValue, decimals = 1) {
  if (!value) {
    return 0;
  }
  const number = parseFloat(value as string);
  return parseFloat((number * Math.pow(10, decimals)).toFixed(0)) / Math.pow(10, decimals);
}

/**
 * Checks if a number is close to an expected value within a certain precision.
 *
 * @param {number} value - The number to check.
 * @param {number} expected - The expected value.
 * @param {number} [precision=1e-5] - The precision within which the values should be considered close.
 * @returns {boolean} Returns true if the absolute difference between the value and the expected value is less than the
 *   precision.
 */

export function isCloseTo(value: number, expected: number, precision = 1e-5): boolean {
  return Math.abs(value - expected) < precision;
}

/**
 * This methods expects two values, before and after an implemented action and returns the impact of in **percent**.
 * As both values can be undefined the special cases are handled to avoid NaN or Infinity.
 *
 * @param after the numerator (the value after the action)
 * @param before the denominator (the value before the action)
 */
export function calculateImpact(after?: number, before?: number): number {
  if (before === undefined || after === undefined) {
    return 0;
  }
  if (before === 0) {
    if (after === 0) {
      return 0;
    } else if (after > 0) {
      return 100;
    } else {
      return -100;
    }
  }
  return (1 - after / before) * -1 * 100;
}

/**
 * This methods expects two values, before and after an implemented action and returns the **absolute** savings while
 * the individual numbers being rounded first. As both values can be undefined the special cases are handled to avoid
 * NaN or Infinity.
 *
 * @param after the value after the action
 * @param before the value before the action
 */
export function calculateSavings(after?: number | null, before?: number | null): number | undefined {
  if (before === undefined || before === null || after === undefined || after === null) {
    return undefined;
  }
  return Math.round(after) - Math.round(before);
}

/**
 * Converts centimeters to meters with a specified number of decimal places.
 *
 * @param {number} cm - The length in centimeters to convert to meters.
 * @param {number} decimalPlaces - The number of decimal places to round the result to. Default is 2.
 * @returns {string} The converted length in meters as a string with the specified precision.
 */ export const cmToM = (cm: number, decimalPlaces = 2): number => parseFloat((cm / 100).toFixed(decimalPlaces));

/**
 * Converts meters to centimeters with a specified number of decimal places.
 *
 * @param {number} m - The length in meters to convert to centimeters.
 * @param {number} decimalPlaces - The number of decimal places to round the result to. Default is 2.
 * @returns {string} The converted length in centimeters as a string with the specified precision.
 */

export const mToCm = (m: number, decimalPlaces = 2): number => parseFloat((m * 100).toFixed(decimalPlaces));

/* This method helps solving the floating-point bug in JavaScript.
 * Source: https://github.com/maslianok/js-floating-point/blob/master/src/index.js
 * @param value
 * @param recurringSymbols
 * @returns
 */
export function fFloatingPoint(value: number, recurringSymbols = 6) {
  const [intPart, decimalPart] = `${value}`.split('.');

  if (!decimalPart) {
    // no decimal part
    return value;
  }

  const regex = new RegExp(`(9{${recurringSymbols},}|0{${recurringSymbols},})(\\d)*$`, 'gm');
  const matched = decimalPart.match(regex);

  if (!matched) {
    // no floating-point bug
    return value;
  }

  const [wrongPart] = matched;
  const correctDecimalsLength = decimalPart.length - wrongPart.length;
  return Number(parseFloat(`${intPart}.${decimalPart}`).toFixed(correctDecimalsLength));
}

export function convertUTCDateToLocalDate(date: Date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}
