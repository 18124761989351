import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../assets/icons';
import DetailedTooltip from '../../../../../components/DetailedTooltip/DetailedTooltip';

const EstimatedFieldsDetailsIcon = () => {
  const { t } = useTranslation();

  return (
    <Box display="inline-flex" sx={{ verticalAlign: 'middle' }}>
      <DetailedTooltip
        labels={{
          title: t('BuildingCreation_EstimationTooltipTitle'),
          preBody: (
            <Trans
              i18nKey="BuildingCreation_EstimationTooltipBody"
              components={{
                br: <Box mb={1} />,
              }}
            />
          ),
        }}
        size={16}
        slotsProps={{
          icon: {
            icon: ICONS.INFO_CONTAINED,
            color: 'text.primary',
          },
        }}
        readMoreLink="https://support.predium.de/artikel/gebude-und-energieausweise-anlegen#block-190ad925e038809baa09cc322b4bdd59"
      />
    </Box>
  );
};

export default EstimatedFieldsDetailsIcon;
