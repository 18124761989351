import { graphql } from '@predium/client-graphql';

graphql(`
  fragment EnvelopeUnit on envelope {
    id
    secondary_id
    area
    envelope_type_id
    insulation_material_name
    u_value
    insulation_thickness
    orientation
    inclination
  }
`);

graphql(`
  fragment EnergyConsumerForAction on energy_consumer {
    name
    id
    energy_consumer_type_id
    energy_source_type_id
    energy_consumer_technology_type_id
    construction_year
    efficiency
    energy_final
    energy_source_type_id
    energy_system_consumer_routes {
      id
      secondary_id
      energy_source_type_id
      energy_system {
        energy_system_type_id
      }
      energy_final
    }
  }
`);

graphql(`
  fragment EnergyPaths on energy_path {
    year
    crrem_co2_emissions
    energy_consumption
    energy_costs_total
    co2_costs_total
    efficiency_class_id
  }
`);

graphql(`
  fragment EnergySystem on energy_system {
    id
    secondary_id
    energy_system_type_id
    energy_system_consumer_routes {
      id
      secondary_id
      energy_consumer {
        ...EnergyConsumerForAction
      }
      energy_final
      energy_source_type_id
      energy_distribution {
        id
        last_hydraulic_balancing
        last_balancing_set_to_never
        heat_transfer_loss
        balancing_with_components
      }
    }
    energy_system_producer_routes {
      energy_source_type_id
      energy_producer {
        nominal_power
      }
    }
  }
`);

graphql(`
  fragment BuildingModel on building_model {
    id
    building {
      id
      year_constructed
    }
    energy_final
    energy_primary
    heating_demand
    energy_paths(order_by: { year: asc }) {
      ...EnergyPaths
    }
    envelope_units(order_by: { id: asc }) {
      ...EnvelopeUnit
    }
    energy_consumers {
      id
      energy_consumer_type_id
      energy_consumer_technology_type_id
      efficiency
      energy_system_consumer_routes {
        energy_system {
          energy_system_type_id
        }
      }
    }
    energy_distributions {
      id
      heat_transfer_loss
      last_hydraulic_balancing
      last_balancing_set_to_never
      balancing_with_components
    }
    energy_systems {
      ...EnergySystem
    }
  }
`);

graphql(`
  fragment EnvelopeRenovation on renovation_envelope {
    new_envelope {
      id
      secondary_id
      area
      envelope_type_id
      insulation_material_name
      u_value
      cost_per_m2
      insulation_lambda
      insulation_method_id
      insulation_thickness
      insulation_material_category_id
    }
    old_entity_id
    old_envelope {
      id
      secondary_id
      building_model {
        envelope_units(order_by: { id: asc }) {
          ...EnvelopeUnit
        }
      }
    }
  }
`);

graphql(`
  fragment Action on action {
    id
    created_at
    implementation_to
    order
    editable
    custom_action_type_id
    custom_name
    custom_saving_fraction
    renovations {
      renovation_type_id
      cost_total
      cost_total_predium
      cost_total_custom
      use_custom_cost
    }
    renovations_energy_consumer {
      new_energy_consumer {
        ...EnergyConsumerForAction
      }
      old_energy_consumer {
        ...EnergyConsumerForAction
      }
    }

    renovations_envelope {
      ...EnvelopeRenovation
    }

    renovations_energy_routes {
      new_energy_system_route {
        id
        energy_source_type_id
        energy_system {
          energy_system_type_id
        }
      }
      old_energy_system_route {
        id
        energy_source_type_id
        energy_system {
          energy_system_type_id
        }
      }
    }

    renovations_energy_producer {
      new_energy_producer {
        name
        nominal_power
        module_peak_power
        module_angle
        solar_active_area
        roof_area_ratio
        cost_per_m2
      }
    }

    building_models {
      ...BuildingModel
    }
  }
`);

graphql(`
  fragment ActionForRecommendedAction on action {
    id
    implementation_to
  }
`);

graphql(`
  fragment ActionPlanningActionSubsidy on action_subsidy {
    id
    subsidy {
      id
      title
      categories {
        subsidy_category_type
      }
    }
    type
    value
    action {
      id
      renovations {
        cost_total
        cost_total_predium
        renovation_type_id
      }
    }
  }
`);

graphql(`
  fragment ModificationImpacts on ModificationImpact {
    co2_emissions {
      after
      before
      impact
    }
    final_energy {
      after
      before
      impact
    }
    primary_energy {
      after
      before
      impact
    }
    efficiency_class {
      before
      after
    }
    stranding_date {
      before
      after
      impact
    }
    energy_cost_total {
      after
      before
      impact
    }
    co2_cost_total {
      after
      before
      impact
    }
    heating_demand {
      after
      before
      impact
    }
  }
`);

graphql(`
  fragment RecommendedActionImpactOutput on RecommendedActionImpactOutput {
    __typename
    co2_cost_total {
      after
      before
      impact
    }
    co2_emissions {
      after
      before
      impact
    }
    efficiency_class {
      after
      before
    }
    energy_cost_total {
      after
      before
      impact
    }
    final_energy {
      after
      before
      impact
    }
    primary_energy {
      after
      before
      impact
    }
    stranding_date {
      after
      before
      impact
    }
    heating_demand {
      after
      before
      impact
    }
    total_estimated_cost
  }
`);

graphql(`
  fragment ActionImpactDataOutput on ActionImpactDataOutput {
    action_id
    total_renovation_cost
    total_subsidy_cost
    total_estimated_cost
    modification_impact {
      ...ModificationImpacts
    }
  }
`);

graphql(`
  fragment ActionPlanImpactDataOutput on ActionPlanImpactDataOutput {
    total_renovation_cost
    total_subsidy_cost
    total_estimated_cost
    modification_impact {
      ...ModificationImpacts
    }
  }
`);

graphql(`
  fragment ActionPlanMetrics on ActionPlanMetricsOutput {
    action_plan {
      ...ActionPlanImpactDataOutput
    }
    actions {
      ...ActionImpactDataOutput
    }
  }
`);

graphql(`
  fragment TargetPaths on target_path {
    value
    year
    target_path_template {
      name
      target_path_template_type_id
    }
  }
`);

graphql(`
  fragment ActionPlanningActionPlanActionPlan on action_plan {
    id
    created_at
    scenario {
      name
      id
      created_at
      portfolio {
        name
      }
      owner {
        first_name
        last_name
        id
      }
    }
    building {
      id
      monument_protection
      year_constructed
      address {
        street
        city
        country_id
        postal_code
      }
      areas(where: { area_type_id: { _eq: EBF } }) {
        value
        type_of_use_id
        area_type_id
        class_of_use_id
      }
      active_building_model {
        ...BuildingModel
      }
      sub_buildings {
        __typename
        type_of_use_id
        subsidies {
          subsidy {
            ...DataCollectionSubBuildingSubBuildingSubsidy
          }
        }
      }
      target_paths(order_by: { year: asc }) {
        ...TargetPaths
      }
    }
  }
`);

graphql(`
  fragment ActionPlanningScenarioActionPlan on action_plan {
    id
    created_at
    building {
      id
      address {
        street
        city
        postal_code
        country_id
      }
      active_building_model {
        energy_paths(where: { year: { _eq: $year } }) {
          ...EnergyPaths
        }
      }
    }
    actions_aggregate {
      aggregate {
        count
        max {
          updated_at
        }
      }
    }
    actions(order_by: { order: desc }, limit: 1) {
      building_models(limit: 1) {
        energy_paths(order_by: { year: asc }) {
          efficiency_class_id
        }
      }
    }
  }
`);

graphql(`
  fragment ScenarioActionPlans on action_plan {
    id
    actions_aggregate {
      aggregate {
        count
        max {
          updated_at
        }
      }
    }
  }
`);

graphql(`
  fragment ActionPlanningScenariosScenario on scenario {
    description
    name
    portfolio {
      id
      name
    }
    id
    created_at
    updated_at
    owner {
      id
      first_name
      last_name
    }
    action_plans {
      ...ScenarioActionPlans
    }
  }
`);

graphql(`
  fragment ActionPlanningScenarioScenario on scenario {
    ...ActionPlanningScenariosScenario
    action_plans {
      ...ActionPlanningScenarioActionPlan
    }
  }
`);

graphql(`
  fragment CreateActionPlanModalBuilding on building {
    id
    address {
      street
      city
      postal_code
    }
    active_building_model {
      action_planning_available
      energy_paths(where: { year: { _eq: $year } }) {
        ...EnergyPaths
      }
    }
  }
`);

graphql(`
  fragment EnergySystemRouteRenovationParameter on EnergySystemRouteRenovationParameterOutput {
    __typename
    energy_source_type
  }
`);

graphql(`
  fragment EnvelopeRenovationParameter on EnvelopeRenovationParameterOutput {
    __typename
    cost_per_m2
    envelope_type
    insulation_lambda
    insulation_material_category
    insulation_method
    insulation_thickness
    material_name
    u_value
  }
`);

graphql(`
  fragment HydraulicBalancingRenovationParameter on HydraulicBalancingRenovationParameterOutput {
    __typename
    costs_with_components
    last_hydraulic_balancing
  }
`);

// The cost_per_m2 field is shared with the other fragments. Only here is it non-nullable,
// so we have to alias it for the union to work.
graphql(`
  fragment SolarRenovationParameter on SolarRenovationParameterOutput {
    __typename
    cost_per_m2_solar: cost_per_m2
    module_angle
    module_peak_power
    roof_area_ratio
    solar_active_area
    technology_name
  }
`);

graphql(`
  fragment SystemRenovationParameter on SystemRenovationParameterOutput {
    __typename
    __typename
    consumer_technology_type
    cost_per_m2
    efficiency
    energy_source_type
    system_type
    technology_name
  }
`);

graphql(`
  fragment RecommendedAction on RecommendedAction {
    __typename
    actionImpact {
      ...RecommendedActionImpactOutput
    }
    parameters {
      __typename
      ... on EnergySystemRouteRenovationParameterOutput {
        ...EnergySystemRouteRenovationParameter
      }
      ... on SystemRenovationParameterOutput {
        ...SystemRenovationParameter
      }
      ... on SolarRenovationParameterOutput {
        ...SolarRenovationParameter
      }
      ... on HydraulicBalancingRenovationParameterOutput {
        ...HydraulicBalancingRenovationParameter
      }
      ... on EnvelopeRenovationParameterOutput {
        ...EnvelopeRenovationParameter
      }
    }
  }
`);

export const GET_ACTIONPLAN = graphql(`
  query ActionPlanningActionPlanGetActionPlan($actionPlanId: Int!) {
    action_plan_by_pk(id: $actionPlanId) {
      ...ActionPlanningActionPlanActionPlan
    }
  }
`);

export const GET_SUBSIDIES = graphql(`
  query ActionPlanningGetSubsidies($actionPlanId: Int!) {
    action_subsidy(where: { action: { action_plan_id: { _eq: $actionPlanId } } }) {
      ...ActionPlanningActionSubsidy
    }
  }
`);

export const GET_SCENARIO = graphql(`
  query ActionPlanningScenarioGetScenario($scenarioId: Int!, $year: Int!) {
    scenario_by_pk(id: $scenarioId) {
      ...ActionPlanningScenarioScenario
    }
  }
`);

export const GET_SCENARIOS = graphql(`
  query ActionPlanningScenariosAllScenarios {
    scenario {
      ...ActionPlanningScenariosScenario
    }
  }
`);

export const GET_SCENARIOS_BASIC_DETAILS = graphql(`
  query ActionPlanningScenariosNames {
    scenario {
      name
      id
    }
  }
`);

export const GET_BUILDINGS = graphql(`
  query CreateActionPlanModalGetBuildings(
    $where: building_bool_exp!
    $limit: Int!
    $offset: Int!
    $orderBy: [building_order_by!]
    $year: Int!
  ) {
    building(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
      __typename
      id
      address {
        __typename
        id
        street
        city
        postal_code
        country_id
      }
      active_building_model {
        id
        action_planning_available
        energy_paths(where: { year: { _eq: $year } }) {
          ...EnergyPaths
        }
      }
    }
    building_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

export const EXPORT_ACTION_PLAN_PDF = graphql(`
  query ExportActionPlanPdf($actionPlanId: Int!, $title: String, $description: String) {
    getActionPlanPdf(actionPlanId: $actionPlanId, title: $title, description: $description) {
      body
    }
  }
`);

export const GET_ACTION_PLAN_METRICS = graphql(`
  query GetActionsPlanMetrics($actionPlanId: Int!) {
    getActionPlanMetrics(id: $actionPlanId) {
      ...ActionPlanMetrics
    }
  }
`);

export const GET_ACTIONS_FOR_ACTION_PLAN = graphql(`
  query GetActionsForActionPlan($actionPlanId: Int!) {
    action(where: { action_plan_id: { _eq: $actionPlanId } }, order_by: { order: asc }) {
      ...Action
    }
  }
`);

export const GET_ACTIONS_FOR_RECOMMENDED_ACTIONS = graphql(`
  query GetActionsForRecommendedActions($actionPlanId: Int!) {
    action(where: { action_plan_id: { _eq: $actionPlanId } }, order_by: { order: asc }) {
      ...ActionForRecommendedAction
    }
  }
`);

export const GET_RECOMMENDED_ACTIONS_FOR_BUILDING_MODEL = graphql(`
  query GetRecommendedActionsForBuildingModel(
    $action_plan_id: Int!
    $implementation_date: DateTime!
    $sorting: recommended_action_sorting_enum!
  ) {
    recommendActions(action_plan_id: $action_plan_id, implementation_date: $implementation_date, sorting: $sorting) {
      recommendedActions {
        ...RecommendedAction
      }
    }
  }
`);

export const SIMULATE_EDIT_ACTION = graphql(`
  query SimulateEditAction(
    $action_id: Int!
    $envelope_renovation_parameter: EnvelopeRenovationParameterInput!
    $implementation_to: DateTime!
  ) {
    simulateEditAction(
      action_id: $action_id
      implementation_to: $implementation_to
      envelope_renovation_parameter: $envelope_renovation_parameter
    ) {
      ...SimulateActionOutputResponse
    }
  }
`);
