import { yupResolver } from '@hookform/resolvers/yup';
import { area_type_enum, building_state_enum, country_enum } from '@predium/enums';
import { ensureDefined } from '@predium/utils';
import { TFunction } from 'i18next';
import isNil from 'lodash/isNil';
import { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DelayedLoading } from '../../../../components/Loading';
import useSessionData from '../../../../hooks/useSessionData';
import useBlockOnBack from './hooks/useBlockOnBack';
import { useGetBuildingDraft } from './hooks/useGetBuildingDraft';
import { ACTION_TYPES, ActionTypeDispatch, GlobalState, useGlobalState } from './hooks/useGlobalState';
import { useUpsertDraftBuilding } from './hooks/useUpsertDraftBuilding';
import { MANUAL_ADDRESS, ManualAddressFormRef } from './Step1Location/Substeps/ManualAddress';
import { getManualAddressFormSchema } from './Step1Location/validations';
import { BUILDING_INFORMATION, InformationFormRef } from './Step2Information';
import { getInformationFormSchema } from './Step2Information/validations';
import { Step3ReviewRef } from './Step3Review';
import { steps } from './Steps';

const getBuildingCreationSchema = (t: TFunction) => {
  return yup.object({
    address: getManualAddressFormSchema(t),
    information: getInformationFormSchema(t),
  });
};

export type BuildingCreationFormCast = ReturnType<ReturnType<typeof getBuildingCreationSchema>['cast']>;

const INITIAL_STEP = 'INITIAL_STEP' as const;

type InitialStep = {
  type: typeof INITIAL_STEP;
};

export type ActiveStepRef = InitialStep | ManualAddressFormRef | InformationFormRef | Step3ReviewRef;

type ContextType = {
  // Core data
  getBuilding: () => BuildingCreationFormCast;

  state: GlobalState;
  dispatch: ActionTypeDispatch;

  activeStep: (typeof steps)[number];
  activeStepRef: React.MutableRefObject<ActiveStepRef>;

  canSaveAsDraft: boolean;

  handleBack: VoidFunction;
  handleNext: VoidFunction;

  handleSaveAsDraft: (onSuccess?: (draftId: number | undefined) => void, skipNotification?: boolean) => void;

  handleCloseMainDialog: VoidFunction;
  handleOpenSaveAsDraftDialog: VoidFunction;
  handleCloseDialog: VoidFunction;

  isLoading: boolean;
  isMainFormDirty: boolean;
};

const BuildingCreationContext = createContext<ContextType | null>(null);

type Props = {
  id?: number;
  children: (value: ContextType) => React.ReactNode;
  onClose: VoidFunction;
};

const BuildingCreationProvider = ({ children, id, onClose: onCloseMainDialog }: Props) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getBuildingCreationSchema(t), [t]);
  const { user } = useSessionData();

  const activeStepRef = useRef<ActiveStepRef>({
    type: INITIAL_STEP,
  });

  const { state, dispatch } = useGlobalState({
    defaultStep: isNil(id) ? 0 : 1,
  });

  const activeStep = steps[state.activeStepIndex];

  const { getValues, formState, setValue, handleSubmit, reset } = useForm({
    defaultValues: schema.cast({
      address: schema.fields.address.cast({
        countryId: country_enum.DE,
        street: undefined,
        postalCode: undefined,
        city: undefined,
      }),
      information: schema.fields.information.cast({
        responsibleUserId: ensureDefined(user).id,
        coreData: {
          state: building_state_enum.INVENTORY,
          portfolioId: undefined,
        },
        additionalInformation: {
          monumentProtection: false,
          milieuProtection: false,
          heritageDistrict: false,
          leasehold: false,
        },
        area: {
          typeOfUse: undefined,
          typeOfArea: area_type_enum.EBF,
          totalArea: undefined,
        },
        energyData: [],
      }),
    }),
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { handleCreateDraftBuilding, loading } = useUpsertDraftBuilding({ id, dispatch, reset });

  const { loading: loadingBuildingDraft } = useGetBuildingDraft({ id, reset });

  const { isValid, isDirty } = formState;

  const getBuilding = useCallback(() => getValues(), [getValues]);

  const canSaveAsDraft = useMemo(() => {
    return isValid && !state.isCurrentStepInValid;
  }, [isValid, state.isCurrentStepInValid]);

  const handleStepSubmit = useCallback(
    ({ onSuccess, onError }: { onSuccess?: VoidFunction; onError?: VoidFunction }) => {
      const ref = activeStepRef.current;

      switch (ref.type) {
        case MANUAL_ADDRESS: {
          const { onSubmit } = ref;

          onSubmit((data) => {
            setValue('address', data);

            onSuccess?.();
          }, onError)();

          break;
        }
        case BUILDING_INFORMATION: {
          const { onSubmit } = ref;

          onSubmit((data) => {
            setValue('information', data);

            onSuccess?.();
          }, onError)();

          break;
        }
        default:
          onSuccess?.();
          break;
      }
    },
    [setValue],
  );

  const handleBack = useCallback(() => {
    handleStepSubmit({
      onSuccess: () => dispatch({ type: ACTION_TYPES.PREVIOUS_STEP }),
    });
  }, [dispatch, handleStepSubmit]);

  const handleNext = useCallback(() => {
    handleStepSubmit({
      onSuccess: () => dispatch({ type: ACTION_TYPES.NEXT_STEP }),
    });
  }, [dispatch, handleStepSubmit]);

  const handleSaveAsDraft = useCallback(
    (onSuccess?: (draftId: number | undefined) => void, skipNotification?: boolean) => {
      handleStepSubmit({
        onSuccess: handleSubmit((building) => {
          handleCreateDraftBuilding(building, skipNotification).then(onSuccess);
        }),
      });
    },
    [handleStepSubmit, handleSubmit, handleCreateDraftBuilding],
  );

  const handleCloseMainDialog = useCallback(() => onCloseMainDialog(), [onCloseMainDialog]);

  const handleOpenSaveAsDraftDialog = useCallback(() => {
    handleStepSubmit({
      onSuccess: () => dispatch({ type: ACTION_TYPES.SET_DRAFT_DIALOG, payload: true }),
    });
  }, [dispatch, handleStepSubmit]);

  const handleCloseDialog = useCallback(() => {
    if (!state.blockClose) {
      onCloseMainDialog();
      return;
    }

    handleStepSubmit({
      onSuccess: handleSubmit(handleOpenSaveAsDraftDialog, () => {
        dispatch({ type: ACTION_TYPES.SET_LEAVE_DIALOG, payload: true });
      }),
      onError: () => {
        dispatch({ type: ACTION_TYPES.SET_LEAVE_DIALOG, payload: true });
      },
    });
  }, [state.blockClose, handleOpenSaveAsDraftDialog, dispatch, handleStepSubmit, handleSubmit, onCloseMainDialog]);

  useBlockOnBack(state.blockClose, handleCloseDialog);

  const value = {
    getBuilding,
    state,
    activeStep,
    canSaveAsDraft,
    handleBack,
    handleNext,
    handleSaveAsDraft,
    handleCloseMainDialog,
    handleOpenSaveAsDraftDialog,
    handleCloseDialog,
    activeStepRef,
    isLoading: loading,
    isMainFormDirty: isDirty,
    dispatch,
  } satisfies ContextType;

  if (loadingBuildingDraft) {
    return <DelayedLoading />;
  }

  return <BuildingCreationContext.Provider value={value}>{children(value)}</BuildingCreationContext.Provider>;
};

export default BuildingCreationProvider;

export const useBuildingCreation = () => {
  const context = useContext(BuildingCreationContext);

  if (!context) {
    throw new Error('useBuildingCreation must be used within a BuildingCreationProvider');
  }

  return context;
};
