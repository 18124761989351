import { useApolloClient } from '@apollo/client';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DropzoneComponent, { UploadingFile } from '../../../../components/DropzoneComponent';
import { CREATE_CONSUMPTION_INVOICE_DRAFT } from '../../../../graphql/DataCollection.mutations';
import useUpdateFilename from '../../../../hooks/useUpdateFilename';

type Props = {
  buildingId: number;
  onConsumptionDraftAdded: (consumptionDraftIds: number[]) => void;
  createdConsumptionDrafts: React.MutableRefObject<{ id: number }[]>;
};

export default function UploadConsumptionInvoiceDraftModal({
  buildingId,
  onConsumptionDraftAdded,
  createdConsumptionDrafts,
}: Props) {
  const { t } = useTranslation();
  const apollo = useApolloClient();
  const { updateFilename } = useUpdateFilename();
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const asyncCreateUploadUrl = async (): Promise<UploadingFile> => {
    const res = await apollo.mutate({
      mutation: CREATE_CONSUMPTION_INVOICE_DRAFT,
      variables: {
        buildingId,
      },
    });

    createdConsumptionDrafts.current.push({
      id: res.data!.createConsumptionInvoiceDraft.CreateConsumptionInvoiceDraftOutput!.id!,
    });
    return {
      fileUrl: res.data!.createConsumptionInvoiceDraft.upload_url,
      fileId: res.data!.createConsumptionInvoiceDraft.CreateConsumptionInvoiceDraftOutput!.file_id!,
    };
  };

  return (
    <>
      <DialogTitle>{t('DataCollection_AddNewConsumptionInvoice_Title')}</DialogTitle>

      <DialogContent>
        <DropzoneComponent
          fileDisplayName={t('General_Invoice', { count: 2 })}
          description={
            <Typography variant="body1">
              <Trans
                i18nKey="DataCollection_AddNewConsumptionInvoice_Description"
                components={{
                  underline: <span style={{ textDecoration: 'underline', cursor: 'pointer' }} />,
                }}
              />
            </Typography>
          }
          createUploadUrl={asyncCreateUploadUrl}
          onUploadSuccess={(file) => {
            updateFilename(file.fileId, file.name);
          }}
          setUploadInProgress={setUploadInProgress}
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => onConsumptionDraftAdded(createdConsumptionDrafts.current.map((draft) => draft.id))}
          variant="contained"
          disabled={uploadInProgress || createdConsumptionDrafts.current.length === 0}
          fullWidth
        >
          {t('General_ReviewData')}
        </Button>
      </DialogActions>
    </>
  );
}
