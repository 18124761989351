import { Icon, IconProps, IconifyIcon } from '@iconify/react';
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';

// ----------------------------------------------------------------------

export type IconifyProps = IconProps &
  BoxProps & {
    icon: IconifyIcon | string;
  };

export default function Iconify({ icon, sx, ...other }: IconifyProps) {
  return <Box component={Icon} icon={icon} sx={sx} {...other} />;
}
