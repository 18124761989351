import { yupResolver } from '@hookform/resolvers/yup';
import { Box, MenuItem, Typography } from '@mui/material';
import { energy_system_type_enum } from '@predium/enums';
import { forwardRef, useImperativeHandle } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { FormProvider, RHFDatePicker, RHFSelect } from '../../../../../../components/hook-form';
import InfoTable from '../../../../../../components/presentations/InfoTable';
import { BuildingTabEnum } from '../../../../../../pages/DataCollection/DataCollectionBuilding';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { createHydraulicBalancingInfoTable } from '../../../../Scenario/scenarios.util';
import { getMaxDate } from '../../../ActionPlan.utils';
import { HydraulicBalancingRenovationParameters } from '../../../ActionPlanSections/RecommendedActions';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../../Components/ActionCustomStyles';
import { HydraulicBalancingFormSchema } from '../../CreateActions/validation-schema';
import { TechnicalActionEditProps } from '../TechnicalAction';

export type HydraulicBalancingForm = {
  implementation_to: DateTime | '';
  hydraulic_balancing_renovation_parameter: {
    last_hydraulic_balancing: DateTime;
    costs_with_components: boolean;
  };
};

const HydraulicBalancingAction = forwardRef(
  (
    {
      previousActionBuildingModel,
      resetSimulatedData,
      minimumDate,
      action,
    }: TechnicalActionEditProps & {
      action: HydraulicBalancingRenovationParameters;
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { language, localize } = useLanguage();

    //currently, hydraulic balancing is available only for heating systems
    const distributions = previousActionBuildingModel.energy_systems
      .filter((energy_system) => energy_system.energy_system_type_id === energy_system_type_enum.HEATING)
      .flatMap((energy_system) => energy_system.energy_system_consumer_routes)
      .flatMap((route) => route.energy_distribution)
      //filter the energy distributions where no hydraulic balancing has been carried out
      .filter((energy_distribution) => energy_distribution && !energy_distribution.last_hydraulic_balancing);

    const buildingId = previousActionBuildingModel.building!.id;

    const sumOfDistributionTransferLosses = distributions.reduce((acc, distribution) => {
      return acc + distribution!.heat_transfer_loss;
    }, 0);
    const sumOfDistributionTransferLossesRounded = Math.round(sumOfDistributionTransferLosses * 100) / 100;

    const setToNever = previousActionBuildingModel.energy_distributions.some(
      (distribution) => distribution.last_balancing_set_to_never,
    );
    const infoTableData = createHydraulicBalancingInfoTable(
      sumOfDistributionTransferLossesRounded,
      setToNever,
      t,
      localize,
    );

    const defaultValues = {
      implementation_to: minimumDate,
      hydraulic_balancing_renovation_parameter: {
        last_hydraulic_balancing: minimumDate,
        costs_with_components: action.parameters.costs_with_components ?? false,
      },
    };

    const formMethods = useForm<HydraulicBalancingForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: yupResolver(HydraulicBalancingFormSchema(minimumDate, language)),
    });
    const { control, setValue } = formMethods;

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    const { isSubmitting } = useFormState({
      control,
    });

    const handleComponentsChange = (value: number) => {
      resetSimulatedData();
      setValue('hydraulic_balancing_renovation_parameter.costs_with_components', !!value);
    };

    return (
      <FormProvider methods={formMethods}>
        <Box mt={3} mb={1}>
          {infoTableData && (
            <Box>
              <InfoTable
                data={infoTableData}
                buildingId={buildingId}
                showTab={BuildingTabEnum.technology}
                title={t('General_StateBeforeRenovation')}
              />
            </Box>
          )}
        </Box>

        <Box mb={3} px={1}>
          <Typography variant="body2" color="text.secondary">
            <Trans i18nKey="ActionPlanning_HydraulicBalancing_Info" components={{ bold: <strong /> }} />
          </Typography>
        </Box>

        <RHFSelect
          name="hydraulic_balancing_renovation_parameter.costs_with_components"
          label={t('General_Selection')}
          size={'small'}
          sx={{ ...SelectSx, mb: 1 }}
          onValueChange={handleComponentsChange}
          value={action.parameters.costs_with_components ? 1 : 0}
        >
          <MenuItem key="withComponents" value={1}>
            <StyledListItemText action={t('ActionPlanning_HydraulicBalancing_CostsWithComponents')} secondary="€" />
          </MenuItem>
          <MenuItem key="withoutComponents" value={0}>
            <StyledListItemText action={t('ActionPlanning_HydraulicBalancing_CostsWithoutComponents')} secondary="€" />
          </MenuItem>
        </RHFSelect>

        <Box mb={3} px={1}>
          <Typography variant="body2" color="text.secondary">
            <Trans i18nKey="ActionPlanning_HydraulicBalancing_ComponentsInfo" />
          </Typography>
        </Box>

        <RHFDatePicker
          label={t('General_PlannedFor')}
          name="implementation_to"
          size={'small'}
          sx={NumberInputSx}
          disabled={isSubmitting}
          minDate={minimumDate}
          maxDate={getMaxDate()}
          onChange={(date) => {
            resetSimulatedData();
            setValue('implementation_to', date as DateTime);
            setValue('hydraulic_balancing_renovation_parameter.last_hydraulic_balancing', date as DateTime);
          }}
        />
      </FormProvider>
    );
  },
);

export default HydraulicBalancingAction;
