import {
  Box,
  capitalize,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';
import { BuildingModelFragment, energy_source_type_enum } from '@predium/client-graphql';
import { translateEnergyConsumerTechnologyTypeEnum_dynamic } from '@predium/i18n/client';
import sumBy from 'lodash/sumBy';
import uniqBy from 'lodash/uniqBy';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../assets/icons';
import Iconify from '../../../../../components/Iconify';
import OverflowText from '../../../../../components/OverflowText';
import { BuildingTabEnum } from '../../../../../pages/DataCollection/DataCollectionBuilding';
import { PATHS } from '../../../../../routes/paths';
import { CustomActionCategory, CustomActionType, getCustomActionName } from '../CreateActions/CustomAction';

export type InfoTableData = {
  label: string;
  value: string | number | ReactNode;
  icon?: ReactNode;
};

type Props = {
  selectedCustomActionType: CustomActionType;
  affectedCategory: CustomActionCategory;
  buildingModel: BuildingModelFragment;
  buildingId?: number;
  sx?: SxProps;
};

const InfoTableCustomAction = ({
  selectedCustomActionType,
  affectedCategory,
  buildingModel,
  buildingId,
  sx,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const energySystems =
    affectedCategory === 'ENERGY_SOURCE'
      ? buildingModel.energy_systems.filter((energySystem) =>
          energySystem.energy_system_consumer_routes.some(
            (route) => route.energy_source_type_id === selectedCustomActionType,
          ),
        )
      : buildingModel.energy_systems.filter(
          (energySystem) => energySystem.energy_system_type_id === selectedCustomActionType,
        );

  let energySources = energySystems.flatMap((energySystem) =>
    uniqBy(
      energySystem.energy_system_consumer_routes.map((r) => r.energy_consumer),
      (c) => c.id,
    ).flatMap((consumer) => {
      return {
        name: consumer.energy_consumer_technology_type_id
          ? translateEnergyConsumerTechnologyTypeEnum_dynamic(consumer.energy_consumer_technology_type_id, t, 1)
          : t('General_Unknown'),
        finalEnergy: sumBy(consumer.energy_system_consumer_routes, (r) => r.energy_final),
        energy_source_type_id:
          consumer.energy_system_consumer_routes.filter(
            (r) => r.energy_source_type_id !== energy_source_type_enum.SOLAR,
          )[0]?.energy_source_type_id ?? t('General_Unknown'),
      };
    }),
  );

  if (affectedCategory === 'ENERGY_SOURCE') {
    energySources = energySources.filter(
      (energySource) => energySource.energy_source_type_id === selectedCustomActionType,
    );
  }

  const totalFinalEnergy = sumBy(energySources, (s) => s.finalEnergy).toFixed(2);

  const selectedActionTitle = getCustomActionName(selectedCustomActionType, t);

  return (
    <Box
      sx={{
        background: theme.palette.grey[200],
        borderRadius: 1,
        p: 2,
        ...sx,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} mb={1}>
        <Typography variant="subtitle1">{t('General_StateBeforeRenovation')}</Typography>
        {!!buildingId && (
          <IconButton
            size="small"
            target="_blank"
            href={PATHS.dataCollection.building(
              { id: buildingId },
              {
                tab: BuildingTabEnum.technology,
              },
            )}
          >
            <Iconify icon={ICONS.EDIT} />
          </IconButton>
        )}
      </Stack>

      <TableContainer component={Paper} sx={{ background: 'transparent', overflow: 'hidden' }}>
        <Table
          sx={{
            p: 0,
            td: {
              px: 0,
              py: 1,
              '&:last-child': {
                pb: 0,
              },
            },
          }}
        >
          <TableBody>
            <TableRow
              sx={{
                borderBottom: '1px solid',
                borderColor: theme.palette.divider,
                '&:last-child': {
                  borderBottom: 'none', // Remove border for the last row
                },
              }}
            >
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} py={1}>
                <OverflowText
                  text={`${t('General_FinalEnergy')} - ${capitalize(selectedActionTitle)}`}
                  maxWidth={300}
                  color="text.secondary"
                />
                <Typography variant="subtitle2">{totalFinalEnergy} kWh/m²a</Typography>
              </Stack>
            </TableRow>
            {affectedCategory === 'SYSTEM' && (
              <>
                <Divider />
                {energySources.length === 1 ? (
                  <ExistingConsumerSingle name={energySources[0].name} />
                ) : (
                  <ExistingConsumerMultiple
                    consumers={energySources.map((energySource) => ({
                      name: energySource.name,
                      finalEnergy: Number(energySource.finalEnergy.toFixed(2)),
                    }))}
                  />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const ExistingConsumerSingle = ({ name }: { name: string }) => {
  const { t } = useTranslation();
  return (
    <TableRow>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} py={1}>
        <Typography variant="body2" color="text.secondary">
          {t('General_ExistingConsumer', { count: 1 })}
        </Typography>
        <Typography variant="subtitle2">{name}</Typography>
      </Stack>
    </TableRow>
  );
};

const ExistingConsumerMultiple = ({ consumers }: { consumers: { name: string; finalEnergy: number }[] }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <TableRow onClick={() => setExpanded(!expanded)} sx={{ cursor: 'pointer' }}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} py={1}>
          <IconButton size="small" sx={{ mr: 0.5 }}>
            <Iconify
              icon={ICONS.CHEVRON_DOWN}
              width={16}
              height={16}
              color="text.secondary"
              sx={{
                transition: 'transform 0.2s cubic-bezier(0.6, 0.6, 0, 1)',
                transform: expanded ? 'rotate(180deg)' : 'rotate(0)',
              }}
            />
          </IconButton>
          <Typography
            variant="body2"
            color="text.secondary"
            whiteSpace={'nowrap'}
            width={'100%'}
            sx={{ userSelect: 'none' }}
          >
            {t('General_ExistingConsumer', { count: 2 })}
          </Typography>
          <Typography variant="subtitle2" whiteSpace={'nowrap'} sx={{ userSelect: 'none' }}>
            {consumers.length} {t('General_Consumer', { count: 2 })}
          </Typography>
        </Stack>
      </TableRow>
      <Box
        sx={{
          maxHeight: expanded ? consumers.length * 60 : 0, // Approximate height per row
          overflow: 'hidden',
          transition: 'max-height 0.2s cubic-bezier(0.6, 0.6, 0, 1)',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {consumers.map((consumer, index) => (
          <>
            <TableRow key={consumer.name}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                width={'100%'}
                alignItems={'center'}
                py={1}
                pl={4}
              >
                <OverflowText text={capitalize(consumer.name)} maxWidth={250} color="text.secondary" />
                <Typography variant="subtitle2">{consumer.finalEnergy.toFixed(2)} kWh/m²a</Typography>
              </Stack>
            </TableRow>
            {index !== consumers.length - 1 && <Divider sx={{ ml: 4 }} />}
          </>
        ))}
      </Box>
    </>
  );
};

export default InfoTableCustomAction;
