import { consumption_source_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateConsumptionSourceTypeEnum = (enumValue: consumption_source_type_enum) => {
  switch (enumValue) {
    case consumption_source_type_enum.ESTIMATION:
      return <Trans i18nKey="Enum_ConsumptionSourceTypeEnum-ESTIMATION" />;
    case consumption_source_type_enum.INVOICE:
      return <Trans i18nKey="Enum_ConsumptionSourceTypeEnum-INVOICE" />;
    case consumption_source_type_enum.MANUAL_ENTRY:
      return <Trans i18nKey="Enum_ConsumptionSourceTypeEnum-MANUAL_ENTRY" />;
    case consumption_source_type_enum.METER_READING:
      return <Trans i18nKey="Enum_ConsumptionSourceTypeEnum-METER_READING" />;
    case consumption_source_type_enum.API_ENTRY:
      return <Trans i18nKey="Enum_ConsumptionSourceTypeEnum-API_ENTRY" />;
  }
};

export const translateConsumptionSourceTypeEnum_dynamic = (
  enumValue: consumption_source_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case consumption_source_type_enum.ESTIMATION:
      return t('Enum_ConsumptionSourceTypeEnum-ESTIMATION');
    case consumption_source_type_enum.INVOICE:
      return t('Enum_ConsumptionSourceTypeEnum-INVOICE');
    case consumption_source_type_enum.MANUAL_ENTRY:
      return t('Enum_ConsumptionSourceTypeEnum-MANUAL_ENTRY');
    case consumption_source_type_enum.METER_READING:
      return t('Enum_ConsumptionSourceTypeEnum-METER_READING');
    case consumption_source_type_enum.API_ENTRY:
      return t('Enum_ConsumptionSourceTypeEnum-API_ENTRY');
  }
};
