import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PreDialog from '../presentations/PreDialog/PreDialog';

type Props = {
  open: boolean;
  onCloseWithSave: VoidFunction;
  onCloseWithoutSave: VoidFunction;
  onStay: VoidFunction;
};

const SaveAsDraftDialog = ({ open, onCloseWithSave, onCloseWithoutSave, onStay }: Props) => {
  const { t } = useTranslation();

  return (
    <PreDialog
      type="definedByProperty"
      open={open}
      onClose={onStay}
      dialogtitle={t('General_SaveAsDraft')}
      content={t('General_SaveAsDraftDescription')}
      actions={
        <Stack direction="row" justifyContent="space-between" flex={1}>
          <Button variant="outlined" onClick={onCloseWithoutSave} color="error">
            {t('General_DontSave')}
          </Button>
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" onClick={onStay} color="error">
              {t('General_Cancel')}
            </Button>
            <Button variant="contained" onClick={onCloseWithSave}>
              {t('General_Save')}
            </Button>
          </Stack>
        </Stack>
      }
    />
  );
};

export default SaveAsDraftDialog;
