import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  ActionPlanMetricsFragment,
  efficiency_class_enum,
} from '../../../../../../../../libs/generated/client-graphql/src/generated/graphql';
import { ICONS } from '../../../../../assets/icons';
import EfficiencyClassLabel from '../../../../../components/EfficiencyClassLabel';
import Iconify from '../../../../../components/Iconify';
import { useLanguage } from '../../../../../provider/LanguageProvider';

type ActionListStatusQuoProps = {
  modificationMetric?: ActionPlanMetricsFragment;
};

export default function ActionListStatusQuo({ modificationMetric }: ActionListStatusQuoProps) {
  const { t } = useTranslation();
  const { localize } = useLanguage();

  const modificationImpact = modificationMetric?.action_plan.modification_impact;

  const statusQuo = {
    final_energy: localize.formatAsFloat(modificationImpact?.final_energy?.before ?? 0),
    primary_energy: localize.formatAsFloat(modificationImpact?.primary_energy?.before ?? 0),
    efficiency_class: modificationImpact?.efficiency_class?.before ?? efficiency_class_enum.UNKNOWN,
    co2_emissions: localize.formatAsFloat(modificationImpact?.co2_emissions?.before ?? 0),
    stranding_date: dayjs(modificationImpact?.stranding_date?.before ?? '').format('YYYY') ?? '',
  };

  return (
    <TableRow>
      <TableCell>
        <Stack direction={'row'} alignItems={'center'} gap={2} py={2}>
          <Iconify icon={ICONS.BUILDING} width={20} height={20} color="text.secondary" />
          <Typography variant="body2">{t('General_StatusQuo')}</Typography>
        </Stack>
      </TableCell>

      <TableCell>
        <Typography variant="body2">{statusQuo.final_energy}</Typography>
      </TableCell>
      <TableCell>
        <EfficiencyClassLabel efficiencyClass={statusQuo.efficiency_class} />
      </TableCell>
      <TableCell>
        <Typography variant="body2">{statusQuo.primary_energy}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{statusQuo.co2_emissions}</Typography>
      </TableCell>
      <TableCell colSpan={4}>
        <Typography variant="body2">{statusQuo.stranding_date}</Typography>
      </TableCell>
    </TableRow>
  );
}
