import {
  area_type_enum,
  building_state_enum,
  energy_source_type_enum,
  energy_system_type_enum,
  type_of_use_enum,
} from '@predium/enums';
import { TFunction } from 'i18next';
import * as yup from 'yup';

const YEAR_CONSTRUCTED_MIN = 1900;
const YEAR_CONSTRUCTED_MAX = new Date().getFullYear();

export const getInformationFormSchema = (t: TFunction) => {
  const REQUIRED = t('General_Required');
  const OPTION_IS_INVALID = t('General_OptionIsInvalid');
  const YEAR_CONSTRUCTED_MESSAGE = t('DataCollection_DraftForm_YearConstructedMessage', {
    min: YEAR_CONSTRUCTED_MIN,
    max: YEAR_CONSTRUCTED_MAX,
  });

  return yup.object({
    responsibleUserId: yup.number().required(REQUIRED),
    coreData: yup.object({
      portfolioId: yup
        .number()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .required(REQUIRED),
      economicUnitId: yup
        .number()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .nullable(),
      state: yup
        .mixed<building_state_enum>()
        .oneOf(Object.values(building_state_enum), OPTION_IS_INVALID)
        .required(REQUIRED),
      constructionYear: yup
        .number()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .min(YEAR_CONSTRUCTED_MIN, YEAR_CONSTRUCTED_MESSAGE)
        .max(YEAR_CONSTRUCTED_MAX, YEAR_CONSTRUCTED_MESSAGE)
        .nullable(),
      externalId: yup.string(),
      units: yup
        .number()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .nullable(),
    }),
    additionalInformation: yup.object({
      monumentProtection: yup.boolean().required(REQUIRED),
      milieuProtection: yup.boolean().required(REQUIRED),
      heritageDistrict: yup.boolean().required(REQUIRED),
      leasehold: yup.boolean().required(REQUIRED),
    }),
    area: yup.object({
      typeOfUse: yup
        .mixed<type_of_use_enum>()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .oneOf([...Object.values(type_of_use_enum), null], OPTION_IS_INVALID)
        .nullable(),
      totalArea: yup
        .number()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .moreThan(0)
        .nullable(),
      typeOfArea: yup
        .mixed<area_type_enum>()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .oneOf(Object.values(area_type_enum), OPTION_IS_INVALID)
        .nullable(),
    }),
    energyData: yup
      .array()
      .of(
        yup.object({
          energyConsumer: yup
            .mixed<energy_system_type_enum>()
            .transform((value, originalValue) => (originalValue === '' ? null : value))
            .oneOf([...Object.values(energy_system_type_enum), null], OPTION_IS_INVALID)
            .required(REQUIRED),
          energySource: yup
            .mixed<energy_source_type_enum>()
            .transform((value, originalValue) => (originalValue === '' ? null : value))
            .oneOf([...Object.values(energy_source_type_enum), null], OPTION_IS_INVALID)
            .required(REQUIRED),
          finalEnergy: yup
            .number()
            .typeError(REQUIRED)
            .transform((value, originalValue) => (originalValue === '' ? null : value))
            .required(REQUIRED),
        }),
      )
      .test({
        test: function (energyCertificateConsumers) {
          if (!energyCertificateConsumers?.length) {
            return true;
          }

          return energyCertificateConsumers.some((item) => item.energyConsumer === energy_system_type_enum.HEATING);
        },
        message: t('DataCollection_DraftForm_EnergyConsumerHeatingMessage'),
      })
      .required(REQUIRED),
  });
};
