import { Box, Container, DialogTitle, Stack, Step, StepButton, Stepper, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../../../components/presentations/PreDialog/PreDialog';
import { useBuildingCreation } from './BuildingCreationProvider';
import { steps } from './Steps';
import { ACTION_TYPES } from './hooks/useGlobalState';

const BuildingCreationTitle = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { state, dispatch, handleCloseDialog } = useBuildingCreation();

  const activeStepIndex = state.activeStepIndex;

  const setActiveStepIndex = (index: number) => {
    dispatch({ type: ACTION_TYPES.SET_ACTIVE_STEP, payload: index });
  };

  return (
    <DialogTitle sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.grey[500_24]}`, mb: 2 })}>
      <Stack spacing={2} px={2} direction="row" justifyContent="space-between">
        <Typography flexShrink={0} flexBasis={300} variant="h6">
          {t('General_CreateBuilding')}
        </Typography>
        <Container
          maxWidth="sm"
          sx={{
            flexShrink: { xs: 0.4, lg: 0 },
            '&.MuiContainer-root': {
              px: { xs: 2, md: 1 },
              maxWidth: 640,
            },
          }}
        >
          <Stepper activeStep={activeStepIndex}>
            {steps.map(({ title, id }, index) => (
              <Step key={id} completed={index < activeStepIndex}>
                <StepButton
                  color="inherit"
                  onClick={() => setActiveStepIndex(index)}
                  sx={{
                    '& .MuiStepLabel-label': {
                      fontWeight: 700,
                    },
                    '& .MuiStepIcon-text': {
                      fontSize: theme.typography.body2,
                      fill: 'currentColor',
                      color: 'text.disabled',
                      fontWeight: 700,
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'grey.300',
                    },
                    '& .Mui-active, & .Mui-completed': {
                      '& .MuiSvgIcon-root': {
                        color: 'black',
                      },
                      '& .MuiStepIcon-text': {
                        color: 'white',
                      },
                    },
                  }}
                >
                  {title}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Container>
        <Box flexBasis={300}>
          <CloseIcon
            sx={{
              right: 24,
            }}
            onClose={handleCloseDialog}
            disabled={false}
          />
        </Box>
      </Stack>
    </DialogTitle>
  );
};

export default BuildingCreationTitle;
