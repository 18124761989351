import { useCallback } from 'react';
import { useBuildingCreation } from '../BuildingCreationProvider';
import { ACTION_TYPES } from '../hooks/useGlobalState';
import ManualAddressForm from './Substeps/ManualAddress';

const Step1Location = () => {
  const { getBuilding, activeStepRef, dispatch, isMainFormDirty } = useBuildingCreation();
  const building = getBuilding();

  const handleValidChange = useCallback(
    (isValid: boolean) => {
      dispatch({ type: ACTION_TYPES.SET_STEP_INVALIDITY, payload: !isValid });
    },
    [dispatch],
  );

  const handleBlockClose = useCallback(
    (dirty: boolean) => {
      dispatch({ type: ACTION_TYPES.SET_BLOCK_CLOSE, payload: dirty });
    },
    [dispatch],
  );

  return (
    <ManualAddressForm
      defaultValues={building.address}
      ref={activeStepRef}
      onValidChange={handleValidChange}
      onDirtyChange={handleBlockClose}
      isParentDirty={isMainFormDirty}
    />
  );
};

export default Step1Location;
