/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Grid, InputAdornment, ListItemIcon, MenuItem } from '@mui/material';
import { energy_consumer_type_enum } from '@predium/client-graphql';
import { ensureDefined } from '@predium/utils';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFDatePicker, RHFNumberField, RHFSelect } from '../../../../../../../components/hook-form';
import InfoTable, { InfoTableData } from '../../../../../../../components/presentations/InfoTable';
import { BuildingTabEnum } from '../../../../../../../pages/DataCollection/DataCollectionBuilding';
import { getActionIcon } from '../../../../../../../utils/icons';
import { createSystemInfoTable } from '../../../../../Scenario/scenarios.util';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../../../Components/ActionCustomStyles';
import { ConsumerPartialRenovationOption } from '../../../CreateAction';
import AffectedConsumerPartsTable from '../../../PartialRenovations/SystemRenovation/AffectedConsumerPartsTable';
import { ActionProps } from '../../../ViewAction';

type LightingActionForm = {
  action: energy_consumer_type_enum;
  efficiency: number;
  implementation_to: string;
};

export default function LightingAction({ action, previousActionBuildingModel, affectedParts }: ActionProps) {
  const { t } = useTranslation();

  const infoTableData: InfoTableData[] = createSystemInfoTable(
    energy_consumer_type_enum.LIGHTING,
    previousActionBuildingModel,
    t,
  );
  const buildingId = previousActionBuildingModel.building!.id;

  const defaultValues = {
    action: energy_consumer_type_enum.LIGHTING,
    implementation_to: action.implementation_to,
    efficiency: ensureDefined(action.renovations_energy_consumer[0].new_energy_consumer).efficiency,
  };

  const formMethods = useForm<LightingActionForm>({
    //@ts-ignore
    defaultValues: defaultValues,
  });

  return (
    <FormProvider methods={formMethods}>
      <Box my={3}>
        <RHFSelect name="action" label={t('General_Category')} size={'small'} sx={{ ...SelectSx, mb: 3 }} disabled>
          <MenuItem value={energy_consumer_type_enum.LIGHTING} disabled>
            <ListItemIcon style={{ minWidth: 24 }}>
              <Box component="img" src={getActionIcon('LIGHTING')} />
            </ListItemIcon>
            <StyledListItemText action={t('Scenarios_ListItem-LightingConsumerType')} />
          </MenuItem>
        </RHFSelect>

        {infoTableData && (
          <Box>
            <InfoTable
              buildingId={buildingId}
              showTab={BuildingTabEnum.technology}
              data={infoTableData}
              title={t('General_StateBeforeRenovation')}
            />
          </Box>
        )}

        <AffectedConsumerPartsTable affectedParts={affectedParts as ConsumerPartialRenovationOption[]} />

        <Grid container spacing={2}>
          <Grid item xs={12} my={3}>
            <RHFNumberField
              name="efficiency"
              label={t('General_LightYield')}
              size={'small'}
              sx={NumberInputSx}
              disabled
              InputProps={{
                endAdornment: <InputAdornment position="end">lm/W</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <RHFDatePicker
          label={t('General_PlannedFor')}
          name="implementation_to"
          size={'small'}
          sx={NumberInputSx}
          disabled
        />
      </Box>
    </FormProvider>
  );
}
