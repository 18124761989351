import {
  energy_consumer_technology_type_enum,
  energy_consumer_type_enum,
  energy_source_type_enum,
  energy_system_type_enum,
  envelope_type_enum,
  insulation_material_category_enum,
  insulation_method_enum,
  language_enum,
} from '@predium/enums';
import { t } from 'i18next'; // Assuming i18next is used for translations
import { SetValueConfig } from 'react-hook-form';
import * as yup from 'yup';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../../../utils/formatTime';
import { getMaxDate } from '../../ActionPlan.utils';

const NonWindowDoorActionsValues = Object.values(envelope_type_enum).filter(
  (value) => value !== envelope_type_enum.WINDOW && value !== envelope_type_enum.DOOR,
);

const GeneralRequiredMessage = () => t('General_Required');

export const SetValueOptions: SetValueConfig = {
  shouldDirty: true,
  shouldValidate: true,
};

const requiredNumber = yup.number().typeError(GeneralRequiredMessage()).required(GeneralRequiredMessage());

const conditionalNumber = (fieldName: string) =>
  yup.number().when(fieldName, {
    is: (value: string) => !!value && value.length > 0,
    then: requiredNumber,
    otherwise: yup.number().nullable().optional(),
  });

//TODO: move this lib during action validations feature PRE-3890. all the validations are temporary and only covers required validations. all specific range and data types will be handle later with features PRE-3890

export const minDateSchema = (minDate: Date, language: language_enum, maxDate: Date = getMaxDate()) =>
  yup.object().shape({
    implementation_to: yup
      .date()
      .typeError(t('General_InvalidDate'))
      .min(
        new Date(minDate.setHours(0, 0, 0, 0)),
        t('General_MinDateMessage', {
          minDate: formatDateToLocale(minDate, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language),
        }),
      )
      .max(
        maxDate,
        t('General_MaxDateMessage', {
          maxDate: formatDateToLocale(maxDate, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language),
        }),
      )
      .required(GeneralRequiredMessage()),
  });

const affectedPartsValidation = (isEditForm: Boolean) =>
  yup.array().when([], {
    is: () => isEditForm,
    then: yup.array().notRequired(),
    otherwise: yup.array().min(1, GeneralRequiredMessage()).required(GeneralRequiredMessage()),
  });

export const DoorValidationSchema = (
  minDate: Date,
  language: language_enum,
  isEditForm: Boolean = false,
  maxDate?: Date,
) =>
  yup.object().shape({
    envelope_renovation_parameter: yup
      .object({
        renovation_ids: yup.array().of(yup.string()),
        material_name: yup.string().required(GeneralRequiredMessage()),
        cost_per_m2: conditionalNumber('material_name'),
        u_value: conditionalNumber('material_name'),
        envelope_type: yup.string().oneOf([envelope_type_enum.DOOR]).required(GeneralRequiredMessage()),
      })
      .required(),
    ...minDateSchema(minDate, language, maxDate).fields,
    affected_parts: affectedPartsValidation(isEditForm),
  });

export const WindowActionFormSchema = (
  minDate: Date,
  language: language_enum,
  isEditForm: Boolean = false,
  maxDate?: Date,
) =>
  yup.object().shape({
    ...minDateSchema(minDate, language, maxDate).fields,
    envelope_renovation_parameter: yup
      .object({
        renovation_ids: yup.array().of(yup.string()),
        envelope_type: yup.string().oneOf(['WINDOW']).required(GeneralRequiredMessage()),
        material_name: yup.string().required(GeneralRequiredMessage()),
        insulation_lambda: conditionalNumber('material_name'),
        cost_per_m2: conditionalNumber('material_name'),
        u_value: conditionalNumber('material_name'),
      })
      .required(),
    affected_parts: affectedPartsValidation(isEditForm),
  });

export const NonWindowDoorActionFormSchema = (
  minDate: Date,
  language: language_enum,
  isEditForm: Boolean = false,
  maxDate?: Date,
) =>
  yup.object().shape({
    ...minDateSchema(minDate, language, maxDate).fields,
    envelope_renovation_parameter: yup
      .object({
        renovation_ids: yup.array().of(yup.string()),
        envelope_type: yup.string().oneOf(NonWindowDoorActionsValues).required(GeneralRequiredMessage()), // Assuming NotWindowDoorEnvelopeType values are validated elsewhere
        insulation_method: yup.string().oneOf(Object.values(insulation_method_enum)).required(GeneralRequiredMessage()),
        material_name: yup.string().required(GeneralRequiredMessage()),
        insulation_material_category: yup.string().when('material_name', {
          is: (material_name: string) => !!material_name && material_name.length > 0,
          then: yup.string().oneOf(Object.values(insulation_material_category_enum)).required(GeneralRequiredMessage()),
          otherwise: yup.string().optional(),
        }),
        insulation_lambda: conditionalNumber('material_name'),
        insulation_thickness: conditionalNumber('material_name'),
        cost_per_m2: conditionalNumber('material_name'),
      })
      .required(),
    affected_parts: affectedPartsValidation(isEditForm),
  });

export const SolarActionFormSchema = (minDate: Date, language: language_enum, maxSurfaceArea: number) =>
  yup.object().shape({
    ...minDateSchema(minDate, language).fields,

    solar_renovation_parameter: yup
      .object({
        module_peak_power: requiredNumber,
        module_angle: requiredNumber,
        solar_active_area: requiredNumber.max(
          maxSurfaceArea,
          t('Validation_MaxSurfaceArea', {
            area: maxSurfaceArea,
          }),
        ),
        roof_area_ratio: requiredNumber.max(100, t('Validation_MaxRoofAreaRatio')),
        cost_per_m2: requiredNumber,
        technology_name: yup.string().required(GeneralRequiredMessage()),
      })
      .required(),
  });

export const HeatingHotWaterActionFormSchema = (minDate: Date, language: language_enum, isEditForm: Boolean = false) =>
  yup.object().shape({
    ...minDateSchema(minDate, language).fields,

    system_renovation_parameter: yup
      .object({
        renovation_ids: yup.array().of(yup.string()).min(1, GeneralRequiredMessage()).required(),
        consumer_technology_type: yup
          .string()
          .oneOf([...Object.values(energy_consumer_technology_type_enum)])
          .required(GeneralRequiredMessage()),
        technology_name: yup.string().when(['consumer_technology_type'], {
          is: (consumer_technology_type: energy_consumer_technology_type_enum) =>
            !!consumer_technology_type && consumer_technology_type.length > 0,
          then: yup.string().required(GeneralRequiredMessage()),
          otherwise: yup.string().nullable().optional(),
        }),
        efficiency: yup.number().when(['technology_name', 'consumer_technology_type'], {
          is: (technology_name: string, consumer_technology_type: string) =>
            !!technology_name &&
            technology_name.length > 0 &&
            !!consumer_technology_type &&
            consumer_technology_type.length > 0,
          then: requiredNumber,
          otherwise: yup.number().nullable().optional(),
        }),
        cost_per_m2: yup.number().when(['technology_name', 'consumer_technology_type'], {
          is: (technology_name: string, consumer_technology_type: string) =>
            !!technology_name &&
            technology_name.length > 0 &&
            !!consumer_technology_type &&
            consumer_technology_type.length > 0,
          then: requiredNumber,
          otherwise: yup.number().nullable().optional(),
        }),
        energy_source_type: yup.string().when(['technology_name', 'consumer_technology_type'], {
          is: (technology_name: string, consumer_technology_type: string) =>
            !!technology_name &&
            technology_name.length > 0 &&
            !!consumer_technology_type &&
            consumer_technology_type.length > 0,
          then: yup
            .string()
            .oneOf([...Object.values(energy_source_type_enum)])
            .required(GeneralRequiredMessage()),
          otherwise: yup.string().nullable().optional(),
        }),

        system_type: yup
          .string()
          .oneOf([...Object.values(energy_system_type_enum)])
          .required(GeneralRequiredMessage()),
      })
      .required(),
    affected_parts: affectedPartsValidation(isEditForm),
  });

export const LightingActionFormSchema = (minDate: Date, language: language_enum, isEditForm: boolean = false) =>
  yup.object().shape({
    ...minDateSchema(minDate, language).fields,

    system_renovation_parameter: yup
      .object({
        efficiency: requiredNumber,
        system_type: yup.string().oneOf([energy_consumer_type_enum.LIGHTING]).required(GeneralRequiredMessage()),
        consumer_technology_type: yup
          .string()
          .oneOf(Object.values(energy_consumer_technology_type_enum))
          .required(GeneralRequiredMessage()),
        energy_source_type: yup
          .string()
          .oneOf(Object.values(energy_source_type_enum))
          .required(GeneralRequiredMessage()),
        technology_name: yup.string().optional(),
      })
      .required(),
    affected_parts: affectedPartsValidation(isEditForm),
  });

export const EnergyRouteActionFormSchema = (minDate: Date, language: language_enum) =>
  yup.object().shape({
    ...minDateSchema(minDate, language).fields,

    energy_system_route_renovation_parameter: yup
      .object({
        energy_source_type: yup
          .string()
          .oneOf(Object.values(energy_source_type_enum))
          .required(GeneralRequiredMessage()),
      })
      .required(GeneralRequiredMessage()),
  });

export const HydraulicBalancingFormSchema = (minDate: Date, language: language_enum) =>
  yup.object().shape({
    ...minDateSchema(minDate, language).fields,

    hydraulic_balancing_renovation_parameter: yup
      .object({
        last_hydraulic_balancing: yup.date().required(GeneralRequiredMessage()),
        costs_with_components: yup.boolean().required(GeneralRequiredMessage()),
      })
      .required(GeneralRequiredMessage()),
  });

export const CustomActionFormSchema = (minDate: Date, language: language_enum, renovationIdsRequired?: boolean) => {
  return yup.object().shape({
    ...minDateSchema(minDate, language).fields,
    custom_action_renovation_parameter: yup.object({
      ...(renovationIdsRequired
        ? {
            renovation_ids: yup
              .array()
              .of(yup.string())
              .min(1, GeneralRequiredMessage())
              .required(GeneralRequiredMessage()),
          }
        : {}),
      fraction: yup
        .number()
        .transform((value) => (isNaN(value) ? 0 : value))
        .max(99, t('ActionPlanning_CreateAction-CustomAction-maxFinalEnergyReductionError', { max: 99 }))
        .required(GeneralRequiredMessage()),
      custom_name: yup.string().required(GeneralRequiredMessage()),
      type: yup.string().required(GeneralRequiredMessage()),
    }),
  });
};
