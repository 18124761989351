/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useApolloClient } from '@apollo/client';
import { Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DropzoneComponent, { UploadingFile } from '../../../components/DropzoneComponent';
import Iconify from '../../../components/Iconify';
import PreDialog from '../../../components/presentations/PreDialog/PreDialog';
import { CREATE_EMISSION_CERTIFICATE_DRAFT } from '../../../graphql/DataCollection.mutations';
import { GET_EMISSION_CERTIFICATE_DRAFT_BY_FILE } from '../../../graphql/DataCollection.queries';
import useSessionData from '../../../hooks/useSessionData';
import useUpdateFilename from '../../../hooks/useUpdateFilename';
import { PATHS } from '../../../routes/paths';

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

export default function EmissionCertificateUploadModal({ open, setOpen }: Props) {
  const { t } = useTranslation();
  const apollo = useApolloClient();
  const { isAdmin } = useSessionData();
  const { updateFilename } = useUpdateFilename();
  const navigate = useNavigate();

  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [createdEmissionCertificateDraftId, setCreatedEmissionCertificateDraftId] = useState<number | null>(null);

  useEffect(() => {
    if (createdEmissionCertificateDraftId !== null) {
      navigate(PATHS.dataCollection.emissionCertificateDraft({ id: createdEmissionCertificateDraftId }));
    }
  }, [createdEmissionCertificateDraftId, navigate]);

  const asyncCreateUploadUrl = async (): Promise<UploadingFile> => {
    const res = await apollo.mutate({
      mutation: CREATE_EMISSION_CERTIFICATE_DRAFT,
    });
    return {
      //@ts-ignore
      fileUrl: res.data.createEmissionCertificateDraft.upload_url,
      //@ts-ignore
      fileId: res.data.createEmissionCertificateDraft.CreateEmissionCertificateDraftOutput.file_id,
    };
  };

  return (
    <>
      {!isAdmin || (
        <Button
          startIcon={<Iconify width={16} height={16} icon="ic:baseline-plus" />}
          variant="contained"
          color="primary"
          sx={{ alignSelf: 'start' }}
          onClick={() => setOpen(true)}
          disabled={!isAdmin}
        >
          {t('DataCollection_EmissionCertificates_AddCertificateButton')}
        </Button>
      )}
      <PreDialog
        type="definedByProperty"
        open={open}
        onClose={() => setOpen(false)}
        dialogtitle={
          <Typography variant="h6" sx={{ p: 3 }}>
            {t('DataCollection_EmissionCertificates_AddCertificateButton')}
          </Typography>
        }
        content={
          <Stack sx={{ mb: 4 }}>
            <DropzoneComponent
              fileDisplayName={t('DataCollection_EmissionCertificates_UploadFileDisplayName')}
              description={
                <Typography variant="body1">
                  <Trans
                    i18nKey="DataCollection_EmissionCertificates_UploadDescription"
                    components={{ underline: <span style={{ textDecoration: 'underline' }} /> }}
                  />
                </Typography>
              }
              createUploadUrl={asyncCreateUploadUrl}
              onUploadSuccess={async (file) => {
                await updateFilename(file.fileId, file.name);

                const createdDraft = await apollo.query({
                  query: GET_EMISSION_CERTIFICATE_DRAFT_BY_FILE,
                  variables: {
                    emissionCertificateDraftFileId: file.fileId,
                  },
                });

                setCreatedEmissionCertificateDraftId(createdDraft.data.emission_certificate_draft[0].id);
              }}
              setUploadInProgress={setUploadInProgress}
            />
          </Stack>
        }
        actions={
          <>
            <Button
              variant="outlined"
              onClick={() => {
                setOpen(false);
              }}
            >
              {t('General_Cancel')}
            </Button>
            <Button
              variant="contained"
              disabled={uploadInProgress || !createdEmissionCertificateDraftId}
              autoFocus
              onClick={() => {
                setOpen(false);
              }}
            >
              {t('General_ContinueToValidation')}
            </Button>
          </>
        }
      />
    </>
  );
}
