import { SxProps, Theme, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { m } from 'framer-motion';
import { useEffect, useState } from 'react';
import FullScreenLoader from './FullScreenLoader';
import LogoSmall from './LogoSmall';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  zIndex: 9,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
  text?: string;
};

function Loading({ text, ...other }: Props) {
  return (
    <RootStyle sx={{ p: 3 }} {...other}>
      <m.div
        initial={{ rotateY: 0 }}
        animate={{ rotateY: 360 }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        <LogoSmall sx={{ width: 40, height: 40 }} />
      </m.div>
      {text && <Typography sx={{ ml: 2 }}>{text}</Typography>}
    </RootStyle>
  );
}

type DelayedLoadingProps = {
  /**
   * time until the loading indicator is shown in milliseconds. Defaults to 1000.
   */
  delay?: number;
  /**
   * SX styles being passed to the div wrapping the loading SVG.
   */
  sx?: SxProps<Theme>;
  /**
   * if text needed to be shown with logo e.g loading, exporting etc
   */
  text?: string;
  /**
   * if the loading indicator should be full screen in a dialog
   */
  fullScreen?: boolean;
};

/**
 * Delaying is useful to not show janky loading indicators for short loading times.
 * Provide direct feedback to users (e.g. button with ripple effect)
 * and show loading on work that exceeds usually 1000ms.
 *
 * @returns Loading indicator after the delay or null
 */
export const DelayedLoading = ({ delay = 1000, sx, text, fullScreen = false }: DelayedLoadingProps) => {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  if (fullScreen) {
    // @ts-ignore
    return <FullScreenLoader open={showLoading} text={text} disableCloseAction />;
  }

  return showLoading ? <Loading sx={sx} text={text} /> : null;
};
