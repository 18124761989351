import { InputAdornment, Stack, TextField } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import FilterWithSearch from '../../../components/search/FilterWithSearch';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
  pl: 3,
}));

type EconomicUnitToolbarProps = {
  children: React.ReactNode;
  filterName: string;
  onFilterName: (value: string) => void;
  handlePortfolioChange: (value: string) => void;
  selectedPortfolio: string;
  portfolioOptions: string[];
};

export default function EconomicUnitToolbar({
  filterName,
  children,
  onFilterName,
  handlePortfolioChange,
  selectedPortfolio,
  portfolioOptions,
}: EconomicUnitToolbarProps) {
  const { t } = useTranslation();

  return (
    <RootStyle>
      <Stack direction="row" sx={{ width: '100%' }} gap={2} alignItems="center">
        {onFilterName && (
          <TextField
            value={filterName}
            onChange={(event) => onFilterName(event.target.value)}
            placeholder={t('General_SearchEconomicUnitPlaceholder')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
            sx={{
              maxWidth: '240px',
              '.MuiOutlinedInput-input': {
                boxSizing: 'border-box',
                height: 36,
                py: 1,
                pr: 1.5,
              },
            }}
          />
        )}
        <FilterWithSearch
          label={t('General_AllPortfolios')}
          selectedLabel={t('General_Portfolio', { count: 2 })}
          items={portfolioOptions.map((portfolio) => ({
            value: portfolio,
            name: portfolio === 'all_portfolios' ? t('General_AllPortfolios') : portfolio,
          }))}
          selectedItem={{
            name:
              (portfolioOptions.find((portfolio) => portfolio === selectedPortfolio) === 'all_portfolios'
                ? t('General_AllPortfolios')
                : selectedPortfolio) ?? '',
            value: selectedPortfolio,
          }}
          onChanged={(value) => handlePortfolioChange(value.toString())}
          searchable
        />
        {children}
      </Stack>
    </RootStyle>
  );
}
