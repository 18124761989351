import { CardProps, useTheme } from '@mui/material';
import { energy_source_type_enum } from '@predium/enums';
import isEqual from 'lodash/isEqual';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import EnergySourceGraph from './EnergySourceGraph';

interface EnergySourceConsumptionGraphProps extends CardProps {
  energySources: {
    energy_source_type: string;
    energy_consumption: number;
    energy_consumption_total: number;
  }[];
  totalArea: number;
  showTotal: boolean;
  onItemSelect?: (value: energy_source_type_enum) => void;
}

function EnergySourceConsumptionGraph({ energySources, showTotal, onItemSelect }: EnergySourceConsumptionGraphProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const sourceWithConsumption = energySources.map((energySource) => {
    return [
      energySource.energy_source_type,
      showTotal ? energySource.energy_consumption_total : energySource.energy_consumption,
    ];
  });

  const energySourceDistribution: { [key: string]: number } = Object.fromEntries(sourceWithConsumption);

  const unit = showTotal ? 'kWh/a' : 'kWh/m²a';

  return (
    <EnergySourceGraph
      title={t('General_FinalEnergyByEnergySource')}
      series={Object.values(energySourceDistribution)}
      labels={Object.keys(energySourceDistribution) as energy_source_type_enum[]}
      colors={Object.keys(energySourceDistribution).map(
        (key) => theme.palette.energySourceTypeColors[key as energy_source_type_enum].light,
      )}
      unit={unit}
      onItemSelect={onItemSelect}
    />
  );
}
export default memo(EnergySourceConsumptionGraph, isEqual);
