import {
  DocumentNode,
  gql,
  OperationVariables,
  SubscriptionHookOptions,
  TypedDocumentNode,
  useApolloClient,
  useQuery,
  useSubscription,
} from '@apollo/client';
import { print } from 'graphql';
import { useMemo } from 'react';

/**
 * This hook is a combination of `useSubscription` and `useQuery` hooks.
 * It first tries to use the subscription hook, and if it fails, it falls back to the query hook.
 *
 * ### How to disable Websocket subscription and fallback to polling:
 *
 * Set Localhost key `disableWebsocket` to `true` in the LocalStorage.
 *
 * ```ts
 * localStorage.setItem('disableWebsocket', 'true');
 * ```
 *
 * @example
 *
 * ```tsx
 * import { type useSubscription } from '@apollo/client';
 * import { useSubscriptionWithFallback } from 'hooks';
 *
 * type Props = { useSubscription: typeof useSubscription };
 *
 * const Child = ({ useSubscription }: Props) => {
 *  const { data, loading, error } = useSubscription(SUBSCRIPTION);
 *
 *  ...
 * }
 *
 * const Parent = () => {
 *  return <Child useSubscription={useSubscriptionWithFallback} />;
 * }
 * ```
 */
const useSubscriptionWithFallback = <TData = any, TVariables = OperationVariables>(
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionHookOptions<TData, TVariables>,
) => {
  const client = useApolloClient();

  const subscriptionResult = useSubscription(subscription, {
    ...options,
    onError: (error) => {
      console.warn('Websocket subscription failed, falling back to polling');
      console.error(error);
      options?.onError?.(error);
    },
  });

  const isSubscriptionWorking = !subscriptionResult.error || subscriptionResult.loading;

  const query = useMemo(
    () => gql`
      ${print(subscription).replace(/subscription/g, 'query')}
    `,
    [subscription],
  );

  const queryResult = useQuery(query, {
    pollInterval: 5 * 1000, // 5 seconds
    fetchPolicy: 'cache-and-network',
    variables: {
      ...subscriptionResult.variables,
    },
    skip: isSubscriptionWorking || options?.skip,
    onCompleted: (data) => {
      options?.onData?.({
        data: { data, loading: false },
        client,
      });
    },
  });

  if (isSubscriptionWorking) {
    return subscriptionResult;
  }

  // If the subscription is not working, return the query result, the data structure is the same as subscriptionResult.
  return {
    data: queryResult.data,
    loading: queryResult.loading,
    error: queryResult.error,
    variables: queryResult.variables,
  };
};

export default useSubscriptionWithFallback;
