/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RHFNumberField } from '../../../../components/hook-form';
import { ReportQuestionSwitchProps, isAnsweredQuestion, isAutoFillQuestion } from '../ReportQuestion';

function NumberQuestion({ question, isSubQuestion, isEditable, autoFocus, formArrayProps }: ReportQuestionSwitchProps) {
  const { t } = useTranslation();
  const { setFocus } = useFormContext();

  //@ts-ignore
  const indexInForm = formArrayProps.fields.findIndex((field) => field._id === question.id);

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        setFocus(`questions.${indexInForm}.value`);
        // TODO: I don't know why a timeout is needed here, but without it the focus is not set on consecutive questions.
        // In the worst case the focus might not get set which is not crucial.
      }, 250);
    }
  }, [autoFocus, indexInForm, setFocus]);

  const showHelperText = isAutoFillQuestion(question) && isAnsweredQuestion(question);

  return (
    <>
      {isSubQuestion && (
        <Box sx={{ ml: 1 }}>
          <Typography>{question.question}</Typography>
        </Box>
      )}
      <Stack spacing={3} sx={{ p: 3 }}>
        <RHFNumberField
          // valueAsText is in place here to make sure the formatting is working as expected when the user updates the field value.
          // without it, when updating the input with a decimal value that ends with a zero the zero will disappear when confirming the change. eg: .50 -> .5
          valueAsText
          name={`questions.${indexInForm}.value`}
          label={t('General_Answer')}
          disabled={!isEditable}
          autoComplete="off"
          helperText={showHelperText ? t('ReportQuestions_HelperText') : undefined}
        />
      </Stack>
    </>
  );
}

export default NumberQuestion;
