import { commonAreaRange, getClassOfUse, mixedUseGRESBAreaRange } from '@predium/client-lookup';
import { area_type_enum, country_enum, sub_building_class_enum, type_of_use_enum } from '@predium/enums';
import { translateTypeOfUseEnum } from '@predium/i18n/client';
import {
  ensureDefined,
  fFloatingPoint,
  getAreaByType,
  getAreaSum,
  getBuildingIsOnlyCommercial,
  getNetArea,
  getValueAreaByType,
} from '@predium/utils';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { FormState } from 'react-hook-form';
import { AreaFormValues, AreaMutation } from './types';

export const getSortedTypesOfUse = () =>
  sortBy(
    Object.values(type_of_use_enum).map((value) => ({
      value,
      label: translateTypeOfUseEnum(value),
      isCommercial: getClassOfUse(value) === sub_building_class_enum.COMMERCIAL,
    })),
    ['isCommercial', 'label'],
  );

export const getAreaMutationsFromForm = (
  areas: AreaFormValues['areas'],
  dirtyAreas: FormState<AreaFormValues>['dirtyFields']['areas'],
) => {
  const requiredFields = ['id'];
  const excludedFields = ['data_source_id'];

  const dirtyAreaFields = dirtyAreas?.map((area) => Object.keys(area ?? {})) ?? [];

  const areaMutations = areas
    .map((area, index) => {
      const dirtyFields = dirtyAreaFields[index] ?? [];
      if (dirtyFields.length === 0) {
        return null;
      }

      // we only send the values that have changed otherwise
      // the backend will consider them as changed by user even if they are the same
      return {
        area_type_id: area.area_type_id,
        create: false,
        delete: false,
        ...omit(pick(area, [...requiredFields, ...dirtyFields]), excludedFields),
      } as AreaMutation;
    })
    .filter(Boolean) as (AreaMutation & { create: boolean; delete: boolean })[];

  return areaMutations;
};

export const getGRESBMinMax = (areas: AreaMutation[]) => {
  const mf = uniqBy(getAreaByType(areas, area_type_enum.MF), 'type_of_use_id');

  const isMixedUse = mf.length > 1;
  if (isMixedUse) {
    return { min: mixedUseGRESBAreaRange[0], max: mixedUseGRESBAreaRange[1] };
  }

  const firstMF = ensureDefined(mf[0]);
  const [GRESBmin, GRESBmax] = commonAreaRange[firstMF.type_of_use_id];
  return { min: GRESBmin, max: GRESBmax };
};

export const calculateNGF = (areas: AreaMutation[], country: country_enum) => {
  const ebf = getNetArea(areas);

  const mf = getAreaSum(getAreaByType(areas, area_type_enum.MF));
  const af = ensureDefined(getValueAreaByType(areas, area_type_enum.AF));

  if ((country === country_enum.DE || country === country_enum.PL) && getBuildingIsOnlyCommercial(areas)) {
    return ebf;
  }

  return fFloatingPoint(mf + af);
};
