import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import Collapsible from '../../../../components/common/Collapsible';
import { useEsgAnalysisPopupFilters } from '../EsgAnalysisFilterPopupContext';
import EsgAnalysisFilterValueLabel from '../EsgAnalysisFilterValueLabel';
import { EsgAnalysisFiltersKeys, FindEsgAnalysisFilterValue } from '../EsgAnalysisFilters';

type FilterOptionsAsCheckboxesProps<Key extends EsgAnalysisFiltersKeys> = {
  title: React.ReactNode;
  type: Key;
  options: FindEsgAnalysisFilterValue<Key>[];
  selectedOptions: FindEsgAnalysisFilterValue<Key>[];
};
const FilterOptionsAsCheckboxes = function FilterOptionsAsCheckboxes<Key extends EsgAnalysisFiltersKeys>({
  title,
  type,
  options,
  selectedOptions,
}: FilterOptionsAsCheckboxesProps<Key>) {
  const { setFilter } = useEsgAnalysisPopupFilters();

  if (options.length < 2) {
    return null;
  }

  const handleSelect = (value: FindEsgAnalysisFilterValue<Key>) => {
    setFilter(type, value);
  };

  return (
    <Collapsible title={title}>
      <Stack spacing={1} mt={-1}>
        <FormGroup
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
            gap: 1,
            width: '100%',
          }}
        >
          {options.map((item, index) => (
            <FormControlLabel
              key={index}
              control={<Checkbox checked={selectedOptions.includes(item)} onChange={() => handleSelect(item)} />}
              value={item}
              label={<EsgAnalysisFilterValueLabel type={type} value={item} />}
            />
          ))}
        </FormGroup>
      </Stack>
    </Collapsible>
  );
};

export default memo(FilterOptionsAsCheckboxes, isEqual);
