import { Fab } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  label: string;
};

const RHFBoolean = ({ name, label }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Fab
          variant="outlinedExtended"
          color={value ? 'primary' : 'default'}
          sx={{
            fontWeight: 400,
            whiteSpace: 'nowrap',
            '&.MuiFab-primary': {
              bgcolor: 'grey.100',
            },
          }}
          onClick={() => onChange(!value)}
        >
          {label}
        </Fab>
      )}
    />
  );
};

export default RHFBoolean;
