import {
  Boolean_comparison_exp,
  Int_comparison_exp,
  building_state_enum_comparison_exp,
  efficiency_class_enum_comparison_exp,
  energy_source_type_enum_comparison_exp,
  eu_taxonomy_compliance_enum_comparison_exp,
  sub_building_class_enum_comparison_exp,
  tax_bracket_enum_comparison_exp,
} from '@predium/client-graphql';
import {
  InBuildingIdFilter,
  InBuildingStateFilter,
  InHeritageDistrictFilter,
  InLeaseholdFilter,
  InMilieuProtectionFilter,
  InMonumentProtectionFilter,
  InNestedEconomicUnitFilter,
  InNestedEnergyPathEUTaxonomyComplianceFilter,
  InNestedEnergyPathEfficiencyClassFilter,
  InNestedEnergyPathTaxBracketFilter,
  InNestedEnergySystemConsumerRouteEnergySourceTypeFilter,
  InNestedPortfolioFilter,
  InNestedSubBuildingClassFilter,
  InRangeCo2EmissionsFilter,
  InRangeFinalEnergyFilter,
  InRangePrimaryEnergyFilter,
  SearchCriteriaFilter,
  WhereFilter,
} from '@predium/graphql-where-client';
import {
  IWhereBoolean,
  IWhereBuildingState,
  IWhereEfficiencyClass,
  IWhereEnergySourceType,
  IWhereEuTaxonomyCompliance,
  IWhereInts,
  IWhereSubBuildingClass,
  IWhereTaxBracket,
} from '@predium/graphql-where-types';
import { EsgAnalysisFiltersSearchParams } from '../sections/EsgAnalysis/EsgAnalysisFilters/EsgAnalysisFilters';
export const getBuildingWhereFilters = (filters: EsgAnalysisFiltersSearchParams) => {
  const newFilters: WhereFilter<
    | IWhereInts
    | IWhereBoolean
    | IWhereSubBuildingClass
    | IWhereEfficiencyClass
    | IWhereEuTaxonomyCompliance
    | IWhereEnergySourceType
    | IWhereBuildingState
    | IWhereTaxBracket,
    | Int_comparison_exp
    | Boolean_comparison_exp
    | sub_building_class_enum_comparison_exp
    | efficiency_class_enum_comparison_exp
    | eu_taxonomy_compliance_enum_comparison_exp
    | energy_source_type_enum_comparison_exp
    | tax_bracket_enum_comparison_exp
    | building_state_enum_comparison_exp
    | SearchCriteriaFilter
  >[] = [
    //@ts-ignore
    new InBuildingIdFilter(filters.buildingIds),
    //@ts-ignore
    new InMonumentProtectionFilter(filters.monumentProtections),
    //@ts-ignore
    new InHeritageDistrictFilter(filters.heritageDistrict),
    //@ts-ignore
    new InMilieuProtectionFilter(filters.milieuProtection),
    //@ts-ignore
    new InLeaseholdFilter(filters.leasehold),
    //@ts-ignore
    new InBuildingStateFilter(filters.buildingStates),
    //@ts-ignore
    new InNestedPortfolioFilter(filters.portfolioIds),
    //@ts-ignore
    new InNestedEconomicUnitFilter(filters.economicUnitIds),
    //@ts-ignore
    new InNestedSubBuildingClassFilter(filters.subBuildingClasses),
    //@ts-ignore
    new InNestedEnergyPathEfficiencyClassFilter(filters.efficiencyClasses),
    //@ts-ignore
    new InNestedEnergyPathEUTaxonomyComplianceFilter(filters.euTaxonomyCompliances),
    //@ts-ignore
    new InNestedEnergySystemConsumerRouteEnergySourceTypeFilter(filters.energySourceTypes),
    //@ts-ignore
    new InNestedEnergyPathTaxBracketFilter(filters.taxBrackets),
    //@ts-ignore
    new InRangeFinalEnergyFilter(filters.minFinalEnergy[0], filters.maxFinalEnergy[0]),
    //@ts-ignore
    new InRangePrimaryEnergyFilter(filters.minPrimaryEnergy[0], filters.maxPrimaryEnergy[0]),
    //@ts-ignore
    new InRangeCo2EmissionsFilter(filters.minCo2Emissions[0], filters.maxCo2Emissions[0]),
  ];

  return newFilters;
};

export const getPortfolioWhereFilters = (filters: EsgAnalysisFiltersSearchParams) => {
  const portfolioFilters: WhereFilter<IWhereInts, Int_comparison_exp | SearchCriteriaFilter>[] = [
    //@ts-ignore
    new InNestedPortfolioFilter(filters.portfolioIds),
  ];

  return portfolioFilters;
};

export const getEconomicUnitWhereFilters = (filters: EsgAnalysisFiltersSearchParams) => {
  const economicUnitFilters: WhereFilter<IWhereInts, Int_comparison_exp | SearchCriteriaFilter>[] = [
    //@ts-ignore
    new InNestedEconomicUnitFilter(filters.economicUnitIds),
  ];

  return economicUnitFilters;
};
