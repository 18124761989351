import { language_enum } from '@predium/enums';
import { getLanguageLocale } from '@predium/i18n/client';
import { Localize } from '@predium/utils';
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import i18n, { DEFAULT_LANGUAGE } from '../../i18n';

const LanguageContext = createContext<{
  language: language_enum;
  setLanguage: (lang: language_enum) => void;
  localize: Localize;
}>({
  language: DEFAULT_LANGUAGE,
  setLanguage: () => {},
  localize: new Localize(DEFAULT_LANGUAGE),
});

export const LanguageProvider = ({ children }: PropsWithChildren<{}>) => {
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);
  const locale = getLanguageLocale(language);
  const localize = useMemo(() => new Localize(locale), [locale]);

  useEffect(() => {
    const i18nLocale = i18n.language;

    if (i18nLocale !== locale) {
      i18n.changeLanguage(locale);
      document.documentElement.lang = locale;
      localize.setLocale(locale);
    }
  }, [locale, localize]);

  return <LanguageContext.Provider value={{ language, setLanguage, localize }}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => useContext(LanguageContext);
