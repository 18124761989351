import { Box, Stack, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';

const CustomLabelWithTooltip = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" alignItems="center" spacing={0.3}>
      <Iconify icon={ICONS.PENCIL_BOX} />
      <span>{t('General_Custom')}</span>
      <Tooltip title={t('General_CustomTooltip')} placement="top" arrow>
        <Box sx={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
          <Iconify icon={ICONS.INFO} />
        </Box>
      </Tooltip>
    </Stack>
  );
};

export default CustomLabelWithTooltip;
