import { Card, CardHeader, useTheme } from '@mui/material';
import { energy_source_type_enum, type_of_use_enum } from '@predium/enums';
import { translateEnergySourceTypeEnum_dynamic, translateTypeOfUseEnum_dynamic } from '@predium/i18n/client';
import { Localize } from '@predium/utils';
import { TFunction } from 'i18next';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { memo, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../provider/LanguageProvider';
import { PATHS } from '../../../routes';
import BaseOptionChart from '../../../theme/apexcharts';
import addCustomCSVExportButtonToChartToolbar from '../../../utils/addCustomCSVExportButtonToChartToolbar';
import { getFilename } from '../../../utils/getFilename';
import { toCsvContent } from '../../../utils/toCsvContent';

type Building = {
  area: number;
  co2Intensity: number;
  finalEnergy: number;
  buildingId: number;
  address: string;
  typeOfUse: type_of_use_enum[];
  year: number;
  mainEnergySourceType: energy_source_type_enum;
};

const getCsvContent = (buildings: Building[], t: TFunction<'translation', undefined>, localize: Localize) => {
  const csvRows = [
    [
      'No.',
      t('General_Address'),
      t('General_TypeOfUse'),
      t('General_ConstructionYear'),
      t('General_EnergyReferenceArea') + ' (m²)',
      t('General_CO2Intensity') + ' (kg CO₂e/m²a)',
      t('General_FinalEnergy') + ' (kWh/m²a)',
      t('General_MainEnergySource'),
    ],
    ...buildings.map((building, index) => [
      index + 1,
      building.address,
      building.typeOfUse.map((type) => translateTypeOfUseEnum_dynamic(type, t)).join(', '),
      building.year,
      localize.formatAsFloat(building.area),
      localize.formatAsFloat(building.co2Intensity),
      localize.formatAsFloat(building.finalEnergy),
      translateEnergySourceTypeEnum_dynamic(building.mainEnergySourceType, t),
    ]),
  ];

  return toCsvContent(csvRows);
};

const handleDuplicates = (buildings: Building[]) => {
  const seen = new Map<string, number>();

  const modifiedBuildings = buildings.map((building) => {
    const key = `${building.co2Intensity}-${building.finalEnergy}-${building.area}`;
    const count = seen.get(key) || 0;
    seen.set(key, count + 1);

    // Offset the duplicates slightly
    const offset = count * 0.1;

    return {
      ...building,
      co2Intensity: building.co2Intensity + offset,
      finalEnergy: building.finalEnergy + offset,
    };
  });

  return { modifiedBuildings, originalBuildings: buildings };
};

type Props = {
  buildings: Building[];
  navigate: ReturnType<typeof useNavigate>;
};

const AreaBubbleChart = ({ buildings, navigate }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { language, localize } = useLanguage();

  const filename = getFilename([t('EsgAnalysis_AreaBubbleChart-Title')], language);
  const { modifiedBuildings, originalBuildings } = handleDuplicates(buildings);
  const maxXValue = Math.max(...modifiedBuildings.map((building) => building.co2Intensity));

  // Grouping the data by energy source type
  const seriesData = useMemo(() => {
    return modifiedBuildings.reduce(
      (acc, building, index) => {
        if (!acc[building.mainEnergySourceType]) {
          acc[building.mainEnergySourceType] = [];
        }
        acc[building.mainEnergySourceType].push({
          x: building.co2Intensity,
          y: building.finalEnergy,
          z: building.area,
          buildingId: building.buildingId,
          address: building.address,
          year: building.year,
          area: building.area,
          typeOfUse: building.typeOfUse.map((type) => translateTypeOfUseEnum_dynamic(type, t)).join(', '),
          mainEnergySourceType: translateEnergySourceTypeEnum_dynamic(building.mainEnergySourceType, t),
          originalData: originalBuildings[index], // Store original data
        });
        return acc;
      },
      {} as Record<
        string,
        {
          area: number;
          x: number;
          y: number;
          z: number;
          buildingId: number;
          address: string;
          typeOfUse: string;
          year: number;
          mainEnergySourceType: string;
          originalData: Building;
        }[]
      >,
    );
  }, [modifiedBuildings, originalBuildings, t]);

  const yaxisConfig = merge(BaseOptionChart().yaxis, {
    labels: {
      formatter: function (value: number) {
        return localize.formatAsInteger(value);
      },
    },
    title: {
      text: t('General_FinalEnergy') + ` (kWh/m²a)`,
      style: {
        fontSize: '12px',
        color: theme.palette.text.secondary,
        fontWeight: 500,
      },
    },
  });

  const xaxisConfig = {
    min: 0,
    tickAmount: 10,
    max: maxXValue,
    labels: {
      formatter: function (value: number) {
        return localize.formatAsInteger(value);
      },
    },
    title: {
      text: t('General_CO2Intensity') + ` (kg CO₂e/m²a)`,
      style: {
        fontSize: '12px',
        color: theme.palette.text.secondary,
        fontWeight: 500,
      },
      offsetY: -2,
    },
  };

  // Chart configuration
  const chartState: ApexCharts.ApexOptions = merge(BaseOptionChart(), {
    chart: {
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: true,
        type: 'xy',
        autoScaleYaxis: true, // this doesn't work properly when clicked on toolbar zoom in button.
        // https://github.com/apexcharts/apexcharts.js/issues/1260, works on mouse zoom in though
      },
      events: {
        dataPointSelection: (__: any, chartContext: any, config: any) => {
          const seriesIndex = config.seriesIndex;
          const seriesData = chartContext.w.config.series[seriesIndex];
          const dataPoint = seriesData.data[config.dataPointIndex];
          navigate(PATHS.esgAnalysis.building({ id: dataPoint.buildingId }));
        },
        mounted: function (chart: any) {
          addCustomCSVExportButtonToChartToolbar(chart.el.id, getCsvContent(buildings, t, localize), filename);
        },
        updated: function (chart: any) {
          addCustomCSVExportButtonToChartToolbar(chart.el.id, getCsvContent(buildings, t, localize), filename);
        },
      },
      toolbar: {
        export: {
          svg: {
            filename,
          },
          png: {
            filename,
          },
        },
        tools: {
          customIcons: [
            {
              icon: `<button> <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor"d="M13 3a9 9 0 0 0-9 9H1l3.89 3.89l.07.14L9 12H6a7 7 0 0 1 7-7a7 7 0 0 1 7 7a7 7 0 0 1-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.9 8.9 0 0 0 13 21a9 9 0 0 0 9-9a9 9 0 0 0-9-9"/></svg> ${t(
                'General_ResetZoom',
              )}</button>`,
              index: -1,
              title: '',
              class: 'custom-reset-axes',
              click: function (chart: any) {
                chart.updateOptions({
                  xaxis: xaxisConfig,
                  yaxis: yaxisConfig,
                });
              },
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 0.8,
    },
    xaxis: xaxisConfig,
    yaxis: yaxisConfig,
    legend: {
      showForSingleSeries: true,
      position: 'right',
      formatter: (legendEntry: energy_source_type_enum) => {
        return `${translateEnergySourceTypeEnum_dynamic(legendEntry, t)}`;
      },
      fontSize: '16px',
      fontWeight: 700,
      fontFamily: theme.typography.fontFamily,
      offsetX: -40,
      offsetY: 20,
      markers: {
        offsetX: -5,
      },
      itemMargin: {
        vertical: 5,
      },
      width: 300,
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    plotOptions: {
      bubble: {
        expandOnClick: false,
        zScaling: true,
        minBubbleRadius: 5,
        maxBubbleRadius: 50,
      },
    },
    tooltip: {
      custom: ({ seriesIndex, dataPointIndex, w }: { seriesIndex: number; dataPointIndex: number; w: any }) => {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex].originalData;

        return `
          <div class="apexcharts-custom-tooltip" style="font-size:12px;">
            <div style="font-weight:600;">${data.address}</div>
            <div>
              <span>${t('General_TypeOfUse')}: ${data.typeOfUse
          .map((type: type_of_use_enum) => translateTypeOfUseEnum_dynamic(type, t))
          .join(', ')}</span>
            </div>
            <div>
              <span>${t('General_EnergyReferenceArea')}: ${localize.formatAsCompact(data.area, {
          unit: 'm²',
        })}
              </span>
            </div>
            <div>
              <span>${t('General_ConstructionYear')}: ${data.year}</span>
            </div>
            <div>
              <span>${t('General_CO2Intensity')}: ${localize.formatAsCompact(data.co2Intensity, {
          unit: 'kg CO₂e/m²a',
        })}</span>
            </div>
            <div>
              <span>${t('General_FinalEnergy')}: ${localize.formatAsCompact(data.finalEnergy, {
          unit: 'kWh/m²a',
        })}</span>
            </div>
          </div>
        `;
      },
    },
  });

  const chartSeries = Object.keys(seriesData).map((seriesKey) => ({
    name: seriesKey,
    data: seriesData[seriesKey],
    color: theme.palette.energySourceTypeColors[seriesKey as energy_source_type_enum].main, // Assign color for each
    // series
  }));

  return (
    <Card
      sx={{
        p: 3,
        pb: 1,
        '.apexcharts-series-markers': {
          cursor: 'pointer',
        },
        '.apexcharts-legend-series': {
          display: 'flex',
          flexDirection: 'row',
        },
        '.apexcharts-legend-marker': {
          marginTop: 0.5,
          minWidth: 12,
        },
        '#area-bubble-chart': {
          cursor: 'crosshair',
        },
        '.exportCSV': {
          display: 'none',
        },
        '.apexcharts-zoomin-icon, .apexcharts-zoomout-icon, .apexcharts-zoom-icon, .apexcharts-pan-icon, .apexcharts-reset-icon':
          {
            display: 'none',
          },
        '.custom-reset-axes button': {
          px: 1,
          py: 0.5,
          background: theme.palette.background.paper,
          color: theme.palette.text.primary,
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: 1,
          fontWeight: 600,
          mr: 1,
          mt: -0.5,
          display: 'flex',
          alignItems: 'center',
          '&:hover': {
            background: theme.palette.action.hover,
          },
          cursor: 'pointer',
          width: 'max-content',
        },
        '.custom-reset-axes svg': {
          width: 20,
          height: 20,
          mr: 0.5,
        },
        '.apexcharts-toolbar, .apexcharts-toolbar-custom-icon': {
          width: 'unset',
          maxWidth: 'unset',
        },
        '.apexcharts-tooltip': {
          marginTop: 6,
        },
      }}
    >
      <CardHeader
        title={t('EsgAnalysis_AreaBubbleChart-Title')}
        sx={{ p: 0, pl: 3 }}
        subheader={t('EsgAnalysis_AreaBubbleChart-SubTitle')}
      />
      <Chart options={chartState} series={chartSeries} type="bubble" height={400} id={'area-bubble-chart'} />
    </Card>
  );
};

export default memo(AreaBubbleChart, isEqual);
