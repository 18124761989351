import { ApolloQueryResult, useQuery } from '@apollo/client';
import { DataCollectionBuildingGetBuildingQuery } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { createContext, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import LoadingScreen from '../../../../components/LoadingScreen';
import { createStandardErrorSnackbar } from '../../../../components/NotistackProvider';
import { PermissionType } from '../../../../contexts/PermissionContext';
import { GET_BUILDING } from '../../../../graphql/DataCollection.queries';
import usePermissions from '../../../../hooks/usePermissions';
import { PATHS } from '../../../../routes';

type BuildingContextProps = {
  building: NonNullable<DataCollectionBuildingGetBuildingQuery['building_by_pk']>;
  hasEditAccess: boolean;
  reloadCurrentBuilding: () => Promise<ApolloQueryResult<DataCollectionBuildingGetBuildingQuery>>;
};

const BuildingContext = createContext<BuildingContextProps | null>(null);

function BuildingContextProvider({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id: buildingIdString } = useParams();
  const buildingId = parseInt(buildingIdString!);

  const { data, refetch: reloadCurrentBuilding } = useQuery(GET_BUILDING, {
    variables: { buildingId, year: new Date().getFullYear() },
    onError: () => createStandardErrorSnackbar(t)(enqueueSnackbar),
  });

  const { checkBuildingPermission } = usePermissions();

  if (!data) return <LoadingScreen />;

  if (!data.building_by_pk) {
    return <Navigate to={PATHS.notFound()} />;
  }

  const hasEditAccess = checkBuildingPermission(data.building_by_pk.id, PermissionType.WRITE);

  return (
    <BuildingContext.Provider
      value={{
        building: data.building_by_pk!,
        hasEditAccess,
        reloadCurrentBuilding,
      }}
    >
      {children}
    </BuildingContext.Provider>
  );
}

export { BuildingContext, BuildingContextProvider };
