import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  SelectProps,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { EnergyConsumerForActionFragment } from '@predium/client-graphql';
import { energy_system_type_enum } from '@predium/enums';
import sumBy from 'lodash/sumBy';
import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../../assets/icons';
import { TechnologyIcons } from '../../../../../../assets/images';
import Iconify from '../../../../../../components/Iconify';
import OverflowText from '../../../../../../components/OverflowText';
import Scrollbar from '../../../../../../components/Scrollbar';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { getEnergySourceNameForPartialRenovations } from '../../../../Scenario/scenarios.util';
import { getConsumerPartialRenovationOptions, getSystemLabel } from '../../../ActionPlan.utils';
import { ConsumerPartialRenovationOption } from '../../CreateAction';
import { HeatingHotWaterDefaultType } from '../../CreateActions/TechnicalActions/SystemActions/HeatingAction';
import PartialRenovationOptionSubtext from '../PartialRenovationOptionSubtext';

type Props = {
  action: energy_system_type_enum;
  energyConsumers: EnergyConsumerForActionFragment[];
  sx: SelectProps['sx'];
  setValue: (value: ConsumerPartialRenovationOption[]) => void;
  disabled?: boolean;
};

export default function ConsumerPartialRenovationsAutocomplete({
  action,
  energyConsumers,
  sx,
  setValue,
  disabled,
}: Props) {
  const { t } = useTranslation();
  const { localize } = useLanguage();

  const { control, watch, setValue: setFormValue } = useFormContext<HeatingHotWaterDefaultType>();

  // we need to copy the value from affected parts to the corresponding parameters
  // this is handled by the passed setValue function
  const affected_parts = watch('affected_parts');

  const [open, setOpen] = useState(false);
  const autocompleteRef = useRef<HTMLDivElement>(null);

  const options: ConsumerPartialRenovationOption[] = getConsumerPartialRenovationOptions(energyConsumers, action);

  const useIndexForOptions = options.some((option, index) =>
    options.some(
      (otherOption, otherIndex) =>
        index !== otherIndex &&
        option.energy_consumer_technology_type_id === otherOption.energy_consumer_technology_type_id,
    ),
  );

  const handleSelectAllClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setValue(options);
    setFormValue('affected_parts', options);
    setOpen(false);

    const input = document.activeElement as HTMLElement;
    input?.blur();
  };

  const handleDeselectAllClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setValue([]);
    setFormValue('affected_parts', []);
  };

  useEffect(() => {
    setValue(options);
    setFormValue('affected_parts', options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return (
    <Controller
      control={control}
      name={'affected_parts'}
      render={({ field: { value }, fieldState: { error } }) => {
        const allSelected = Array.isArray(value) ? value.length === options.length : false;
        return (
          <Autocomplete
            ref={autocompleteRef}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            multiple
            disableClearable
            limitTags={3}
            options={options}
            size="small"
            onChange={(_, newValue) => {
              setValue(newValue);
              setFormValue('affected_parts', newValue);
            }}
            value={options.filter((option) =>
              // TODO PRE-5602 change to secondary id of consumer
              affected_parts.some(
                (p) =>
                  p.energy_system_consumer_routes[0].secondary_id ===
                  option.energy_system_consumer_routes[0].secondary_id,
              ),
            )}
            sx={sx}
            disableCloseOnSelect
            disabled={disabled}
            popupIcon={<Iconify icon={ICONS.CHEVRON_DOWN} width={20} height={20} />}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  '& .MuiInputBase-root': { flexWrap: 'nowrap' },
                  '& .Mui-focused': {
                    flexWrap: 'wrap',
                  },
                }}
                label={t('General_AffectedParts')}
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
            getOptionLabel={(option) => {
              return getSystemLabel(option, useIndexForOptions, t);
            }}
            slotProps={{
              paper: {
                sx: {
                  mt: 1,
                  boxShadow: (theme) => theme.customShadows.dropdown,
                },
              },
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: 'preventOverflow',
                    enabled: false,
                    options: {
                      altBoundary: true,
                    },
                  },
                  {
                    name: 'flip',
                    enabled: true,
                    options: {
                      fallbackPlacements: ['top-start'],
                    },
                  },
                ],
              },
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const label = getSystemLabel(option, useIndexForOptions, t);
                if (label.length > 20 && index !== 0 && !open) {
                  return (
                    <>
                      <OverflowText text={label} maxWidth="250px" variant="body2" {...getTagProps({ index })} />
                      {index !== value.length - 1 ? ', ' : ''}
                    </>
                  );
                }
                return (
                  <Typography variant="body2" {...getTagProps({ index })} whiteSpace={'nowrap'}>
                    {label}
                    {index !== value.length - 1 ? ', ' : ''}
                  </Typography>
                );
              })
            }
            ListboxComponent={(props) => (
              <List
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                sx={{
                  boxShadow: (theme) => theme.customShadows.dropdown,
                  '& li': {
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                  },
                }}
                {...props}
              >
                <Scrollbar sx={{ maxHeight: 340 }}>{props.children}</Scrollbar>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} px={2} pt={1}>
                  {!allSelected ? (
                    <Button type="button" variant="text" onClick={handleSelectAllClick}>
                      {t('General_SelectAll')}
                    </Button>
                  ) : (
                    <Button type="button" variant="text" disabled={value.length === 0} onClick={handleDeselectAllClick}>
                      {t('General_DeselectAll')}
                    </Button>
                  )}
                </Stack>
              </List>
            )}
            renderOption={(props, option, { selected }) => {
              const label = getSystemLabel(option, useIndexForOptions, t);

              const constructionYear = option.construction_year;
              const energyFinal = localize.formatAsFloat(sumBy(option.energy_system_consumer_routes, 'energy_final'));

              return (
                <ListItem
                  {...props}
                  style={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Checkbox checked={selected} />
                  <Stack direction={'column'} width={'100%'}>
                    <Typography>{label}</Typography>
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                      <PartialRenovationOptionSubtext
                        title={t('General_EnergySource')}
                        icon={<Box component="img" src={TechnologyIcons.energySourceV2} width={16} height={16} />}
                        value={getEnergySourceNameForPartialRenovations(option, t)}
                      />
                      <PartialRenovationOptionSubtext
                        title={t('General_FinalEnergy')}
                        icon={ICONS.ENERGY}
                        value={!!energyFinal ? `${energyFinal} kWh/m²a` : '-'}
                      />
                      <PartialRenovationOptionSubtext
                        title={t('General_Efficiency')}
                        icon={ICONS.COG}
                        value={`${option.efficiency?.toFixed(2)}`}
                      />
                      <PartialRenovationOptionSubtext
                        title={t('General_ConstructionYear')}
                        icon={ICONS.CALENDAR}
                        value={constructionYear ?? '-'}
                      />
                    </Stack>
                  </Stack>
                </ListItem>
              );
            }}
          />
        );
      }}
    />
  );
}
