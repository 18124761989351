import { ListItemText, SxProps, Theme, Typography } from '@mui/material';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';

export const SelectSx: SxProps<Theme> = {
  mb: 2,
  width: '100%',
  '& .MuiSelect-select': {
    typography: 'body2',
    display: 'inline-flex',
    '.MuiListItemIcon-root': {
      alignItems: 'center',
      width: 20,
      height: 20,
    },
    //TODO fix this
    '.MuiFormLabel-root': {
      fontSize: '14px',
    },
  },
};

export const DropdownIcon = (props: any) => <Iconify {...props} icon={ICONS.CHEVRON_DOWN} width={20} height={20} />;

export const NumberInputSx: SxProps<Theme> = {
  '.MuiInputBase-input': {
    fontSize: '14px',
  },
  '.MuiFormHelperText-root': {
    ml: '8px !important',
  },
};

export const StyledListItemText = ({ action, secondary }: { action: string | JSX.Element; secondary?: string }) => (
  <>
    <ListItemText
      primary={action}
      sx={{
        '.MuiTypography-root': {
          overflow: 'hidden',
          fontSize: '14px',
        },
      }}
    />
    {secondary && (
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          paddingLeft: '8px',
        }}
      >
        {secondary}
      </Typography>
    )}
  </>
);
