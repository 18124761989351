import { Badge, Button, Stack, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import { EsgAnalysisFiltersKeys, EsgAnalysisFiltersSearchParams } from './EsgAnalysisFilters';

export const excludeKeys = [
  EsgAnalysisFiltersKeys.buildingIds,
  EsgAnalysisFiltersKeys.economicUnitIds,
  EsgAnalysisFiltersKeys.portfolioIds,
];

export const calculateFilterCount = (filters: EsgAnalysisFiltersSearchParams): number => {
  return Object.entries(filters)
    .filter(([key]) => !excludeKeys.includes(key as EsgAnalysisFiltersKeys))
    .reduce((acc, [, filter]) => acc + filter.length, 0);
};

type FilterButtonStackProps = {
  isOpen: boolean;
  handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
};

const FilterButton: React.FC<FilterButtonStackProps> = ({ isOpen, handleOpen }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { filters: globalFilters, resetAllFilters: resetAllGlobalFilters } = useEsgAnalysisFilters();

  const filterCount = calculateFilterCount(globalFilters);

  const count =
    (globalFilters?.buildingIds?.length || 0) +
    (globalFilters?.economicUnitIds?.length || 0) +
    (globalFilters?.portfolioIds?.length || 0);

  return (
    <Stack direction={'row'} mr={1} gap={1}>
      <Badge badgeContent={filterCount} color="error">
        <Button
          variant="outlined"
          startIcon={<Iconify icon={'ic:round-filter-list'} />}
          onClick={(e) => {
            handleOpen(e);
          }}
          sx={{
            borderColor: isOpen ? theme.palette.common.black : theme.palette.divider,
            '&:hover': {
              borderColor: isOpen ? theme.palette.common.black : theme.palette.divider,
            },
          }}
        >
          {t('General_MoreFilters')}
        </Button>
      </Badge>
      {(filterCount > 0 || count > 0) && (
        <Button
          variant="text"
          onClick={() => {
            resetAllGlobalFilters();
          }}
        >
          {t('General_ClearAll')}
        </Button>
      )}
    </Stack>
  );
};

export default FilterButton;
