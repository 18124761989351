import { Card, CardProps, useTheme } from '@mui/material';
import { energy_source_type_enum } from '@predium/enums';
import { translateEnergySourceTypeEnum_dynamic } from '@predium/i18n/client';
import { Localize } from '@predium/utils';
import { TFunction } from 'i18next';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { memo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../../provider/LanguageProvider';
import BaseOptionChart from '../../../theme/apexcharts';
import addCustomCSVExportButtonToChartToolbar from '../../../utils/addCustomCSVExportButtonToChartToolbar';
import { getFilename } from '../../../utils/getFilename';
import { toCsvContent } from '../../../utils/toCsvContent';

const getCsvContent = (
  series: number[],
  categories: string[],
  title: string,
  t: TFunction<'translation', undefined>,
  localize: Localize,
) => {
  const csvRows = [
    [title, ''],
    [t('General_Category'), t('General_NumberOfBuildings')],
    ...categories.map((category, index) => [category, localize.formatAsInteger(series[index])]),
  ];

  return toCsvContent(csvRows);
};

export const updateStylingForLegend = (id: string) => {
  const legend = document.getElementById(id)?.querySelector<HTMLElement>('.apexcharts-legend');
  if (legend) {
    legend.style.justifyContent = 'center';
  }
};

type DataItem = {
  count: number;
  energySourceType: energy_source_type_enum;
};

interface Props extends CardProps {
  data: DataItem[];
  animated?: boolean;
}

function calculateColumnWidth(seriesLength: number) {
  return 5 + (seriesLength - 1) * 5;
}

function BuildingsByEnergySourceType({ data, animated }: Props) {
  const { t } = useTranslation();
  const { language, localize } = useLanguage();
  const theme = useTheme();
  const sortedData = [...data].sort((a, b) => (a.energySourceType < b.energySourceType ? -1 : 1));

  const categories = sortedData.map((item: DataItem) => {
    const value = translateEnergySourceTypeEnum_dynamic(item.energySourceType, t);
    if (value.length < 25) return value;

    const splitValue = value.split(' ');
    const phraseMiddle = Math.floor(splitValue.length / 2);

    return [splitValue.slice(0, phraseMiddle).join(' '), splitValue.slice(phraseMiddle, value.length).join(' ')];
  });

  const series = sortedData.map((item: DataItem) => {
    return item.count;
  });

  const colors = sortedData.map((item: DataItem) => {
    return theme.palette.energySourceTypeColors[item.energySourceType as energy_source_type_enum].light;
  });

  const baseRotate = -45;
  const baseMinHeight = 140;
  const increment = 5;

  const rotate = categories.length >= 5 ? baseRotate - Math.floor(categories.length / increment) * 5 : undefined;
  const minHeight = categories.length >= 5 ? baseMinHeight + Math.floor(categories.length / increment) * 5 : undefined;

  const titleText = t('General_DistributionByEnergySource');
  const filename = getFilename([titleText], language);

  const chartState: ApexCharts.ApexOptions = {
    colors,
    chart: {
      stacked: true,
      animations: {
        enabled: !!animated,
      },
      type: 'bar',
      events: {
        mounted(chart: any) {
          addCustomCSVExportButtonToChartToolbar(
            chart.el.id,
            getCsvContent(
              series,
              sortedData.map((it) => translateEnergySourceTypeEnum_dynamic(it.energySourceType, t)),
              titleText,
              t,
              localize,
            ),
            titleText,
          );
        },
        updated(chart: any) {
          addCustomCSVExportButtonToChartToolbar(
            chart.el.id,
            getCsvContent(
              series,
              sortedData.map((it) => translateEnergySourceTypeEnum_dynamic(it.energySourceType, t)),
              titleText,
              t,
              localize,
            ),
            titleText,
          );
        },
      },
      toolbar: {
        export: {
          svg: {
            filename: filename,
          },
          png: {
            filename: filename,
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value: number) {
        return localize.formatAsInteger(value > 0 ? value : null, {
          defaultString: '',
        });
      },
      style: {
        fontSize: '12px',
        colors: [theme.palette.text.primary],
      },
      offsetY: -18,
    },
    tooltip: {
      enabled: false,
    },
    title: {
      text: titleText,
    },
    xaxis: {
      categories,
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
        },
        hideOverlappingLabels: false,
        rotate: rotate,
        minHeight: minHeight,
      },
    },
    yaxis: {
      title: { text: t('General_NumberOfBuildings'), style: { fontSize: '13px', fontWeight: 600 } },
      labels: {
        formatter: function (value) {
          return localize.formatAsInteger(value);
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
        columnWidth: `${calculateColumnWidth(series.length)}%`,
        borderRadius: 8,
        borderRadiusApplication: 'end',
        dataLabels: {
          position: 'top',
          orientation: 'horizontal',
          hideOverflowingLabels: false,
        },
      },
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'darken',
          value: 0.5,
        },
      },
    },
  };

  return (
    <Card
      sx={{
        p: 3,
      }}
    >
      <ReactApexChart
        id="distribution_by_energy_source_graph"
        type={chartState.chart?.type}
        options={merge(BaseOptionChart(), chartState)}
        series={[{ data: series }]}
        height="350px"
      />
    </Card>
  );
}

export default memo(BuildingsByEnergySourceType, isEqual);
