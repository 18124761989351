import { CardProps, useTheme } from '@mui/material';
import { tax_bracket_enum } from '@predium/enums';
import isEqual from 'lodash/isEqual';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { MemoizedPortfolioBuildingDistributionGraph } from './MemoizedPortfolioBuildingDistributionGraph';

interface Props extends CardProps {
  buildingsByTaxBracket: {
    count: number;
    taxBracket: tax_bracket_enum;
  }[];
}

function UnmemoizedPortfolioBuildingDistributionByCO2CostGraph({ buildingsByTaxBracket, sx }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const series = buildingsByTaxBracket.map((group) => group.count).reverse();
  const categories = buildingsByTaxBracket.map((group) => group.taxBracket).reverse();
  const colors = buildingsByTaxBracket.map((group) => theme.palette.taxBracketColors[group.taxBracket].light).reverse();

  return (
    <MemoizedPortfolioBuildingDistributionGraph
      id="distribution_by_co2_cost_share_graph"
      title={t('General_DistributionByCO2CostShare')}
      series={series}
      categories={categories}
      colors={colors}
      sx={sx}
      type="taxBracket"
    />
  );
}

export const MemoizedPortfolioBuildingDistributionByCO2CostGraph = memo(
  UnmemoizedPortfolioBuildingDistributionByCO2CostGraph,
  isEqual,
);
