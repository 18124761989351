/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { InputAdornment, MenuItem, Stack } from '@mui/material';
import { getClassOfUse } from '@predium/client-lookup';
import { type_of_use_enum } from '@predium/enums';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FormProvider, RHFNumberField, RHFSelect } from '../../../../../components/hook-form';
import { useLanguage } from '../../../../../provider/LanguageProvider';
import { AreaMutation } from '../types';
import { getSortedTypesOfUse } from '../utils';

export type EBFFormSubmitValues = Pick<AreaMutation, 'type_of_use_id' | 'class_of_use_id' | 'value'>;

type Props = {
  // Because this is only an edit form, we have to provide a default value
  defaultValue?: EBFFormSubmitValues;
  onSubmit: (values: EBFFormSubmitValues) => void;
};

const EBFForm = ({ onSubmit, defaultValue }: Props) => {
  const { t } = useTranslation();
  const { localize } = useLanguage();

  const schema = yup.object({
    type_of_use_id: yup
      .mixed<type_of_use_enum>()
      .oneOf(Object.values(type_of_use_enum), t('General_Required'))
      .required(t('General_Required')),
    value: yup
      .number()
      .min(0.01, t('General_MinRangeMessage', { min: 0 }))
      .max(99_999_999, t('General_MaxRangeMessage', { max: localize.formatAsCompact(99_999_999) }))
      .required(t('General_Required')),
  });

  const methods = useForm<yup.InferType<typeof schema>>({
    defaultValues: {
      type_of_use_id: defaultValue?.type_of_use_id,
      value: defaultValue?.value,
    },
    resolver: yupResolver(schema),
  });

  const handleSubmit = methods.handleSubmit((values) => {
    onSubmit({
      ...values,
      class_of_use_id: getClassOfUse(values.type_of_use_id),
    });
  });

  const sortedTypeOfUse = getSortedTypesOfUse();

  return (
    <FormProvider methods={methods} formId="ebf-form" onSubmit={handleSubmit}>
      <Stack spacing={3} my={1}>
        <RHFSelect name="type_of_use_id" label={t('General_TypeOfUse')}>
          {sortedTypeOfUse.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </RHFSelect>
        <RHFNumberField
          name="value"
          label={t('General_Area')}
          variant="outlined"
          decimalScale={2}
          InputProps={{
            endAdornment: <InputAdornment position="end">m²</InputAdornment>,
          }}
          max={99_999_999}
        />
      </Stack>
    </FormProvider>
  );
};

export default EBFForm;
