import { Stack } from '@mui/material';
import React from 'react';

type CenterBoxProps = {
  children: React.ReactNode;
  height?: string | number;
};

const CenterBox: React.FC<CenterBoxProps> = ({ children, height = '100%' }) => {
  return (
    <Stack height={height} justifyContent="center" alignItems="center" width={'100%'}>
      {children}
    </Stack>
  );
};

export default CenterBox;
