import { Button, Stack } from '@mui/material';
import React, { memo } from 'react';
import Collapsible from '../../../../components/common/Collapsible';
import { useEsgAnalysisPopupFilters } from '../EsgAnalysisFilterPopupContext';
import { EsgAnalysisFiltersKeys, FindEsgAnalysisFilterValue } from '../EsgAnalysisFilters';
import EsgAnalysisFilterValueLabel from '../EsgAnalysisFilterValueLabel';

type FilterOptionsAsChipsProps<Key extends EsgAnalysisFiltersKeys> = {
  title: React.ReactNode;
  type: Key;
  options: FindEsgAnalysisFilterValue<Key>[];
  selectedOptions: FindEsgAnalysisFilterValue<Key>[];
};

const FilterOptionsAsChips = memo(function FilterOptionsAsChips<Key extends EsgAnalysisFiltersKeys>({
  title,
  type,
  options,
  selectedOptions,
}: FilterOptionsAsChipsProps<Key>) {
  const { setFilter } = useEsgAnalysisPopupFilters();

  if (options.length < 2) {
    return null;
  }

  const handleSelect = (value: FindEsgAnalysisFilterValue<Key>) => {
    setFilter(type, value);
  };

  return (
    <Collapsible title={title}>
      <Stack direction="row" flexWrap="wrap" gap={1} width={'100%'}>
        {options.map((item, index) => (
          <Button
            key={index}
            variant={selectedOptions.includes(item) ? 'outlined' : 'soft'}
            onClick={() => handleSelect(item)}
            size="small"
            sx={{
              backgroundColor: selectedOptions.includes(item) ? 'background.neutral' : 'background.paper',
              border: selectedOptions.includes(item) ? '1px solid' : '1px solid transparent',
              borderColor: selectedOptions.includes(item) ? 'primary.main' : 'divider',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              '&.Mui-disabled': {
                backgroundColor: selectedOptions.includes(item) ? 'background.neutral' : 'background.paper',
              },
            }}
          >
            <EsgAnalysisFilterValueLabel type={type} value={item} />
          </Button>
        ))}
      </Stack>
    </Collapsible>
  );
});

export default FilterOptionsAsChips;
