import { Accordion, AccordionDetails, AccordionSummary, IconButton, Typography } from '@mui/material';
import React from 'react';
import { ICONS } from '../../assets/icons';
import Iconify from '../Iconify';

type CollapsibleSectionProps = {
  title: React.ReactNode;
  children: React.ReactNode;
};

const Collapsible: React.FC<CollapsibleSectionProps> = ({ title, children }) => {
  return (
    <Accordion
      defaultExpanded
      sx={{
        p: 1,
        border: 'none',
        '&.Mui-expanded': {
          boxShadow: 'none',
          m: 0,
        },
        '&:before': {
          display: 'none',
        },
      }}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={
          <IconButton>
            <Iconify icon={ICONS.CHEVRON_DOWN} width={20} height={20} />
          </IconButton>
        }
        sx={{
          m: 0,
          '&.Mui-expanded': {
            m: 0,
          },
        }}
      >
        <Typography variant="subtitle1">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ py: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default Collapsible;
