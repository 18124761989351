import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import { Localize } from '@predium/utils';
import { TFunction } from 'i18next';
import merge from 'lodash/merge';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../provider/LanguageProvider';
import BaseOptionChart from '../../theme/apexcharts';
import addCustomCSVExportButtonToChartToolbar from '../../utils/addCustomCSVExportButtonToChartToolbar';
import { toCsvContent } from '../../utils/toCsvContent';

type DataToExport = {
  actualPath: [number, number][];
  targetPath: [number, number][];
  actionPlanPath?: [number, number][];
};

function getCsvContent(dataToExport: DataToExport, t: TFunction<'translation', undefined>, localize: Localize) {
  const includeActionPlanPath = dataToExport.actionPlanPath !== undefined;

  const valueFormatter = (value: number | undefined) =>
    localize.formatAsFloat(value, {
      defaultString: 'N/A',
    });

  const yearHeading = t('General_Year');
  const actionPlanPathHeading = t('General_ObjectWithActions');
  const targetPathHeading = t('General_1_5CTargetPath');
  const actualPathHeading = t('General_Object');

  const csvRows = [
    [yearHeading, ...(includeActionPlanPath ? [actionPlanPathHeading] : []), targetPathHeading, actualPathHeading],
    ...dataToExport.actualPath.map((row, index) => [
      row[0],
      ...(includeActionPlanPath ? [valueFormatter(dataToExport.actionPlanPath?.[index][1])] : []),
      valueFormatter(row[1]),
      valueFormatter(dataToExport.targetPath[index][1]),
    ]),
  ];

  return toCsvContent(csvRows);
}

/**
 * PRE-393
 * disabling click action and pointer cursor on legends of charts which doesn't support building analysis filters
 */
export const disableCursorPointerForMarkers = (id: string) => {
  const markers = document.getElementById(id)?.querySelectorAll<HTMLElement>('.apexcharts-legend-marker');

  markers?.forEach((marker: HTMLElement) => {
    marker.style.cursor = 'auto';
  });
};

export type PathGraphSeries = {
  data: [number, number | null][];
  color: string;
  name: string;
  zIndex?: number;
};

type Props = {
  series: PathGraphSeries[];
  annotations?: ApexAnnotations;
  filename: string;
  title?: string;
  xAxisLabel?: string;
  options?: ApexCharts.ApexOptions; // additional options to merge with the default options
  unit?: string;
  dataToExport: DataToExport;
  noDataMessage?: string;
  addDataButton?: React.ReactNode;
};

export function PathGraph({
  series,
  title,
  xAxisLabel = '',
  annotations,
  filename,
  options,
  unit = '',
  dataToExport,
  noDataMessage = '',
  addDataButton,
}: Props) {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const theme = useTheme();
  const [chartKey, setChartKey] = useState(0);

  const isDataAvailable = series.length > 0;

  useEffect(() => {
    //PRE-4227 using key state to re-render the graph after simulation with updated values. options doesn't get updated
    // by just the isDataAvailable value change
    setChartKey((prevKey) => prevKey + 1);
  }, [isDataAvailable]);

  const chartState: ApexCharts.ApexOptions = merge(BaseOptionChart(), {
    chart: {
      id: 'path-graph',
      animations: {
        enabled: false,
      },
      type: 'line',
      zoom: {
        enabled: true,
      },
      events: {
        mounted: function (chart: any) {
          disableCursorPointerForMarkers(chart.el.id);
          addCustomCSVExportButtonToChartToolbar(chart.el.id, getCsvContent(dataToExport, t, localize), filename);
        },
        updated: function (chart: any) {
          disableCursorPointerForMarkers(chart.el.id);
          addCustomCSVExportButtonToChartToolbar(chart.el.id, getCsvContent(dataToExport, t, localize), filename);
        },
      },
      toolbar: {
        ...options?.chart?.toolbar,
        export: {
          svg: {
            filename: filename,
          },
          png: {
            filename: filename,
          },
        },
      },
    },
    markers: {
      show: false,
    },
    title: {
      text: title ?? '',
    },
    legend: {
      ...(options?.legend ?? {
        show: true,
      }),
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    stroke: {
      ...(options?.stroke
        ? {
            curve: options.stroke.curve,
            dashArray: options.stroke.dashArray,
            width: options.stroke.width,
            lineCap: 'square',
          }
        : {
            curve: 'straight',
          }),
    },
    annotations: {
      ...annotations,
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          fontSize: '12px',
        },
        formatter: (value: number, timestamp: number) => (timestamp % 2 === 0 ? value : ''),
      },
      ...(!isDataAvailable
        ? {
            categories: Array.from({ length: 31 }, (_, i) => 2020 + i),
            tickAmount: 15,
            type: 'numeric',
          }
        : {}),
    },
    yaxis: {
      min: 0,
      ...(xAxisLabel ? { title: { text: xAxisLabel } } : {}),
      tooltip: {
        enabled: false,
      },

      title: {
        text: unit,
        style: {
          fontSize: '12px',
          color: theme.palette.text.secondary,
          fontWeight: 500,
        },
      },

      labels: {
        formatter: function (value: number) {
          return localize.formatAsInteger(value);
        },
      },
    },

    tooltip: {
      shared: false,
      custom: ({
        series,
        seriesIndex,
        dataPointIndex,
        w,
      }: {
        series: any;
        seriesIndex: number;
        dataPointIndex: number;
        w: any;
      }) => {
        const seriesName = w.config.series[seriesIndex].name;
        const xValue = w.globals.labels[dataPointIndex];
        const yValue = series[seriesIndex][dataPointIndex];
        return `<div
        style="background-color: #fff;border: 1px solid #e0e0e0; border-radius: 5px; padding: 10px; font-size: 12px; color: #000"
        > <span style="font-weight:600">${seriesName}</span><br/> <span >${xValue}</span><br/> <span >${localize.formatAsFloat(
          yValue,
          { unit },
        )}</span></div>`;
      },
      style: {
        fontFamily: theme.typography.fontFamily,
      },
    },

    grid: {
      strokeDashArray: 0,
    },
  });

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
      }}
    >
      <ReactApexChart
        key={chartKey} // Use the chartKey to re-render after simulation
        id="co2_path_graph"
        type={chartState.chart?.type}
        options={chartState}
        series={series}
        height={350}
      />
      {!isDataAvailable && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            mt: -2,
            background: theme.palette.common.white,
            p: 1,
          }}
        >
          <Box>
            <Typography variant="caption" color="text.secondary">
              {noDataMessage}
            </Typography>
          </Box>
          {addDataButton}
        </Box>
      )}
    </Box>
  );
}

export type LegendKeys =
  | 'strandingWithoutActions'
  | 'strandingPeriod'
  | 'object'
  | '1_5CTargetPath'
  | 'actualPath'
  | 'withActions'
  | 'StrandingCRREMPath1_5';

export function PathGraphLegend({ legends }: { legends: { key: LegendKeys; color: string; text: string }[] }) {
  const getLegendItem = (legend: { key: LegendKeys; color: string; text: string }) => {
    switch (legend.key) {
      case 'StrandingCRREMPath1_5':
        return <Box component={'img'} src="/images/PathGraph/stranding-year.png" width={12} mr={0.5} />;
      case 'strandingPeriod':
        return <Box width={10} height={10} bgcolor={legend.color} mr={0.5} />;
      case 'strandingWithoutActions':
        return <Box width={10} height={10} bgcolor={legend.color} borderRadius={10} mr={0.5} />;
      case '1_5CTargetPath':
        return <Box component={'img'} src="/images/PathGraph/target-path.png" mr={0.5} />;
      case 'actualPath':
        return <Box component={'img'} src="/images/PathGraph/actual-path.png" mr={0.5} />;

      default:
        return <Box width={20} height={4} bgcolor={legend.color} mr={0.5} />;
    }
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent={'center'}>
        {legends.map((legend, index) => (
          <Box key={index} ml={2}>
            <Stack direction="row" alignItems={'center'} alignContent={'center'} my={2}>
              {getLegendItem(legend)}

              <Typography variant="body2">{legend.text}</Typography>
            </Stack>
          </Box>
        ))}
      </Grid>
    </>
  );
}
