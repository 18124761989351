import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { pathValuesWithYearFromYear } from '@predium/client-lookup';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LegendKeys, PathGraph, PathGraphLegend } from '../../../../components/data-visialization/PathGraph';
import { useLanguage } from '../../../../provider/LanguageProvider';
import { getFilename } from '../../../../utils/getFilename';
import { esgPathGraphOptions } from '../../DemandAnalysis/CrremAnalysis';

type Props = {
  energyPath: number[];
  energyTargetPath: number[];
  buildingLegendName: string;
  graphStartYear: number;
};

export function EnergyPathGraph({ energyPath, energyTargetPath, buildingLegendName, graphStartYear }: Props) {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const theme = useTheme();

  const energyDataSeries: [number, number][] = pathValuesWithYearFromYear(energyPath, graphStartYear);
  const targetSeries: [number, number][] = pathValuesWithYearFromYear(energyTargetPath, graphStartYear);

  const isDataAvailable = energyDataSeries.length > 0;

  const energySeries = useMemo(() => {
    if (!isDataAvailable) return [];

    return [
      {
        data: energyDataSeries,
        color: theme.palette.common.black,
        name: buildingLegendName,
      },
      {
        data: targetSeries,
        color: theme.palette.energyEfficiencyClassColors.AA.light,
        name: t('General_1_5CTargetPath'),
      },
    ];
  }, [isDataAvailable, energyDataSeries, targetSeries, buildingLegendName, theme.palette, t]);

  const legends = useMemo(() => {
    if (!isDataAvailable) return [];

    const legends: { key: LegendKeys; color: string; text: string }[] = [
      { key: 'object', color: theme.palette.common.black, text: t('General_Object') },
      {
        key: '1_5CTargetPath',
        color: theme.palette.energyEfficiencyClassColors.AA.light,
        text: t('General_1_5CTargetPath'),
      },
    ];
    return legends;
  }, [isDataAvailable, theme.palette, t]);

  const dataToExport = useMemo(() => {
    if (!isDataAvailable) return { actualPath: [], targetPath: [] };

    return {
      actualPath: energyDataSeries,
      targetPath: targetSeries,
    };
  }, [isDataAvailable, energyDataSeries, targetSeries]);

  const unit = 'kWh/m²a';

  return (
    <Box
      sx={{
        '.apexcharts-marker': {
          display: 'none', //using prop from apexchart doesn't work for some reason so we hide it here
        },
      }}
    >
      <PathGraph
        filename={getFilename([t('General_EnergyPath')], language)}
        series={energySeries}
        options={esgPathGraphOptions}
        unit={unit}
        dataToExport={dataToExport}
        noDataMessage={t('General_NoDataAvailable')}
      />
      {isDataAvailable && <PathGraphLegend legends={legends} />}
    </Box>
  );
}
