import { checkDistrictHeating, getEmissionsFactor } from '@predium/client-lookup';
import {
  country_enum,
  country_state_enum,
  emission_factor_type_enum,
  energy_source_type_enum,
  energy_system_type_enum,
} from '@predium/enums';
import { accessEnum, ensureDefined, Localize } from '@predium/utils';
import { UseFormSetValue } from 'react-hook-form';
import { EnergySystemFormValues, getDefaultParameterValues } from '../../../../../BuildingTechnology';
import { generateSystemRouteFieldNames } from '../../../../../Common/building.util';
import { GlOBAL_EMISSION_CERTIFICATE_ID } from './EnergySourceEditModal';

type Values = {
  emissionFactor: number;
  primaryEnergyFactor: number;
  primaryEnergyFactorEmissionCertificateId: number;
  emissionCertificateId: number;
};

const updateRelatedSource = (
  fieldNames: Record<keyof Values, string>,
  energySource: energy_source_type_enum,
  setValue: UseFormSetValue<any>,
  values: Values,
  globalEmissionFactorType: emission_factor_type_enum,
  address: {
    country_id: country_enum;
    country_state_id: country_state_enum;
    postal_code: string;
  },
  localize: Localize,
) => {
  setValue(fieldNames.emissionCertificateId, values.emissionCertificateId, {
    shouldDirty: true,
  });

  if (values.emissionCertificateId === GlOBAL_EMISSION_CERTIFICATE_ID) {
    setValue(
      fieldNames.emissionFactor,
      getEmissionsFactor({
        energySourceType: energySource,
        emissionFactorType: globalEmissionFactorType,
        year: new Date().getFullYear(),
        country: address.country_id,
      }),
      {
        shouldDirty: true,
      },
    );
  } else {
    setValue(fieldNames.emissionFactor, values.emissionFactor, {
      shouldDirty: true,
    });
  }

  setValue(fieldNames.primaryEnergyFactorEmissionCertificateId, values.primaryEnergyFactorEmissionCertificateId, {
    shouldDirty: true,
  });

  if (values.primaryEnergyFactorEmissionCertificateId === GlOBAL_EMISSION_CERTIFICATE_ID) {
    setValue(
      fieldNames.primaryEnergyFactor,
      getDefaultParameterValues(energySource, address, localize).primaryEnergyFactor,
      {
        shouldDirty: true,
      },
    );
  } else {
    setValue(fieldNames.primaryEnergyFactor, values.primaryEnergyFactor, {
      shouldDirty: true,
    });
  }
};

export const updateAllRelatedEnergySources = (
  systemsGrouped: EnergySystemFormValues,
  setValue: UseFormSetValue<any>,
  values: Values,
  globalEmissionFactorType: emission_factor_type_enum,
  address: {
    country_id: country_enum;
    country_state_id: country_state_enum;
    postal_code: string;
  },
  localize: Localize,
) => {
  const relatedHeatingSources = Object.entries(systemsGrouped).flatMap(([systemType, systems]) =>
    systems.flatMap((system, systemIndex) =>
      system.routes
        .map((route, routeIndex) => {
          if (!route.energySource || !checkDistrictHeating(route.energySource).isDistrictHeating) {
            return undefined;
          }

          const fieldNames = generateSystemRouteFieldNames(
            accessEnum(energy_system_type_enum, systemType),
            systemIndex,
            routeIndex,
          );

          return {
            energySource: route.energySource,
            fieldNames: {
              emissionFactor: fieldNames.emissionFactorFieldname,
              primaryEnergyFactor: fieldNames.primaryFactorFieldname,
              primaryEnergyFactorEmissionCertificateId: fieldNames.primaryEnergyFactorEmissionCertificateIdFieldname,
              emissionCertificateId: fieldNames.emissionCertificateIdFieldname,
            },
          };
        })
        .filter((source) => source !== undefined),
    ),
  );

  relatedHeatingSources.forEach((source) =>
    updateRelatedSource(
      source.fieldNames,
      source.energySource,
      setValue,
      values,
      globalEmissionFactorType,
      address,
      localize,
    ),
  );
};

export const getDefaultValuesForDistrictHeating = (systemsGrouped: EnergySystemFormValues) => {
  const similarSystems = Object.entries(systemsGrouped)
    .flatMap(([, systems]) =>
      systems.flatMap((heating) =>
        heating.routes.find(
          (route) => route.energySource && checkDistrictHeating(route.energySource).isDistrictHeating,
        ),
      ),
    )
    .filter((route) => route !== undefined);

  if (similarSystems.length > 0) {
    const route = ensureDefined(similarSystems[0]);

    return {
      primaryEnergyFactorEmissionCertificateId: route.primaryEnergyFactorEmissionCertificateId,
      emissionCertificateId: route.emissionCertificateId,
    };
  }

  return {};
};
