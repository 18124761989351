import { Stack, Typography } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { useLanguage } from '../../provider/LanguageProvider';
import Iconify from '../Iconify';

type ChangeType = 'positive' | 'neutral' | 'negative';

export const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

type Props = {
  percent: number;
};

export function classifyChange(change: number): ChangeType {
  if (change > 0) {
    return 'positive';
  } else if (change < 0) {
    return 'negative';
  } else {
    return 'neutral';
  }
}

export default function WidgetSummary({ percent }: Props) {
  const { localize } = useLanguage();
  const theme = useTheme();
  const roundedPercent = percent > 0 ? Math.floor(percent * 10) / 10 : Math.ceil(percent * 10) / 10;
  const percentageSignal = classifyChange(roundedPercent);

  let color;

  if (percentageSignal === 'positive') {
    color = theme.palette.error.main;
  } else if (percentageSignal === 'negative') {
    color = theme.palette.success.main;
  } else {
    color = theme.palette.text.primary;
  }
  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{ ml: 1 }}>
      <IconWrapperStyle
        sx={{
          color: color,
          bgcolor: alpha(color, 0.16),
        }}
      >
        <Iconify
          width={16}
          height={16}
          icon={
            percentageSignal === 'positive'
              ? 'eva:trending-up-fill'
              : percentageSignal === 'negative'
              ? 'eva:trending-down-fill'
              : 'eva:activity-outline'
          }
        />
      </IconWrapperStyle>
      <Typography component="span" variant="subtitle2">
        {localize.formatAsPercentage(percent, {
          signDisplay: 'exceptZero',
          fractionDigits: percentageSignal === 'neutral' ? 0 : 1,
        })}
      </Typography>
    </Stack>
  );
}
