import { useQuery } from '@apollo/client';
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { getSupportedSourceTypesForSystemType } from '@predium/client-lookup';
import {
  area_type_enum,
  consumption_type_enum,
  energy_source_type_enum,
  invoice_payer_type_enum,
} from '@predium/enums';
import { translateInvoicePayerTypeEnum } from '@predium/i18n/client';
import { getValueAreaByType } from '@predium/utils';
import uniq from 'lodash/uniq';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Iconify from '../../../../components/Iconify';
import Label from '../../../../components/Label';
import { RHFDatePicker, RHFSelect, RHFTextField } from '../../../../components/hook-form';
import { PermissionType } from '../../../../contexts/PermissionContext';
import { GET_BUILDING_CONSUMPTION_PRECONDITION } from '../../../../graphql/DataCollection.queries';
import usePermissions from '../../../../hooks/usePermissions';
import useSessionData from '../../../../hooks/useSessionData';
import { ConsumptionInvoiceDraftFormData } from '../Drafts/SubBuildingConsumptionDraftForm';
import SingleConsumptionDraftBox from './SingleConsumptionDraftBox';

type Props = {
  disabled?: boolean;
  isSingleConsumption?: boolean;
};

const SubBuildingConsumptionInvoiceDraft = ({ disabled = false, isSingleConsumption = false }: Props) => {
  const { t } = useTranslation();
  const { org } = useSessionData();
  const { checkBuildingPermission, checkPortfolioPermission } = usePermissions();

  const [selectedAreaType, setSelectedAreaType] = useState<area_type_enum>();

  const { data } = useQuery(GET_BUILDING_CONSUMPTION_PRECONDITION, {
    variables: {
      orgId: org?.id ?? 0,
    },
    skip: !org,
  });

  const { watch, control, setValue, getValues, formState, resetField } =
    useFormContext<ConsumptionInvoiceDraftFormData>();
  const { fields, append, remove } = useFieldArray<ConsumptionInvoiceDraftFormData>({
    control,
    name: 'consumptionDrafts',
  });
  const currentAreaId = watch('areaId');
  const currentBuildingId = watch('buildingId');
  let { id: buildingIdSource } = useParams();
  const selectedBuildingId = Number(buildingIdSource);

  const building = data?.building.find(({ id }) => id === currentBuildingId);
  const commonAreaValue = getValueAreaByType(building?.areas ?? [], area_type_enum.AF) ?? 0;

  // Initializes the area type select with the correct state
  useEffect(() => {
    const areaTypeSelected = data?.building
      .find(({ id }) => id === currentBuildingId)
      ?.areas.find(({ id }) => id === currentAreaId)?.area_type_id;

    if (areaTypeSelected) {
      setSelectedAreaType(areaTypeSelected);
    }
  }, [currentAreaId, currentBuildingId, data]);

  const currentEnergySystems =
    data?.building
      .find(({ id }) => id === currentBuildingId)
      ?.building_models.flatMap(({ energy_systems }) => energy_systems) ?? [];

  const supportedEnergySourcesForEnergySystems = uniq(
    currentEnergySystems.flatMap(({ energy_system_type_id }) =>
      getSupportedSourceTypesForSystemType(energy_system_type_id!),
    ),
  );

  const areasMFAutocompleteOptions =
    data?.building
      .find(({ id }) => id === currentBuildingId)
      ?.areas.filter(({ area_type_id }) => area_type_id === area_type_enum.MF)
      .map(({ id, name, description }) => ({
        label: [description, name].filter(Boolean).join(' ') || t('DataCollectionAreas_UnassignedRentalArea'),
        value: id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const getAreaTypeName = (areaType: area_type_enum) => {
    switch (areaType) {
      case area_type_enum.MF:
        return t('DataCollectionAreas_TenantArea');

      case area_type_enum.AF:
        return t('DataCollectionAreas_CommonArea');

      case area_type_enum.NGF:
        return t('DataCollectionAreas_TotalBuilding');

      default:
        return '';
    }
  };

  const from = watch('from');
  const to = watch('to');
  // used for custom behavior
  const firstConsumptionIsEmpty = !watch(`consumptionDrafts.0.consumptionTypeId`);

  const autocompleteOptions =
    data?.building
      .filter(
        ({ id, economic_unit }) =>
          checkBuildingPermission(id, PermissionType.WRITE) ||
          checkPortfolioPermission(economic_unit.portfolio.id, PermissionType.WRITE),
      )
      .map(({ id, address }) => ({
        label: `${address.street}, ${address.postal_code} ${address.city}`,
        value: id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  return (
    <Stack>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {t('DataCollection_ConsumptionDraftLocationInput-label')}
          </Typography>

          <Autocomplete
            // TODO: Changing buildings is not yet supported on a single consumption
            disabled={disabled || isSingleConsumption}
            disableClearable
            style={{ flexGrow: 1, marginBottom: 24 }}
            options={autocompleteOptions}
            // @ts-ignore
            value={autocompleteOptions.find((option) => option.value === getValues('buildingId')) ?? null}
            onChange={(_, chosenOption) => {
              const chosenValue = chosenOption as unknown as (typeof autocompleteOptions)[number];

              setValue('buildingId', chosenValue.value, { shouldValidate: true, shouldDirty: true });
              setValue('areaId', undefined as any);
              setSelectedAreaType(undefined);
            }}
            renderOption={(props, option) => (
              <MenuItem {...props} key={option.value} style={{ display: 'flex' }}>
                <span style={{ flexGrow: 1, maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {option.label}
                </span>
                {option.value === selectedBuildingId && (
                  <Tooltip
                    title={t('DataCollectionSubBuildingConsumptionInvoiceDraft_CurrentBuilding')}
                    arrow
                    placement="top"
                  >
                    <Label ml={1}>{t('DataCollectionSubBuildingConsumptionInvoiceDraft_CurrentBuilding')}</Label>
                  </Tooltip>
                )}
              </MenuItem>
            )}
            renderInput={(params) => (
              <Controller
                name="buildingId"
                control={control}
                render={({ fieldState: { error }, field: { ref } }) => {
                  return (
                    <TextField
                      {...params}
                      error={Boolean(error)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: formState.dirtyFields.buildingId ? (
                          <>
                            <InputAdornment position="end">
                              <Tooltip
                                arrow
                                placement="top"
                                title={t('DataCollection_ConsumptionAssociatedBuildingSelectReset-tooltip')}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => resetField('buildingId')}
                                  disabled={disabled || isSingleConsumption}
                                >
                                  <Iconify icon="icon-park-outline:return" />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>

                            {params.InputProps.endAdornment}
                          </>
                        ) : (
                          params.InputProps.endAdornment
                        ),
                      }}
                      inputRef={ref}
                      helperText={
                        error
                          ? error?.message
                          : formState.dirtyFields.buildingId
                          ? t('DataCollection_ConsumptionAssociatedBuildingSelect-valueChangedHelperText')
                          : undefined
                      }
                      label={t('DataCollection_ConsumptionAssociatedBuildingSelect-label')}
                    />
                  );
                }}
              />
            )}
          />

          <FormControl
            error={Boolean(formState.errors.areaId && selectedAreaType !== area_type_enum.MF)}
            style={{ width: '100%', marginBottom: 24 }}
          >
            <InputLabel id="consumption-draft-area-type-select">{t('General_ReferenceArea')}</InputLabel>
            <Select
              labelId="consumption-draft-area-type-select"
              placeholder={t('General_SelectArea')}
              disabled={disabled}
              label={t('General_ReferenceArea')}
              value={selectedAreaType ?? ''}
              onChange={(e) => {
                const areaType = e.target.value as area_type_enum;
                setSelectedAreaType(areaType);
                if (areaType === area_type_enum.NGF || areaType === area_type_enum.AF) {
                  const areaId = data?.building
                    .find(({ id }) => id === currentBuildingId)
                    ?.areas.find(({ area_type_id }) => area_type_id === areaType)!.id;
                  setValue('areaId', areaId, { shouldDirty: true, shouldValidate: true });
                } else if (areaType === area_type_enum.MF) {
                  setValue('areaId', undefined as any, { shouldDirty: true });
                }
              }}
            >
              {[area_type_enum.MF, area_type_enum.AF, area_type_enum.NGF]
                .filter((areaType) => !(areaType === area_type_enum.AF && commonAreaValue === 0))
                .map((areaType) => (
                  <MenuItem key={areaType} value={areaType}>
                    {getAreaTypeName(areaType)}
                  </MenuItem>
                ))}
            </Select>
            {formState.errors.areaId && selectedAreaType !== area_type_enum.MF && (
              <FormHelperText> {formState.errors.areaId.message!}</FormHelperText>
            )}
          </FormControl>

          {selectedAreaType === area_type_enum.MF && (
            <Autocomplete
              disabled={disabled}
              style={{ flexGrow: 1 }}
              options={areasMFAutocompleteOptions}
              // @ts-ignore
              value={areasMFAutocompleteOptions.find((option) => option.value === getValues('areaId')) ?? null}
              onChange={(_, chosenOption) => {
                const chosenValue = chosenOption as unknown as (typeof areasMFAutocompleteOptions)[number];

                setValue('areaId', chosenValue?.value, {
                  shouldValidate: Boolean(chosenValue?.value),
                  shouldDirty: true,
                });
              }}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.value} style={{ display: 'flex' }}>
                  <span style={{ flexGrow: 1, maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {option.label}
                  </span>
                  {option.value === currentAreaId && (
                    <Tooltip
                      title={t('DataCollectionSubBuildingConsumptionInvoiceDraft_CurrentArea')}
                      arrow
                      placement="top"
                    >
                      <Label ml={1}>{t('DataCollectionSubBuildingConsumptionInvoiceDraft_CurrentArea')}</Label>
                    </Tooltip>
                  )}
                </MenuItem>
              )}
              renderInput={(params) => (
                <Controller
                  name="areaId"
                  control={control}
                  render={({ fieldState: { error }, field: { ref } }) => {
                    return (
                      <TextField
                        {...params}
                        error={Boolean(error)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: formState.dirtyFields.areaId ? (
                            <>
                              <InputAdornment position="end">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={t('DataCollection_ConsumptionAssociatedTenantAreaSelect-tooltip')}
                                >
                                  <IconButton size="small" onClick={() => resetField('areaId')} disabled={disabled}>
                                    <Iconify icon="icon-park-outline:return" />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>

                              {params.InputProps.endAdornment}
                            </>
                          ) : (
                            params.InputProps.endAdornment
                          ),
                        }}
                        inputRef={ref}
                        helperText={
                          error
                            ? error?.message
                            : formState.dirtyFields.areaId
                            ? t('DataCollection_ConsumptionAssociatedTenantAreaSelect-valueChangedHelperText')
                            : undefined
                        }
                        label={t('DataCollection_ConsumptionAssociatedTenantAreaSelect-label')}
                      />
                    );
                  }}
                />
              )}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {t('DataCollection_ConsumptionDraftGeneralData-title')}
          </Typography>
          <RHFTextField label={t('General_Provider')} name="provider" disabled={disabled} sx={{ mb: 2 }} />

          <Grid container spacing={2}>
            {!isSingleConsumption && (
              <>
                <Grid item xs={6}>
                  <RHFDatePicker label={t('General_InvoiceDate')} name="invoiceDate" disabled={disabled} />
                </Grid>

                <Grid item xs={6}>
                  <RHFSelect name="invoicePayer" label={t('General_Paid_by')} disabled={disabled}>
                    {Object.values(invoice_payer_type_enum).map((option) => (
                      <MenuItem key={option} value={option}>
                        {/* TODO: Add proper icon later */}

                        {translateInvoicePayerTypeEnum(option)}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>
              </>
            )}

            <Grid item xs={6}>
              <RHFDatePicker maxDate={to as any} label={t('General_FromTo-from')} name="from" disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <RHFDatePicker minDate={from as any} label={t('General_FromTo-to')} name="to" disabled={disabled} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {t('DataCollection_ConsumptionDraftData-title')}
          </Typography>

          <Stack gap={2}>
            {fields.map(({ id }, index) => (
              <SingleConsumptionDraftBox
                id={id}
                key={id}
                index={index}
                remove={() => {
                  if (fields.length > 1) {
                    remove(index);
                  }
                  // Just erase the values
                  else {
                    setValue(`consumptionDrafts.0.consumptionTypeId`, undefined as any);
                    setValue(`consumptionDrafts.0.energySourceTypeId`, undefined);
                    setValue(`consumptionDrafts.0.subTypeId`, undefined);
                    setValue(`consumptionDrafts.0.value`, undefined);
                    setValue(`consumptionDrafts.0.displayUnitValue`, undefined);
                    setValue(`consumptionDrafts.0.cost`, undefined);
                    setValue(`consumptionDrafts.0.waste`, undefined);
                  }
                }}
                disabled={disabled}
                isSingleConsumption={isSingleConsumption}
                limitEnergySourcesBy={supportedEnergySourcesForEnergySystems}
              />
            ))}
          </Stack>

          {!isSingleConsumption && !firstConsumptionIsEmpty && (
            <Button
              variant="text"
              startIcon={<Iconify icon={'mdi:plus'} />}
              sx={{ mt: 2 }}
              disabled={disabled}
              onClick={() => {
                // Add empty consumption draft
                append({
                  value: undefined as unknown as number,
                  cost: undefined as unknown as number,
                  consumptionTypeId: undefined as unknown as consumption_type_enum,
                  energySourceTypeId: undefined as unknown as energy_source_type_enum,
                });
              }}
            >
              {t('General_Add')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SubBuildingConsumptionInvoiceDraft;
