import { useCallback } from 'react';
import { defaultFilters } from '../../contexts/EsgAnalysisFilterContext';
import {
  EsgAnalysisFiltersKeys,
  EsgAnalysisFiltersSearchParams,
  FindEsgAnalysisFilterValue,
} from '../../sections/EsgAnalysis/EsgAnalysisFilters/EsgAnalysisFilters';

export const useEsgAnalysisFilterActions = (
  setFilters: React.Dispatch<React.SetStateAction<EsgAnalysisFiltersSearchParams>>,
  filters: EsgAnalysisFiltersSearchParams,
) => {
  const addOrExcludeIfExist = useCallback(<T,>(currentValues: T[], newValue: T): T[] => {
    if (currentValues.includes(newValue)) {
      return currentValues.filter((value) => value !== newValue);
    }

    return [...currentValues, newValue];
  }, []);

  const setFilter = useCallback(
    <Key extends EsgAnalysisFiltersKeys>(key: Key, value: FindEsgAnalysisFilterValue<Key>) => {
      setFilters((prevState) => ({
        ...prevState,
        [key]: addOrExcludeIfExist(prevState[key] ?? [], value),
      }));
    },
    [addOrExcludeIfExist, setFilters],
  );

  const replaceFilter = useCallback(
    <Key extends EsgAnalysisFiltersKeys>(key: Key, value: FindEsgAnalysisFilterValue<Key>[]) => {
      setFilters((prevState) => ({ ...prevState, [key]: value }));
    },
    [setFilters],
  );

  const resetAllFilters = useCallback(() => {
    setFilters(defaultFilters());
  }, [setFilters]);

  const resetFilter = useCallback(
    (filter: EsgAnalysisFiltersKeys) => setFilters((prevState) => ({ ...prevState, [filter]: [] })),
    [setFilters],
  );

  const resetFilters = useCallback(
    (keysToReset: EsgAnalysisFiltersKeys[]) => {
      const updatedFilters = { ...filters };

      keysToReset.forEach((key) => {
        updatedFilters[key as EsgAnalysisFiltersKeys] = [];
      });
      setFilters(updatedFilters);
    },
    [filters, setFilters],
  );

  return {
    setFilter,
    replaceFilter,
    resetAllFilters,
    resetFilter,
    resetFilters,
  };
};
