/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FileThumbnail, { FileFormat } from '../../../../components/FileThumbnail';
import Iconify from '../../../../components/Iconify';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import EvidenceDocumentViewer from './EvidenceForm/EvidenceDocumentViewer';
import { EvidenceListEntry } from './RootQuestionEvidences';

type Props = {
  evidence: EvidenceListEntry;
  defaultValue: string;
  disabled: boolean;
  onCompleted: (updatedDescription: string) => void;
};

function EditEvidenceDescriptionDialog({ evidence, defaultValue, disabled, onCompleted }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip title={t('EditEvidenceDescriptionDialog_EditEvidence-title')}>
        <span>
          <IconButton disabled={disabled} onClick={() => setOpen(true)}>
            <Iconify icon="ic:baseline-edit" />
          </IconButton>
        </span>
      </Tooltip>

      <PreDialog type="definedByChildren" open={open} onClose={() => setOpen(false)}>
        <EditEvidenceDescriptionDialogContent
          evidence={evidence}
          onClose={() => setOpen(false)}
          defaultValue={defaultValue}
          onCompleted={onCompleted}
        />
      </PreDialog>
    </>
  );
}

type DialogContentProps = {
  evidence: EvidenceListEntry;
  defaultValue: string;
  onClose: () => void;
  onCompleted: (updatedDescription: string) => void;
};

function EditEvidenceDescriptionDialogContent({ evidence, defaultValue, onClose, onCompleted }: DialogContentProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [evidenceDescription, setEvidenceDescription] = useState(defaultValue);
  const [showDocumentByUrl, setShowDocumentByUrl] = useState<string | null>(null);

  const { title, type, url } = evidence;

  return (
    <>
      <DialogTitle>{t('EditEvidenceDescriptionDialog_EditEvidence-title')}</DialogTitle>

      <DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 8,
            marginBottom: 24,
          }}
        >
          {type === 'file' ? (
            <>
              <IconButton
                sx={{ mr: 0.75 }}
                onClick={() => {
                  setShowDocumentByUrl(url);
                }}
              >
                <FileThumbnail format={FileFormat.PDF} />
              </IconButton>
              <Typography style={{ flexGrow: 1 }}>{title}</Typography>
              <IconButton
                onClick={() => {
                  setShowDocumentByUrl(url);
                }}
              >
                <Iconify icon="material-symbols:arrow-outward" />
              </IconButton>
            </>
          ) : (
            <>
              <Iconify
                style={{ marginRight: 16, marginLeft: 12 }}
                width={24}
                height={24}
                icon="material-symbols:link"
              />
              <Link style={{ flexGrow: 1 }} href={url}>
                {title}
              </Link>
            </>
          )}
        </div>
        <Trans
          i18nKey="ReportEvidence_EvidenceHintOptional"
          components={{
            span: <span style={{ color: theme.palette.grey[600] }} />,
          }}
        />
        <p>{t('EditEvidenceDescriptionDialog_DialogContent-description')}</p>
        <TextField
          style={{ marginTop: 16 }}
          multiline
          fullWidth
          label={t('General_Note')}
          minRows={3}
          maxRows={6}
          value={evidenceDescription}
          onChange={(e) => setEvidenceDescription(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t('General_Cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onCompleted(evidenceDescription);
            onClose();
          }}
        >
          {t('General_Save')}
        </Button>
      </DialogActions>

      <EvidenceDocumentViewer
        open={Boolean(showDocumentByUrl)}
        onClose={() => setShowDocumentByUrl(null)}
        //@ts-ignore
        documentUrl={showDocumentByUrl}
      />
    </>
  );
}

export default EditEvidenceDescriptionDialog;
