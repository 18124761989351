import { alpha, useTheme } from '@mui/material/styles';

import { TableCell, TableCellProps } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren & TableCellProps;

export default function StickyTableCell({ children, sx, ...props }: Props) {
  const theme = useTheme();
  return (
    <TableCell
      className="sticky-cell"
      sx={{
        position: 'sticky',
        right: 0,
        background: theme.palette.background.paper,
        transition: theme.transitions.create(['box-shadow']),
        '.MuiTableRow-hover:hover &': {
          background: alpha(theme.palette.grey[200], 1),
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '6px',
          height: '100%',
          boxShadow: (theme) => `inset -5px 0 6px -5px ${alpha(theme.palette.grey[500], 0.5)}`,
          opacity: 0,
          transition: theme.transitions.create('opacity'),
        },
        '&.scrolled::before': {
          opacity: 1,
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </TableCell>
  );
}
