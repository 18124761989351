import { useQuery } from '@apollo/client';
import { CompleteBuildingQueryVariables, data_source_type_enum } from '@predium/client-graphql';
import { ensureDefined } from '@predium/utils';
import { useEffect, useMemo, useState } from 'react';
import { COMPLETE_BUILDING } from '../../../../../graphql/DataCollection.queries';
import { useBuildingCreation } from '../BuildingCreationProvider';

const DUMMY_LOADER_TIMEOUT = 2_000;

export const useCompleteBuilding = () => {
  const { getBuilding } = useBuildingCreation();
  const [dummyLoading, setDummyLoading] = useState(true);

  const variables = useMemo(() => {
    const building = getBuilding();

    return {
      areaUsable: building.information.area.totalArea,
      typeOfUse: building.information.area.typeOfUse,
      units: building.information.coreData.units,
      year: building.information.coreData.constructionYear,
      energyData:
        building.information.energyData?.map((data) => ({
          energySource: ensureDefined(data.energySource),
          energySystemType: ensureDefined(data.energyConsumer),
          finalEnergyDemand: ensureDefined(data.finalEnergy),
        })) ?? [],
    } satisfies CompleteBuildingQueryVariables;
  }, [getBuilding]);

  const query = useQuery(COMPLETE_BUILDING, {
    variables,
  });

  const loading = query.loading || dummyLoading;

  const completedBuilding = useMemo(() => {
    const building = getBuilding();
    const missingData = query.data?.completeBuilding;

    if (!missingData || loading) {
      return building;
    }

    return {
      ...building,
      information: {
        ...building.information,
        coreData: {
          ...building.information.coreData,
          constructionYear: missingData.yearConstructed,
          units: missingData.units,
        },
        area: {
          ...building.information.area,
          typeOfUse: missingData.typeOfUse,
          totalArea: missingData.areaUsable,
        },
        energyData: building.information.energyData?.length
          ? building.information.energyData
          : missingData.energyData.map((data) => ({
              energyConsumer: data.energySystemType,
              energySource: data.energySource,
              finalEnergy: data.finalEnergyDemand,
            })),
      },
    };
  }, [getBuilding, query.data?.completeBuilding, loading]);

  const estimatedFieldsCount = useMemo(() => {
    const missingData = query.data?.completeBuilding;
    if (!missingData || loading) {
      return 0;
    }

    // For each energy data entry, we estimate 3 fields (source, system type, demand) minus 1
    // since energyDataSource is already counted in the filter below
    const energyDataEstimatedCount =
      missingData.energyDataSource === data_source_type_enum.APPROXIMATED ? missingData.energyData.length * 3 - 1 : 0;

    return (
      Object.values(missingData).filter((value) => value?.toString() === data_source_type_enum.APPROXIMATED).length +
      energyDataEstimatedCount
    );
  }, [query.data?.completeBuilding, loading]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDummyLoading(false);
    }, DUMMY_LOADER_TIMEOUT);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return { completedBuilding, ...query, loading, estimatedFieldsCount };
};
