import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useBuildingCreation } from './BuildingCreationProvider';
import { steps } from './Steps';
import { useCreateBuilding } from './hooks/useCreateBuilding';

const BuildingCreationActions = () => {
  const { t } = useTranslation();
  const { state, handleBack, handleNext, handleSaveAsDraft, isLoading } = useBuildingCreation();

  const [createBuildingFromBuildingDraft, { loading: createLoading }] = useCreateBuilding();

  const showBackButton = state.activeStepIndex > 0;
  const showNextButton = state.activeStepIndex < steps.length - 1;
  const showSaveButton = state.activeStepIndex === steps.length - 1;
  const showSaveAsDraft = state.activeStepIndex > 0;

  const handleCreateBuilding = async () => {
    handleSaveAsDraft((draftId) => {
      if (draftId) {
        createBuildingFromBuildingDraft({ variables: { buildingDraftId: draftId } });
      }
    }, true);
  };

  const loading = isLoading || createLoading;

  return (
    <Stack direction="row" justifyContent={showBackButton ? 'space-between' : 'flex-end'} spacing={2} flex={1} px={2}>
      {showBackButton && (
        <Button
          sx={{ borderColor: 'divider' }}
          variant="outlined"
          onClick={handleBack}
          disabled={state.isCurrentStepInValid || loading}
        >
          {t('General_Back')}
        </Button>
      )}
      <Stack direction="row" spacing={2}>
        {showSaveAsDraft && (
          <Button
            sx={{ borderColor: 'divider' }}
            variant="outlined"
            onClick={() => handleSaveAsDraft()}
            disabled={state.isCurrentStepInValid || loading}
          >
            {t('General_SaveAsDraft')}
          </Button>
        )}
        {showNextButton && (
          <Button variant="contained" onClick={handleNext} disabled={state.isCurrentStepInValid || loading}>
            {t('General_Next')}
          </Button>
        )}
        {showSaveButton && (
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleCreateBuilding}
            disabled={state.isCurrentStepInValid || loading}
          >
            {t('General_Save')}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
};

export default BuildingCreationActions;
