/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { GET_All_EVIDENCESQuery } from '@predium/client-graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../assets/icons';
import FileThumbnail, { FileFormat } from '../../../../../components/FileThumbnail';
import Iconify from '../../../../../components/Iconify';
import { DelayedLoading } from '../../../../../components/Loading';
import Scrollbar from '../../../../../components/Scrollbar';
import SearchNotFound from '../../../../../components/SearchNotFound';
import PreDialog from '../../../../../components/presentations/PreDialog/PreDialog';
import { TableHeadCustom, TableSearchNotFound } from '../../../../../components/table';
import { HeadCell } from '../../../../../components/table/TableHeadCustom';
import TableMoreMenu from '../../../../../components/table/TableMoreMenu';
import TablePaginationStandard from '../../../../../components/table/TablePaginationStandard';
import { GET_All_EVIDENCES } from '../../../../../graphql/Report.queries';
import useTable, { applySortFilter } from '../../../../../hooks/useTable';
import { useLanguage } from '../../../../../provider/LanguageProvider';
import { getFilename } from '../../../../../utils/getFilename';
import EvidenceDocumentViewer from '../EvidenceForm/EvidenceDocumentViewer';
import { EvidenceDataTypes } from '../RootQuestionEvidences';
import DeleteEvidence from './DeleteEvidence';
import EvidenceExplorerToolbar from './EvidenceExplorerToolbar';
import EvidenceQuestionDisplay from './EvidenceQuestionDisplay';

type Props = {
  reportId: number;
};

function EvidenceExplorer({ reportId }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { data, loading } = useQuery(GET_All_EVIDENCES, {
    variables: {
      reportId,
    },
  });

  if (loading) return <DelayedLoading />;

  return (
    <>
      {/*@ts-ignore */}
      {data.report_evidence.length > 0 && (
        <Button
          style={{ width: 200 }}
          fullWidth
          size="large"
          variant="outlined"
          endIcon={<Iconify icon={'material-symbols:folder-managed-outline-rounded'} width={20} height={20} />}
          onClick={() => setOpen(true)}
        >
          {t('General_Evidence_other')}
        </Button>
      )}
      <PreDialog type="definedByChildren" fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t('General_Evidence_other')}</DialogTitle>
        <DialogContent>
          {/*@ts-ignore */}
          <EvidenceExplorerContent reportId={reportId} evidencesData={data.report_evidence} />
        </DialogContent>
      </PreDialog>
    </>
  );
}

export const AVAILABLE_DATA_TYPES = ['all_files', 'file', 'url'] as const;

type EnrichedEvidences = {
  id: number;
  title: string;
  associatedQuestions: { id: number; title: string }[];
  type: EvidenceDataTypes;
  downloadUrl?: string;
};

type EvidenceExplorerContentProps = {
  reportId: number;
  evidencesData: GET_All_EVIDENCESQuery['report_evidence'];
};

function EvidenceExplorerContent({ reportId, evidencesData }: EvidenceExplorerContentProps) {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const theme = useTheme();

  const [detailedQuestion, setDetailedQuestion] = useState<{ id: number; title: string } | null>(null);
  const [evidenceToDelete, setEvidenceToDelete] = useState<{ id: number; title: string } | null>(null);

  const { page, order, orderBy, rowsPerPage, setPage, onSort, setRowsPerPage } = useTable({
    defaultRowsPerPage: 5,
    defaultOrderBy: 'title',
  });

  const [filterName, setFilterName] = useState('');
  const [selectedDataType, setSelectedDataType] = useState<(typeof AVAILABLE_DATA_TYPES)[number]>('all_files');
  const [showDocumentByUrl, setShowDocumentByUrl] = useState<string | null>(null);

  const evidencesEnrichedByType = evidencesData.map<EnrichedEvidences>((evidence) => ({
    id: evidence.id,
    associatedQuestions: evidence.report_question_evidences.map((evidence) => ({
      id: evidence.report_template_question.id,
      title: evidence.report_template_question.question,
    })),

    //@ts-ignore
    title: evidence.file?.name ?? evidence.url,
    type: evidence.file ? 'file' : 'url',
    downloadUrl: evidence.file?.downloadUrl,
  }));

  const filteredEvidences = applySortFilter({
    data: evidencesEnrichedByType,
    nameFilter: {
      fieldName: 'title',
      filterValue: filterName,
    },
    orderOptions: {
      order,
      orderBy,
    },
    dataFilters: selectedDataType !== 'all_files' ? [(evidence) => evidence.type === selectedDataType] : undefined,
  });

  const tableHead: HeadCell[] = [
    { id: 'title', label: t('General_Evidence_one'), width: 300 },
    { id: 'questions', label: t('General_Question'), sortingDisabled: true },
    { id: 'actions', label: '' },
  ];

  return (
    <Box sx={{ border: `1px solid ${theme.palette.grey[300]}`, borderRadius: 2, mb: 4 }}>
      <EvidenceExplorerToolbar
        filterName={filterName}
        handleFilterNameChange={setFilterName}
        selectedDataType={selectedDataType}
        handleDataTypeChange={setSelectedDataType}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, minHeight: 450 }}>
          <Table>
            <TableHeadCustom order={order} orderBy={orderBy} headLabel={tableHead} onSort={onSort} />
            <TableBody>
              {filteredEvidences.length > 0 ? (
                filteredEvidences.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((evidence, index) => {
                  const { id, title, type, downloadUrl, associatedQuestions } = evidence;

                  return (
                    <TableRow
                      key={id}
                      sx={{
                        borderBottom:
                          index !== filteredEvidences.length - 1 ? `1px solid ${theme.palette.grey[500_16]}` : 'none',
                      }}
                    >
                      <TableCell
                        sx={{
                          maxWidth: 300,
                          overflow: 'hidden',
                          verticalAlign: 'top',
                          pl: 1,
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {type === 'file' ? (
                            <IconButton
                              style={{ marginRight: '1rem' }}
                              //@ts-ignore
                              onClick={() => setShowDocumentByUrl(downloadUrl)}
                            >
                              <FileThumbnail format={FileFormat.PDF} />
                            </IconButton>
                          ) : (
                            <IconButton style={{ marginRight: '1rem' }} href={title} target="_blank" rel="noopener">
                              <Iconify width={28} height={28} icon="material-symbols:link" />
                            </IconButton>
                          )}
                          <Tooltip title={title}>
                            <Typography
                              style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {title}
                            </Typography>
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: 'top', pt: 2.5 }}>
                        {associatedQuestions.map((question) => (
                          <Box>
                            <Button
                              key={question.id}
                              endIcon={<Iconify width={16} height={16} icon="material-symbols:link" />}
                              sx={{
                                marginRight: 0.5,
                                fontWeight: 400,
                                textAlign: 'left',
                                alignItems: 'start',

                                '.MuiButton-endIcon': {
                                  mx: 1,
                                  mt: '5px',
                                },
                              }}
                              onClick={() => setDetailedQuestion({ id: question.id, title: question.title })}
                            >
                              {/* truncate the question title if longer than 200 character until we decided on what should best be displayed. */}
                              {question.title.length > 200 ? (
                                <Tooltip title={question.title}>
                                  <span>{`${question.title.substring(0, 200)}...`}</span>
                                </Tooltip>
                              ) : (
                                question.title
                              )}
                            </Button>
                          </Box>
                        ))}
                      </TableCell>

                      <TableCell align="right" sx={{ verticalAlign: 'top' }}>
                        <TableMoreMenu
                          actions={
                            <>
                              {type === 'file' && (
                                //@ts-ignore
                                <MenuItem onClick={() => setShowDocumentByUrl(downloadUrl)}>
                                  <Iconify icon={'material-symbols:file-open-outline-rounded'} />
                                  {t('General_Open')}
                                </MenuItem>
                              )}
                              {type === 'file' && (
                                <MenuItem
                                  component={Link}
                                  href={downloadUrl}
                                  download={`${getFilename([title], language)}.pdf`}
                                  target="_blank"
                                >
                                  <Iconify icon={'ic:baseline-file-download'} />
                                  {t('General_Download')}
                                </MenuItem>
                              )}

                              {type === 'url' && (
                                <MenuItem component={Link} href={title} target="_blank" rel="noopener">
                                  <Iconify icon={'material-symbols:file-open-outline-rounded'} />
                                  {t('General_Open')}
                                </MenuItem>
                              )}

                              <Tooltip
                                title={
                                  associatedQuestions.length > 0
                                    ? t('EvidenceExplorer_DeleteEvidence-tooltip')
                                    : undefined
                                }
                              >
                                <span>
                                  <MenuItem
                                    disabled={associatedQuestions.length > 0}
                                    onClick={() => setEvidenceToDelete({ id, title })}
                                    sx={{ color: 'error.main' }}
                                  >
                                    <Iconify icon={ICONS.TRASH} />
                                    {t('General_Delete')}
                                  </MenuItem>
                                </span>
                              </Tooltip>
                            </>
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : filterName !== '' ? (
                <SearchNotFound searchQuery={filterName} />
              ) : (
                <TableSearchNotFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePaginationStandard
        count={filteredEvidences.length}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        labelRowsPerPage={t('EvidenceExplorer_RowsPerPage')}
      />

      <EvidenceDocumentViewer
        open={Boolean(showDocumentByUrl)}
        onClose={() => setShowDocumentByUrl(null)}
        //@ts-ignore
        documentUrl={showDocumentByUrl}
      />

      <EvidenceQuestionDisplay
        onClose={() => setDetailedQuestion(null)}
        reportId={reportId}
        questionDetails={detailedQuestion}
      />

      <DeleteEvidence evidenceToDelete={evidenceToDelete} onClose={() => setEvidenceToDelete(null)} />
    </Box>
  );
}

export default EvidenceExplorer;
