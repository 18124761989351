/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { Box, MenuItem } from '@mui/material';
import {
  CreatedEnergyCertificateDraftFragment,
  DataCollectionGetEconomicUnitsListWithBuildingsQuery,
} from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../../assets/icons';
import DeleteConfirmationModal, { AssociatedDataType } from '../../../components/DeleteConfirmationModal';
import Iconify from '../../../components/Iconify';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import PreDialog from '../../../components/presentations/PreDialog/PreDialog';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import { PermissionType } from '../../../contexts/PermissionContext';
import { DELETE_ECONOMIC_UNIT } from '../../../graphql/DataCollection.mutations';
import useGetAssociatedDataForBuilding from '../../../hooks/useGetAssociatedDataForBuilding';
import usePermissions from '../../../hooks/usePermissions';
import useSessionData from '../../../hooks/useSessionData';
import useTooltipConstants from '../../../hooks/useTooltipConstants';
import { PATHS } from '../../../routes';
import UploadEnergyCertificateDraftModal from '../Building/Drafts/UploadEnergyCertificateDraftModal';
import LimitedBulkExport from '../Buildings/BulkActions/LimitedBulkExport';
import EditEconomicUnitModal from './EditEconomicUnitModal';

type Props = {
  economicUnit: DataCollectionGetEconomicUnitsListWithBuildingsQuery['economic_unit'][number];
};

export default function EconomicUnitActionMenu({ economicUnit }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { tooltips: deleteTooltip } = useTooltipConstants();
  const { checkPortfolioPermission } = usePermissions();
  const {
    serverSideFeatureFlags: { BUILDING_CREATION },
  } = useSessionData();
  const createdEnergyCertificateDrafts = useRef<CreatedEnergyCertificateDraftFragment[]>([]);

  const [openUploadEnergyCertificateDraftDialog, setOpenUploadEnergyCertificateDraftDialog] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [openBuildingExportDialog, setOpenBuildingExportDialog] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const { associatedDataForDeletion } = useGetAssociatedDataForBuilding({
    buildingIds: economicUnit.buildings_ids.map((b) => b.id),
    modalOpen: deleteModalOpen,
  });

  const hasEditAccess = useMemo(() => {
    return checkPortfolioPermission(economicUnit.portfolio.id, PermissionType.WRITE);
  }, [economicUnit, checkPortfolioPermission]);

  const associatedDataForDeletionWithBuildings = useMemo(() => {
    const map = new Map(associatedDataForDeletion);
    if (economicUnit.buildings_ids?.length) {
      map.set(AssociatedDataType.BUILDING, economicUnit.buildings_ids.length);
    }
    return map;
  }, [associatedDataForDeletion, economicUnit]);

  const onEnergyCertificateDraftsAdded = (energyCertificateDraftIds: number[]) => {
    if (energyCertificateDraftIds.length > 0) {
      setOpenUploadEnergyCertificateDraftDialog(false);
      createdEnergyCertificateDrafts.current = [];

      if (energyCertificateDraftIds.length === 1) {
        // Navigate to the newly created energyCertificateDraft
        navigate(PATHS.dataCollection.energyCertificateDraft({ id: energyCertificateDraftIds[0] }));
      }

      enqueueSnackbar(t('General_DataUploadedSuccessMessage'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    } else {
      enqueueSnackbar(t('DataCollection_AddEnergyCertificateDraft-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    }
  };

  const showErrorSnackbar = () => {
    enqueueSnackbar(t('DataCollection_DeleteEconomicUnit-error_one'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const [deleteEconomicUnit] = useMutation(DELETE_ECONOMIC_UNIT, {
    update: (cache, { data }) => {
      //@ts-ignore
      if (data.delete_economic_unit_by_pk) {
        const normalizedId = cache.identify({
          //@ts-ignore
          id: data.delete_economic_unit_by_pk.id,

          //@ts-ignore
          __typename: data.delete_economic_unit_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onError: () => showErrorSnackbar(),
    onCompleted: (data) => {
      if (!data.delete_economic_unit_by_pk) {
        showErrorSnackbar();
        return;
      }
      enqueueSnackbar(t('DataCollection_DeleteEconomicUnit-Success_one'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      setDeleteModalOpen(false);
    },
  });

  return (
    <>
      <TableMoreMenu
        actions={
          <>
            {hasEditAccess && (
              <MenuItem onClick={() => setEditModalOpen(true)}>
                <Iconify icon={'material-symbols:edit-sharp'} />
                {t('General_Edit')}
              </MenuItem>
            )}
            {hasEditAccess && !BUILDING_CREATION && (
              <MenuItem onClick={() => setOpenUploadEnergyCertificateDraftDialog(true)}>
                <Box component={'img'} src="/icons/add_building.svg" mr={2} />
                {t('General_AddBuilding')}
              </MenuItem>
            )}
            {hasEditAccess && BUILDING_CREATION && (
              <>
                <MenuItem onClick={() => setOpenUploadEnergyCertificateDraftDialog(true)}>
                  <Iconify icon={ICONS.FILEUPLOADOUTLINE} />
                  {t('General_AddBuilding')} {t('BuildingCreation_WithEnergyCertificate')}
                </MenuItem>
                <MenuItem onClick={() => navigate(PATHS.dataCollection.buildingDraftNew())}>
                  <Iconify icon={ICONS.DRAW_PEN} />
                  {t('General_AddBuilding')} {t('General_Manually')}
                </MenuItem>
              </>
            )}
            <MenuItem
              disabled={economicUnit.buildings_ids.length === 0}
              onClick={() => setOpenBuildingExportDialog(true)}
            >
              <Box component={'img'} src="/icons/data_export.svg" mr={2} />
              {t('General_DataExport')}
            </MenuItem>
            {hasEditAccess && (
              <MenuItem onClick={() => setDeleteModalOpen(true)} sx={{ color: 'error.main' }}>
                <Iconify icon={ICONS.TRASH} />
                {t('General_Delete')}
              </MenuItem>
            )}
          </>
        }
      />

      <PreDialog
        type="definedByChildren"
        fullWidth
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
        }}
      >
        <EditEconomicUnitModal
          onEconomicUnitEdited={() => setEditModalOpen(false)}
          onCancel={() => setEditModalOpen(false)}
          economicUnit={economicUnit}
        />
      </PreDialog>

      <PreDialog
        type="definedByChildren"
        fullWidth
        open={openUploadEnergyCertificateDraftDialog}
        onClose={() => {
          setOpenUploadEnergyCertificateDraftDialog(false);
        }}
      >
        <UploadEnergyCertificateDraftModal
          economicUnitId={economicUnit.id}
          portfolioId={economicUnit.portfolio.id}
          onEnergyCertificateDraftsAdded={onEnergyCertificateDraftsAdded}
          createdEnergyCertificateDrafts={createdEnergyCertificateDrafts}
        />
      </PreDialog>

      <PreDialog
        type="definedByChildren"
        open={openBuildingExportDialog}
        onClose={() => setOpenBuildingExportDialog(false)}
      >
        <LimitedBulkExport buildingIds={economicUnit.buildings_ids.map((building) => building.id)} />
      </PreDialog>

      <DeleteConfirmationModal
        open={deleteModalOpen}
        title={t('General_DeleteModalTitle-EconomicUnit', { count: 1 })}
        description={
          <Trans
            i18nKey={'General_DeleteModalDescription-EconomicUnit'}
            values={{
              name: economicUnit.name,
              count: 1,
              alongWithAssociatedData:
                associatedDataForDeletionWithBuildings.size > 0
                  ? t('General_DeleteWarningAssociatedDataText', { count: 1 })
                  : '',
            }}
            components={{ bold: <strong /> }}
          />
        }
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        onDelete={() => {
          deleteEconomicUnit({
            variables: {
              id: economicUnit.id,
            },
          });
        }}
        associatedData={associatedDataForDeletionWithBuildings}
        deleteConfirmationText={t('General_DeleteModalConfirmation-EconomicUnit', { count: 1 })}
        dataLossWarningText={
          associatedDataForDeletionWithBuildings.size > 0 ? t('General_DeleteModalWarning-EconomicUnit') : undefined
        }
        tooltips={deleteTooltip}
      />
    </>
  );
}
