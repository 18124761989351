import { Box, CircularProgress, Container, Divider, Stack, Typography } from '@mui/material';
import some from 'lodash/some';
import { useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../assets/icons';
import Iconify from '../../../../../components/Iconify';
import { useBuildingCreation } from '../BuildingCreationProvider';
import EstimatedFieldsDetailsIcon from '../Components/EstimatedFieldsDetailsIcon';
import { useCompleteBuilding } from '../hooks/useCompleteBuilding';
import { ACTION_TYPES } from '../hooks/useGlobalState';
import AdditionalDataReview from './sections/AdditionalDataReview';
import CoreDataReview from './sections/CoreDataReview';
import EnergyDataReview from './sections/EnergyDataReview';

const REVIEW = 'REVIEW' as const;

export type Step3ReviewRef = {
  type: typeof REVIEW;
};

const Step3Review = () => {
  const { t } = useTranslation();
  const { activeStepRef, dispatch } = useBuildingCreation();

  const { completedBuilding, loading, estimatedFieldsCount, data } = useCompleteBuilding();

  useImperativeHandle(activeStepRef, () => ({
    type: REVIEW,
  }));

  const hasAnyAdditionalData = some(Object.values(completedBuilding.information.additionalInformation));

  useEffect(() => {
    dispatch({ type: ACTION_TYPES.SET_STEP_INVALIDITY, payload: loading });
  }, [loading, dispatch]);

  return (
    <Container
      maxWidth="sm"
      sx={{
        '&.MuiContainer-root': { pr: 0, pb: 5, pt: 3, maxWidth: 640 },
      }}
    >
      <Box border="1px solid" borderColor="divider" borderRadius={2}>
        <Stack pt={3} pb={2} px={3} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2" fontWeight={700}>
            {t('General_GeneralData')}
          </Typography>
          {loading ? (
            <Stack spacing={1} direction="row" alignItems="center">
              <CircularProgress size={16} />
              <Typography variant="body2">{t('BuildingCreation_EstimateMissingData')}</Typography>
            </Stack>
          ) : (
            estimatedFieldsCount > 0 && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Iconify width={20} height={20} color="info.main" icon={ICONS.APPROXIMATED} />
                <Typography variant="body2">{t('BuildingCreation_EstimatedFields')}</Typography>
                <EstimatedFieldsDetailsIcon />
              </Stack>
            )
          )}
        </Stack>
        <Divider />
        <Stack divider={<Divider />} spacing={2} px={3} pt={2} pb={3}>
          <CoreDataReview completedBuilding={completedBuilding} loading={loading} data={data} />
          <EnergyDataReview completedBuilding={completedBuilding} loading={loading} data={data} />
          {hasAnyAdditionalData && <AdditionalDataReview completedBuilding={completedBuilding} />}
        </Stack>
      </Box>
    </Container>
  );
};

export default Step3Review;
