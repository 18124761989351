/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Card, Table, TableBody, TableContainer } from '@mui/material';
import { SubBuildingDocumentsFileFragment } from '@predium/client-graphql';
import isNil from 'lodash/isNil';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DelayedLoading } from '../../../../components/Loading';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import { TableSearchNotFound } from '../../../../components/table';
import TableHeadCustom, { HeadCell } from '../../../../components/table/TableHeadCustom';
import TablePaginationStandard from '../../../../components/table/TablePaginationStandard';
import { GET_BUILDING_FILES } from '../../../../graphql/DataCollection.queries';
import useTable, { applySortFilter } from '../../../../hooks/useTable';
import useBuilding from '../Context/useBuilding';
import DocumentsRow from './DocumentsRow';
import DocumentsToolbar from './DocumentsToolbar';

export enum DataType {
  ALL = 'ALL',
  ENERGY_CERTIFICATE = 'ENERGY_CERTIFICATE',
  INVOICE = 'INVOICE',
  OTHER = 'OTHER',
}

export const getAvailableDataTypes = (t: (key: string) => string) => [
  { key: DataType.ALL, label: t('General_AllDocuments') },
  { key: DataType.ENERGY_CERTIFICATE, label: t('General_EnergyCertificate') },
  { key: DataType.INVOICE, label: t('General_Invoice_one') },
  { key: DataType.OTHER, label: t('General_Other') },
];

export type EnrichedFileType = SubBuildingDocumentsFileFragment & {
  type: DataType;
};

function Documents() {
  const { building, hasEditAccess } = useBuilding();
  const buildingId = building.id;

  const { t } = useTranslation();

  const TABLE_HEAD: HeadCell[] = [
    { id: 'name', label: t('General_Name') },
    { id: 'type', label: t('General_Type') },
    { id: 'created_at', label: t('General_UploadedAt'), sortingDisabled: true },
    { id: 'actions', label: '' },
  ];

  const { page, order, orderBy, rowsPerPage, setPage, onSort, setRowsPerPage } = useTable({
    defaultOrderBy: 'id',
    defaultOrder: 'desc',
    defaultRowsPerPage: 25,
  });

  const [filterName, setFilterName] = useState('');
  const [selectedDataType, setSelectedDataType] = useState<DataType>(DataType.ALL);

  const { data, previousData, refetch } = useQuery(GET_BUILDING_FILES, {
    variables: {
      buildingId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const files = useMemo(() => {
    const building = data?.building_by_pk ?? previousData?.building_by_pk;
    if (!building) {
      return [];
    }

    const files: EnrichedFileType[] = [];
    const subBuilding = building.sub_buildings[0]!;

    files.push(
      ...subBuilding.energy_certificates
        .map((certificate) => certificate.file)
        .filter((file) => !isNil(file))
        .map((file) => ({ ...file, type: DataType.ENERGY_CERTIFICATE })),
    );

    files.push(
      ...subBuilding.consumptions
        .map((consumption) => consumption.consumption_invoice?.file)
        .filter((file) => !isNil(file))
        .map((file) => ({ ...file, type: DataType.INVOICE })),
    );

    if (hasEditAccess) {
      files.push(
        ...subBuilding.consumption_drafts
          .map((draft) => draft.consumption_invoice_draft?.file)
          .filter((file) => !isNil(file))
          .map((file) => ({ ...file, type: DataType.INVOICE })),
      );
    }

    files.push(
      ...building.building_documents.map((document) => ({
        ...document.file,
        type: DataType.OTHER,
      })),
    );

    return files;
  }, [data, previousData, hasEditAccess]);

  const filteredDocuments = applySortFilter({
    data: files,
    nameFilter: {
      fieldName: 'name',
      filterValue: filterName,
    },
    orderOptions: {
      order,
      orderBy,
    },
    dataFilters: selectedDataType === DataType.ALL ? undefined : [(file) => file.type === selectedDataType],
  });

  const hasDocuments = filteredDocuments.length > 0;

  const cursorFrom = page * rowsPerPage;
  const cursorTo = cursorFrom + rowsPerPage;

  if (!data) {
    return <DelayedLoading />;
  }

  return (
    <Card sx={{ pt: 3 }}>
      <DocumentsToolbar
        filterName={filterName}
        handleFilterNameChange={setFilterName}
        selectedDataType={selectedDataType}
        handleDataTypeChange={setSelectedDataType}
        onUploadSuccess={refetch}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHeadCustom order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onSort={onSort} />
            <TableBody>
              {hasDocuments ? (
                filteredDocuments.slice(cursorFrom, cursorTo).map((file) => <DocumentsRow key={file.id} file={file} />)
              ) : filterName !== '' ? (
                <SearchNotFound searchQuery={filterName} />
              ) : (
                <TableSearchNotFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePaginationStandard
        count={filteredDocuments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        paginationOptions={[25, 50, 100, 200, 500]}
      />
    </Card>
  );
}

export default Documents;
