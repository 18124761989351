import { useLazyQuery, useQuery } from '@apollo/client';
import { IconButton, Stack, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import {
  ActionPlanningActionPlanActionPlanFragment,
  BuildingModelFragment,
  RecommendedActionImpactOutputFragment,
  energy_consumer_technology_type_enum,
  energy_source_type_enum,
  energy_system_type_enum,
  envelope_type_enum,
  insulation_material_category_enum,
  insulation_method_enum,
  recommended_action_sorting_enum,
  renovation_type_enum,
} from '@predium/client-graphql';
import { TFunction } from 'i18next';
import last from 'lodash/last';
import omit from 'lodash/omit';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';
import MenuPopover from '../../../../components/MenuPopover';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import {
  GET_ACTIONS_FOR_RECOMMENDED_ACTIONS,
  GET_RECOMMENDED_ACTIONS_FOR_BUILDING_MODEL,
} from '../../../../graphql/ActionPlanning.queries';
import useWindowSize from '../../../../hooks/useWindowSize';
import RecommendedAction from './RecommendedActions/RecommendedAction';
import RecommendedActionSkeletons from './RecommendedActions/RecommendedActionSkeletons';
import RecommendedActionsFilters from './RecommendedActions/RecommendedActionsFilters';

export type ActionType = 'all' | 'envelope' | 'system' | 'non-invasive';

const buttonLabels = (t: TFunction<'translation', undefined>) => {
  return [
    {
      id: 'all',
      name: t('General_All'),
    },
    {
      id: 'envelope',
      name: t('General_Envelope'),
    },
    {
      id: 'system',
      name: t('General_System'),
    },
    {
      id: 'non-invasive',
      name: t('General_NonInvasive'),
    },
  ];
};

export type SortingId = recommended_action_sorting_enum;

export type SortCriteria = {
  name: string;
  id: SortingId;
};

export const filters = (t: TFunction<'translation', undefined>): SortCriteria[] => {
  return [
    {
      name: t('ActionPlanning_ActionRecommendations-MaximumCO2Reduction'), // 'Maximum CO₂ Reduction'
      id: recommended_action_sorting_enum.CO2,
    },
    {
      name: t('ActionPlanning_ActionRecommendations-MaximumFinalEnergyReduction'), // 'Maximum Final Energy Reduction'
      id: recommended_action_sorting_enum.FINAL_ENERGY,
    },
    {
      name: t('ActionPlanning_ActionRecommendations-MaximumPrimaryEnergyReduction'), // 'Maximum primary energy reduction'
      id: recommended_action_sorting_enum.PRIMARY_ENERGY,
    },
    {
      name: t('ActionPlanning_ActionRecommendations-CostEffectiveCO2Reduction'), // 'Cost-Effective CO2e-Reduction'
      id: recommended_action_sorting_enum.COST_CO2,
    },
    {
      name: t('ActionPlanning_ActionRecommendations-CostEffectiveFinalEnergyReduction'), // 'Cost-Effective Final Energy Reduction'
      id: recommended_action_sorting_enum.COST_FINAL_ENERGY,
    },
    {
      name: t('ActionPlanning_ActionRecommendations-CostEffectivePrimaryEnergyReduction'), // 'Cost-effective primary energy reduction'
      id: recommended_action_sorting_enum.COST_PRIMARY_ENERGY,
    },
  ];
};

// TODO move these somewhere else where they can be reused
export type EnergySystemRouteRenovationParameters = {
  renovation_type: renovation_type_enum.ENERGY_SYSTEM_ROUTE;
  parameters: {
    energy_source_type: energy_source_type_enum;
  };
};

export type SystemRenovationParameters = {
  renovation_type: renovation_type_enum.ENERGY_CONSUMER;
  parameters: {
    consumer_technology_type: energy_consumer_technology_type_enum;
    cost_per_m2?: number | null;
    efficiency: number;
    energy_source_type: energy_source_type_enum;
    system_type: energy_system_type_enum;
    technology_name: string;
  };
};

export type EnvelopeRenovationParameters = {
  renovation_type: renovation_type_enum.ENVELOPE;
  parameters: {
    cost_per_m2?: number | null;
    envelope_type: envelope_type_enum;
    insulation_lambda?: number | null;
    insulation_material_category?: insulation_material_category_enum | null;
    insulation_method?: insulation_method_enum | null;
    insulation_thickness?: number | null;
    material_name: string;
    u_value?: number | null;
  };
};

export type HydraulicBalancingRenovationParameters = {
  renovation_type: renovation_type_enum.HYDRAULIC_BALANCING;
  parameters: {
    costs_with_components?: boolean | null;
    last_hydraulic_balancing: DateTime;
  };
};

export type SolarRenovationParameters = {
  renovation_type: renovation_type_enum.SOLAR_PLANT;
  parameters: {
    module_angle: number;
    module_peak_power: number;
    roof_area_ratio: number;
    solar_active_area: number;
    technology_name: string;
    cost_per_m2: number;
  };
};

export type ActionParameters =
  | EnergySystemRouteRenovationParameters
  | EnvelopeRenovationParameters
  | SystemRenovationParameters
  | HydraulicBalancingRenovationParameters
  | SolarRenovationParameters;

export type ActionWithParameters = ActionParameters & {
  id: number;
  action_type: ActionType;
};

export type RecommendationOutput = ActionWithParameters & {
  actionImpact: RecommendedActionImpactOutputFragment;
};

type Props = {
  baseBuildingModel: BuildingModelFragment;
  actionPlan: ActionPlanningActionPlanActionPlanFragment;
  totalArea: number;
  actionPlanEmpty: boolean;
};

const RecommendedActions = ({ baseBuildingModel, actionPlan, totalArea, actionPlanEmpty }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { width } = useWindowSize();

  const [expanded, setExpanded] = useState(true);
  const [selectedSorting, setSelectedSorting] = useState<SortingId>(recommended_action_sorting_enum.COST_CO2);
  const [selectedButton, setSelectedButton] = useState<ActionType>('all');
  const [hiddenActions, setHiddenActions] = useState<number[]>([]);
  const [stickyFilters, setStickyFilters] = useState(true);

  const [newDate, setNewDate] = useState(new Date());
  const [minDate, setMinDate] = useState(new Date());

  const [infoAnchorEl, setInfoAnchorEl] = useState<HTMLElement | null>(null);

  useQuery(GET_ACTIONS_FOR_RECOMMENDED_ACTIONS, {
    variables: { actionPlanId: actionPlan.id },
    onCompleted: (data) => {
      const lastAction = last(data.action);
      if (lastAction) {
        const lastActionDate = new Date(lastAction.implementation_to);
        setMinDate(lastActionDate);
        const tempDate = new Date(lastActionDate.setFullYear(lastActionDate.getFullYear() + 1));
        setNewDate(tempDate);
      }
    },
  });

  const [getRecommendedActions, { data: actionsData, loading: actionsLoading }] = useLazyQuery(
    GET_RECOMMENDED_ACTIONS_FOR_BUILDING_MODEL,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const data = actionsData?.recommendActions;

  const fetchData = () => {
    getRecommendedActions({
      variables: { implementation_date: newDate, action_plan_id: actionPlan.id, sorting: selectedSorting },
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDate, selectedSorting]);

  let uniqueIdCounter = 0;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const generateUniqueId = () => {
    uniqueIdCounter += 1;
    return uniqueIdCounter;
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setStickyFilters(false);
      } else {
        setStickyFilters(true);
      }
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array since we want this to run once on mount

  const updateActionType = useCallback(
    (actions: NonNullable<typeof data>['recommendedActions']): RecommendationOutput[] => {
      return actions.map((action): RecommendationOutput => {
        switch (action.parameters.__typename) {
          case 'EnergySystemRouteRenovationParameterOutput':
            return {
              actionImpact: action.actionImpact,
              parameters: omit(action.parameters, '__typename'), // Even though this is redundant, it seems to make the type-checker happy
              renovation_type: renovation_type_enum.ENERGY_SYSTEM_ROUTE,
              action_type: 'non-invasive',
              id: generateUniqueId(),
            };
          case 'EnvelopeRenovationParameterOutput':
            return {
              actionImpact: action.actionImpact,
              parameters: omit(action.parameters, '__typename'),
              renovation_type: renovation_type_enum.ENVELOPE,
              action_type: 'envelope',
              id: generateUniqueId(),
            };
          case 'HydraulicBalancingRenovationParameterOutput':
            return {
              actionImpact: action.actionImpact,
              parameters: omit(action.parameters, '__typename'),
              renovation_type: renovation_type_enum.HYDRAULIC_BALANCING,
              action_type: 'non-invasive',
              id: generateUniqueId(),
            };
          case 'SolarRenovationParameterOutput':
            return {
              actionImpact: action.actionImpact,
              parameters: {
                ...omit(action.parameters, '__typename', 'cost_per_m2_solar'),
                cost_per_m2: action.parameters.cost_per_m2_solar,
              },
              renovation_type: renovation_type_enum.SOLAR_PLANT,
              action_type: 'system',
              id: generateUniqueId(),
            };
          case 'SystemRenovationParameterOutput':
            return {
              actionImpact: action.actionImpact,
              parameters: omit(action.parameters, '__typename'),
              renovation_type: renovation_type_enum.ENERGY_CONSUMER,
              action_type: 'system',
              id: generateUniqueId(),
            };
          default:
            throw new Error('Unreachable');
        }
      });
    },
    [generateUniqueId],
  );

  //This is Temporary, might pushed to backend
  const allActions = useMemo(() => {
    return data ? updateActionType(data.recommendedActions) : [];
  }, [data, updateActionType]);

  const filteredRecommendations = allActions;

  const selectedRecommendations = filteredRecommendations.filter((action) => {
    return selectedButton === 'all' ? true : action.action_type === selectedButton;
  });

  const filteredActions = selectedRecommendations.filter((action) => !hiddenActions.includes(action.id as number));

  const hideAction = (actionId: number) => {
    setHiddenActions((prev) => [...prev, actionId]);
    enqueueSnackbar(t('ActionPlanning_RecommendedActions-RecommendationHiddenMessage'), {
      variant: 'success',
      autoHideDuration: SnackbarTimeouts.Success,
    });
  };

  const handleInfoClick = (event: React.MouseEvent<HTMLElement>) => {
    setInfoAnchorEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setInfoAnchorEl(null);
  };

  //if the recommended acitons header width gets below 1000, disable sticky
  useEffect(() => {
    if (width < 1200) {
      setStickyFilters(false);
    } else {
      setStickyFilters(true);
    }
  }, [width]);

  return (
    <>
      <TableRow
        sx={{
          '& .MuiTableCell-root': {
            backgroundColor: theme.palette.grey[100],
            borderRadius: '16px 16px 0 0',
            borderBottom: 'none',
            position: 'sticky',
          },
        }}
      >
        <TableCell colSpan={10}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              position: 'relative',
              width: '100%',
              minWidth: '100%',
            }}
            id="recommended-actions-header"
          >
            {/* Left side */}
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              sx={{
                position: 'sticky',
                left: 16,
                backgroundColor: theme.palette.grey[100],
                zIndex: 2,
                pr: 2,
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  height: '100%',
                  width: '8px',
                  background: `linear-gradient(to right, ${theme.palette.grey[100]}, transparent)`,
                },
              }}
            >
              <Iconify
                icon={ICONS.RECOMMENDED_ACTIONS}
                width={32}
                height={32}
                sx={{ mr: 1 }}
                color={theme.palette.info.main}
              />
              <Stack direction="row" alignItems="center" gap={0.5}>
                <Typography variant="subtitle1" whiteSpace="nowrap">
                  {actionPlanEmpty
                    ? t('General_RecommendedAction', { count: 2 })
                    : t('General_RecommendedNextAction', { count: 2 })}
                </Typography>

                <IconButton onClick={handleInfoClick} sx={{ p: 0.5 }}>
                  <Iconify icon={ICONS.INFO} width={16} height={16} color="text.secondary" />
                </IconButton>
              </Stack>

              <MenuPopover
                open={infoAnchorEl}
                anchorEl={infoAnchorEl}
                onClose={handleInfoClose}
                sx={{ width: 320, p: 2 }}
                arrow="top-center"
              >
                <Typography variant="h6" gutterBottom>
                  {t('General_ActionRecommendation', { count: 2 })}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', textWrap: 'balance' }}>
                  {t('ActionPlanning_ActionRecommendations-InfoDescription')}
                </Typography>
              </MenuPopover>
            </Stack>

            {/* Right side  */}
            <RecommendedActionsFilters
              actionsLoading={actionsLoading}
              stickyFilters={stickyFilters}
              selectedSorting={selectedSorting}
              setSelectedSorting={setSelectedSorting}
              selectedButton={selectedButton}
              setSelectedButton={setSelectedButton}
              newDate={newDate}
              setNewDate={setNewDate}
              minDate={minDate}
              setExpanded={setExpanded}
              expanded={expanded}
              buttonLabels={buttonLabels}
            />
          </Stack>
        </TableCell>
      </TableRow>
      {expanded && (
        <>
          {actionsLoading ? (
            <>
              <RecommendedActionSkeletons />
            </>
          ) : filteredActions && filteredActions.length > 0 ? (
            filteredActions
              .slice(0, 3)
              .map((action) => (
                <RecommendedAction
                  key={action.id}
                  action={action}
                  baseBuildingModel={baseBuildingModel}
                  actionPlan={actionPlan}
                  defaultImplementationDate={newDate}
                  totalArea={totalArea}
                  hideAction={hideAction}
                />
              ))
          ) : (
            <TableRow>
              <TableCell colSpan={10}>
                <Stack
                  sx={{
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    color: 'text.secondary',
                    py: 4,
                  }}
                >
                  <Typography variant="h6" color={'text.secondary'}>
                    {t('ActionPlanning_ActionRecommendations-NoRecommendations')}
                  </Typography>
                  <Typography variant="body2" color={'text.secondary'}>
                    {t('ActionPlanning_ActionRecommendations-NoRecommendationsDescription')}
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    </>
  );
};

export default RecommendedActions;
