/* eslint-disable no-restricted-imports */
import { useQuery } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { tax_bracket_enum } from '@predium/enums';
import map from 'lodash/map';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import { DelayedLoading } from '../../../components/Loading';
import MenuPopover from '../../../components/MenuPopover';
import Scrollbar from '../../../components/Scrollbar';
import { GET_BUILDING_FILTER_VALUES } from '../../../graphql/EsgAnalysis.queries';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import { CurrentPageView } from '../Portfolio/PortfolioAnalysisHeader';
import { useEsgAnalysisPopupFilters } from './EsgAnalysisFilterPopupContext';
import { EsgAnalysisFiltersKeys, esgAnalysisFilters } from './EsgAnalysisFilters';
import EsgRangeFilter from './EsgRangeFilter';
import { calculateFilterCount, excludeKeys } from './FilterButton';
import FilterOptionsAsCheckboxes from './FiltersComponents/FilterOptionsAsCheckboxes';
import FilterOptionsAsChips from './FiltersComponents/FilterOptionsAsChips';

type Props = {
  onClose: VoidFunction;
  anchorEl: HTMLElement | null;
  setAnchorEl: (value: HTMLElement | null) => void;
  currentPageView?: CurrentPageView;
};

export default function EsgAnalysisFiltersSidebar({ onClose, anchorEl, setAnchorEl, currentPageView }: Props) {
  const { t } = useTranslation();
  const { resetFilters, filters, setFilters, isError } = useEsgAnalysisPopupFilters();
  const {
    isFilterDataLoading,
    filters: globalFilters,
    setFilters: setGlobalFilters,
    resetFilters: resetGlobalFilters,
  } = useEsgAnalysisFilters();

  const currentYear = new Date().getFullYear();

  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);

  const { loading: filtersListLoading, data: filtersList } = useQuery(GET_BUILDING_FILTER_VALUES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      currentYear,
    },
  });

  useEffect(() => {
    setIsFilterChanged(JSON.stringify(filters) !== JSON.stringify(globalFilters));
  }, [filters, globalFilters]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    onClose();
  }, [onClose, setAnchorEl]);

  const handleApplyFilters = useCallback(async () => {
    setGlobalFilters(filters);
    handleClose();
    setIsFilterChanged(false); // Reset the filter changed state after applying filters
  }, [setGlobalFilters, filters, handleClose]);

  /**
   * The options are coming from the database, so we need to map them to the correct keys.
   * We query the database to only show the filters that are available for the current data set.
   */
  const TaxBracketOrder = [
    tax_bracket_enum._10_90,
    tax_bracket_enum._20_80,
    tax_bracket_enum._30_70,
    tax_bracket_enum._40_60,
    tax_bracket_enum._50_50,
    tax_bracket_enum._60_40,
    tax_bracket_enum._70_30,
    tax_bracket_enum._80_20,
    tax_bracket_enum._90_10,
    tax_bracket_enum._100_0,
  ];

  //@ts-ignore
  const options: typeof filters = useMemo(
    () => ({
      subBuildingClasses: filtersList ? map(filtersList.sub_building_class, 'class_of_use_id') : [],
      energySourceTypes: filtersList ? map(filtersList.energy_system_consumer_route, 'energy_source_type_id') : [],
      efficiencyClasses: filtersList ? map(filtersList.energy_path_efficiency_class, 'efficiency_class_id') : [],
      taxBrackets: filtersList
        ? map(filtersList.energy_path_tax_bracket, 'tax_bracket_id').sort(
            (a, b) => TaxBracketOrder.indexOf(a as tax_bracket_enum) - TaxBracketOrder.indexOf(b as tax_bracket_enum),
          )
        : [],
      euTaxonomyCompliances: filtersList
        ? map(filtersList.energy_path_eu_taxonomy_compliance, 'eu_taxonomy_compliance_id')
        : [],
      buildingStates: filtersList ? map(filtersList.building_state_id, 'building_state_id') : [],
      monumentProtections: filtersList ? map(filtersList.monument_protection, 'monument_protection') : [],
      heritageDistrict: filtersList ? map(filtersList.heritage_district, 'heritage_district') : [],
      milieuProtection: filtersList ? map(filtersList.milieu_protection, 'milieu_protection') : [],
      leasehold: filtersList ? map(filtersList.leasehold, 'leasehold') : [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersList],
  );

  const getOptions = (key: EsgAnalysisFiltersKeys) => {
    const values = options[key];

    if (values) {
      return values;
    }

    return [];
  };

  const handlePopoverClose = useCallback(() => {
    handleClose();
    // Clear the new filters when the popover is closed and apply button is not clicked
    if (!applyClicked && isFilterChanged) {
      setFilters(globalFilters);
    }
  }, [applyClicked, globalFilters, handleClose, isFilterChanged, setFilters]);

  const visibleFilters = esgAnalysisFilters.filter(({ showInSidebar }) => showInSidebar);
  const chipFilters = visibleFilters.filter(({ showAs, showInSidebar }) => showAs === 'chip' && showInSidebar);
  const checkboxFilters = visibleFilters.filter(({ showAs, showInSidebar }) => showAs === 'checkbox' && showInSidebar);

  const filterCount = calculateFilterCount(filters);

  const noFiltersAvailable = visibleFilters.length === 0;

  return (
    <>
      <MenuPopover
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        sx={{ width: 400, p: 0, mt: 0.5, ml: 0 }}
        arrow="top-left"
        disabledArrow
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ pl: 3, pr: 2, pt: 2 }}>
          <Typography variant="h6">{t('General_Filter', { count: 2 })}</Typography>
          <IconButton onClick={handleClose}>
            <Iconify icon={'eva:close-fill'} width={20} height={20} />
          </IconButton>
        </Stack>

        {filtersListLoading ? (
          <Stack height={'20vh'}>
            <DelayedLoading delay={0} />
          </Stack>
        ) : (
          <Scrollbar
            sx={{
              maxHeight: 500,
            }}
          >
            {noFiltersAvailable && (
              <Typography color="text.secondary" sx={{ p: 3, textAlign: 'center' }}>
                No filters available
              </Typography>
            )}
            {chipFilters.map(({ key, title }) => (
              <FilterOptionsAsChips
                key={key}
                title={title}
                type={key}
                options={getOptions(key)}
                selectedOptions={filters[key] ?? []}
              />
            ))}
            {checkboxFilters.map(({ key, title }) => (
              <FilterOptionsAsCheckboxes
                key={key}
                title={title}
                type={key}
                options={getOptions(key)}
                selectedOptions={filters[key] ?? []}
              />
            ))}

            <EsgRangeFilter
              minKey={EsgAnalysisFiltersKeys.minCo2Emissions}
              maxKey={EsgAnalysisFiltersKeys.maxCo2Emissions}
              title="General_CO2Intensity"
              unit="kg CO₂e/m²a"
              minKeyFilters={filters.minCo2Emissions}
              maxKeyFilters={filters.maxCo2Emissions}
            />
            <EsgRangeFilter
              minKey={EsgAnalysisFiltersKeys.minPrimaryEnergy}
              maxKey={EsgAnalysisFiltersKeys.maxPrimaryEnergy}
              title={t('General_PrimaryEnergy')}
              unit="kWh/m²a"
              maxKeyFilters={filters.maxPrimaryEnergy}
              minKeyFilters={filters.minPrimaryEnergy}
            />
            <EsgRangeFilter
              minKey={EsgAnalysisFiltersKeys.minFinalEnergy}
              maxKey={EsgAnalysisFiltersKeys.maxFinalEnergy}
              title={t('General_FinalEnergy')}
              unit="kWh/m²a"
              minKeyFilters={filters.minFinalEnergy}
              maxKeyFilters={filters.maxFinalEnergy}
            />

            {(currentPageView === 'esg-portfolio' || currentPageView === 'esg-economics-units') && (
              <Stack mb={4}>
                <EsgRangeFilter
                  minKey={EsgAnalysisFiltersKeys.minStrandingYear}
                  maxKey={EsgAnalysisFiltersKeys.maxStrandingYear}
                  title={t('General_StrandingYear')}
                  minKeyFilters={filters.minStrandingYear}
                  maxKeyFilters={filters.maxStrandingYear}
                  filterType="StrandingYears"
                />
              </Stack>
            )}
          </Scrollbar>
        )}

        {!noFiltersAvailable && (
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ py: 2, px: 3 }}>
            <Button
              variant="text"
              onClick={() => {
                resetFilters(
                  Object.keys(globalFilters).filter(
                    (key) => !excludeKeys.includes(key as EsgAnalysisFiltersKeys),
                  ) as EsgAnalysisFiltersKeys[],
                );
                resetGlobalFilters(
                  Object.keys(globalFilters).filter(
                    (key) => !excludeKeys.includes(key as EsgAnalysisFiltersKeys),
                  ) as EsgAnalysisFiltersKeys[],
                );
              }}
              disabled={filterCount === 0}
              sx={{ width: 'min-content', whiteSpace: 'nowrap' }}
            >
              {t('General_Clear')}
            </Button>

            <LoadingButton
              variant="contained"
              onClick={() => {
                setApplyClicked(true);
                handleApplyFilters();
                calculateFilterCount(filters);
              }}
              sx={{ width: 'min-content', whiteSpace: 'nowrap' }}
              loading={isFilterDataLoading}
              disabled={isFilterDataLoading || isError || !isFilterChanged}
            >
              {t('General_Apply')}
            </LoadingButton>
          </Stack>
        )}
      </MenuPopover>
    </>
  );
}
